import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { LocationDescriptor } from "history";

export interface RenderRouterHashModalProps {
  open: boolean;
  onClose(event: {}, reason?: "backdropClick" | "escapeKeyDown"): void;
}
export interface RouterHashModalProps {
  hash: string;
  previousLocation: LocationDescriptor;
  excludeFromHistory?: boolean;
  renderModal(props: RenderRouterHashModalProps): React.ReactNode;
}

function RouterHashModal(props: RouterHashModalProps) {
  const { hash, previousLocation, excludeFromHistory, renderModal } = props;

  const history = useHistory();

  const [open, setOpen] = useState(() => history.location.hash === hash);

  useEffect(() => {
    const listener = history.listen((route) => {
      // We need to listen for back button press to close calendar test list
      if (route.hash !== hash && open) {
        setOpen(false);
      } else if (route.hash === hash && !open) {
        setOpen(true);
      }
    });

    return listener;
  }, [hash, history, open]);

  const handleClose = useCallback(
    (event: {}, reason?: "backdropClick" | "escapeKeyDown") => {
      if (
        event != null &&
        (event as React.KeyboardEvent | React.MouseEvent).type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(false);

      if (history.location.hash === hash) {
        // This will trigger our history listener to close the dialog
        if (excludeFromHistory) {
          // We're not including this hash route in the history
          history.replace(previousLocation);
        } else {
          history.push(previousLocation);
        }
      }
    },
    [hash, history, previousLocation, excludeFromHistory]
  );

  return <>{renderModal({ open, onClose: handleClose })}</>;
}

export default RouterHashModal;

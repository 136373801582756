import { createContext, useContext, useMemo, Dispatch, SetStateAction, PropsWithChildren } from "react";
import usePersistedState from "../hooks/persisted-state.hook";
import errorMessages from "../translations/en/errors.json";
import { darkTheme, lightTheme } from "../theme";
import { Theme, ThemeProvider } from "@material-ui/core/styles";

const prefersDark = window ? window?.matchMedia("(prefers-color-scheme: dark)").matches || false : false;

export type ThemeType = "default" | "dark";

const defaultThemeType = prefersDark ? "dark" : "default";

export interface ThemeContext {
  themeType: ThemeType;
  setThemeType: Dispatch<SetStateAction<ThemeType>>;
  theme: Theme;
}

const Context = createContext<ThemeContext>(null!);

function ThemeContextProvider(props: PropsWithChildren<{}>): JSX.Element {
  const [themeType, setThemeType] = usePersistedState<ThemeType>(`context/theme`, {
    storageType: "local",
    defaultValue: defaultThemeType,
  });

  // Theme is a side effect of the string theme above.
  const theme: Theme = useMemo(() => {
    return themeType === "dark" ? darkTheme : lightTheme;
  }, [themeType]);

  const contextValue: ThemeContext = {
    themeType,
    setThemeType,
    theme,
  };

  return (
    <Context.Provider value={{ ...contextValue }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </Context.Provider>
  );
}

function useThemeContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { ThemeContextProvider, useThemeContext };

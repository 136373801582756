import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { LocationCity } from "@material-ui/icons";

export interface FacilityIconProps extends SvgIconProps {}

function FacilityIcon(props: FacilityIconProps) {
  const { ...rest } = props;

  const iconProps: SvgIconProps = {
    ...rest,
  };

  return <LocationCity {...iconProps} />;
}

export default FacilityIcon;

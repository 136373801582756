import Button from "../Button/Button";
import ImageWithText, { ImageWithTextProps } from "../ImageWithText/ImageWithText";
import ErrorScreen from "../Svg/ErrorScreen";

export interface RetryWidgetProps extends ImageWithTextProps {
  onRetry?: () => void;
}

function RetryWidget(props: RetryWidgetProps) {
  const { onRetry, ...rest } = props;

  const baseProps: ImageWithTextProps = {
    align: "center",
    title: "Something went wrong",
    subheader: `There may be connectivity issues. These things happen... please try again. If the issue persists, we'll see it in our logs and we will fix it.`,
    image: <ErrorScreen size="80px" color="error" />,
    action: (
      <Button variant="outlined" onClick={onRetry}>
        Retry
      </Button>
    ),
    ...rest,
  };

  return <ImageWithText {...baseProps} />;
}

export default RetryWidget;

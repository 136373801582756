import { useState, useEffect, useCallback, SetStateAction, useMemo, useRef } from "react";
import { EventEmitter } from "events";
import lzstring from "lz-string";

const isCallable = <T>(value: SetStateAction<T>): value is () => T => typeof value === "function";

export interface PersistedStateOptions<T> {
  defaultValue?: T;
  clearStateHandler?: { emitter: EventEmitter; event: string };
  /**
   * Local is semi-permanant, session is temporary.
   */
  storageType?: "session" | "local";
  encodeData?: boolean;
  priority?: number;
}

function usePersistedState<T>(key: string, options?: PersistedStateOptions<T>): [T, React.Dispatch<React.SetStateAction<T>>] {
  const defaultOptions: Partial<PersistedStateOptions<T>> = {
    storageType: "session",
    encodeData: false,
  };

  const _options: PersistedStateOptions<T> = Object.assign(defaultOptions, options);

  const { clearStateHandler, defaultValue, encodeData, storageType } = _options;

  // Storage is required if this hook is used so type it as such
  const storage: Storage = storageType === "session" ? window.sessionStorage : window.localStorage;

  const _defaultValue = useMemo(() => (defaultValue == null ? undefined : isCallable(defaultValue) ? defaultValue() : (defaultValue as T)), [defaultValue]);
  const _defaultValueString = useMemo(() => JSON.stringify(_defaultValue), [_defaultValue]);

  const [value, setValue] = useState<T>(() => {
    const persistedVal = storage.getItem(key);

    try {
      return persistedVal == null ? _defaultValue : encodeData ? JSON.parse(lzstring.decompressFromUTF16(persistedVal)!) : JSON.parse(persistedVal);
    } catch {
      // Ignore encoding as toggling that could cause an error.
      // TODO: Make this better, encoding is poorly implemented right now.
      return persistedVal == null ? _defaultValue : JSON.parse(persistedVal);
    }
  });

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Peresist state to storage.
    const _newValueString = JSON.stringify(value);

    if (_newValueString !== _defaultValueString && value != null) {
      try {
        storage.setItem(key, encodeData ? lzstring.compressToUTF16(_newValueString) : _newValueString);
      } catch {
        // Ignore
        console.warn(`Cannot set storage for ${key}.`);
      }
    } else {
      storage.removeItem(key);
    }
  }, [key, encodeData, storage, value, defaultValue, _defaultValueString]);

  const clearStateListener = useCallback(() => {
    // Clear storage first as the component using this hook could be unmounted.
    // This would also happen in useEffect.
    storage.removeItem(key);

    if (!mounted.current) {
      return;
    }

    setValue(_defaultValue as T);
  }, [_defaultValue, key, storage]);

  useEffect(() => {
    if (!key || !clearStateHandler) {
      return;
    }

    clearStateHandler.emitter.removeListener(clearStateHandler.event, clearStateListener).once(clearStateHandler.event, clearStateListener);
    // We do not want to unsubscribe on unmount as the state would remain in storage.
  }, [clearStateHandler, clearStateListener, key]);

  return [value, setValue];
}

export default usePersistedState;

export enum SearchbarSearchType {
  Org = "org",
  Facility = "facility",
  Device = "device",
}

export type SearchbarSearchQuerySearch = {
  query?: string;
  page?: number;
  type?: SearchbarSearchType;
  facilityId?: number;
  deviceId?: number;
  hideInactiveMachines?: boolean;
};

export type SearchbarSearchResult = {
  id: number;
  name: string;
  label: string;
  type: SearchbarSearchType;
  tags: string[];
  disabled?: boolean;
  parent?: SearchbarSearchResult;
};

import React from "react";
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, PropTypes } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

const ExtendedEdgeProps = [
  "left",
  "right",
  "top",
  "bottom",
  "top-left",
  "top-right",
  "bottom-left",
  "bottom-right",
  "top-bottom",
  "left-right",
  "all",
] as const;

type ExtendedEdgeProp = typeof ExtendedEdgeProps[number];
type IconButtonVariant = "text" | "outlined";
export interface IconButtonProps extends Pick<MuiIconButtonProps, Exclude<keyof MuiIconButtonProps, "edge" | "color">> {
  edge?: ExtendedEdgeProp;
  variant?: IconButtonVariant;
  color?: PropTypes.Color | string;
}

type IconButtonStyleProps = {
  edge?: string | number;
  variant?: IconButtonVariant;
  disabled?: boolean;
  color?: string;
};

//(theme.palette as any)[props.textColor as any]?.main || props.textColor || theme.palette.text.primary
const useStyles = makeStyles((theme) => ({
  root: (props: IconButtonStyleProps) => ({
    padding: theme.spacing(1),
    margin: props.edge || undefined,

    border: props.variant === "outlined" ? "1px solid" : undefined,
    borderColor:
      props.variant !== "outlined"
        ? undefined
        : props.disabled
        ? theme.palette.divider
        : !props.color || props.color === "default"
        ? theme.palette.action.disabled
        : props.color === "primary"
        ? theme.palette.primary.main
        : props.color === "secondary"
        ? theme.palette.secondary.main
        : props.color,
    color:
      props.disabled || !props.color || props.color === "default"
        ? undefined
        : props.color === "primary"
        ? theme.palette.primary.main
        : props.color === "secondary"
        ? theme.palette.secondary.main
        : props.color,
  }),
}));

const defaultProps = { variant: "text" } as IconButtonProps;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref?) => {
  const { edge, variant, color, ...rest } = { ...defaultProps, ...props };

  const theme = useTheme();

  const edgeMargin = theme.spacing(1) * -1;

  const edgeOffset =
    edge === "all"
      ? edgeMargin
      : props.edge === "top"
      ? `${edgeMargin}px 0 0`
      : props.edge === "top-right"
      ? `${edgeMargin}px ${edgeMargin}px 0 0`
      : props.edge === "bottom-right"
      ? `0 ${edgeMargin}px ${edgeMargin}px 0`
      : props.edge === "bottom"
      ? `0 0 ${edgeMargin}px 0`
      : props.edge === "bottom-left"
      ? `0 0 ${edgeMargin}px ${edgeMargin}px`
      : props.edge === "top-left"
      ? `${edgeMargin}px 0 0 ${edgeMargin}px`
      : props.edge === "top-bottom"
      ? `${edgeMargin}px 0`
      : props.edge === "left-right"
      ? `0 ${edgeMargin}px`
      : props.edge === "left"
      ? `0 0 0 ${edgeMargin}px`
      : props.edge === "right"
      ? `0 ${edgeMargin}px 0 0`
      : 0;

  const classes = useStyles({ color, edge: edgeOffset, variant, disabled: props.disabled });

  const defaultButtonProps: MuiIconButtonProps = {
    // Skip edge as we want to redefine it
    ...rest,
    ref,
  };

  return <MuiIconButton classes={classes} {...defaultButtonProps} />;
});

export default IconButton;

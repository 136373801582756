export const passwordRules = (fieldName = "Password") => ({
  required: {
    value: true,
    message: `${fieldName} is required`,
  },
  minLength: {
    value: 8,
    message: `${fieldName} must be at least 8 characters`,
  },
  validate: {
    hasNumber: (value: string) => (/.*[0-9].*/.test(value) ? true : `${fieldName} must have a number`),
    hasUppercase: (value: string) => (/.*[A-Z].*/.test(value) ? true : `${fieldName} must have a uppercase letter`),
    hasLowercase: (value: string) => (/.*[a-z].*/.test(value) ? true : `${fieldName} must have a lowercase letter`),
  },
});

export const phoneRules = (fieldName = "Phone") => ({
  pattern: {
    value: /^\d+$/,
    message: `${fieldName} must be a number`,
  },
});

export const emailRules = () => ({
  required: {
    value: true,
    message: `Email is required`,
  },
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: `Email is invalid`,
  },
});

export const nameRules = () => ({
  required: {
    value: true,
    message: `Name is required`,
  },
  pattern: {
    value: /^[A-Za-z]+\s[A-Za-z]+$/,
    message: `Last name is required`,
  },
});

import React from "react";
import { SvgProps } from "./svg.model";
import useThemePalette from "../../../hooks/theme-palette.hook";

function Calendar(props: SvgProps) {
  const color = props.color || "secondary";
  const size = props.size || "100%";

  const [mainColor, lightColor, darkColor] = useThemePalette({ color });
  const gradientId = `_linearGradient_Calendar_Svg`;

  return (
    <svg width={size} height={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id={gradientId} gradientUnits="userSpaceOnUse" x1="63.731" x2="463.845" y1="377.304" y2="146.298">
        <stop offset="0" stopColor={lightColor} />
        <stop offset="1" stopColor={lightColor} />
      </linearGradient>
      <g>
        <path
          d="m505.626 291.921c-11.826-34.61-38.138-60.16-69.852-72.202-16.43-6.239-27.346-21.856-27.587-39.429-.005-.384-.012-.769-.021-1.154-1.507-64.582-55.488-116.33-120.077-115.221-38.094.654-71.788 19.355-92.905 47.92-18.185 24.599-46.49 39.965-77.08 39.949-.898 0-1.797.009-2.7.029-61.917 1.354-114.567 54.478-115.393 116.404-.88 65.931 52.302 119.654 118.032 119.654 50.947 0 94.349-32.277 110.886-77.497 4.327-11.832 17.152-18.174 29.283-14.772.151.042.303.085.455.127 11.099 3.064 18.458 13.64 17.55 25.119-1.217 15.383.581 31.255 5.798 46.796 20.521 61.135 87.188 94.685 148.493 74.68 62.711-20.465 96.379-88.185 75.118-150.403z"
          fill={`url(#${gradientId})`}
        />
        <path
          d="m414.506 131.933h-37.006v-12c0-5.523-4.477-10-10-10h-12c-5.523 0-10 4.477-10 10v12h-46v-12c0-5.523-4.477-10-10-10h-12c-5.523 0-10 4.477-10 10v12h-46v-12c0-5.523-4.477-10-10-10h-12c-5.523 0-10 4.477-10 10v12h-46v-12c0-5.523-4.477-10-10-10h-12c-5.523 0-10 4.477-10 10v12h-37c-11.046 0-20 8.954-20 20v266c0 11.046 8.954 20 20 20h340c11.046 0 20-8.954 20-20v-265.981c0-11.056-8.951-20.019-19.994-20.019z"
          fill="#2b2ab5"
          opacity=".11"
        />
        <g fill="#fff">
          <path d="m432.499 419.933h-339.999c-11.046 0-20-8.954-20-20v-266c0-11.046 8.954-20 20-20h340c11.046 0 20 8.954 20 20v265.999c0 11.046-8.955 20.001-20.001 20.001z" />
          <path d="m390.5 361.933h-254c-2.761 0-5-2.239-5-5v-119c0-2.761 2.239-5 5-5h254c2.761 0 5 2.239 5 5v119c0 2.761-2.238 5-5 5z" />
          <path d="m452.5 178.933v-45c0-11.046-8.954-20-20-20h-340c-11.046 0-20 8.954-20 20v45z" />
          <path d="m151.5 142.933h-12c-5.523 0-10-4.477-10-10v-31c0-5.523 4.477-10 10-10h12c5.523 0 10 4.477 10 10v31c0 5.523-4.477 10-10 10z" />
          <path d="m229.5 142.933h-12c-5.523 0-10-4.477-10-10v-31c0-5.523 4.477-10 10-10h12c5.523 0 10 4.477 10 10v31c0 5.523-4.477 10-10 10z" />
          <path d="m307.447 142.933h-11.947c-5.523 0-10-4.477-10-10v-31c0-5.523 4.477-10 10-10h12c5.523 0 10 4.477 10 10v31.031c-.001 5.506-4.501 9.969-10.053 9.969z" />
          <path d="m385.5 142.933h-12c-5.523 0-10-4.477-10-10v-31c0-5.523 4.477-10 10-10h12c5.523 0 10 4.477 10 10v31c0 5.523-4.477 10-10 10z" />
        </g>
        <path d="m145.5 255.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" fill="#6e80f8" />
        <path d="m211.5 255.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" fill="#6e80f8" />
        <path d="m276.5 255.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" fill="#6e80f8" />
        <path d="m341.5 255.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" fill="#6e80f8" />
        <path
          d="m407.466 255.933h-27.966c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v27.965c0 5.542-4.492 10.035-10.034 10.035z"
          fill="#6e80f8"
        />
        <g>
          <path
            d="m145.5 260.933h-28c-8.271 0-15-6.729-15-15v-28c0-8.271 6.729-15 15-15h28c8.271 0 15 6.729 15 15v28c0 8.271-6.729 15-15 15zm-28-48c-2.757 0-5 2.243-5 5v28c0 2.757 2.243 5 5 5h28c2.757 0 5-2.243 5-5v-28c0-2.757-2.243-5-5-5z"
            fill="#2b2ab5"
          />
        </g>
        <g>
          <path
            d="m211.5 260.933h-28c-8.271 0-15-6.729-15-15v-28c0-8.271 6.729-15 15-15h28c8.271 0 15 6.729 15 15v28c0 8.271-6.729 15-15 15zm-28-48c-2.757 0-5 2.243-5 5v28c0 2.757 2.243 5 5 5h28c2.757 0 5-2.243 5-5v-28c0-2.757-2.243-5-5-5z"
            fill="#2b2ab5"
          />
        </g>
        <g>
          <path
            d="m276.5 260.933h-28c-8.271 0-15-6.729-15-15v-28c0-8.271 6.729-15 15-15h28c8.272 0 15 6.729 15 15v28c0 8.271-6.728 15-15 15zm-28-48c-2.757 0-5 2.243-5 5v28c0 2.757 2.243 5 5 5h28c2.757 0 5-2.243 5-5v-28c0-2.757-2.243-5-5-5z"
            fill="#2b2ab5"
          />
        </g>
        <g>
          <path
            d="m341.5 260.933h-28c-8.272 0-15-6.729-15-15v-28c0-8.271 6.728-15 15-15h28c8.272 0 15 6.729 15 15v28c0 8.271-6.728 15-15 15zm-28-48c-2.757 0-5 2.243-5 5v28c0 2.757 2.243 5 5 5h28c2.757 0 5-2.243 5-5v-28c0-2.757-2.243-5-5-5z"
            fill="#2b2ab5"
          />
        </g>
        <g>
          <path
            d="m407.465 260.933h-27.965c-8.272 0-15-6.729-15-15v-28c0-8.271 6.728-15 15-15h28c8.272 0 15 6.729 15 15v27.965c0 8.29-6.745 15.035-15.035 15.035zm-27.965-48c-2.757 0-5 2.243-5 5v28c0 2.757 2.243 5 5 5h27.965c2.776 0 5.035-2.259 5.035-5.035v-27.965c0-2.757-2.243-5-5-5z"
            fill="#2b2ab5"
          />
        </g>
        <g fill="#6e80f8">
          <path d="m145.5 320.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" />
          <path d="m211.5 320.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" />
          <path d="m276.5 320.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" />
          <path d="m341.5 320.933h-28c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v28c0 5.523-4.477 10-10 10z" />
          <path d="m407.466 320.933h-27.966c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v27.965c0 5.542-4.492 10.035-10.034 10.035z" />
          <path d="m145.466 385.933h-27.966c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v27.965c0 5.541-4.492 10.035-10.034 10.035z" />
          <path d="m211.466 385.933h-27.966c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v27.965c0 5.541-4.492 10.035-10.034 10.035z" />
          <path d="m276.466 385.933h-27.966c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v27.965c0 5.541-4.492 10.035-10.034 10.035z" />
          <path d="m341.466 385.933h-27.966c-5.523 0-10-4.477-10-10v-28c0-5.523 4.477-10 10-10h28c5.523 0 10 4.477 10 10v27.965c0 5.541-4.493 10.035-10.034 10.035z" />
        </g>
        <path
          d="m379.63 495.364c-3.74 0-7.479-1.423-10.326-4.27-5.694-5.694-5.694-14.958 0-20.651 5.694-5.694 14.958-5.694 20.652 0 5.693 5.693 5.693 14.957 0 20.65-2.847 2.847-6.587 4.271-10.326 4.271zm0-19.196c-1.179 0-2.357.449-3.255 1.347-1.794 1.794-1.794 4.714 0 6.509 1.795 1.793 4.715 1.793 6.509 0 1.794-1.795 1.794-4.715 0-6.51-.897-.897-2.076-1.346-3.254-1.346z"
          fill="#6e80f8"
        />
        <path
          d="m209.326 70.991c-3.901 0-7.568-1.52-10.326-4.278-5.693-5.693-5.693-14.957 0-20.65 5.694-5.694 14.958-5.693 20.651-.001 5.694 5.694 5.694 14.958 0 20.651-2.758 2.759-6.425 4.278-10.325 4.278zm0-19.204c-1.179 0-2.357.449-3.254 1.346-1.794 1.795-1.794 4.715 0 6.51.87.869 2.025 1.349 3.254 1.349s2.385-.479 3.255-1.349c1.794-1.795 1.794-4.715 0-6.51-.898-.897-2.077-1.346-3.255-1.346z"
          fill="#6e80f8"
        />
        <path
          d="m258.562 27.933h-3.062v-3.062c0-2.403-1.609-4.612-3.965-5.084-3.214-.644-6.035 1.796-6.035 4.896v3.25h-3.062c-2.404 0-4.614 1.61-5.085 3.968-.642 3.214 1.798 6.032 4.897 6.032h3.25v3.062c0 2.403 1.609 4.612 3.965 5.084 3.214.644 6.035-1.796 6.035-4.896v-3.25h3.25c3.098 0 5.539-2.818 4.897-6.032-.471-2.358-2.681-3.968-5.085-3.968z"
          fill="#6e80f8"
        />
        <path
          d="m341.562 444.933h-3.062v-3.062c0-2.403-1.609-4.612-3.965-5.084-3.214-.644-6.035 1.796-6.035 4.896v3.25h-3.062c-2.404 0-4.613 1.61-5.085 3.968-.642 3.214 1.798 6.032 4.897 6.032h3.25v3.062c0 2.403 1.609 4.612 3.965 5.084 3.214.644 6.035-1.796 6.035-4.896v-3.25h3.25c3.098 0 5.539-2.818 4.897-6.032-.471-2.358-2.681-3.968-5.085-3.968z"
          fill="#6e80f8"
        />
        <path
          d="m122.562 444.933h-3.062v-3.062c0-2.403-1.609-4.612-3.965-5.084-3.214-.644-6.035 1.796-6.035 4.896v3.25h-3.062c-2.404 0-4.614 1.61-5.085 3.968-.642 3.214 1.799 6.032 4.897 6.032h3.25v3.062c0 2.403 1.609 4.612 3.965 5.084 3.214.644 6.035-1.796 6.035-4.896v-3.25h3.25c3.098 0 5.539-2.818 4.897-6.032-.471-2.358-2.681-3.968-5.085-3.968z"
          fill="#6e80f8"
        />
        <path
          d="m432.5 108.933h-32v-7c0-8.284-6.716-15-15-15h-16c-6.075 0-11 4.925-11 11v11h-36v-11c0-6.075-4.925-11-11-11h-20c-6.075 0-11 4.925-11 11v11h-36v-11c0-6.075-4.925-11-11-11h-20c-6.075 0-11 4.925-11 11v11h-36v-11c0-6.075-4.925-11-11-11h-17.8c-7.29 0-13.2 5.91-13.2 13.2v8.8h-35c-12.15 0-22 9.85-22 22v271.777c0 12.273 9.95 22.223 22.223 22.223h73.081c.283 0 .563-.105.767-.301 4.55-4.362 1.531-9.699-2.733-9.699h-68.338c-8.284 0-15-6.716-15-15v-216h370v134.71c0 2.403 1.609 4.612 3.965 5.084 3.214.644 6.035-1.796 6.035-4.895v-184.904c0-13.782-11.215-24.995-25-24.995zm-59-12h12c2.761 0 5 2.239 5 5v31c0 2.761-2.239 5-5 5h-12c-2.761 0-5-2.239-5-5v-31c0-2.761 2.239-5 5-5zm-78 0h12c2.761 0 5 2.239 5 5v31c0 2.761-2.239 5-5 5h-12c-2.761 0-5-2.239-5-5v-31c0-2.761 2.239-5 5-5zm-78 0h12c2.761 0 5 2.239 5 5v31c0 2.761-2.239 5-5 5h-12c-2.761 0-5-2.239-5-5v-31c0-2.761 2.239-5 5-5zm-78 0h12c2.761 0 5 2.239 5 5v31c0 2.761-2.239 5-5 5h-12c-2.761 0-5-2.239-5-5v-31c0-2.761 2.239-5 5-5zm-62 77v-40c0-8.284 6.716-15 15-15h32v14c0 8.284 6.716 15 15 15h12c8.284 0 15-6.716 15-15v-14h36v14c0 8.284 6.716 15 15 15h12c8.284 0 15-6.716 15-15v-14h36v14c0 8.284 6.716 15 15 15h12c8.284 0 15-6.716 15-15v-14h36v14c0 8.284 6.716 15 15 15h12c8.284 0 15-6.716 15-15v-14h32c8.284 0 15 6.716 15 15v40z"
          fill="#2b2ab5"
        />
        <path
          d="m447.5 345.301v52.803c0 9.294-7.535 16.829-16.829 16.829h-243.808c-.282 0-.563.105-.767.3-4.55 4.362-1.531 9.7 2.733 9.7h244.94c13.106 0 23.731-10.625 23.731-23.731v-53.938c0-4.267-5.344-7.283-9.7-2.729-.195.203-.3.484-.3.766z"
          fill="#2b2ab5"
        />
      </g>
    </svg>
  );
}

export default Calendar;

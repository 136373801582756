import React, { useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import { LockOpenOutlined, LockOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../../context/auth.context";
import Button from "../../../../components/Button/Button";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import { LoginRequest } from "../../../../../models/login-request.model";
import IconButton from "../../../../components/IconButton/IconButton";
import { emailRules } from "./form-validation-rules";

function LoginForm() {
  const { login } = useAuthContext();

  const {
    control,
    handleSubmit,
    //formState: { errors },
  } = useForm({
    mode: "all",
  });

  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const toggleShowPassword = useCallback(
    (show?: boolean) => (event: never) => {
      setShowPassword((prev) => (show ? show : !prev));
    },
    []
  );

  const handleSubmitLogin = useCallback(
    (formValue: LoginRequest) => {
      const { username, password } = formValue;

      setError("");
      setLoading(true);
      login({ username: username, password })
        .then(() => {
          // Nav to home
          history.replace("/");
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    },
    [history, login]
  );

  return (
    <>
      <form action={"login"} onSubmit={handleSubmit(handleSubmitLogin)} noValidate>
        <Grid container spacing={2}>
          {error && (
            <Grid container item xs={12} justify="center">
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name="username"
              control={control}
              defaultValue=""
              rules={emailRules()}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <TextInput
                  inputRef={ref}
                  {...rest}
                  margin="none"
                  fullWidth
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  autoFocus
                  label={fieldState.error?.message || "Email"}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: "Password is required",
                },
              }}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <TextInput
                  inputRef={ref}
                  {...rest}
                  margin="none"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  label={fieldState.error?.message || "Password"}
                  error={Boolean(fieldState.error)}
                  InputProps={{
                    endAdornment: (
                      <IconButton type="button" edge="right" onClick={toggleShowPassword()}>
                        {showPassword ? <LockOpenOutlined /> : <LockOutlined />}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
              {!loading ? "Sign in" : "Signing in..."}
            </Button>
          </Grid>
        </Grid>
      </form>
      {loading && <PageLoader opacity="0.5" position="fixed" />}
    </>
  );
}

export default LoginForm;

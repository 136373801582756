import { useCallback } from "react";
import { useHttpClientContext, HttpClientRequestConfig } from "../context/http-client.context";
import { MachineTypeTest, MachineTestSetup, MachineTestProcedure, MachineTest, MachineSubtest } from "../models/machine-test.model";
import environment, { EnvId } from "../env";
import { toCamel } from "convert-keys";
import { MachineLocation } from "../models/machine-location.model";
import { MachineOptionParam, getMachineOptionUrlPrefix } from "../models/machine-option-type.model";
import { getEventSearchParams, EventSearchParams } from "../models/event-search-params.model";

export interface MachineTestServiceOptions {}

export interface MachineTestService {
  /**
   * Get machine type tests. These are tests assigned to machine types (MRI, CT, etc.), which are then setup onto an actual physical machine.
   * @param criteria
   */
  getMachineTypeTests(criteria: { machineType: string }): Promise<MachineTypeTest[]>;
  getMachineTypeTest(orgId: number, testId: number): Promise<MachineTypeTest>;

  /**
   * Get machine tests tied to actual physical machines.
   * @param machineOptionType
   * @param criteria
   */
  getMachineTests(machineOptionType: MachineOptionParam, criteria: EventSearchParams): Promise<{ items: MachineTest[]; total: number }>;
  getTestSetup(orgId: number, machineId: number, testId: number): Promise<MachineTestSetup>;
  getTestProcedures(criteria: { orgId: number; testId: number }): Promise<MachineTestProcedure[]>;
  saveTestConfig(
    orgId: number,
    criteria: {
      machineId: number;
      testVersionId: number;
      frequency: number;
      startDate: string | null;
      alias: string | null;
      procedureId: number | null;
      values: { testFieldId: number; val: any }[];
      enabledSubtests: { subtestValueId: number }[];
    }
  ): Promise<void>;

  reorderTestConfigSubtestsV2(
    orgId: number,
    criteria: {
      machineId: number;
      testId: number;
      sourceSubtestValueId: number;
      targetSubtestValueId: number;
    }
  ): Promise<MachineSubtest>;
  getMachineTestLocations(criteria: { machineId: number; testId: number }): Promise<{ items: MachineLocation[]; total: number }>;
  approveMachineTestResultsV2(orgId: number, testIds: number[], facilityIds: number[], machineIds: number[]): Promise<any>;
  signoffMachineTestResultsV2(orgId: number, testIds: number[], facilityIds: number[], machineIds: number[]): Promise<any>;
}

function useMachineTestService(options?: MachineTestServiceOptions): MachineTestService {
  const { httpClient } = useHttpClientContext();

  /**
   * Gets machine tests
   */
  const getMachineTests = useCallback(
    (machineOptionType: MachineOptionParam, criteria: EventSearchParams) => {
      const prefix = getMachineOptionUrlPrefix(machineOptionType);

      const endpoint = `${prefix}/status`;

      const apiCriteria = getEventSearchParams(criteria, true);

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.root as MachineTest[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  /**
   * Gets list of tests by machine type
   */
  const getMachineTypeTests = useCallback((criteria: { machineType: string }) => {
    //TODO: Move this to the api
    const endpoint = `https://s3.amazonaws.com/zapit-tests/${criteria.machineType}${environment.id !== EnvId.Prod ? "-test" : ""}.json?timestamp=${Date.now()}`;

    return fetch(new Request(endpoint))
      .then((response) => response.json())
      .then((items: MachineTypeTest[]) => {
        return toCamel<MachineTypeTest[]>(items);
      });
  }, []);

  const getMachineTypeTest = useCallback(
    (orgId: number, testId: number) => {
      const endpoint = `orgs/${orgId}/machine-tests/${testId}`;

      return httpClient.get(endpoint, { baseURL: environment.newApiUrl }).then((response) => {
        const items = response.data as MachineTypeTest;

        return items;
      });
    },
    [httpClient]
  );

  /**
   * Gets the setup for a test
   */
  const getTestSetup = useCallback(
    (orgId: number, machineId: number, testId: number) => {
      const endpoint = `orgs/${orgId}/machine-tests/${testId}/setup`;

      const config: HttpClientRequestConfig = {
        params: { machineId: machineId },
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data as MachineTestSetup;

        return items;
      });
    },
    [httpClient]
  );

  /**
   * Gets the procedures for a test
   */
  const getTestProcedures = useCallback(
    (criteria: { orgId: number; testId: number }) => {
      const endpoint = `orgs/${criteria.orgId}/procedures?testId=${criteria.testId}`;

      return httpClient.get(endpoint, { baseURL: environment.newApiUrl }).then((response) => {
        const items = response.data as MachineTestProcedure[];

        return items;
      });
    },
    [httpClient]
  );

  /**
   * Saves the test config for a test
   */
  const saveTestConfig = useCallback(
    (
      orgId: number,
      criteria: {
        machineId: number;
        testVersionId: number;
        frequency: number;
        startDate: string | null;
        alias: string | null;
        procedureId: number | null;
        values: { testFieldId: number; val: any }[];
        enabledSubtests: { subtestValueId: number }[];
      }
    ) => {
      const { machineId, testVersionId, ...rest } = criteria;

      const endpoint = `v2/orgs/${orgId}/machine-tests/setup`;

      return httpClient.post(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  const reorderTestConfigSubtestsV2 = useCallback(
    (
      orgId: number,
      criteria: { machineId: number; testId: number; testSetupId: number; sourceSubtestValueId: number; targetSubtestValueId: number }
    ): Promise<MachineSubtest> => {
      const endpoint = `v2/orgs/${orgId}/machine-tests/subtest-reorder`;

      return httpClient.put(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        return response.data as MachineSubtest;
      });
    },
    [httpClient]
  );

  const getMachineTestLocations = useCallback(
    (criteria: { machineId: number; testId: number }) => {
      const endpoint = `machine/${criteria.machineId}/test/${criteria.testId}/institution_name`;

      return httpClient.get(endpoint).then((response) => {
        const items = response.data.root as MachineLocation[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  const approveMachineTestResultsV2 = useCallback(
    (orgId: number, testIds: number[], facilityIds: number[], machineIds: number[]) => {
      const endpoint = `v2/orgs/${orgId}/machine-tests/action`;
      const criteria = { machineIds: machineIds, facilityIds: facilityIds, testIds: testIds, action: "approve" };

      return httpClient.put(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        const result = response.data;
        return result;
      });
    },
    [httpClient]
  );

  const signoffMachineTestResultsV2 = useCallback(
    (orgId: number, testIds: number[], facilityIds: number[], machineIds: number[]) => {
      const endpoint = `v2/orgs/${orgId}/machine-tests/action`;
      const criteria = { machineIds: machineIds, facilityIds: facilityIds, testIds: testIds, action: "signoff" };

      return httpClient.put(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        const result = response.data;
        return result;
      });
    },
    [httpClient]
  );

  return {
    getMachineTypeTests,
    getMachineTests,
    getMachineTypeTest,
    getTestSetup,
    getTestProcedures,
    saveTestConfig,
    reorderTestConfigSubtestsV2,
    getMachineTestLocations,
    approveMachineTestResultsV2,
    signoffMachineTestResultsV2,
  };
}

export default useMachineTestService;

import React from "react";
import clsx from "clsx";
import { makeStyles, PropTypes, Theme, useTheme } from "@material-ui/core";
import Chip, { ChipProps } from "../Chip/Chip";

export interface StatusChipProps extends ChipProps {
  status: string;
  severity?: "error" | "warning" | "success" | "info";
}

const useStyles = makeStyles({
  root: (props: { theme: Theme }) => ({
    maxWidth: 160,
    "&.error": {
      backgroundColor: props.theme.palette.error.light,
    },
    "&.warning": {
      backgroundColor: props.theme.palette.warning.light,
    },
    "&.success": {
      backgroundColor: props.theme.palette.success.light,
    },
    "&.info": {
      backgroundColor: props.theme.palette.info.light,
    },
  }),
});

/**
 * Humanizes our status_name into Status Name.
 * @param status
 */
export function humanizeStatus(status: string): string {
  // Cap first letter
  let label = status.charAt(0).toUpperCase() + status.slice(1);

  const underscoreIndex = status.lastIndexOf("_");

  if (underscoreIndex >= 0) {
    label = `${label.substr(0, underscoreIndex)} ${humanizeStatus(status.slice(underscoreIndex + 1))}`;
  }

  return label;
}

function StatusChip(props: StatusChipProps) {
  const { status, severity, ...rest } = props;
  const theme = useTheme();

  const classes = useStyles({ theme });
  const label = humanizeStatus(status);

  const defaultProps: ChipProps = {
    size: "small",
    ...rest,
  };

  return <Chip className={clsx(classes.root, severity)} {...defaultProps} label={label} title={label} />;
}

export default StatusChip;

// TODO: Remove this once we switch over to new API for all paginated requests.
export interface QuerySearchParams {
  page?: number;
  itemsPerPage?: number;
  start?: number;
  end?: number;
  sortField?: string;
  sortDirection?: string;
  search?: string;
}

export function getQuerySearchParams<T extends QuerySearchParams>(searchParams: T, removeFalseParams = false): any {
  const { itemsPerPage, ...rest } = searchParams;

  let criteria: any = {
    ...rest,
    items_per_page: itemsPerPage,
  };

  if (removeFalseParams) {
    const newCriteria = Object.keys(criteria)
      .filter((key) => (criteria as any)[key] !== false)
      .map((key) => ({
        [key]: (criteria as any)[key],
      }))
      .reduce((a, b) => ({ ...a, ...b }));

    criteria = newCriteria;
  }

  return criteria;
}

import { useCallback } from "react";
import { useHttpClientContext } from "../context/http-client.context";
import { Notification } from "../models/notification.model";

export interface NotificationService {
  getNotifications(): Promise<Notification[]>;
}

function useNotificationService(): NotificationService {
  const { httpClient } = useHttpClientContext();

  const getNotifications = useCallback(() => {
    return httpClient.get(`notification`).then((response) => {
      const items = response.data.root as Notification[];
      return items;
    });
  }, [httpClient]);

  return {
    getNotifications,
  };
}

export default useNotificationService;

import React, { ElementType } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List as MuiList, ListProps as MuiListProps, ListTypeMap } from "@material-ui/core";

export type CardListProps<D extends React.ElementType = ListTypeMap["defaultComponent"]> = MuiListProps<
  D,
  {
    height?: string | number;
  }
>;

const useStyles = makeStyles((theme) => ({
  root: (props?: Partial<CardListProps>) => ({
    height: props?.height || undefined,
    overflow: props?.height ? "auto" : undefined,
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    //position: "relative",
    //...((props?.classes?.root as any) || {}),
  }),
}));

function CardList<D extends ElementType = ListTypeMap["defaultComponent"]>(props: CardListProps<D>) {
  const { height, ...rest } = props;

  const classes = useStyles(props);
  const defaultProps: MuiListProps = {
    disablePadding: true,
    classes,
    ...rest,
  };

  return <MuiList {...defaultProps} />;
}

export default React.memo(CardList);

import React from "react";
import Avatar, { AvatarProps } from "../Avatar/Avatar";

import { User } from "../../../models/user.model";
import { PersonOutlined } from "@material-ui/icons";

export interface UserAvatarProps extends AvatarProps {
  user?: User;
}

function UserAvatar(props: UserAvatarProps) {
  const { user, size, ...rest } = props;

  const defaultProps: AvatarProps = {
    alt: user?.firstname ? `${user.firstname} ${user.lastname}` : "New User",
    src: user?.avatarFile?.fileUrl || undefined,
    size,
    ...rest,
  };

  return (
    <Avatar {...defaultProps}>
      {user?.firstname ? `${user.firstname?.substr(0, 1).toUpperCase()}${user.lastname?.substr(0, 1).toUpperCase()}` : <PersonOutlined fontSize="large" />}
    </Avatar>
  );
}

export default React.memo(UserAvatar);

import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function EditBoxIcon(props: SvgIconProps) {
  const { ...rest } = props;

  const iconProps: SvgIconProps = {
    ...rest,
  };

  return (
    <SvgIcon {...iconProps}>
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
      <path d="M17.136 8.244l-1.38-1.38c-.708-.708-1.848-.708-2.544 0L6.864 13.212V17.136h3.924l6.348-6.348C17.844 10.08 17.844 8.94 17.136 8.244zM9.96 15.276h-1.236v-1.236L12.864 9.9 14.1 11.136 9.96 15.276z" />
    </SvgIcon>
  );
}

export default EditBoxIcon;

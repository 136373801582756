import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme, createStyles, lighten } from "@material-ui/core";

import { ListAltOutlined, AssessmentOutlined, EditOutlined, SlideshowOutlined, CheckBoxOutlined } from "@material-ui/icons";
import { MachineTest } from "../../../models/machine-test.model";
import TestFrequencyIcon from "../Icons/TestFrequencyIcon";
import StatusChip from "../StatusChip/StatusChip";
import Tooltip from "../Tooltip/Tooltip";
import Button from "../Button/Button";
import Table, { BaseTableProps, TableColumn } from "../Table/Table";
import EditBoxIcon from "../Icons/EditBoxIcon";
import IconButton from "../IconButton/IconButton";
import useSearchbarService from "../../../hooks/searchbar-service.hook";
import { DeviceTest } from '../../../models/device-test.model';

export interface TestListProps extends BaseTableProps<MachineTest> {
  viewItemAction?: {
    disabled?: (item: MachineTest) => boolean;
    disabledText?: (item?: MachineTest) => string;
    onClick: (item: MachineTest) => void;
  };
  onViewTestResults?: (test: MachineTest) => void;
  onViewTestGraph?: (test: MachineTest) => void;
  runTestsAction?: {
    disabled?: (tests: MachineTest[]) => boolean;
    disabledText?: (tests: MachineTest[]) => string;
    onClick: (tests: MachineTest[]) => void;
  };
  signTestsAction?: {
    disabled?: (tests: MachineTest[]) => boolean;
    disabledText?: (tests: MachineTest[]) => string;
    onClick: (tests: MachineTest[]) => void;
  };
  approveTestsAction?: {
    disabled?: (tests: MachineTest[]) => boolean;
    disabledText?: (tests: MachineTest[]) => string;
    onClick: (tests: MachineTest[]) => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    table: {
      minWidth: 750,
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    spacedRow: {
      "&>*:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    spaceBetweenRow: {
      justifyContent: "space-between",
    },
    name: {
      minWidth: 180,
      maxWidth: 200,
      //overflow: "hidden"
    },
    pagination: {
      paddingRight: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    highlighted: {
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
  })
);

function TestList(props: TestListProps) {
  const {
    id,
    header,
    actions,
    items,
    pageLimits,
    page,
    limit,
    total,
    hideSort,
    hideSelect,
    onSort,
    onChangePage,
    onViewTestGraph,
    onViewTestResults,
    viewItemAction,
    runTestsAction,
    signTestsAction,
    approveTestsAction,
    enableRowReordering,
    onRowReorder
  } = props;

  const [selected, setSelected] = useState<string[]>();
  const classes = useStyles();
  const { searchbarFilter } = useSearchbarService();

  const handleSetSelected = useCallback((newSelected: string[]) => {
    setSelected(newSelected);
  }, []);

  const handleViewTestResults = useCallback(
    (test: MachineTest) => (event: React.MouseEvent<HTMLElement>) => {
      // Stop row from being selected if button pressed
      event.stopPropagation();
      if (!onViewTestResults) {
        return;
      }

      onViewTestResults(test);
    },
    [onViewTestResults]
  );

  const handleViewItem = useCallback(
    (test: MachineTest) => (event: React.MouseEvent<HTMLElement>) => {
      // Stop row from being selected if button pressed
      event.stopPropagation();
      if (!viewItemAction) {
        return;
      }

      viewItemAction.onClick(test);
    },
    [viewItemAction]
  );

  const handleViewTestGraph = useCallback(
    (test: MachineTest) => (event: React.MouseEvent<HTMLElement>) => {
      // Stop row from being selected if button pressed
      event.stopPropagation();
      if (!onViewTestGraph) {
        return;
      }

      onViewTestGraph(test);
    },
    [onViewTestGraph]
  );

  const handleRunTests = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!runTestsAction) {
        return;
      }

      const selectedTests = items.filter((i) => selected?.includes(i.id));

      runTestsAction.onClick(selectedTests);
    },
    [runTestsAction, items, selected]
  );

  const handleSignoff = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!signTestsAction) {
        return;
      }

      const selectedTests = items.filter((i) => selected?.includes(i.id));

      signTestsAction.onClick(selectedTests);
    },
    [signTestsAction, items, selected]
  );

  const handleApprove = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!approveTestsAction) {
        return;
      }

      const selectedTests = items.filter((i) => selected?.includes(i.id));

      approveTestsAction.onClick(selectedTests);
    },
    [approveTestsAction, items, selected]
  );

  const renderActions = useCallback(
    (row: MachineTest) => {
      if (!viewItemAction && !onViewTestResults && !onViewTestGraph) {
        return;
      }

      const viewItemDisabled = viewItemAction?.disabled && viewItemAction.disabled(row);
      const viewItemDisabledText = viewItemAction?.disabledText ? viewItemAction.disabledText(row) : "";

      return (
        <>
          {!onViewTestResults ? null : (
            <Tooltip title="Test results">
              <IconButton color="primary" aria-label="test results" onClick={handleViewTestResults(row)}>
                <ListAltOutlined />
              </IconButton>
            </Tooltip>
          )}
          {!onViewTestGraph ? null : (
            <Tooltip title="Test graph">
              <IconButton color="primary" aria-label="test graph" onClick={handleViewTestGraph(row)}>
                <AssessmentOutlined />
              </IconButton>
            </Tooltip>
          )}

          {!viewItemAction ? null : (
            <Tooltip title={viewItemDisabled ? viewItemDisabledText : "Test setup"}>
              <span>
                <IconButton color="primary" aria-label="test setup" onClick={handleViewItem(row)} disabled={viewItemDisabled}>
                  <EditBoxIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      );
    },
    [viewItemAction, onViewTestResults, onViewTestGraph, handleViewTestResults, handleViewTestGraph, handleViewItem]
  );

  const columns: TableColumn<MachineTest>[] = useMemo(
    () => {
      const arrayOfColumns = [
        {
          keyProp: "label",
          label: "Test",
          renderCell: (row: { label: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
            return <div className={classes.name}>{row.label}</div>;
          },
        },
        {
          keyProp: "id",
          label: "",
          hideSort: true,
          renderCell: renderActions,
        },
        {
          keyProp: "machineName",
          label: "Machine",
        },
        {
          keyProp: "frequency",
          label: "Frequency",
          renderCell: (row: { frequency: number; }) => {
            return (
              <div className={clsx(classes.row, classes.spacedRow)}>
                <TestFrequencyIcon frequency={row.frequency} />
                <span>{row.frequency === 1 ? "Daily" : `${row.frequency} Days`}</span>
              </div>
            );
          },
        },
        {
          keyProp: "daysSinceLastRun",
          label: "Last Run",
          renderCell: (row: { daysSinceLastRun: number | null; }) => {
            return row.daysSinceLastRun == null
              ? "Never"
              : row.daysSinceLastRun === 0
                ? "Today"
                : row.daysSinceLastRun === 1
                  ? "Yesterday"
                  : `${row.daysSinceLastRun} Days Ago`;
          },
        },
        {
          keyProp: "status",
          label: "Status",
          renderCell: (row: { due: boolean; status: string; }) => {
            let status = row.status.toLowerCase();
            let statusColor: any = status === "ready" ? "success" : "error";

            if (status === "ready") {
              status = row.due ? "due" : status;
              statusColor = row.due ? "warning" : statusColor;
            }

            if (status === "procedure is not set") {
              statusColor = "info";
            }

            return (
              <div className={clsx(classes.row, classes.spacedRow)}>
                <StatusChip status={status} severity={statusColor} />
              </div>
            );
          },
        },
        {
          keyProp: "pendingApprove",
          label: "Pending",
          renderCell: (row: { pendingApprove: number; }) => <span>{row.pendingApprove ? `${row.pendingApprove} test ${row.pendingApprove > 1 ? "runs" : "run"}` : "None"}</span>,
        }
      ];

      if (!searchbarFilter.facilityId) {
        arrayOfColumns.push({
          keyProp: "facilityName",
          label: "Facility",
        })
      }

      return arrayOfColumns as any;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.name, classes.row, classes.spacedRow, renderActions]
  );

  const RunTestsButton = useCallback(() => {
    if (!runTestsAction) {
      return null;
    }

    const selectedTests = items.filter((i) => selected?.includes(i.id));
    const disabled = runTestsAction?.disabled && runTestsAction.disabled(selectedTests);
    const disabledText = runTestsAction?.disabledText ? runTestsAction.disabledText(selectedTests) : "";

    return (
      <Tooltip title={disabled ? disabledText : ""}>
        <span>
          <Button variant="text" color="primary" aria-label="run selected tests" onClick={handleRunTests} startIcon={<SlideshowOutlined />} disabled={disabled}>
            <span>Run tests</span>
          </Button>
        </span>
      </Tooltip>
    );
  }, [items, selected, runTestsAction, handleRunTests]);

  const SignTestsButton = useCallback(() => {
    if (!signTestsAction) {
      return null;
    }

    const selectedTests = items.filter((i) => selected?.includes(i.id));
    const disabled = signTestsAction?.disabled && signTestsAction.disabled(selectedTests);
    const disabledText = signTestsAction?.disabledText ? signTestsAction.disabledText(selectedTests) : "";

    return (
      <Tooltip title={disabled ? disabledText : ""}>
        <span>
          <Button variant="text" color="primary" aria-label="sign selected tests" onClick={handleSignoff} startIcon={<EditOutlined />} disabled={disabled}>
            <span>Signoff</span>
          </Button>
        </span>
      </Tooltip>
    );
  }, [items, selected, handleSignoff, signTestsAction]);

  const ApproveTestsButton = useCallback(() => {
    if (!approveTestsAction) {
      return null;
    }

    const selectedTests = items.filter((i) => selected?.includes(i.id));
    const disabled = approveTestsAction?.disabled && approveTestsAction.disabled(selectedTests);
    const disabledText = approveTestsAction?.disabledText ? approveTestsAction.disabledText(selectedTests) : "";

    return (
      <Tooltip title={disabled ? disabledText : ""}>
        <span>
          <Button
            variant="text"
            color="primary"
            aria-label="approve selected tests"
            onClick={handleApprove}
            startIcon={<CheckBoxOutlined />}
            disabled={disabled}
          >
            <span>Approve</span>
          </Button>
        </span>
      </Tooltip>
    );
  }, [items, selected, approveTestsAction, handleApprove]);

  return (
    <Table
      id={id}
      items={items}
      keyProp="id"
      columns={columns}
      header={header}
      page={page}
      limit={limit}
      total={total}
      pageLimits={pageLimits}
      hideSort={hideSort}
      hideSelect={hideSelect}
      onSort={onSort}
      onChangePage={onChangePage}
      onSelectKeys={handleSetSelected}
      actions={actions}
      enableRowReordering={enableRowReordering}
      onRowReorder={onRowReorder}
      selectedActions={
        <>
          <RunTestsButton />
          <SignTestsButton />
          <ApproveTestsButton />
        </>
      }
    />
  );
}

export default TestList;

import { BlockOutlined, CheckCircleOutlined, ErrorOutline, RadioButtonUncheckedOutlined } from "@material-ui/icons";
import moment from "moment";
import { MachineTestResult } from "../../../models/machine-test-result.model";
import StatusChip from "../StatusChip/StatusChip";
import Tooltip from "../Tooltip/Tooltip";
import { Grid } from "@material-ui/core";

export interface TestResultStatusChipsProps {
  testResult: MachineTestResult;
  dateFormat: string;
}

function TestResultStatusChips({ testResult, dateFormat }: TestResultStatusChipsProps) {
  const row = testResult;

  /**
   <Tooltip title={row.signoffUser ? `Signed by ${row.signoffUser.firstname} ${row.signoffUser.lastname}` : ""}>
    <span>
      <StatusChip
        icon={row.signoffDate ? <CheckCircleOutlined /> : <RadioButtonUncheckedOutlined />}
        status={row.signoffDate ? moment(row.signoffDate).format(dateFormat) : "Not Signed"}
        severity={row.status === row.rejectDate ? "error" : row.signoffDate ? "success" : undefined}
      />
    </span>
  </Tooltip>
   */
  const signoff = {
    user: row.signoffUser || row.approveUser,
    date: row.signoffDate || row.approveDate,
  };

  return (
    <Grid container>
      {/** status */}
      <Grid item>
        <StatusChip
          icon={
            row.exclude ? (
              <BlockOutlined />
            ) : row.resolved ? (
              <CheckCircleOutlined />
            ) : row.status === "not_run" ? (
              <BlockOutlined />
            ) : row.status === "pass" ? (
              <CheckCircleOutlined />
            ) : (
              <ErrorOutline />
            )
          }
          status={row.status === "not_run" ? "Not Run" : row.exclude ? "Excluded" : row.resolved ? "Resolved" : `${row.status}ed`}
          severity={row.status === "pass" ? "success" : row.status === "not_run" ? undefined : row.exclude ? undefined : row.resolved ? "info" : "error"}
          style={{ backgroundColor: row.exclude ? 'lightblue' : "" }}
        />
      </Grid>
      {/** sign off */}
      <Grid item>
        <Tooltip title={signoff.user ? `Signed by ${signoff.user.firstname} ${signoff.user.lastname}` : ""}>
          <span>
            <StatusChip
              icon={signoff.date ? <CheckCircleOutlined /> : <RadioButtonUncheckedOutlined />}
              status={signoff.date ? moment(signoff.date).format(dateFormat) : "Not Signed"}
              severity={row.status === row.rejectDate ? "error" : signoff.date ? "success" : undefined}
            />
          </span>
        </Tooltip>
      </Grid>
      {/** approval */}
      <Grid item>
        <Tooltip
          title={
            row.approveUser
              ? `Approved by ${row.approveUser.firstname} ${row.approveUser.lastname}`
              : row.rejectUser
                ? `Rejected by ${row.rejectUser.firstname} ${row.rejectUser.lastname}`
                : ""
          }
        >
          <span>
            <StatusChip
              icon={row.approveDate ? <CheckCircleOutlined /> : row.rejectDate ? <BlockOutlined /> : <RadioButtonUncheckedOutlined />}
              status={row.approveDate || row.rejectDate ? moment(row.approveDate || row.rejectDate).format(dateFormat) : "Pending"}
              severity={row.approveDate ? "success" : row.rejectDate ? "error" : undefined}
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default TestResultStatusChips;

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GtmConfig } from "../models/gtm-config.model";
import errorMessages from "../translations/en/errors.json";

export enum GtmEvent {
  setUser = "set_user",
}

/**
 * Returns the text snippet for dataLayer.
 * @param config - The GTM config
 */
export const getDataLayerSnippet = (config: GtmConfig): string => {
  return `window.${config.dataLayerName} = window.${config.dataLayerName} || []; window.${config.dataLayerName}.push(${JSON.stringify(config.dataLayer)})`;
};

/**
 * Returns the text snippet for iframe.
 * @param config - The GTM config
 */
export const getIframeSnippet = (config: GtmConfig): string => {
  const params = config.env ? `&gtm_auth=${config.env.gtmAuth}&gtm_preview=${config.env.gtmPreview}&gtm_cookies_win=x` : "";
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.id}${params}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
};

/**
 * Returns the text snippet for GTM.
 * @param config - The GTM config
 */
export const getGTMScript = (config: GtmConfig): string => {
  const params = config.env ? `+"&gtm_auth=${config.env.gtmAuth}&gtm_preview=${config.env.gtmPreview}&gtm_cookies_win=x"` : "";

  return `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl${params};f.parentNode.insertBefore(j,f);
      })(window,document,'script','${config.dataLayerName}','${config.id}');
    `;
};

export interface GtmContext {
  gtmConfig: GtmConfig;
  setGtmConfig: React.Dispatch<React.SetStateAction<GtmConfig>>;
  gtmEnabled: boolean;
  /**
   * Function to send the events to GTM
   * @param data - The data to push
   */
  sendToDataLayer(data: Object): void;
}

/**
 * The initial state
 */
export const defaultConfig: GtmConfig = {
  id: "",
  dataLayerName: "dataLayer",
  dataLayer: undefined,
  env: undefined,
};

const Context = createContext<GtmContext>(null!);

function GtmContextProvider(props: React.PropsWithChildren<Partial<GtmConfig>>): JSX.Element {
  const { children, id, ...rest } = props;

  const [gtmConfig, setGtmConfig] = useState<GtmConfig>({ ...defaultConfig, ...rest, id: id || defaultConfig.id });
  const [gtmEnabled, setGtmEnabled] = useState(false);

  // Get the scripts and add them to the document upon initial render.
  useEffect(() => {
    if (gtmConfig.id === "") {
      setGtmEnabled((prev) => (prev ? false : prev));
      return;
    }

    // Initialize dataLayer
    // We can ignore the scripts and just initialize it right away in code.
    //const dataLayerScript = document.createElement("script");
    //dataLayerScript.innerHTML = getDataLayerSnippet(config);
    (window as any)[gtmConfig.dataLayerName] = (window as any)[gtmConfig.dataLayerName] || [];

    const noScript = document.createElement("noscript");
    noScript.innerHTML = getIframeSnippet(gtmConfig);

    const script = document.createElement("script");
    script.innerHTML = getGTMScript(gtmConfig);

    window.document.head.insertBefore(script, document.head.childNodes[0]);
    //window.document.head.insertBefore(dataLayerScript, document.head.childNodes[0]);
    window.document.body.insertBefore(noScript, document.body.childNodes[0]);

    setGtmEnabled(true);
  }, [gtmConfig]);

  const sendToDataLayer = useCallback(
    (data: Object): void => {
      /*if (!(window as any)[config.dataLayerName]) {
        (window as any)[config.dataLayerName] = [];
      }*/

      const dataLayer = (window as any)[gtmConfig.dataLayerName];
      dataLayer.push(data);
    },
    [gtmConfig.dataLayerName]
  );

  const contextValue: GtmContext = {
    gtmConfig,
    setGtmConfig,
    gtmEnabled,
    sendToDataLayer,
  };

  return <Context.Provider value={{ ...contextValue }}>{children}</Context.Provider>;
}

function useGtmContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { GtmContextProvider, useGtmContext };

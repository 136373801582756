import React, { useEffect, useState, createContext, useContext, useCallback, useRef } from "react";

import Amplify, { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession, CognitoIdToken, CognitoRefreshToken, CognitoAccessToken, CognitoUserPool } from "amazon-cognito-identity-js";

import { AppEvent, appEventEmitter, triggerAppEvent, useAppContext } from "./app.context";
import {
  VerificationCodeRequest,
  LoginRequest,
  RegisterRequest,
  VerifyResetRequest,
  VerifyRegisterRequest,
  ChangePasswordRequest,
} from "../models/login-request.model";
import errorMessages from "../translations/en/errors.json";
import usePersistedState from "../hooks/persisted-state.hook";
import { AuthSession } from "../models/auth-session.model";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import environment from "../env";

export interface AuthContext {
  session?: AuthSession | null;
  setSession(session: (AuthSession & { username: string }) | null): void;
  login(request: LoginRequest): Promise<void>;
  register(request: RegisterRequest): Promise<void>;
  refresh(): Promise<AuthSession>;
  verifySignUp(request: VerifyRegisterRequest): Promise<void>;
  resendSignUpCode(request: VerificationCodeRequest): Promise<void>;
  resetPassword(request: VerificationCodeRequest): Promise<void>;
  verifyResetPassword(request: VerifyResetRequest): Promise<void>;
  changePassword(request: ChangePasswordRequest): Promise<void>;
  signOut(): Promise<void>;
  isAuthReady: boolean;
}

const Context = createContext<AuthContext>(null!);

function AuthContextProvider(props: React.PropsWithChildren<{}>): JSX.Element {
  // Check params for auth
  const history = useHistory();
  const { search } = useLocation();

  const [authParams] = useState<{ username?: string; access_token?: string; refresh_token?: string; issued_at?: number; expires_in?: number }>(
    queryString.parse(search)
  );

  const [isAuthReady, setIsAuthReady] = useState(false);

  const [session, setSession] = usePersistedState<(AuthSession & { username: string }) | null>("context/session", {
    storageType: "local",
    priority: 1, // This should be the highest priory in the app as it is needed to get in
    clearStateHandler: { emitter: appEventEmitter, event: AppEvent.signOut },
  });

  const sessionRef = useRef(session || null);

  useEffect(() => {
    setIsAuthReady(false);
    const awsConfig = {
      Analytics: {
        disabled: true,
      },
      Auth: {
        oauth: {
          // domain: process.env.REACT_APP_OAUTH_DOMAIN,
          scope: ["email"],
          // redirectSignIn: process.env.REACT_APP_SIGNIN_CALLBACK,
          // redirectSignOut: process.env.REACT_APP_SIGNOUT_CALLBACK,
          responseType: "code",
        },
        region: "us-east-1",
        userPoolId: "us-east-1_TuozR9j2Y",
        userPoolWebClientId: "582vpnerq19genvm8072o2dtcn",
      },
    };

    Auth.configure(awsConfig);

    const hasAuthParams = Boolean(
      authParams?.access_token && authParams?.expires_in && authParams?.refresh_token && authParams?.issued_at && authParams?.username
    );

    // If we have auth params set up the Cognito session with those
    if (hasAuthParams) {
      history.replace("");

      const localSession = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: authParams.access_token! }),
        RefreshToken: new CognitoRefreshToken({ RefreshToken: authParams.refresh_token! }),
        AccessToken: new CognitoAccessToken({ AccessToken: authParams.access_token! }),
      });

      const userPool = new CognitoUserPool({
        ClientId: "582vpnerq19genvm8072o2dtcn",
        UserPoolId: "us-east-1_TuozR9j2Y",
      });

      const localUser = new CognitoUser({
        Username: authParams.username!,
        Pool: userPool,
      });

      localUser.setSignInUserSession(localSession);
    }

    const response = Auth.currentSession()
      .catch((e) => null)
      .then((response) => {
        if (response) {
          const accessToken = response.getIdToken().getJwtToken();
          const username = response.getAccessToken().payload.username;

          // Only set session if access token has changed.
          setSession((prev) => {
            if (prev?.accessToken === accessToken) {
              return prev;
            }

            return {
              username,
              accessToken: accessToken,
              expiresIn: response.getIdToken().getExpiration(),
              issuedAt: response.getIdToken().getIssuedAt(),
              refreshToken: response.getRefreshToken().getToken(),
            };
          });
        }

        return;
      });

    response.finally(() => setIsAuthReady(true));
  }, [authParams, history, setSession]);

  useEffect(() => {
    sessionRef.current = session || null;
  }, [session]);

  const completeUpdatePassword = useCallback((loginRequest: LoginRequest, authProvider: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      authProvider.completeNewPasswordChallenge(loginRequest.password, [], {
        onSuccess: (result: any) => {
          resolve(result);
        },
        onFailure: (error: any) => {
          reject(error);
        },
      });
    });
  }, []);

  const login = useCallback(
    async ({ username, password }: LoginRequest) => {
      const response = await Auth.signIn(username, password);
      let responseSession = response.signInUserSession;

      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        const newPasswordResponse = await completeUpdatePassword({ username, password }, response);
        responseSession = newPasswordResponse;
      }

      setSession({
        username,
        accessToken: responseSession.idToken.jwtToken,
        issuedAt: responseSession.idToken.payload.iat,
        expiresIn: responseSession.idToken.payload.exp,
        refreshToken: responseSession.refreshToken.token,
      });
    },
    [completeUpdatePassword, setSession]
  );

  const register = useCallback(({ username, password, name }: RegisterRequest) => {
    return Auth.signUp({
      username,
      password,
      attributes: {
        name: name,
        email: username,
      },
    }).then((response) => Promise.resolve());
  }, []);

  const refresh = useCallback((): Promise<AuthSession> => {
    if (!sessionRef.current) {
      return Promise.reject(errorMessages.auth.unauthorized);
    }

    const { username, refreshToken } = sessionRef.current;

    return axios
      .post<AuthSession>(
        `auth/refresh`,
        {
          username,
          refreshToken,
        },
        {
          baseURL: environment.newApiUrl,
        }
      )
      .then((response) => {
        setSession({ ...response.data, username });

        return response.data;
      })
      .catch((error) => {
        setSession(null);
        throw error;
      });
  }, [setSession]);

  const resetPassword = useCallback((request: VerificationCodeRequest) => {
    return Auth.forgotPassword(request.username).then((response) => Promise.resolve());
  }, []);

  const verifyResetPassword = useCallback(
    (request: VerifyResetRequest) => {
      return Auth.forgotPasswordSubmit(request.username, request.verificationCode, request.password).then((response) => login(request));
    },
    [login]
  );

  const verifySignUp = useCallback(
    (request: VerifyRegisterRequest) => {
      return Auth.confirmSignUp(request.username, request.verificationCode).then((response) => login(request));
    },
    [login]
  );

  const resendSignUpCode = useCallback((request: VerificationCodeRequest) => {
    return Auth.resendSignUp(request.username).then((response) => Promise.resolve());
  }, []);

  const signOut = useCallback(() => {
    return Auth.signOut({ global: true })
      .catch((err) => /**Just ignore any errors as we are clearing the session regardless */ null)
      .then(() => {
        triggerAppEvent(AppEvent.signOut);
        setSession(null);
      });
  }, [setSession]);

  const changePassword = useCallback(async (request: ChangePasswordRequest): Promise<void> => {
    const authUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(authUser, request.password, request.newPassword);
  }, []);

  const contextValue: AuthContext = {
    session,
    setSession,
    isAuthReady,
    login,
    register,
    refresh,
    verifySignUp,
    resendSignUpCode,
    resetPassword,
    verifyResetPassword,
    signOut,
    changePassword,
  };

  return <Context.Provider value={{ ...contextValue }}>{props.children}</Context.Provider>;
}

function useAuthContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { AuthContextProvider, useAuthContext };

import React, { useState, useEffect, useMemo, useCallback, RefObject, ForwardedRef } from "react";
import clsx from "clsx";
import { Calendar as MuiCalendar } from "@material-ui/pickers";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import moment from "moment";
import { Typography, List, ListItem, Grid, ListItemProps, ListProps, Box, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MachineTest, MachineTestFrequency } from "../../../models/machine-test.model";
import TestFrequencyIcon from "../Icons/TestFrequencyIcon";
import { MachineEvent } from "../../../models/machine-event.model";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Badge from "../Badge/Badge";
import { MachineLog } from "../../../models/machine-log.model";
import { enumToLookup } from "../../../models/lookup.model";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { MachineTestResult, WidgetTestResult } from "../../../models/machine-test-result.model";
import { TestRunStatus } from "../../../models/test-run.model";
import ImageWithText from "../ImageWithText/ImageWithText";
import CalendarSvg from "../Svg/Calendar";
import { Components, Virtuoso } from "react-virtuoso";
import { CalendarLegend } from "./CalendarLegend";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DeviceTest } from "../../../models/device-test.model";
import useSearchbarService from "../../../hooks/searchbar-service.hook";

interface CalendarListItem {
  item: any;
  key: number | string;
  type: "test" | "testResult" | "event" | "log";
  label: string;
  facilityName?: string;
  caption: string;
  badgeColor?: string;
  frequency?: number;
}

export interface CalendarProps {
  tests: DeviceTest[];
  testsTotal: number;
  testResults?: WidgetTestResult[];
  events?: MachineEvent[];
  logs?: MachineLog[];
  value: MaterialUiPickersDate;
  onValueChange: (date: MaterialUiPickersDate) => void;
  onMonthChange: (date: MaterialUiPickersDate) => void;
  onSelectMachineTests: (machine: { name: string; tests: MachineTest[] }) => void;
  onSelectMachineTestResults?: (machine: { name: string; id: number; frequency?: number }) => void;
  onSelectMachineEvent?: (event: MachineEvent) => void;
  onSelectMachineLog?: (log: MachineLog) => void;
  actions?: React.ReactNode;
  eventListStyles?: CSSProperties;
  dateFormat: string;
}

const machineTestFrequencies = enumToLookup(MachineTestFrequency);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        flexWrap: "none",
      },
    },
    calendar: {
      marginTop: "-4px", // Offset the weird margin atop the calendar
      flex: "1 0 0%",
      zIndex: 0, // Fix stupid next/previous month buttons showing on top of popups

      marginRight: theme.spacing(5),

      [theme.breakpoints.down("md")]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
        width: "100%",
      },
    },
    eventBadge: {
      zIndex: 0 // HACK to keep the circle and square indicators from showing on top of the legend popup
    },
    dayWithEventsContainer: {
      position: "relative",
    },
    dayEventDotList: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      width: "100%",
      pointerEvents: "none",
      bottom: "5px",
      "&>*:not(:last-child)": {
        marginRight: "1px",
      },
    },
    dayEventDot: {
      width: "0.5em",
      height: "0.5em",
      border: "1px solid #fff",
    },
    machineTestDot: {
      backgroundColor: theme.palette.warning.main,
      borderRadius: "0.5em",
      "&.overdue": {
        backgroundColor: theme.palette.warning.light,
      },
    },

    machineEventDot: {
      backgroundColor: theme.palette.warning.main,
      "&.overdue": {
        backgroundColor: theme.palette.warning.light,
      },
    },
    machineTestResultDot: {
      backgroundColor: theme.palette.success.main,
      borderRadius: "0.5em",
      "&.not-run": {
        backgroundColor: theme.palette.warning.light,
      },
      "&.resolved": {
        backgroundColor: theme.palette.info.main,
      },
      "&.fail": {
        backgroundColor: theme.palette.error.main,
      },
    },
    machineLogDot: {
      backgroundColor: theme.palette.info.main,
    },

    // Have to make this look good next to mui calendar, so some strange margins/padding to follow...
    events: {
      flex: "1",
      height: 320,
      minHeight: 320,
      display: "flex",
      flexDirection: "column",
    },
    eventsHeader: {
      height: 40,
      display: "flex",
      alignItems: "center",
      marginBottom: 4.667,
      color: theme.palette.primary.main,
    },
    eventsList: {
      height: "100%",
      marginTop: "12px",
      marginLeft: -theme.spacing(2), // Offset list with padding of items
      marginRight: -theme.spacing(2),
    },
    event: {
      display: "flex",
      alignItems: "center",
    },
    eventText: {
      marginLeft: theme.spacing(3),
    },

    legend: {
      flex: "1 1 100%",
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.divider}`
    }
  })
);

function Calendar({
  value,
  onValueChange,
  onMonthChange,
  onSelectMachineTests,
  onSelectMachineTestResults,
  onSelectMachineEvent,
  onSelectMachineLog,
  tests,
  testsTotal,
  testResults,
  events,
  logs,
  actions,
  eventListStyles,
  dateFormat,
}: CalendarProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [testsByDate, setTestsByDate] = useState<{ date: string; tests: DeviceTest[] }[]>([]);
  const [eventsByDate, setEventsByDate] = useState<{ date: string; items: MachineEvent[] }[]>([]);
  const [testResultsByDate, setTestResultsByDate] = useState<{ date: string; items: WidgetTestResult[] }[]>([]);
  const [logsByDate, setLogsByDate] = useState<{ date: string; items: MachineLog[] }[]>([]);
  const { searchbarFilter } = useSearchbarService();
  const today = moment().format(dateFormat);

  useEffect(() => {
    if (!tests?.length) {
      setTestsByDate([]);
      return;
    }

    const grouped = tests
      .filter((i) => i.machineActive)
      .map((i) => {
        // Convert to utc here bc the system determines the due date in utc
        const dueUtc = moment.utc(i.dueAt);
        const itemDate = dueUtc.format(dateFormat);
        const dueToday = moment.utc().startOf("date").isAfter(moment.utc(itemDate));

        return {
          date: dueToday ? today : itemDate,
          tests: [i],
        };
      });

    const byDate = [grouped[0]];

    for (let i = 1; i < grouped.length; i++) {
      const groupedItem = grouped[i];

      const existingItem = byDate.find((x) => x.date === groupedItem.date);

      if (existingItem) {
        existingItem.tests = [...existingItem.tests, ...groupedItem.tests];
        continue;
      }

      byDate.push(groupedItem);
    }

    setTestsByDate(byDate);
  }, [dateFormat, tests, today]);

  useEffect(() => {
    if (!events?.length) {
      setEventsByDate([]);
      return;
    }

    const grouped = events
      .filter((i) => i.active)
      .map((i) => {
        const itemDate = moment(i.dueDate).format(dateFormat);
        const dueToday = moment(today).startOf("date").isAfter(moment(itemDate));

        return {
          date: dueToday ? today : itemDate,
          items: [i],
        };
      });

    const byDate = [grouped[0]];

    for (let i = 1; i < grouped.length; i++) {
      const groupedItem = grouped[i];

      const existingItem = byDate.find((x) => x.date === groupedItem.date);

      if (existingItem) {
        existingItem.items = [...existingItem.items, ...groupedItem.items];
        continue;
      }

      byDate.push(groupedItem);
    }

    setEventsByDate(byDate);
  }, [dateFormat, events, today]);

  useEffect(() => {
    if (!testResults?.length) {
      setTestResultsByDate([]);
      return;
    }

    const grouped = testResults.map((i) => {
      // Group by test date as that was saved as utc in our system and the timezone conversion will be correct.
      return {
        date: moment(i.testDate).format(dateFormat),
        items: [i],
      };
    });

    const byDate = [grouped[0]];

    for (let i = 1; i < grouped.length; i++) {
      const groupedItem = grouped[i];

      const existingItem = byDate.find((x) => x.date === groupedItem.date);

      if (existingItem) {
        existingItem.items = [...existingItem.items, ...groupedItem.items];
        continue;
      }

      byDate.push(groupedItem);
    }

    setTestResultsByDate(byDate);
  }, [dateFormat, testResults]);

  useEffect(() => {
    if (!logs?.length) {
      setLogsByDate([]);
      return;
    }

    const grouped = logs
      .filter((i) => i.status.name === "closed" && i.machineLogEvents?.length)
      .map((i) => ({
        date: moment(i.date).format(dateFormat),
        items: [i],
      }));

    const byDate = grouped?.length ? [grouped[0]] : [];

    for (let i = 1; i < grouped.length; i++) {
      const groupedItem = grouped[i];

      const existingItem = byDate.find((x) => x.date === groupedItem.date);

      if (existingItem) {
        existingItem.items = [...existingItem.items, ...groupedItem.items];
        continue;
      }

      byDate.push(groupedItem);
    }

    setLogsByDate(byDate);
  }, [dateFormat, logs]);

  const getEventDotColor = useCallback(
    (items: MachineEvent[]) => {
      const hasOverdue = items.findIndex((i) => moment(today).startOf("date").isAfter(moment(i.dueDate))) >= 0;
      return hasOverdue ? theme.palette.warning.light : theme.palette.warning.main;
    },
    [theme.palette.warning.light, theme.palette.warning.main, today]
  );

  const getLogDotColor = useCallback(
    (items: MachineLog[]) => {
      return theme.palette.success.light;
    },
    [theme.palette.success.light]
  );

  const getTestDotColor = useCallback(
    (items: DeviceTest[]) => {
      const hasOverdue = items.findIndex((t) => t.frequency !== 1 && moment(today).startOf("date").isAfter(moment(t.dueAt))) >= 0;
      return hasOverdue ? theme.palette.warning.light : theme.palette.warning.light;
    },
    [theme.palette.warning.light, today]
  );

  const getTestResultDotColor = useCallback(
    (items: WidgetTestResult[]) => {
      if (items.findIndex((t) => t.status === TestRunStatus.notRun) >= 0) {
        return theme.palette.default.main;
      } else if (items.findIndex((t) => t.status === TestRunStatus.fail && !t.resolved) >= 0) {
        return theme.palette.error.main;
      } else if (items.findIndex((t) => t.status === TestRunStatus.fail) >= 0) {
        return theme.palette.info.main;
      }

      return theme.palette.success.main;
    },
    [theme.palette.default.main, theme.palette.error.main, theme.palette.info.main, theme.palette.success.main]
  );

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      return onValueChange(moment(date || new Date()));
    },
    [onValueChange]
  );

  const handleMonthChange = useCallback(
    (date: MaterialUiPickersDate) => {
      const calendarDate = moment(date).format(dateFormat);
      const useToday = moment(calendarDate).format("YYYY-MM") === moment(today).format("YYYY-MM");
      const nextSelectedDate = useToday ? today : calendarDate;

      return onMonthChange(moment(nextSelectedDate));
    },
    [dateFormat, onMonthChange, today]
  );

  const handleSelectMachineTests = useCallback(
    (name: string, tests: MachineTest[]) => () => {
      return onSelectMachineTests({ name, tests });
    },
    [onSelectMachineTests]
  );

  const handleSelectMachineTestResults = useCallback(
    (name: string, id: number, frequency?: number) => () => {
      if (!onSelectMachineTestResults) {
        return;
      }

      return onSelectMachineTestResults({ name, id, frequency });
    },
    [onSelectMachineTestResults]
  );

  const handleSelectMachineLog = useCallback(
    (log: MachineLog) => () => {
      if (!onSelectMachineLog) {
        return;
      }

      return onSelectMachineLog(log);
    },
    [onSelectMachineLog]
  );

  const handleSelectMachineEvent = useCallback(
    (event: MachineEvent) => () => {
      if (!onSelectMachineEvent) {
        return;
      }

      return onSelectMachineEvent(event);
    },
    [onSelectMachineEvent]
  );

  /** https://stackoverflow.com/questions/52709772/put-some-mark-in-specific-date-in-material-ui-using-react */
  const renderDay = useCallback(
    (date: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => {
      if (!date || !dayInCurrentMonth) {
        return <div className={classes.dayWithEventsContainer}>{dayComponent}</div>;
      }

      const calendarDate = moment(date).format(dateFormat);

      const dayTests = testsByDate.find((i) => i.date === calendarDate)?.tests;
      const dayEvents = eventsByDate.find((i) => i.date === calendarDate)?.items;
      const dayResults = testResultsByDate.find((i) => i.date === calendarDate)?.items;
      const dayLogs = logsByDate.find((i) => i.date === calendarDate)?.items;

      const dots: { classes: string; color: string }[] = [];

      if (dayTests?.length) {
        dots.push({
          classes: `${classes.dayEventDot} ${classes.machineTestDot}`,
          color: getTestDotColor(dayTests!),
        });
      }

      if (dayEvents?.length) {
        dots.push({
          classes: `${classes.dayEventDot} ${classes.machineEventDot}`,
          color: getEventDotColor(dayEvents!),
        });
      }

      if (dayResults?.length) {
        dots.push({
          classes: `${classes.dayEventDot} ${classes.machineTestResultDot}`,
          color: getTestResultDotColor(dayResults!),
        });
      }

      if (dayLogs?.length) {
        dots.push({
          classes: `${classes.dayEventDot} ${classes.machineLogDot}`,
          color: getLogDotColor(dayLogs!),
        });
      }

      if (dots.length) {
        return (
          <div className={classes.dayWithEventsContainer}>
            {dayComponent}
            <div className={classes.dayEventDotList}>
              {dots.map((dot, index) => (
                <div key={index} className={clsx(dot.classes)} style={{ background: dot.color }} />
              ))}
            </div>
          </div>
        );
      }

      return <div className={classes.dayWithEventsContainer}>{dayComponent}</div>;
    },
    [
      dateFormat,
      testsByDate,
      eventsByDate,
      testResultsByDate,
      logsByDate,
      classes.dayWithEventsContainer,
      classes.dayEventDot,
      classes.machineTestDot,
      classes.machineEventDot,
      classes.machineTestResultDot,
      classes.machineLogDot,
      classes.dayEventDotList,
      getTestDotColor,
      getEventDotColor,
      getTestResultDotColor,
      getLogDotColor,
    ]
  );

  // Memoize function result by value and side affect of value
  // so it only changes when value changes
  const testsBySelectedDate = useMemo(() => {
    const formattedValue = moment(value).format(dateFormat);

    return testsByDate.find((i) => i.date === formattedValue)?.tests || [];
  }, [value, dateFormat, testsByDate]);

  const eventsBySelectedDate = useMemo(() => {
    const formattedValue = moment(value).format(dateFormat);

    return eventsByDate.find((i) => i.date === formattedValue)?.items || [];
  }, [value, dateFormat, eventsByDate]);

  const testResultsBySelectedDate = useMemo(() => {
    const formattedValue = moment(value).format(dateFormat);

    return testResultsByDate.find((i) => i.date === formattedValue)?.items || [];
  }, [value, dateFormat, testResultsByDate]);

  const logsBySelectedDate = useMemo(() => {
    const formattedValue = moment(value).format(dateFormat);
    return logsByDate.find((i) => i.date === formattedValue)?.items || [];
  }, [value, dateFormat, logsByDate]);

  // Memoize function result by side affect of value
  const testsByMachineFrequency = useMemo(() => {
    const grouped = testsBySelectedDate.map((i) => ({
      key: `test_${i.frequency}_${i.machineId}`,
      frequency: i.frequency,
      frequencyName: machineTestFrequencies.find((freq) => freq.id === i.frequency)?.name,
      machineName: i.machineName,
      facilityName: i.facilityName,
      tests: [i],
    }));

    const byMacFreq = grouped.length ? [grouped[0]] : [];

    for (let i = 1; i < grouped.length; i++) {
      const groupedItem = byMacFreq.find((g) => g.key === grouped[i].key);

      if (groupedItem) {
        groupedItem.tests = [...groupedItem.tests, ...grouped[i].tests];
        continue;
      }

      byMacFreq.push(grouped[i]);
    }

    return byMacFreq;
  }, [testsBySelectedDate]);

  const testResultsByMachineFrequency = useMemo(() => {
    const grouped = testResultsBySelectedDate.map((i) => {
      const frequency = i.frequency || 1;
      return {
        id: i.machineId,
        key: `testresult_${i.frequency || 1}_${i.machineId}`,
        frequency: frequency,
        frequencyName: machineTestFrequencies.find((freq) => freq.id === frequency)?.name,
        machineName: i.machineName,
        facilityName: i.facilityName,
        items: [i],
      };
    });

    const byMacFreq = grouped.length ? [grouped[0]] : [];

    for (let i = 1; i < grouped.length; i++) {
      const groupedItem = byMacFreq.find((g) => g.key === grouped[i].key);

      if (groupedItem) {
        groupedItem.items = [...groupedItem.items, ...grouped[i].items];
        continue;
      }

      byMacFreq.push(grouped[i]);
    }

    return byMacFreq;
  }, [testResultsBySelectedDate]);

  const MemoizedCalendar = useMemo(
    () => (
      <MuiCalendar
        date={value}
        onChange={handleDateChange}
        renderDay={renderDay}
        onMonthChange={handleMonthChange}
        leftArrowButtonProps={{ edge: "start" }}
        rightArrowButtonProps={{ edge: "end" }}
        classes={{ transitionContainer: "{ minHeight: 200 }" }}
      />
    ),
    [value, handleDateChange, renderDay, handleMonthChange]
  );

  const listItems = useMemo(() => {
    const eventListItems: CalendarListItem[] = !events
      ? []
      : eventsBySelectedDate.map((item, index) => ({
        item: item,
        key: `event_${item.id}`,
        type: "event",
        facilityName: item.machine?.facility?.name,
        label: item.machine.name,
        caption: item.title,
        badgeColor: getEventDotColor([item]),
      }));

    const logListItems: CalendarListItem[] = !logs
      ? []
      : logsBySelectedDate.map((item, index) => ({
        item: item,
        key: `log_${item.id}`,
        type: "log",
        facilityName: item.machine?.facility?.name,
        label: item.machine.name,
        caption: item.title,
        badgeColor: getLogDotColor([item]),
      }));

    const testResultListItems: CalendarListItem[] = !testResults
      ? []
      : testResultsByMachineFrequency.map((item, index) => ({
        item: item,
        key: item.key,
        type: "testResult",
        facilityName: item.facilityName,
        label: item.machineName,
        caption: `${item.items.length} test${item.items.length > 1 ? "s" : ""} done`,
        frequency: item.frequency,
        badgeColor: getTestResultDotColor(item.items),
      }));

    const testListItems: CalendarListItem[] = !tests
      ? []
      : testsByMachineFrequency.map((item, index) => ({
        item: item,
        key: item.key,
        type: "test",
        facilityName: item.facilityName,
        label: item.machineName,
        caption: `${item.tests.length} ${item.frequencyName} test${item.tests.length > 1 ? "s" : ""}`,
        frequency: item.frequency,
        badgeColor: getTestDotColor(item.tests),
      }));

    return eventListItems.concat(logListItems).concat(testResultListItems).concat(testListItems);
  }, [
    events,
    eventsBySelectedDate,
    getEventDotColor,
    getLogDotColor,
    getTestDotColor,
    getTestResultDotColor,
    logs,
    logsBySelectedDate,
    testResults,
    testResultsByMachineFrequency,
    tests,
    testsByMachineFrequency,
  ]);

  const renderListItem = useCallback(
    (index: number) => {
      const item = listItems[index];

      return (
        <>
          <Badge className={classes.eventBadge}
            variant={item.type === "event" || item.type === "log" ? "dot" : "dot"}
            shape={item.type === "event" || item.type === "log" ? "square" : undefined}
            htmlColor={item.badgeColor}
          >
            {item.type === "event" || item.type === "log" ? <CalendarTodayOutlined /> : <TestFrequencyIcon frequency={item.frequency!} />}
          </Badge>

          <div className={classes.eventText}>
            <div>{item.label}</div>
            <Typography variant="caption" color="textSecondary">
              {!searchbarFilter.facilityId && <div>{item.facilityName}</div>}
              {item.caption}
            </Typography>
          </div>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.eventText, classes.eventBadge, listItems]
  );

  const listComponents: Components = useMemo(() => {
    return {
      List: React.forwardRef(({ style, children }: ListProps, listRef: ForwardedRef<HTMLDivElement>) => {
        return (
          <List disablePadding style={{ ...style }} component="div" ref={listRef}>
            {children}
          </List>
        );
      }),

      Item: ({ children, ...props }) => {
        const index = props["data-item-index"];
        const disabled = false;

        const listItem = listItems[index];

        const handleClick =
          listItem.type === "event"
            ? handleSelectMachineEvent(listItem.item)
            : listItem.type === "log"
              ? handleSelectMachineLog(listItem.item)
              : listItem.type === "testResult"
                ? handleSelectMachineTestResults(listItem.label, listItem.item.id, listItem.item.frequency)
                : listItem.type === "test"
                  ? handleSelectMachineTests(listItem.label, listItem.item.tests)
                  : undefined;

        return (
          <ListItem component="div" {...props} button disabled={disabled} onClick={handleClick}>
            {children}
          </ListItem>
        );
      },
    };
  }, [handleSelectMachineEvent, handleSelectMachineLog, handleSelectMachineTestResults, handleSelectMachineTests, listItems]);

  return (
    <div id="calendar-container" className={clsx(classes.root)}>
      <div id="calendar" className={clsx(classes.calendar)}>
        {MemoizedCalendar}
      </div>
      <div id="calendar-events" className={classes.events} style={eventListStyles}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="body1" className={classes.eventsHeader}>
              {moment(value).format("MMMM Do, YYYY")}
            </Typography>
          </Grid>
          {actions && <Grid item>{actions}</Grid>}
        </Grid>
        {!testsBySelectedDate?.length && !testResultsBySelectedDate?.length && !eventsBySelectedDate?.length && !logsBySelectedDate?.length ? (
          <ImageWithText image={<CalendarSvg size="80px" />} title="Nothing to show" subheader="There are no calendar items for the selected day." />
        ) : (
          <>
            <Typography variant="caption" color="textSecondary">
              {tests && `${testsTotal >= 100 ? "100+" : testsBySelectedDate.length} Test${testsBySelectedDate.length !== 1 ? "s" : ""} Due`}
              {testResults && `, ${testResultsBySelectedDate.length} Test Result${testResultsBySelectedDate.length !== 1 ? "s" : ""}`}
              {events && `, ${eventsBySelectedDate.length} Event${eventsBySelectedDate.length !== 1 ? "s" : ""} Due`}
            </Typography>
            <Box className={classes.eventsList}>
              <Virtuoso components={listComponents} totalCount={listItems.length} itemContent={renderListItem} />
            </Box>

            {/*<List component="div" disablePadding>
              {events &&
                eventsBySelectedDate.map((item, index) => (
                  <ListItem key={`event_${index}`} button className={classes.event} onClick={handleSelectMachineEvent(item)}>
                    <Badge variant="dot" shape="square" htmlColor={getEventDotColor([item])}>
                      <CalendarTodayOutlined></CalendarTodayOutlined>
                    </Badge>

                    <div className={classes.eventText}>
                      <div>{item.machine.name}</div>
                      <Typography variant="caption" color="textSecondary">
                        {item.title}
                      </Typography>
                    </div>
                  </ListItem>
                ))}

              {logs &&
                logsBySelectedDate.map((item, index) => (
                  <ListItem key={`log_${index}`} button className={classes.event} onClick={handleSelectMachineLog(item)}>
                    <Badge variant="dot" shape="square" htmlColor={getLogDotColor([item])}>
                      <CalendarTodayOutlined></CalendarTodayOutlined>
                    </Badge>

                    <div className={classes.eventText}>
                      <div>{item.machine.name}</div>
                      <Typography variant="caption" color="textSecondary">
                        <div>{item.title}</div>
                      </Typography>
                    </div>
                  </ListItem>
                ))}

              {testResults &&
                testResultsByMachineFrequency.map((item, index) => (
                  <ListItem key={item.key} button className={classes.event} onClick={handleSelectMachineTestResults(item.machineName, item.items)}>
                    <Badge badgeContent={item.items.length} htmlColor={getTestResultDotColor(item.items)}>
                      <TestFrequencyIcon frequency={item.frequency}></TestFrequencyIcon>
                    </Badge>

                    <div className={classes.eventText}>
                      <div>{item.machineName}</div>
                      <Typography variant="caption" color="textSecondary">
                        {item.items.length} {`test${item.items.length > 1 ? "s" : ""} done`}
                      </Typography>
                    </div>
                  </ListItem>
                ))}

              {tests &&
                testsByMachineFrequency.map((item, index) => (
                  <ListItem key={item.key} button className={classes.event} onClick={handleSelectMachineTests(item.machineName, item.tests)}>
                    <Badge badgeContent={item.tests.length} htmlColor={getTestDotColor(item.tests)}>
                      <TestFrequencyIcon frequency={item.frequency}></TestFrequencyIcon>
                    </Badge>

                    <div className={classes.eventText}>
                      <div>{item.machineName}</div>
                      <Typography variant="caption" color="textSecondary">
                        {item.tests.length} {item.frequencyName} {`test${item.tests.length > 1 ? "s" : ""}`}
                      </Typography>
                    </div>
                  </ListItem>
                ))}
                </List>*/}
          </>
        )}
      </div>
    </div>
  );
}

export default Calendar;

import { useCallback } from "react";
import { useHttpClientContext, HttpClientRequestConfig } from "../context/http-client.context";
import errorMessages from "../translations/en/errors.json";
import { MachineOptionParam, getMachineOptionUrlPrefix } from "../models/machine-option-type.model";
import { getEventSearchParams, EventSearchParams } from "../models/event-search-params.model";
import { MachineTestResultSearchParams, getMachineTestResultSearchParams } from "../models/machine-test-result-search-params.model";
import { MachineTestActionResponse } from "../models/machine-test-action-response.model";
import { MachineTestResult, MachineTestResultFile, MachineTestResultNote } from "../models/machine-test-result.model";
import useFileUploadService from "./file-upload-service.hook";
import { File as OrgFile } from "../models/file.model";
import { Note } from "../models/note.model";
import environment from "../env";

export interface MachineTestResultService {
  getMachineTestResults(machineOptionType: MachineOptionParam, criteria: EventSearchParams): Promise<{ items: MachineTestResult[]; total: number }>;
  getMachineTestResult(id: number): Promise<MachineTestResult>;
  updateMachineTestResult(orgId: number, item: MachineTestResult): Promise<MachineTestResult>;
  addMachineTestResultFile(orgId: number, id: number, file: File): Promise<MachineTestResultFile>;
  addMachineTestResultNote(orgId: number, id: number, note: Note): Promise<MachineTestResultNote>;
  deleteMachineTestResultFile(orgId: number, testResultId: number, fileId: number): Promise<void>;
  deleteMachineTestResultNote(orgId: number, testResultId: number, noteId: number): Promise<void>;

  getMachineTestResultsByTestId(
    orgId: number,
    criteria: MachineTestResultSearchParams & { machineId: number; testId: number }
  ): Promise<{ items: MachineTestResult[]; total: number }>;
  getLastMachineTestResultByTestId(orgId: number, criteria: { machineId: number; testId: number; testVersionId?: number }): Promise<MachineTestResult>;
  signTestRuns(criteria: { ids?: number[]; machineTests?: string[] }): Promise<MachineTestActionResponse | undefined>;
  approveTestRuns(criteria: { ids?: number[]; machineTests?: string[] }): Promise<MachineTestActionResponse | undefined>;
  approveTestResultsV2(orgId: number, testResultIds: number[]): Promise<any>;
  signTestResultsV2(orgId: number, testResultIds: number[]): Promise<any>;
  rejectTestResultsV2(orgId: number, testResultIds: number[]): Promise<any>;
  rejectTestRuns(criteria: { ids?: number[]; machineTests?: string[] }): Promise<MachineTestActionResponse | undefined>;
}

function useMachineTestResultService(): MachineTestResultService {
  const { httpClient } = useHttpClientContext();
  const fileUploadService = useFileUploadService({ maxFileSizeBytes: 50000000 });

  /**
   * Gets machine test results.
   */
  const getMachineTestResults = useCallback(
    (machineOptionType: MachineOptionParam, criteria: EventSearchParams) => {
      const prefix = getMachineOptionUrlPrefix(machineOptionType);

      const endpoint = `${prefix}/testrun`;

      const apiCriteria = getEventSearchParams(criteria, true);

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.root as MachineTestResult[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  /**
   * Gets a machine test result.
   */
  const getMachineTestResult = useCallback(
    (id: number) => {
      const endpoint = `testrun`;

      const config: HttpClientRequestConfig = {
        params: { id },
        useRequestParamsToSnake: false,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const item = response.data.root[0] as MachineTestResult;

        return item;
      });
    },
    [httpClient]
  );

  /**
   * Updates a machine test result.
   */
  const updateMachineTestResult = useCallback(
    (orgId: number, item: MachineTestResult) => {
      const endpoint = `v2/orgs/${orgId}/test-results/${item.id}`;
      return httpClient.put(endpoint, item, { baseURL: environment.newApiUrl }).then((response) => {
        const item = response.data as MachineTestResult;

        return item;
      });
    },
    [httpClient]
  );

  /**
   * Adds a file to a machine test result.
   */
  const addMachineTestResultFile = useCallback(
    (orgId: number, id: number, file: File): Promise<MachineTestResultFile> => {
      const orgFileRequest = fileUploadService.uploadFile(file);

      return orgFileRequest.then((file: OrgFile) =>
        httpClient.post(`orgs/${orgId}/test-results/${id}/files`, file, { baseURL: environment.newApiUrl }).then((response) => {
          const item = response.data as MachineTestResultFile;
          return item;
        })
      );
    },
    [fileUploadService, httpClient]
  );

  /**
   * Adds a note to a machine test result.
   */
  const addMachineTestResultNote = useCallback(
    (orgId: number, id: number, note: Note): Promise<MachineTestResultNote> => {
      if (!note?.content) {
        return Promise.reject({ message: "Note is required." });
      }

      return httpClient.post(`orgs/${orgId}/test-results/${id}/notes`, note, { baseURL: environment.newApiUrl }).then((response) => {
        const item = response.data as MachineTestResultNote;
        return item;
      });
    },
    [httpClient]
  );

  /**
   * delete file from machine test result.
   */
  const deleteMachineTestResultFile = useCallback(
    (orgId: number, testResultId: number, fileId: number) => {
      return httpClient.delete(`orgs/${orgId}/test-results/${testResultId}/files/${fileId}`, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  /**
   * delete note from machine test result.
   */
  const deleteMachineTestResultNote = useCallback(
    (orgId: number, testResultId: number, noteId: number) => {
      return httpClient.delete(`orgs/${orgId}/test-results/${testResultId}/notes/${noteId}`, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  /**
   * Gets the test runs for a test.
   */
  const getMachineTestResultsByTestId = useCallback(
    (orgId: number, criteria: MachineTestResultSearchParams & { machineId: number; testId: number }) => {
      // Extract the fields that need converted or excluded
      const apiCriteria = getMachineTestResultSearchParams(criteria, true);

      //const endpoint = `machine/${criteria.machineId}/test/${criteria.testId}/testrun`;
      const endpoint = `v2/orgs/${orgId}/test-results`;
      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.items as MachineTestResult[];
        //const items = response.data.root as MachineTestResult[];
        const total = response.data.total as number;
        return { items, total };
      });
    },
    [httpClient]
  );

  /**
   * Gets the last test result for a test id
   */
  const getLastMachineTestResultByTestId = useCallback(
    (orgId: number, criteria: { machineId: number; testId: number; testVersionId?: number }) => {
      const newCriteria = {
        ...criteria,
        notexcluded: true,
        sortField: "test_date",
        sortDirection: "d" as any,
        page: 1,
        itemsPerPage: 1,
        includeData: 1,
      };

      return getMachineTestResultsByTestId(orgId, newCriteria).then((response) => (response?.items[0] || null) as MachineTestResult);
    },
    [getMachineTestResultsByTestId]
  );

  const signTestRuns = useCallback(
    (criteria: { ids?: number[]; machineTests?: string[] }) => {
      const endpoint = `testrun/action/signoff`;

      const body = { action: "signoff", ...criteria };

      return httpClient.post(endpoint, body).then((response) => {
        const result: MachineTestActionResponse | undefined = response.data.root[0];
        return result;
      });
    },
    [httpClient]
  );

  const approveTestRuns = useCallback(
    (criteria: { ids?: number[]; machineTests?: string[] }) => {
      const endpoint = `testrun/action/approve`;

      const body = { action: "approve", ...criteria };

      return httpClient.post(endpoint, body).then((response) => {
        const result: MachineTestActionResponse | undefined = response.data.root[0];
        return result;
      });
    },
    [httpClient]
  );

  const approveTestResultsV2 = useCallback(
    (orgId: number, testResultIds: number[]) => {
      const endpoint = `v2/orgs/${orgId}/test-results/action`;
      const criteria = { testResultIds: testResultIds, action: "approve" };

      return httpClient.put(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        const result = response.data;
        return result;
      });
    },
    [httpClient]
  );

  const signTestResultsV2 = useCallback(
    (orgId: number, testResultIds: number[]) => {
      const endpoint = `v2/orgs/${orgId}/test-results/action`;
      const criteria = { testResultIds: testResultIds, action: "signoff" };

      return httpClient.put(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        const result = response.data;
        return result;
      });
    },
    [httpClient]
  );

  const rejectTestResultsV2 = useCallback(
    (orgId: number, testResultIds: number[]) => {
      const endpoint = `v2/orgs/${orgId}/test-results/action`;
      const criteria = { testResultIds: testResultIds, action: "reject" };

      return httpClient.put(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        const result = response.data;
        return result;
      });
    },
    [httpClient]
  );

  const rejectTestRuns = useCallback(
    (criteria: { ids?: number[]; machineTests?: string[] }) => {
      const endpoint = `testrun/action/reject`;

      const body = { action: "reject", ...criteria };

      return httpClient.post(endpoint, body).then((response) => {
        const result: MachineTestActionResponse | undefined = response.data.root[0];
        return result;
      });
    },
    [httpClient]
  );

  return {
    getMachineTestResults,
    getMachineTestResult,
    updateMachineTestResult,
    addMachineTestResultFile,
    addMachineTestResultNote,
    deleteMachineTestResultFile,
    deleteMachineTestResultNote,
    getMachineTestResultsByTestId,
    getLastMachineTestResultByTestId,
    signTestRuns,
    approveTestRuns,
    approveTestResultsV2,
    signTestResultsV2,
    rejectTestResultsV2,
    rejectTestRuns,
  };
}

export default useMachineTestResultService;

import React from "react";
import Card, { CardProps } from "../Card/Card";
import { MoreVert } from "@material-ui/icons";
import CardHeader from "../CardHeader/CardHeader";
import PageLoader from "../PageLoader/PageLoader";
import IconButton from "../IconButton/IconButton";

export interface WidgetCardProps extends CardProps {
  header?: string;
  subheader?: React.ReactNode | string;
  loading?: boolean;
  actions?: React.ReactNode;
}

function WidgetCard({ children, header, subheader, loading, actions, ...rest }: WidgetCardProps) {
  const defaultProps: CardProps = {
    style: { minHeight: "200px" },
    ...rest,
  };

  return (
    <Card {...defaultProps}>
      {(actions || header || subheader) && (
        <>
          <CardHeader action={actions} title={header} subheader={subheader} />
          <br />
        </>
      )}
      {children}
      {loading && <PageLoader opacity="0.5" position="absolute" />}
    </Card>
  );
}

export default WidgetCard;

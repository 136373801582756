export type FacilityOptionParam = { orgId: number } | { facilityId: number };
export type MachineOptionParam = FacilityOptionParam | { machineId: number };

export type FacilityOptionTypeName = "organization" | "facility";
export type MachineOptionTypeName = FacilityOptionTypeName | "machine";

/**
 * Converts machine option param to group with id for requests.
 * @param machineOptionType
 */
export function getMachineOptionUrlPrefix(machineOptionType: MachineOptionParam): string {
  const group = (machineOptionType as any).machineId
    ? { name: "machine", groupId: (machineOptionType as any).machineId }
    : (machineOptionType as any).facilityId
    ? { name: "facility", groupId: (machineOptionType as any).facilityId }
    : { name: "org", groupId: (machineOptionType as any).orgId };

  return `${group.name}/${group.groupId}`;
}

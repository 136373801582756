import React, { createContext, useContext, useEffect } from "react";
import usePersistedState from "../hooks/persisted-state.hook";
import errorMessages from "../translations/en/errors.json";

export interface StorageContext {}

const Context = createContext<StorageContext>(null!);

function StorageContextProvider(props: React.PropsWithChildren<{ version: string }>): JSX.Element {
  const { children, version } = props;

  const [_version, _setVersion] = usePersistedState<string | undefined>("context/storage/version", { priority: 1, storageType: "local" });

  useEffect(() => {
    if (version !== _version) {
      console.log(`Clearing storage for update ${version} > ${_version}...`);
      // New version so clear storage.
      // The issue here is if code for storage changes, stuff will remain in local storage, never again to be used.
      localStorage.clear();
      sessionStorage.clear();
      _setVersion(version);

      // Force reload of window
      window.location.reload();
    }
  }, [version, _version, _setVersion]);

  const contextValue: StorageContext = {};

  return <Context.Provider value={{ ...contextValue }}>{children}</Context.Provider>;
}

function useStorageContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { StorageContextProvider, useStorageContext };

import { MachineType, Machine } from "./machine.model";
import { TestGraphSeries } from "./test-graph.model";
import { User } from "./user.model";

export enum MachineTestFrequencyId {
  Daily = 1,
  Weekly = 2,
  Biweekly = 3,
  Monthly = 4,
  Quarterly = 5,
  SemiAnnual = 6,
  Yearly = 7,
  BiAnnual = 730,
}

export enum MachineTestFrequency {
  Daily = 1,
  Weekly = 7,
  Biweekly = 14,
  Monthly = 30,
  Quarterly = 91,
  Semiannual = 183,
  Yearly = 365,
  Biannual = 730,
}

export interface MachineTest {
  daysSinceLastRun: number;
  dueAt: string;
  facilityId: number;
  facilityName: string;
  frequency: number;
  id: string;
  due: number;
  label: string;
  machineActive: number;
  machineId: number;
  machineName: string;
  machineType: string;
  machineTypeId: number;
  maxTestDate: string;
  //minOfAllMaxes: any;
  mytests: number;
  numReadyGroups: number;
  numReadyItems: number;
  orderby: number;
  orgId: number;
  orgId2: number;
  orgName: string;
  partial: number;
  pendingApprove: number;
  setupId: number;
  setupId2: number;
  setupMatches: number;
  startDate: string;
  status: string;
  //subtests: any;
  //subtestsEnabled: any;
  testId: number;
  testName: string;
  testType: string;
  testVersionId: number;
  testOrderId: number;
  testOrdinal: number;
}

export interface MachineTestOrder {
  id: number;
  testId: number;
  machineId: number;
  ordinal: number;
}

/**
 * derived from setup_subtest table
 */
export interface MachineSubtest {
  enabled: boolean;
  id: number;
  machineId: number;
  ordinal: number;
  subtestValue: MachineSubtestValue;
  subtestValueId: number;
  testId: number;
}

export interface MachineSubtestValue {
  id: number;
  setId: number;
  name: string;
  label: string;
  units: string;
  value: string;
}

export interface MachineTypeTest {
  id: number;
  machineTypeId: number;
  active: boolean;
  name: string;
  graphs: TestGraphSeries[];
  machineType: MachineType;
  versions: MachineTypeSubtest[];
  updatedAt: string;
}

export interface MachineTestProcedure {
  id: number;
  name: string;
  orgId: number;
  setups: { id: number }[];
}

export interface MachineTestSetup {
  id: number;
  machineId: number;
  oldId: string;
  alias: string;
  frequency: number;
  procedure: any;
  procedureId: number;
  startDate: string;
  testSetupFields: MachineTestSetupData[];
  data: MachineTestSetupData[];
  subtests: MachineSubtest[];
  testVersion: MachineTypeSubtest;
  testVersionId: number;
  updatedAt: string;
}

export interface MachineTestSetupData {
  id: number;
  setupId: number;
  testField: MachineTypeSubtestField;
  testFieldId: number;
  updatedAt: string;
  // TODO: return value as string and then return the type so we can properly parse
  val: any;
}

/**
 * derived from test_version table
 */
export interface MachineTypeSubtest {
  defaultFrequency: string;
  id: number;
  label: string;
  name: string;
  parentId: number | null;
  parent?: MachineTypeSubtest;
  subtestValueId: number | null;
  testFields: MachineTypeSubtestField[];
  test?: MachineTypeTest;
  testId: number;
  version: number;
}

export interface MachineTypeSubtestField {
  activeIfOld: string;
  analyzePath: string;
  comboValues: { name: string; value: any; label: string }[];
  defaultValue: any;
  evalLabel: boolean;
  evalUnits: boolean;
  formula: string;
  groupLabel: string;
  id: number;
  isActive: boolean;
  label: string;
  name: string;
  optional: boolean;
  orderby: number;
  parentTestOnly: boolean;
  path: string;
  postRunLabel: string;
  testFieldActive: TestFieldActive[];
  testVersionId: number;
  type: string;
  units: string;
  xtype: string;
}

export interface TestFieldActive {
  val: any;
  criteriaField: {
    name: string;
  };
  criteriaFieldId: number;
}

export interface MachineTestSetupHistorySearch {
  machineId: number;
  testId: number;
  search?: string;
  page?: number;
  itemsPerPage?: number;
}

export interface MachineTestSetupHistory {
  id: number;
  startDate: Date;
  createdAt: Date;
  userCreatedId: number;
  userCreatedName: string;
  userCreatedEmail: string;
  machineId: number;
  testId: number;
  testVersionId: number;
  procedureId: number;
  procedureName: number;
  frequencyId: number;
  frequencyVal: number;
  frequencyLabel: string;
}

export interface MachineTestSetupFieldHistory {
  id: number;
  testFieldId: number;
  value: string;
  previousValue: string;
  valueChanged: boolean;
  type: string;
  label: string;
  units: string;
  orderBy: number;
  subTest: boolean;
  xType: string;
  testName: string;
}

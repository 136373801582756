import { createContext, useContext, useCallback, useMemo, useEffect, useState, Dispatch, SetStateAction, PropsWithChildren, useRef } from "react";
import usePersistedState from "../hooks/persisted-state.hook";
import { User, UserNotificationItem } from "../models/user.model";
import useUserService from "../hooks/user-service.hook";
import errorMessages from "../translations/en/errors.json";
import { Permission } from "../models/group.model";
import { useAuthContext } from "./auth.context";
import { GtmEvent, useGtmContext } from "./gtm.context";
import moment from "moment";
import { useQuery } from "react-query";
import { Organization } from "../models/organization.model";
import { SearchbarSearchResult } from "../models/searchbar.model";
import useOrgService from "../hooks/org-service.hook";
import { useStatusEffect } from "../hooks/status-effect.hook";

export type Role = "super" | "default";
const defaultRole = "default";

export interface UserContext {
  loading: boolean;
  user: User | null;
  org: Organization | null;
  notifications: UserNotificationItem[] | null;
  deleteNotification(notif: UserNotificationItem): Promise<void>;

  role: Role;
  setRole: Dispatch<SetStateAction<Role>>;

  setOrgId: Dispatch<SetStateAction<number | null>>;

  facility: SearchbarSearchResult | null;
  setFacility: Dispatch<SetStateAction<SearchbarSearchResult | null>>;

  machine: SearchbarSearchResult | null;
  setMachine: Dispatch<SetStateAction<SearchbarSearchResult | null>>;

  updateUser(user: User): Promise<User>;
  refreshOrg(): void;
  hasPermission(permission: Permission, options?: { facilityId?: number; deviceTypeId?: number }): boolean;
}

const Context = createContext<UserContext>(null!);

function UserContextProvider(props: PropsWithChildren<{}>): JSX.Element {
  const [notifications, setNotifications] = useState<UserNotificationItem[] | null>([]);

  const [role, setRole] = usePersistedState<Role>(`context/user/role`, {
    defaultValue: defaultRole,
  });

  const { gtmEnabled, sendToDataLayer } = useGtmContext();
  const { session, signOut } = useAuthContext();

  const { getUser, updateUser: _updateUser, deleteNotificationItem: _deleteNotifItem } = useUserService();

  const { getOrgById } = useOrgService();

  // eslint-disable-next-line
  const orgAdminPermissions = [
    Permission.orgAdminEquipmentFields,
    Permission.orgAdminManageAutoLogout,
    Permission.orgAdminManagePasswordSettings,
    Permission.orgAdminManagePermissionGroups,
    Permission.orgAdminViewHiddenFields,
  ];

  // Get current user.
  const [refreshUser, setRefreshUser] = useState(0);
  const [_refreshOrg, setRefreshOrg] = useState(0);
  const hasOrgs = useRef(false);

  const {
    data: user,
    status: userStatus,
    error: userError,
    isFetchedAfterMount: userLoaded,
  } = useQuery<User | undefined, Error>(
    ["context/user", session, refreshUser],
    async () => {
      const response = !session ? undefined : await getUser();
      if (response) {
        // Adding calculated property (orgOrSuperAdmin) to user object
        response.orgOrSuperAdmin = (response.superAdmin ?? false) || (response.orgAdmin ?? false);
      }
      return response;
    },
    {
      // This allows us to not reload the UI based on the user being refetched when session changes.
      keepPreviousData: true,
    }
  );

  // User state saved to local storage. These are persisted even after logout.

  const [orgId, setOrgId] = usePersistedState<number | null>(`context/user/org`, {
    defaultValue: null,
    storageType: "local",
    priority: 1,
  });

  const [facility, setFacility] = usePersistedState<SearchbarSearchResult | null>("context/user/facility", {
    defaultValue: null,
    storageType: "local",
    priority: 1,
  });

  const [machine, setMachine] = usePersistedState<SearchbarSearchResult | null>("context/user/machine", {
    defaultValue: null,
    storageType: "local",
    priority: 1,
  });

  // Get selected org.
  const {
    data: org,
    status: orgStatus,
    error: orgError,
    isFetchedAfterMount: orgLoaded,
  } = useQuery<Organization | undefined, Error>(
    ["context/user/org", user, orgId, _refreshOrg],
    async () => {
      const response = !orgId || !user ? undefined : await getOrgById(orgId);
      return response;
    },
    {
      // This allows us to not reload the UI based on the user being refetched when session changes.
      keepPreviousData: true,
    }
  );

  // Roles are a side effect of org.
  const roles = useMemo(() => (org ? org.orgRoles || [] : null), [org]);

  // If errors then remove state and log out.
  useEffect(() => {
    if (userError || orgError) {
      signOut();
    }
  }, [signOut, userError, orgError]);

  // If user changes send user data to tag manager.
  useEffect(() => {
    if (gtmEnabled) {
      if (user) {
        // Remember this info is publicly visible so we only want to store what we need.
        const { email, firstname, lastname } = user;
        sendToDataLayer({ event: GtmEvent.setUser, user: { email, firstname, lastname, org: org?.name, facility: facility?.name } });
      } else {
        sendToDataLayer({ event: GtmEvent.setUser, user: null });
      }
    }
  }, [gtmEnabled, sendToDataLayer, user, org, facility]);

  // Adjust state based on user.
  useStatusEffect(
    (status) => {
      if (status.current === "unmount" || !userLoaded) {
        return;
      }

      // Notifications
      const sortedNotifs = !user?.notifications ? null : user.notifications.sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix());

      setNotifications(sortedNotifs);
      setRole((prev) => {
        const r = user?.superAdmin ? "super" : defaultRole;

        if (prev === r) {
          return prev;
        }

        return r;
      });

      hasOrgs.current = Boolean(user && user.orgs && user.orgs.length > 0);
    },
    [setRole, user, userLoaded]
  );

  // Adjust state based on org.
  useStatusEffect(
    (status) => {
      if (status.current === "unmount" || !userLoaded || !user || !orgLoaded) {
        return;
      }

      if (!org) {
        // No org selected so select the first or null. Null could only happen if a user was removed from all orgs.
        setFacility(null);
        setOrgId(user && user.orgs && user.orgs.length ? user.orgs[0].id : null);
        return;
      }

      // The user has access to the org fetched by the orgId.
      setFacility((prev) => {
        return prev?.parent?.id === org.id ? prev : null;
      });
    },
    [user, userLoaded, org, orgLoaded, setFacility, setOrgId]
  );

  // Adjust state based on facility.
  useStatusEffect(
    (status) => {
      if (status.current === "unmount") {
        return;
      }

      if (!facility) {
        // No org selected so select the first or null. Null could only happen if a user was removed from all orgs.
        setMachine((prev) => (prev == null ? prev : null));
        return;
      }

      // The user has access to the facility.
      setMachine((prev) => (prev?.parent?.id === facility.id ? prev : null));
    },
    [facility, setMachine]
  );

  // Adjust state based on roles.
  useStatusEffect(
    (status) => {
      if (status.current === "unmount" || !userLoaded || !user || !orgLoaded) {
        return;
      }

      if (!roles) {
        // If roles do not exist then we need to wait for them to exist.
        return;
      }

      setFacility((prev) => {
        // If not selected then nothing to do.
        if (!prev || role === "super") {
          return prev;
        }

        // If selected but no roles then remove selection.
        if (!roles || !roles.length) {
          return null;
        }

        const facilityRole = roles.find((i) => i.facilityId === prev.id) || null;

        if (!facilityRole) {
          return null;
        }

        // User can access selected so all good.
        return prev;
      });

      setMachine((prev) => {
        // If not selected then nothing to do.
        if (!prev || role === "super") {
          return prev;
        }

        // If selected but no roles then remove selection.
        if (!roles || !roles.length) {
          return null;
        }

        const facilityRole = roles.find((i) => i.facilityId === prev.parent!.id) || null;

        if (!facilityRole) {
          return null;
        }

        // User can access selected so all good.
        return prev;
      });
    },
    [user, userLoaded, orgLoaded, roles, setFacility, setMachine, role]
  );

  const updateUser = useCallback(
    async (user: User) => {
      const response = await _updateUser(user);
      // Refresh the user.
      setRefreshUser((prev) => prev + 1);
      return response;
    },
    [_updateUser]
  );

  const refreshOrg = useCallback(
    () => {
      // Refresh the org.
      setRefreshOrg((prev) => prev + 1);
    },
    [setRefreshOrg]
  );

  const deleteNotification = useCallback(
    async (notif: UserNotificationItem) => {
      setNotifications((prev) => prev ? prev!.filter((n) => n.id !== notif.id) : null);

      try {
        _deleteNotifItem(notif);
      } catch (e) {
        setNotifications((prev_1) => [...prev_1!, notif].sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix()));
      }
    },
    [_deleteNotifItem, setNotifications]
  );

  const hasPermission = useCallback(
    (permission: Permission, options?: { facilityId?: number; deviceTypeId?: number }): boolean => {
      if (role === "super") {
        return true;
      }

      // check if the permission is an old org admin permission
      // if it is, check if the user is an org admin or super admin
      if (orgAdminPermissions.some((p) => p === permission)) {
        return user?.orgOrSuperAdmin ?? false;
      }

      if (!roles) {
        return false;
      }

      const { facilityId, deviceTypeId } = options || {};

      // If no facility id then use the org role.
      const orgRole = facilityId ? roles.find((r) => r.facilityId === facilityId) : roles.find((r) => r.facilityId === null);

      if (!orgRole) {
        return false;
      }

      const perms = orgRole.role.permissions || [];
      const deviceTypes = orgRole.deviceTypes || [];

      return perms.includes(permission) && (!deviceTypeId || deviceTypes.includes(deviceTypeId));
    },
    [role, roles, orgAdminPermissions, user?.orgOrSuperAdmin]
  );

  const contextValue: UserContext = {
    loading: !userLoaded || !orgLoaded || ["error", "loading"].includes(userStatus) || ["error", "loading"].includes(orgStatus),
    user: user || null,
    notifications,
    deleteNotification,
    org: org || null,
    setOrgId,
    facility,
    setFacility,
    machine,
    setMachine,
    updateUser,
    refreshOrg,
    hasPermission,
    role,
    setRole,
  };

  return <Context.Provider value={{ ...contextValue }}>{props.children}</Context.Provider>;
}

function useUserContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { UserContextProvider, useUserContext };

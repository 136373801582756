import React, { useState, FormEvent, useEffect, useCallback } from "react";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../../context/auth.context";
import { useUserContext } from "../../../../context/user.context";
import { useHttpClientContext } from "../../../../context/http-client.context";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import Footer from "../../../components/Footer/Footer";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { useAppContext } from "../../../../context/app.context";
import environment, { EnvId } from "../../../../env";
import LoginForm from "./Forms/LoginForm";
import RegisterForm from "./Forms/RegisterForm";
import { FormControlErrors } from "../../../../hooks/form.hook";
import { LoginRequest, RegisterRequest } from "../../../../models/login-request.model";
import { Alert } from "@material-ui/lab";
import ResetForm from "./Forms/ResetForm";

const useStyles = makeStyles((theme) => ({
  page: {
    background: theme.palette.background.default,
    width: "100%",
    minHeight: "100%",
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
  },
  centerContainer: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  login: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexShrink: 0,
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  logo: {
    transform: 'scale(.7) translate(0, 50px)',
    height: 80,
  },
  enzeeLogoCaption: {
    transform: 'translate(0, 25px)'
  },
  enzeeLogo: {
    height: 80,
    transform: 'scale(.4)'
  },
  learnMoreEnzee: {
    transform: 'translate(0, 20px)',
    fontSize: '1.3rem'
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginBottom: theme.spacing(2),
  },
}));

export type loginFormType = "login" | "register" | "reset";

function Login(props: { formType?: loginFormType }) {
  const formType = props.formType || "login";

  const location = useLocation();

  //const verifyCode = useMemo(() => location.pathname.endsWith("/verify"), [location.pathname]);

  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Container className={classes.centerContainer} component="div" maxWidth="xs">
        <div className={classes.login}>
          <div className={classes.logoContainer}>
            <img src="/logo.svg" className={classes.logo} alt="ZapIT - A product of Enzee" />
            <Typography component="div" color="textPrimary" className={classes.enzeeLogoCaption} >A product of</Typography>
            <img src="/enzee-logo.svg" className={classes.enzeeLogo} alt="Enzee" />
          </div>
          <Typography component="h1" variant="h5" className={classes.header} color="textPrimary">
            {formType === "reset" ? "Reset Password" : formType === "register" ? "Join Us" : "Sign In"}
          </Typography>
          <div className={classes.form}>{formType === "reset" ? <ResetForm /> : formType === "register" ? <RegisterForm /> : <LoginForm />}</div>
          {
            <Grid container justify={formType === "login" ? "space-between" : "center"}>
              {formType === "login" && (
                <Grid item>
                  <Link component={RouterLink} to={`/reset`} variant="body2">
                    Forgot password? Reset it
                  </Link>
                </Grid>
              )}
              <Grid item>
                {formType === "register" ? (
                  <Link component={RouterLink} to="/login" variant="body2">
                    {"Have an account? Sign in"}
                  </Link>
                ) : (
                  <Link component={RouterLink} to="/register" variant="body2">
                    {"Need an account? Sign up"}
                  </Link>
                )}
              </Grid>
            </Grid>
          }
          <Typography className={classes.learnMoreEnzee} color="textPrimary">
            Learn more about Enzee <Link target="_blank" href="http://www.enzeehealth.com">here</Link>
          </Typography>
          <Box mt={8}>
            <Footer env={environment.id === EnvId.Prod ? undefined : EnvId[environment.id].toLowerCase()} version={environment.version} />
          </Box>
        </div>
      </Container>
    </div>
  );
}

export default Login;

import Button from "@material-ui/core/Button";
import ImageWithText from "../../../components/ImageWithText/ImageWithText";
import Missing from "../../../components/Svg/Missing";
import { Link } from "react-router-dom";

import { Link as MuiLink } from "@material-ui/core";

function NotFound() {
  return (
    <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <ImageWithText
        align="center"
        title="Page not found"
        subheader="The page you're looking for has been moved or removed."
        image={<Missing size="120px" color="#d0d0d0" />}
        action={
          <MuiLink component={Link} underline="none" to="/">
            <Button color="secondary" variant="outlined">
              Go home
            </Button>
          </MuiLink>
        }
      />
    </div>
  );
}

export default NotFound;

import { FrequencyLookup } from "@zapitmedical/zapit-common/dist/lookups";
import moment from "moment";
import { useCallback } from "react";
import { useHttpClientContext } from "../context/http-client.context";
import environment from "../env";
import { MachineTestFrequencyId } from "../models/machine-test.model";
import { Notification, NotificationId, NotificationText } from "../models/notification.model";
import { UserNotification, UserNotificationItem, UserNotificationQuerySearch } from "../models/user.model";

export interface UserNotificationService {
  saveNotification(data: UserNotification): Promise<UserNotification>;
  getNotifications(search: UserNotificationQuerySearch): Promise<UserNotification[]>;
  getNotificationTypes(): Promise<Notification[]>;
  getMyNotificationItems(search: UserNotificationQuerySearch): Promise<UserNotificationItem[]>;
  deactivateNotificationItem(notification: UserNotificationItem): Promise<void>;
  getNotificationText(item: UserNotification): NotificationText;
}

export interface UserServiceOptions {}

function useUserNotificationService(options?: UserServiceOptions): UserNotificationService {
  const { httpClient } = useHttpClientContext();

  const saveNotification = useCallback(
    async (data: UserNotification) => {
      const options = {
        baseURL: environment.newApiUrl,
      };
      const response = await httpClient.post("v2/user-notifications", data, options);
      return response.data;
    },
    [httpClient]
  );

  const getNotifications = useCallback(
    async (search: UserNotificationQuerySearch) => {
      const options = {
        params: search,
        baseURL: environment.newApiUrl,
      };
      const response = await httpClient.get<UserNotification[]>("v2/user-notifications/me", options);
      return response.data;
    },
    [httpClient]
  );

  const getNotificationTypes = useCallback(async () => {
    const options = {
      baseURL: environment.newApiUrl,
    };
    const response = await httpClient.get<Notification[]>("v2/user-notifications/types", options);
    return response.data;
  }, [httpClient]);

  const getMyNotificationItems = useCallback(
    async (search: UserNotificationQuerySearch) => {
      const options = {
        params: search,
        baseURL: environment.newApiUrl,
      };
      const response = await httpClient.get<UserNotificationItem[]>("v2/user-notifications/myitems", options);
      return response.data;
    },
    [httpClient]
  );

  const deactivateNotificationItem = useCallback(
    async (notificationItem: UserNotificationItem) => {
      const url = `v2/user-notifications/myitems/${notificationItem.id}/deactivate`;
      const options = { baseURL: environment.newApiUrl };
      await httpClient.post(url, {}, options);
    },
    [httpClient]
  );

  function getNotificationText(item: UserNotification): NotificationText {
    const frequencies = Object.values(FrequencyLookup);
    const notificationType = item.notification;

    const orgfacmac = (item.machine || item.facility || item.org)?.name;
    const report = item.report ? `Report ${item.report.title}` : "";
    const notif = !report && notificationType ? notificationType.emailSubject : "";
    const frequency = frequencies.find((f) => f.id === item.deliveryFrequencyId);
    const machineType = item.machineType?.label;
    const schedule =
      item.notificationId === NotificationId.ScheduleItemDue
        ? `${item.daysPrior} ${(item.daysPrior || 1) > 1 ? "days" : "day"} before due`
        : // Get the correct frequency and time here
        frequency
        ? frequency.id === MachineTestFrequencyId.Daily
          ? `${frequency.label} at ${item.notificationTime.label}`
          : frequency.id === MachineTestFrequencyId.Weekly
          ? // The `day` method is zero-based, i.e., Sunday = 0
            `Weekly on ${moment().day(item.notificationDayOfWeek).format("dddd")}`
          : frequency.id === MachineTestFrequencyId.Monthly
          ? `Monthly on the ${moment.utc(`2020-01-${String(item.notificationDayOfMonth).padStart(2, "0")}`).format("Do")}`
          : frequency.label
        : "Immediate";

    const title = `${orgfacmac ? `${orgfacmac} - ` : ""}${report ? `${report} ` : ""}${notif ? `${notif} ` : ""}${machineType ? `(${machineType})` : ""}`;

    return {
      title,
      schedule: `${schedule}`,
    };
  }

  return {
    saveNotification,
    getNotifications,
    getNotificationTypes,
    getMyNotificationItems,
    deactivateNotificationItem,
    getNotificationText,
  };
}

export default useUserNotificationService;

import React, { useEffect, useState, useCallback, useMemo, ForwardedRef, useRef } from "react";
import WidgetCard, { WidgetCardProps } from "../../../components/WidgetCard/WidgetCard";
import { MachineTest } from "../../../../models/machine-test.model";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles, ListItem, Typography, ListItemIcon, ListItemText, Link, List, Box, ListProps, Grid, MenuItem, Menu } from "@material-ui/core";
import { HelpOutline, MoreVert, RemoveCircleOutline } from "@material-ui/icons";
import _ from "underscore";
import { enumToLookup } from "../../../../models/lookup.model";

import { MachineTestFrequency } from "../../../../models/machine-test.model";
import Button from "../../../components/Button/Button";
import { EventSearchParams } from "../../../../models/event-search-params.model";
import useMachineTestService from "../../../../hooks/machine-test-service.hook";
import IconButton from "../../../components/IconButton/IconButton";
import { useUserContext } from "../../../../context/user.context";
import ImageWithText from "../../../components/ImageWithText/ImageWithText";
import Math from "../../../components/Svg/Atom";
import { Permission } from "../../../../models/group.model";
import RetryWidget from "../../../components/ImageCaptions/RetryWidget";
import { Components, Virtuoso } from "react-virtuoso";
import { useQuery } from "react-query";
import useDeviceTestService from "../../../../hooks/device-test-service.hook";
import { DeviceTest } from "../../../../models/device-test.model";
import { DeviceTestSearch } from "../../../../models/device-test-search.model";
import useSearchbarService from "../../../../hooks/searchbar-service.hook";

interface ScheduleWidgetOptions {
  criteria: DeviceTestSearch;
}

const defaultScheduleWidgetOptions: ScheduleWidgetOptions = {
  criteria: {
    itemsPerPage: 1000
  },
};

const machineTestFrequencies = enumToLookup(MachineTestFrequency);

export interface ScheduleWidgetProps extends WidgetCardProps {
  onRemoveWidget?: () => void;
}

function ScheduleWidget(props: ScheduleWidgetProps) {
  const { getDeviceTests, hasTestPermission } = useDeviceTestService();
  const { org, facility, machine, hasPermission } = useUserContext();
  const [retry, setRetry] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [scheduleWidgetOptions] = useState<ScheduleWidgetOptions>(defaultScheduleWidgetOptions);
  const { searchbarFilter } = useSearchbarService();
  const history = useHistory();

  const options = scheduleWidgetOptions || defaultScheduleWidgetOptions;

  const {
    isLoading: testsLoading,
    isError: testsError,
    data: tests,
  } = useQuery(["home/widget/schedule/tests", searchbarFilter, org, retry], {
    queryFn: async () => {
      if (!options.criteria || !searchbarFilter || !org) {
        return Promise.resolve([]);
      }

      options.criteria.facilityId = searchbarFilter.facilityId;
      options.criteria.machineId = searchbarFilter.machineId;
      options.criteria.page = 1;
      options.criteria.itemsPerPage = 1000;
      const orgId = org ? org.id : searchbarFilter.orgId!;

      const result = await getDeviceTests(orgId, options.criteria);
      return result.items.filter(test => hasTestPermission(test.facilityId, test.machineTypeId, test.frequency));
    },
    refetchOnMount: 'always',
  });

  // Memoize function result by side affect of value
  const testsByMachineFrequency = useMemo(() => {
    if (!tests?.length) {
      return [];
    }

    const displayTests = tests.map((i) => {
      const nowUtc = moment.utc();
      const dueUtc = moment.utc(i.dueAt);

      const dueDate = nowUtc.startOf("date").isAfter(dueUtc) ? nowUtc : dueUtc;

      const dueMonth = dueDate.format("MMM").toUpperCase();
      const dueDay = dueDate.format("D");
      const due = dueDate.format("YYYY-MM-DD");

      return {
        key: `${i.frequency}_${i.machineId}_${due}`,
        frequency: machineTestFrequencies.find((freq) => freq.id === i.frequency)?.name,
        machineName: i.machineName,
        facilityName: i.facilityName,
        dueDate: due,
        dueMonth,
        dueDay,
        tests: [i],
      };
    });

    const t = [displayTests[0]];

    for (let i = 1; i < displayTests.length; i++) {
      const groupedItem = t.find((g) => g.key === displayTests[i].key);

      if (groupedItem) {
        groupedItem.tests = [...groupedItem.tests, ...displayTests[i].tests];
        continue;
      }

      t.push(displayTests[i]);
    }

    return t.sort((a, b) => moment(a.dueDate).diff(moment(b.dueDate), "days"));
  }, [tests]);

  const handleRunTests = useCallback(
    (tests: DeviceTest[]) => () => {
      history.push(`/tests/runs/${tests[0].machineId}`, {
        previousLocation: { pathname: `/` },
        tests: tests,
      });
    },
    [history]
  );

  const handleRetry = useCallback(() => {
    setRetry((prev) => prev + 1);
  }, []);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const renderListItem = useCallback(
    (index: number) => {
      const item = testsByMachineFrequency[index];
      const getSecondaryText = (item: any) => {
        const text = `${item.tests.length} ${item.frequency} test${item.tests.length > 1 ? "s" : ""}`;

        if (searchbarFilter.facilityId) {
          return text;
        }

        return `${item.facilityName}\n${text}`;
      };

      return (
        <>
          <ListItemIcon>
            <Button color="primary">
              <div>
                <div className="month">
                  <Typography variant="caption">{item.dueMonth}</Typography>
                </div>
                <div className="day">{item.dueDay}</div>
              </div>
            </Button>
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ style: { overflow: "hidden" } }}
            primary={item.machineName}
            // Use whiteSpace: "pre-line" so all white space is removed except new lines.
            // Some machine and/or facility names have leading white space, and "pre-line"
            // prevents that leading white space from rendering.
            secondaryTypographyProps={{ variant: "caption", style: { whiteSpace: "pre-line" } }}
            secondary={getSecondaryText(item)}
          />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [testsByMachineFrequency]
  );

  const listComponents: Components = useMemo(() => {
    return {
      List: React.forwardRef(({ style, children }: ListProps, listRef: ForwardedRef<HTMLDivElement>) => {
        return (
          <List disablePadding style={{ ...style }} component="div" ref={listRef}>
            {children}
          </List>
        );
      }),

      Item: ({ children, ...props }) => {
        const index = props["data-item-index"];
        const item = testsByMachineFrequency[index];

        return (
          <ListItem component="div" {...props} dense button onClick={handleRunTests(item.tests)}>
            {children}
          </ListItem>
        );
      },
    };
  }, [handleRunTests, testsByMachineFrequency]);

  const ScheduleItemList = useMemo(
    () =>
      testsError ? (
        <RetryWidget onRetry={handleRetry} />
      ) : !tests?.length ? (
        <ImageWithText
          image={<Math size="80px" />}
          title="All caught up!"
          subheader="Tests grouped by due date will show here, so you can quickly run the tests that are due."
        />
      ) : (
        <Box height={320} ml={-2} mr={-2}>
          <Virtuoso components={listComponents} totalCount={testsByMachineFrequency.length} itemContent={renderListItem} />
        </Box>
      ),
    [testsError, handleRetry, listComponents, renderListItem, tests?.length, testsByMachineFrequency.length]
  );

  const { onRemoveWidget, ...rest } = props;

  const defaultWidgetCardProps = {
    ...rest,
  };

  const widgetMenuButtonRef = useRef(null);

  return (
    <WidgetCard
      id="schedule-widget"
      header="ZapThruIT"
      loading={testsLoading}
      {...defaultWidgetCardProps}
      actions={
        <Grid container>
          <Grid item>
            <Link
              component={IconButton}
              color="textSecondary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://zapit-user-manual.s3.amazonaws.com/ZapThruIT.pdf"
              aria-label="ZapThruIT help"
            >
              <HelpOutline />
            </Link>
          </Grid>

          {onRemoveWidget != null && (
            <Grid item ref={widgetMenuButtonRef}>
              <IconButton id="schedule-widget-menu-button" aria-label="schedule widget menu" aria-haspopup="true" onClick={handleOpenMenu}>
                <MoreVert />
              </IconButton>
              <Menu
                id="schedule-menu"
                keepMounted
                anchorEl={widgetMenuButtonRef.current}
                open={Boolean(menuAnchorEl?.id === "schedule-widget-menu-button")}
                onClose={handleCloseMenu}
                disableScrollLock={true}
                container={widgetMenuButtonRef.current}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem button onClick={onRemoveWidget}>
                  <ListItemIcon style={{ marginLeft: "-8px", marginRight: "8px" }}>
                    <RemoveCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary="Remove widget" />
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>
      }
    >
      {ScheduleItemList}
    </WidgetCard>
  );
}

export default React.memo(ScheduleWidget);

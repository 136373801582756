import { GtmConfig } from "./models/gtm-config.model";

export enum EnvId {
  Local = 0,
  Dev = 1,
  Test = 2,
  Prod = 3,
}

export interface Env {
  // From .env.json
  id: EnvId;
  apiUrl: string;
  newApiUrl: string;
  version: string;
  gtmConfig?: Partial<GtmConfig>;
  // Default values
  defaultDateTimeFormat: string;
  defaultDateFormat: string;
  defaultTimezone: string;
  serverDateTimeFormat: string;
  pageLimits: number[];
}

const defaultSettings = {
  version: process.env.REACT_APP_version as string,
  defaultDateTimeFormat: "M/D/YYYY h:mma",
  defaultDateFormat: "M/D/YYYY",
  defaultTimezone: "America/New_York",
  serverDateTimeFormat: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  pageLimits: [24, 48, 96],
};

const allEnvSettings = {
  local: {
    PORT: 5000,
    NODE_ENV: "development",
    REACT_APP_id: 0,
    REACT_APP_apiUrl: "https://test-api-5.zapitmedical.com/test/",
    REACT_APP_newApiUrl: "http://localhost:4009/",
  },
  dev: {
    NODE_ENV: "development",
    REACT_APP_id: 1,
    REACT_APP_apiUrl: "https://test-api-5.zapitmedical.com/test/",
    REACT_APP_newApiUrl: "https://test-api.zapitmedical.com/",
  },
  test: {
    NODE_ENV: "production",
    REACT_APP_id: 2,
    REACT_APP_apiUrl: "https://test-api-5.zapitmedical.com/test/",
    REACT_APP_newApiUrl: "https://test-api.zapitmedical.com/",
    REACT_APP_gtmConfig: '{ "id": "GTM-5F7BHSN", "env": { "gtmAuth": "pnwnejRK9RCxtjEXOuWQLw", "gtmPreview": "env-7" } }',
  },
  prod: {
    NODE_ENV: "production",
    REACT_APP_id: 3,
    REACT_APP_apiUrl: "https://api3.zapitmedical.com/prod/",
    REACT_APP_newApiUrl: "https://api.zapitmedical.com/",
    REACT_APP_gtmConfig: '{ "id": "GTM-5F7BHSN" }',
  },
};

function getEnvId(): EnvId {
  // if building locally (or in docker) check if REACT_APP_env is set.
  const npmEnv = process.env.REACT_APP_env;
  if (npmEnv) {
    console.log(`Using ${npmEnv} environment`);
    return envIdFromString(npmEnv.toLocaleLowerCase());
  }

  // if running as static js, check URL for environment
  const host = window.location.hostname.toLocaleLowerCase();
  if (host === "localhost") return EnvId.Local;

  // figure out env from subdomain (app.z.com is prod, app-test.z.com, app-dev.z.com is dev)
  const subdomain = host.split(".")[0].replace("app", "").replace("-", "") || "prod";
  return envIdFromString(subdomain);
}

function envIdFromString(name: string): EnvId {
  if (name === "prod") return EnvId.Prod;
  else if (name === "test") return EnvId.Test;
  else if (name === "dev") return EnvId.Dev;
  else if (name === "local") return EnvId.Local;
  throw new Error("Unable to set config");
}

function buildEnv(): Env {
  const appId = getEnvId();

  const envSettings = (allEnvSettings as any)[EnvId[appId].toLocaleLowerCase()];
  return {
    ...defaultSettings,
    id: appId,
    apiUrl: envSettings.REACT_APP_apiUrl as string,
    newApiUrl: envSettings.REACT_APP_newApiUrl as string,
    gtmConfig: envSettings.REACT_APP_gtmConfig ? JSON.parse(envSettings.REACT_APP_gtmConfig as string) : undefined,
  };
}

const environment: Env = buildEnv();
export default environment;

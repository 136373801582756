import { withStyles, createStyles, Theme, WithStyles, makeStyles, lighten } from "@material-ui/core/styles";
import { Button as MuiButton, ButtonProps as MuiButtonProps, ExtendButtonBaseTypeMap, ButtonBaseTypeMap, ButtonTypeMap } from "@material-ui/core";
import React, { ElementType } from "react";
import { OverrideProps, OverridableComponent } from "@material-ui/core/OverridableComponent";
import { ElevationPropType } from "../../../theme";

export type ButtonProps<
  D extends React.ElementType = ButtonTypeMap["defaultComponent"],
  P = {
    elevation?: ElevationPropType | boolean;
    fab?: boolean;
  }
> = OverrideProps<ButtonTypeMap<P, D>, D>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 56,
    minWidth: 56,
    lineHeight: "normal",
    "&:not(:disabled)": {
      boxShadow: (props?: ButtonProps) =>
        !props?.elevation ? "none" : `${props.elevation === true ? theme.elevation.low : theme.elevation[props.elevation]} ${theme.shadowColor}`,
      "&:active": {
        boxShadow: "none",
      },
    },
  },
  label: {
    lineHeight: "inherit",
  },
  containedPrimary: {
    "&:not(:disabled)": {
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
      //boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      boxShadow: (props?: ButtonProps) =>
        !props?.elevation ? "none" : `${props.elevation === true ? theme.elevation.low : theme.elevation[props.elevation]} ${theme.palette.primary.main}`,
      color: "white",

      "&:active": {
        boxShadow: "none",
      },
    },
  },
  outlined: {
    background: theme.palette.background.paper,
    "&:not(:disabled)": {
      "&:hover": {
        background: theme.palette.background.paper,
      },
    },
  },
  outlinedPrimary: {
    "&:not(:disabled)": {
      boxShadow: (props?: ButtonProps) =>
        !props?.elevation ? "none" : `${props.elevation === true ? theme.elevation.low : theme.elevation[props.elevation]} ${theme.palette.primary.main}`,

      "&:active": {
        boxShadow: "none",
      },
    },
  },

  sizeSmall: {
    minHeight: 40,
    minWidth: 40,
    fontSize: "inherit",
    padding: `4px 16px`,
  },
  iconSizeSmall: {
    fontSize: "1.5rem",
    "&>*:first-child": {
      fontSize: "inherit",
    },
  },
  textSizeSmall: {
    padding: theme.spacing(1),
  },
  sizeLarge: {
    minHeight: 72,
    minWidth: 72,
  },
}));

/*
export type ExtendButtonBase<M extends OverridableTypeMap> = ((
  props: { href: string } & OverrideProps<ExtendButtonBaseTypeMap<ButtonTypeMap>, 'a'>
) => JSX.Element) &
  OverridableComponent<ExtendButtonBaseTypeMap<M>>;

declare const Bn: ExtendButtonBase<ButtonTypeMap>;
*/

/*export type ButtonProps<E extends ElementType<any> = "button"> = OverrideProps<
  ExtendButtonBaseTypeMap<ButtonTypeMap>,
  E
>;*/

/*export type ButtonProps<D extends React.ElementType = ButtonTypeMap["defaultComponent"], P = {}> = OverrideProps<
  ButtonTypeMap<P, D>,
  D
>;*/

function Button(props: ButtonProps) {
  const { elevation, fab, ...rest } = props;

  const classes = useStyles(props);

  const defaultProps: MuiButtonProps = {
    size: "small",
    variant: "contained",
    style: fab
      ? {
          padding: 0,
          borderRadius: "50%",
        }
      : undefined,
    ...rest,
  };

  return <MuiButton classes={classes} {...defaultProps}></MuiButton>;
}

export default Button;

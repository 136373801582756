import { toSnake } from "convert-keys";
import { useCallback } from "react";
import { HttpClientRequestConfig, useHttpClientContext } from "../context/http-client.context";
import environment from "../env";
import { DeviceSearch } from "../models/device-search.model";
import { EventSearchParams, getEventSearchParams } from "../models/event-search-params.model";
import { MachineEvent } from "../models/machine-event.model";
import { getMachineOptionUrlPrefix, MachineOptionParam } from "../models/machine-option-type.model";
import { Machine, MachineCode, MachineType, MachineTypeWithTests } from "../models/machine.model";
import { PagedList } from "../models/paged-list.model";

export interface MachineService {
  getMachines(orgId: number, criteria?: DeviceSearch): Promise<PagedList<Machine> & { orgTotal?: number; orgLimit?: number }>;
  getMachineById(orgId: number, machineId: number): Promise<Machine>;
  addMachine(orgId: number, criteria: Partial<Machine>): Promise<Machine>;
  updateMachine(orgId: number, machine: Machine): Promise<Machine>;
  updateMachineWithEvents(orgId: number, machine: Machine, scheduledEventIdsToActivate?: number[]): Promise<Machine>;
  getMachineTypes(orgId: number): Promise<MachineType[]>;
  getMachineCodes(): Promise<MachineCode[]>;
  getMachineTypesWithTests(orgId: number): Promise<MachineTypeWithTests[]>;
  getMachineSchedule(
    orgId: number,
    criteria: { start?: number; machineId?: number; facilityId?: number; incInactiveScheds?: boolean }
  ): Promise<{ items: MachineEvent[]; total: number }>;
  copyMachine(
    orgId: number,
    machineId: number,
    criteria: { copyMachineData: boolean; copyTestSetupData: boolean; copyIdentifyingData?: boolean; copyToFacilityId?: number }
  ): Promise<Machine>;
  deleteMachine(userId: number, orgId: number, machineId?: number): Promise<void>;
}

function useMachineService(): MachineService {
  const { httpClient } = useHttpClientContext();

  /**
   * Gets machines by machine type, facility or organization
   */
  const getMachines = useCallback(
    async (orgId: number, criteria?: DeviceSearch) => {
      const { query, status, ...reportParams } = criteria || {};

      let params: any = { ...reportParams };

      if (query && query.length) {
        params = { ...params, query };
      }

      if (status && status.length) {
        params = { ...params, status: status.join(",") };
      }
      const response = await httpClient.get<PagedList<Machine>>(`orgs/${orgId}/machines`, { params, baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const getMachineById = useCallback(
    (orgId: number, machineId: number) => {
      const endpoint = `orgs/${orgId}/machines/${machineId}`;

      return httpClient.get(endpoint, { baseURL: environment.newApiUrl }).then((response) => {
        const item = response.data as Machine;

        return item;
      });
    },
    [httpClient]
  );

  const getMachineTypesWithTests = useCallback(
    async (orgId: number) => {
      const response = await httpClient.get<MachineTypeWithTests[]>(`orgs/${orgId}/machine-types`, { baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const getMachineTypes = useCallback(
    async (orgId: number) => {
      const endpoint = `orgs/${orgId}/machines/types`;

      const response = await httpClient.get(endpoint, { baseURL: environment.newApiUrl });
      const items = response.data as MachineType[];
      return items;
    },
    [httpClient]
  );

  const getMachineCodes = useCallback(() => {
    const endpoint = `/code`;

    return httpClient.get(endpoint, { baseURL: environment.newApiUrl }).then((response) => {
      const items = response.data as MachineCode[];
      return items;
    });
  }, [httpClient]);

  const getMachineSchedule = useCallback(
    (orgId: number, criteria: { start: number; machineId?: number; facilityId?: number; incInactiveScheds?: boolean }) => {
      const endpoint = `orgs/${orgId}/machines/schedule`;

      const config: HttpClientRequestConfig = {
        params: criteria,
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.items as MachineEvent[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  const copyMachine = useCallback(
    async (
      orgId: number,
      machineId: number,
      criteria: { copyMachineData: boolean; copyTestSetupData: boolean; copyIdentifyingData?: boolean; copyToFacilityId?: number }
    ): Promise<Machine> => {
      const endpoint = `orgs/${orgId}/machines/${machineId}`;

      const config: HttpClientRequestConfig = {
        params: criteria,
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      const response = await httpClient.post(endpoint, criteria, { baseURL: environment.newApiUrl });
      if (response.data.error) {
        return Promise.reject(response.data.error);
      }
      return response.data;
    },
    [httpClient]
  );

  const addMachine = useCallback(
    async (orgId: number, criteria: Partial<Machine>): Promise<Machine> => {
      const endpoint = `orgs/${orgId}/machines`;
      const response = await httpClient.post(endpoint, criteria, { baseURL: environment.newApiUrl });
      if (response.data.error) {
        return Promise.reject(response.data.error);
      }
      return response.data;
    },
    [httpClient]
  );

  const updateMachine = useCallback(
    async (orgId: number, machine: Machine): Promise<Machine> => {
      const endpoint = `orgs/${orgId}/machines/${machine.id}`;
      const response = await httpClient.put(endpoint, machine, { baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const updateMachineWithEvents = useCallback(
    async (orgId: number, machine: Machine, scheduledEventIdsToActivate: number[] = []): Promise<Machine> => {
      const endpoint = `orgs/${orgId}/machines/${machine.id}`;
      let updatedMachine = machine;
      if (scheduledEventIdsToActivate.length > 0) {
        updatedMachine = { ...machine, scheduledEventIdsToActivate: scheduledEventIdsToActivate };
      }
      const response = await httpClient.put(endpoint, updatedMachine, { baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const deleteMachine = useCallback(
    async (orgId: number, machineId?: number) => {
      const endpoint = `orgs/${orgId}/machines/${machineId}`;
      await httpClient.delete(endpoint, { baseURL: environment.newApiUrl });
      return;
    },
    [httpClient]
  );

  return {
    getMachines,
    getMachineById,
    getMachineTypes,
    getMachineCodes,
    getMachineTypesWithTests,
    getMachineSchedule,
    copyMachine,
    addMachine,
    updateMachine,
    updateMachineWithEvents,
    deleteMachine,
  };
}

export default useMachineService;

import { memo, useMemo } from "react";
import { Redirect, Route, Switch, RouteProps } from "react-router-dom";

import { MuiThemeProvider, Theme } from "@material-ui/core/styles";

import Login from "./containers/pages/Login/Login";
import NotFound from "./containers/pages/NotFound/NotFound";

import { useAuthContext } from "../context/auth.context";
import PageLoader from "./components/PageLoader/PageLoader";
import Shell from "./containers/Shell/Shell";

import { lightTheme } from "../theme";
import { Helmet } from "react-helmet";
import { UserSettingsContextProvider } from "../context/user-settings.context";

function PrivateRoute({ loading, children, ...rest }: RouteProps & { loading?: boolean }) {
  const { isAuthReady, session } = useAuthContext();

  const renderChildren = useMemo(() => !!session, [session]);

  if (loading || (!renderChildren && !isAuthReady)) {
    return <PageLoader />;
  }

  return renderChildren ? <Route {...rest}>{children}</Route> : <Redirect to="/login" />;
}

export interface AppProps {
  theme: Theme;
  loading?: boolean;
}

function App({ theme, loading }: AppProps) {
  // path="/" not exact will match on any route after / as well
  return (
    <Switch>

      <Route path="/analytics">
        <>
          <Helmet>
            <title>analytics.txt</title>
          </Helmet>
          <MuiThemeProvider theme={lightTheme}>
            GooGhywoiu9839t543j0s7543uw1 - pls add bmurray@enzeehealth.com to GA account 83911180 with Administrator permissions - date 04/23/24
          </MuiThemeProvider>
        </>
      </Route>

      <Route exact path="/login">
        <>
          <Helmet>
            <title>Login</title>
          </Helmet>
          <MuiThemeProvider theme={lightTheme}>
            <Login />
          </MuiThemeProvider>
        </>
      </Route>

      <Route path="/reset">
        <>
          <Helmet>
            <title>Reset Password</title>
          </Helmet>
          <MuiThemeProvider theme={lightTheme}>
            <Login formType="reset" />
          </MuiThemeProvider>
        </>
      </Route>

      <Route path="/register">
        <>
          <Helmet>
            <title>Register</title>
          </Helmet>
          <MuiThemeProvider theme={lightTheme}>
            <Login formType="register" />
          </MuiThemeProvider>
        </>
      </Route>

      <PrivateRoute loading={loading} path="/">
        <UserSettingsContextProvider>
          <Shell theme={theme} />
        </UserSettingsContextProvider>
      </PrivateRoute>
      <Route component={NotFound} />
    </Switch>
  );
}

export default memo(App);

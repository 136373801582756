import { useRef, useEffect, DependencyList, MutableRefObject } from "react";

export type LifecycleStatus = "mount" | "update" | "unmount";

export type StatusEffectCallback = (status: MutableRefObject<LifecycleStatus>) => void | (() => void);

/**
 * Accepts a function that contains imperative, possibly effectful code.
 * Passes the current mount status of `mount`, `update`, or `unmount` as a ref which
 * Can then be checked for the real time status, including `unmount`.
 *
 * @param effect Imperative function that can return a cleanup function.
 * @param deps If present, effect will only activate if the values in the list change.
 *
 * @returns the lifecycle status which can be used outside of this hook.
 */
export function useStatusEffect(effect?: StatusEffectCallback, deps?: DependencyList) {
  const status = useRef<LifecycleStatus>("mount");
  const mounted = useRef(false);

  // Update status.
  useEffect(() => {
    // Skip the first render (mount).
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    status.current = "update";

    return () => {
      status.current = "unmount";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  // Call the callback.
  useEffect(() => {
    if (!effect) {
      return;
    }

    return effect(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { status };
}

import clsx from "clsx";
import { makeStyles, PaperProps, PropTypes, StandardProps } from "@material-ui/core";
import * as React from "react";
import { useCallback } from "react";
import Path from "./Path";

const VIEWBOX_WIDTH = 100;
const VIEWBOX_HEIGHT = 100;
const VIEWBOX_HEIGHT_HALF = 50;
const VIEWBOX_CENTER_X = 50;
const VIEWBOX_CENTER_Y = 50;

export type CircularProgressChartClassKey = "root" | "trail" | "path" | "text" | "background";

export interface CircularProgressChartProps extends StandardProps<PaperProps, CircularProgressChartClassKey> {
  value: number;
  background?: boolean;
  backgroundPadding?: number;
  circleRatio?: number;
  strokeWidth?: number;
  counterClockwise?: boolean;
  maxValue?: number;
  minValue?: number;
  text?: React.ReactNode;
  rotation?: number; // Number of turns, 0-1
  strokeLinecap?: string;
  transition?: string;
  transitionDuration?: string;
  trailColor?: string;
  pathColor?: string;
  textColor?: string;
}

type StyleProps = {
  classes?: Partial<Record<CircularProgressChartClassKey, string>>;
  rotation?: number;
  transform?: string;
  transformOrigin?: string;
  transition?: string;
  transitionDuration?: string;
  strokeLinecap?: string;
  trailColor?: string;
  pathColor?: string;
  textColor?: string;
};

const useStyles = makeStyles((theme) => ({
  root: (props: StyleProps) => ({
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
    width: "100%",
    /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
    verticalAlign: "middle",
  }),
  trail: (props: StyleProps) => ({
    stroke: (theme.palette as any)[props.trailColor as any]?.main || props.trailColor || theme.palette.divider,
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
    strokeLinecap: (props.strokeLinecap as any) || "round",
    transform: props.transform,
    transformOrigin: props.transformOrigin,
  }),
  path: (props: StyleProps) => ({
    stroke: (theme.palette as any)[props.pathColor as any]?.main || props.pathColor || theme.palette.text.hint,
    strokeLinecap: (props.strokeLinecap as any) || "round",
    transform: props.transform,
    transformOrigin: props.transformOrigin,
    transition: props.transition || `stroke-dashoffset ${props.transitionDuration || "0.5s"} ease 0s`,
    transitionDuration: props.transitionDuration,
  }),
  text: (props: StyleProps) => ({
    fill: (theme.palette as any)[props.textColor as any]?.main || props.textColor || theme.palette.text.primary,
    fontSize: "20px",
    dominantBaseline: "middle",
    textAnchor: "middle",
  }),
  background: (props: StyleProps) => ({
    fill: theme.palette.background.paper,
    // TODO: figure out how to override classes with those passed in like mui
    //...props.classes?.background
  }),
}));

/**
 * Based on: https://github.com/kevinsqi/react-circular-progressbar
 * @param props
 */
function CircularProgressChart(props: CircularProgressChartProps) {
  const defaultProps = {
    background: false,
    backgroundPadding: 0,
    circleRatio: 1,
    strokeWidth: 8,
    counterClockwise: false,
    maxValue: 100,
    minValue: 0,
  };

  const { className, classes: propClasses, value, text, rotation, strokeLinecap, trailColor, pathColor } = props;
  const { background, backgroundPadding, circleRatio, strokeWidth, counterClockwise, minValue, maxValue } = { ...defaultProps, ...props };

  const transform = rotation == null ? undefined : `rotate(${rotation}turn)`;
  const transformOrigin = rotation == null ? undefined : "center center";

  const classes = useStyles({ classes: propClasses, rotation, transform, transformOrigin, strokeLinecap, trailColor, pathColor });

  const getBackgroundPadding = useCallback(() => {
    if (!background) {
      // Don't add padding if not displaying background
      return 0;
    }
    return backgroundPadding;
  }, [background, backgroundPadding]);

  const getPathRadius = useCallback(() => {
    // The radius of the path is defined to be in the middle, so in order for the path to
    // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
    return VIEWBOX_HEIGHT_HALF - strokeWidth / 2 - getBackgroundPadding();
  }, [getBackgroundPadding, strokeWidth]);

  // Ratio of path length to trail length, as a value between 0 and 1
  const getPathRatio = useCallback(() => {
    const boundedValue = Math.min(Math.max(value, minValue), maxValue);
    return (boundedValue - minValue) / (maxValue - minValue);
  }, [maxValue, minValue, value]);

  const pathRadius = getPathRadius();
  const pathRatio = getPathRatio();

  return (
    <svg className={clsx(classes.root, className)} viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`} data-test-id="CircularProgressChart">
      {background ? <circle className={classes.background} cx={VIEWBOX_CENTER_X} cy={VIEWBOX_CENTER_Y} r={VIEWBOX_HEIGHT_HALF} /> : null}

      <g>
        <Path
          className={classes.trail}
          counterClockwise={counterClockwise}
          dashRatio={circleRatio}
          pathRadius={pathRadius}
          strokeWidth={strokeWidth}
          viewboxCenterX={VIEWBOX_CENTER_X}
          viewboxCenterY={VIEWBOX_CENTER_Y}
        />

        <Path
          className={classes.path}
          counterClockwise={counterClockwise}
          dashRatio={pathRatio * circleRatio}
          pathRadius={pathRadius}
          strokeWidth={strokeWidth}
          viewboxCenterX={VIEWBOX_CENTER_X}
          viewboxCenterY={VIEWBOX_CENTER_Y}
        />
      </g>

      {text ? (
        <text className={classes.text} x={VIEWBOX_CENTER_X} y={VIEWBOX_CENTER_Y}>
          {text}
        </text>
      ) : null}
    </svg>
  );
}

export default CircularProgressChart;

import { createContext, useContext, Dispatch, SetStateAction, PropsWithChildren } from "react";
import usePersistedState from "../hooks/persisted-state.hook";
import errorMessages from "../translations/en/errors.json";
import { enumToLookup } from "../models/lookup.model";
import { useUserContext } from "./user.context";

export enum DashboardWidget {
  Calendar = "calendar",
  Schedule = "schedule",
  Tracker = "tracker",
  Score = "score",
}

export interface UserSettings {
  hideInactiveMachines: boolean;
}

export interface DashboardSettings {
  widgets: string[];
}

const defaultUserSettings: UserSettings = {
  hideInactiveMachines: false,
};

const defaultDashboardSettings: DashboardSettings = {
  widgets: Object.values(DashboardWidget),
};

export interface UserSettingsContext {
  userSettings: UserSettings;
  setUserSettings: Dispatch<SetStateAction<UserSettings>>;
  dashboardSettings: DashboardSettings;
  setDashboardSettings: Dispatch<SetStateAction<DashboardSettings>>;
}

const Context = createContext<UserSettingsContext>(null!);

function UserSettingsContextProvider(props: PropsWithChildren<{}>): JSX.Element {
  const { user } = useUserContext();

  const [userSettings, setUserSettings] = usePersistedState<UserSettings>(`${user?.email}/context/user-settings`, {
    storageType: "local",
    defaultValue: defaultUserSettings,
  });

  const [dashboardSettings, setDashboardSettings] = usePersistedState<DashboardSettings>(`${user?.email}/context/user-settings/dashboard`, {
    storageType: "local",
    defaultValue: defaultDashboardSettings,
  });

  const contextValue: UserSettingsContext = {
    userSettings: userSettings || defaultUserSettings,
    setUserSettings,
    dashboardSettings: dashboardSettings || defaultDashboardSettings,
    setDashboardSettings,
  };

  return <Context.Provider value={{ ...contextValue }}>{props.children}</Context.Provider>;
}

function useUserSettingsContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { UserSettingsContextProvider, useUserSettingsContext };

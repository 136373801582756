import React from "react";
import { CalendarTodayOutlined } from "@material-ui/icons";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export interface TestFrequencyIconProps extends SvgIconProps {
  frequency: number;
}

function TestFrequencyIcon(props: TestFrequencyIconProps) {
  const { frequency, ...rest } = props;

  const iconProps: SvgIconProps = {
    ...rest,
  };

  return (
    <SvgIcon {...iconProps}>
      <CalendarTodayOutlined {...iconProps}></CalendarTodayOutlined>
      <text textAnchor="middle" fontWeight="700" fontSize="0.5rem" letterSpacing="0px" x="50%" y="75%">
        {frequency}
      </text>
    </SvgIcon>
  );
}

export default TestFrequencyIcon;

import { useCallback } from "react";
import { useHttpClientContext } from "../context/http-client.context";
import environment from "../env";
import { TrackerItem, TrackerSearch } from "../models/tracker.model";

export interface TrackerService {
  getTrackerData(search: TrackerSearch): Promise<TrackerItem[]>;
}

function useTrackerService(): TrackerService {
  const { httpClient } = useHttpClientContext();

  const getTrackerData = useCallback(
    async (search: TrackerSearch) => {
      const options = {
        params: {
          startDate: search.startDate,
          facilityId: search.facilityId,
          machineId: search.machineId,
          userId: search.userId,
        },
        baseURL: environment.newApiUrl,
      };
      const response = await httpClient.get<TrackerItem[]>(`v2/orgs/${search.orgId}/tracker`, options);
      return response.data;
    },
    [httpClient]
  );

  return { getTrackerData };
}

export default useTrackerService;

import React, { createContext, useContext, useCallback, useMemo, useRef, useEffect } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";
import { toSnake, toCamel } from "convert-keys";
import * as queryString from "querystring";

import environment from "../env";
import { File } from "../models/file.model";
import mime from "mime";
import path from "path";
import errorMessages from "../translations/en/errors.json";
import { useAuthContext } from "./auth.context";
import { useStatusEffect } from "../hooks/status-effect.hook";

export interface HttpClientContext {
  httpClient: AxiosInstance;
  getFileUrl: (file: File) => string;
}

export interface HttpClientRequestConfig extends AxiosRequestConfig {
  retry?: boolean;
  useAuth?: boolean;
  useRequestParamsToSnake?: boolean;
  useRequestBodyToSnake?: boolean;
  useResponseToCamel?: boolean;
}

const apiCallTimeout = 45000;

const defaultConfig: HttpClientRequestConfig = {
  baseURL: environment.apiUrl,
  timeout: apiCallTimeout,
  timeoutErrorMessage: `Request timed out after ${apiCallTimeout / 1000} seconds.`,
  headers: {
    Accept: "*/*",
    //"Content-Type": "text/plain",
  },
  useAuth: true,
  useRequestBodyToSnake: true,
  useRequestParamsToSnake: true,
  useResponseToCamel: true,
};

const Context = createContext<HttpClientContext>(null!);

function HttpClientContextProvider(props: React.PropsWithChildren<{}>): JSX.Element {
  const { session, refresh } = useAuthContext();
  const sessionRef = useRef(session || null);

  useEffect(() => {
    sessionRef.current = session || null;
  }, [session]);

  const refreshing = useRef(false);
  const retryQueue = useRef<
    { request: HttpClientRequestConfig; retry: (request: HttpClientRequestConfig, newAccessToken: string) => void; fail: (error: any) => void }[]
  >([]);

  const httpClient: AxiosInstance = useMemo(() => {
    const _httpClient = axios.create(defaultConfig);

    // Request interceptors run pre request sent
    _httpClient.interceptors.request.use(
      async (req: HttpClientRequestConfig) => {
        const config: HttpClientRequestConfig = Object.assign({ ..._httpClient.defaults }, { ...req });
        // TODO: Remove once we are fully migrated to new api...
        const newApi = config.baseURL === environment.newApiUrl;

        if (config.useAuth && !sessionRef.current) {
          return Promise.reject(errorMessages.auth.unauthorized);
        }

        if (config.useAuth && sessionRef.current && !req.headers?.Authorization) {
          // Add to headers
          req.headers = {
            ...req.headers,
            Authorization: newApi ? `Bearer ${sessionRef.current.accessToken}` : `${sessionRef.current.accessToken}`,
          };
        }

        // Use snake_case for requests
        if (!newApi && config.useRequestParamsToSnake) {
          req.paramsSerializer = (params: { [key: string]: any }) => {
            return queryString.stringify(toSnake(params));
          };
        }

        if (!newApi && config.useRequestBodyToSnake) {
          req.data = toSnake(req.data);
        }

        return { ...req };
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Response interceptors run pre response return
    _httpClient.interceptors.response.use(
      // 2xx status codes will be processed here
      async (res: AxiosResponse) => {
        // TODO: Remove this with new API
        // If a response is larger than 2M chars the old API stores the response in s3 to be fetched from there.
        if (res.data.Location) {
          const locationConfig: HttpClientRequestConfig = Object.assign({ ..._httpClient.defaults, useAuth: false });

          return await _httpClient.get(res.data.Location, locationConfig);
        }

        const config: HttpClientRequestConfig = Object.assign({ ..._httpClient.defaults }, { ...res.config });
        // TODO: New api is already camel. Remove once we are fully migrated to new api...
        const newApi = config.baseURL === environment.newApiUrl;

        if (!newApi && config.useResponseToCamel && (!res.config.responseType || res.config.responseType !== "blob")) {
          res.data = toCamel(res.data);
        }

        return { ...res };
      },
      // 4xx through 5xx status codes will be processed here
      (error: { message: string; response: AxiosResponse; config: HttpClientRequestConfig }) => {
        const { response, config } = error;

        if (!response) {
          // Offline or timeout.
          return Promise.reject(new Error("Could not reach server or server did not respond."));
        }

        const { data, status } = response;

        // Custom error handling.
        // 401
        if (config.useAuth && sessionRef.current && status === 401 && !config.retry) {
          const newApi = config.baseURL === environment.newApiUrl;
          // Attempt to refresh token.
          // Only the first response with 401 may enter here.
          if (!refreshing.current) {
            // Only refresh one time even though there could be multiple responses in need of new token.
            // We will queue the responses to finish after the refresh below.
            refreshing.current = true;
            // Ensure we do not retry the requests we are already retrying.
            config.retry = true;

            return refresh()
              .then(({ accessToken }) => {
                const updatedHeaders = { ...config.headers, Authorization: newApi ? `Bearer ${accessToken}` : accessToken };

                config.headers = updatedHeaders;
                retryQueue.current.forEach(({ request, retry }) => retry(request, accessToken));
                return _httpClient(config);
              })
              .catch((innerError) => {
                retryQueue.current.forEach(({ fail }) => fail(error));
                // If refresh fails just return original error.
                return Promise.reject(error);
              })
              .finally(() => {
                retryQueue.current = [];
                refreshing.current = false;
              });
          }

          return new Promise((resolve, reject) => {
            // Add requests to retry after refresh (requests that expect a new token from the first request) as a promise
            // that is not resolved until the callback, which the first request executes after refresh token.
            retryQueue.current.push({
              request: config,
              retry: (request, newAccessToken) => {
                request.retry = true;

                const updatedHeaders = { ...request.headers, Authorization: newApi ? `Bearer ${newAccessToken}` : newAccessToken };

                request.headers = updatedHeaders;
                // Resolve the request in the callback which will be delayed until the refresh token.
                resolve(_httpClient(request));
              },
              fail: (error) => {
                // If the first request could not update the token, we must return the original error.
                reject(error);
              },
            });
          });
        } // End 401 logic

        return Promise.reject({ status, message: (response?.data?.error || error?.message) });
      }
    );

    return _httpClient;
  }, [refresh]);

  // TODO: Kill this concept when we get actual file urls into the db and returned from api
  // This is so bad...
  const getFileUrl = useCallback(
    (file: File) => {
      if (!session) {
        throw new Error("You must have a valid session to get a file url.");
      }

      let filename = file.oldId || ""; //+ "." + mime.getExtension(file.type);

      if (filename.indexOf(".") === -1) {
        filename = filename + (file.type ? "." + mime.getExtension(file.type) : path.extname(file.filename));
      }

      const url = `${environment.apiUrl}file/image/${filename}?_auth=${session?.accessToken}&direct=1`;
      return url;
    },
    [session]
  );

  // const delay = useCallback((ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms)), []);

  const contextValue: HttpClientContext = {
    httpClient,
    getFileUrl,
  };

  return <Context.Provider value={{ ...contextValue }}>{props.children}</Context.Provider>;
}

function useHttpClientContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }

  return context;
}

export { HttpClientContextProvider, useHttpClientContext };

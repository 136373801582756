import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
    },
    header: {
        color: theme.palette.text.secondary,
        margin: `0 0 ${theme.spacing(.5)}px 0`
    },
    items: {
    }
}));

export interface CalendarLegendSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
}

export function CalendarLegendSection(props: React.PropsWithChildren<CalendarLegendSectionProps>) {
    const classes = useStyles();

    return (
        <div className={clsx(props.className, classes.root)}>
            <h4 className={classes.header}>{props.title}</h4>
            <div className={classes.items}>
                {props.children}
            </div>
        </div>
    )
}
import { useCallback } from "react";
import { useHttpClientContext, HttpClientRequestConfig } from "../context/http-client.context";
import { MachineOptionParam, getMachineOptionUrlPrefix } from "../models/machine-option-type.model";
import { EventSearchParams, getEventSearchParams } from "../models/event-search-params.model";
import { MachineLog, MachineLogFile, MachineLogNote } from "../models/machine-log.model";
import useFileUploadService from "./file-upload-service.hook";
import { File as OrgFile } from "../models/file.model";
import { Note } from "../models/note.model";
import environment from "../env";
import qs from "qs";

export interface MachineLogServiceOptions {}

export interface MachineLogService {
  getMachineLogs(machineOptionType: MachineOptionParam, criteria: EventSearchParams): Promise<{ items: MachineLog[]; total: number }>;
  getMachineLogsV2(
    orgId: number,
    userRequestingId: number,
    criteria: EventSearchParams,
    facilityId?: number,
    machineId?: number
  ): Promise<{ items: MachineLog[]; total: number }>;

  getMachineLog(orgId: number, id: number): Promise<MachineLog>;
  addMachineLog(orgId: number, newMachineLog: MachineLog): Promise<MachineLog>;
  updateMachineLog(orgId: number, updatedMachineLog: any): Promise<MachineLog>;
  deleteMachineLog(orgId: number, id: number): Promise<void>;
  addMachineLogFile(orgId: number, machineLogId: number, file: File): Promise<MachineLogFile>;
  addMachineLogNote(orgId: number, id: number, note: Note): Promise<MachineLogNote>;
  deleteMachineLogFile(orgId: number, machineLogId: number, machineLogFileId: number): Promise<void>;
  deleteMachineLogNote(orgId: number, machineLogId: number, machineLogNoteId: number): Promise<void>;
  sendCreateMachineLogNotification(orgId: number, machineLogId: number): Promise<void>;
}

function useMachineLogService(options?: MachineLogServiceOptions): MachineLogService {
  const { httpClient } = useHttpClientContext();
  const fileUploadService = useFileUploadService({ maxFileSizeBytes: 50000000 });

  const getMachineLogs = useCallback(
    (machineOptionType: MachineOptionParam, criteria: EventSearchParams) => {
      const prefix = getMachineOptionUrlPrefix(machineOptionType);

      const endpoint = `${prefix}/log`;

      const apiCriteria = getEventSearchParams(criteria, true);

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.root as MachineLog[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  const getMachineLogsV2 = useCallback(
    (orgId: number, userRequestingId: number, criteria: EventSearchParams, facilityId?: number, machineId?: number) => {
      const endpoint = `v2/orgs/${orgId}/machine-logs`;

      const apiCriteria = getEventSearchParams(criteria, true);

      if (machineId) {
        apiCriteria.machineId = machineId;
      }

      if (facilityId) {
        apiCriteria.facilityId = facilityId;
      }

      apiCriteria.userRequestingId = userRequestingId;

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat", encodeValuesOnly: true }),
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.items as MachineLog[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  const getMachineLog = useCallback(
    (orgId: number, id?: number) => {
      const endpoint = `orgs/${orgId}/machine-logs/${id}`;

      const config: HttpClientRequestConfig = {
        params: { orgId, id },

        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const item = response.data as MachineLog;

        return item;
      });
    },
    [httpClient]
  );

  const addMachineLogFile = useCallback(
    (orgId: number, machineLogId: number, file: File) => {
      const orgFileRequest = fileUploadService.uploadFile(file);

      return orgFileRequest.then((file: OrgFile) =>
        httpClient.post(`orgs/${orgId}/machine-logs/${machineLogId}/files`, file, { baseURL: environment.newApiUrl }).then((response) => {
          const item = response.data as MachineLogFile;
          return item;
        })
      );
    },
    [fileUploadService, httpClient]
  );

  const addMachineLogNote = useCallback(
    (orgId: number, id: number, note: Note): Promise<MachineLogNote> => {
      if (!note?.content) {
        return Promise.reject({ message: "Note is required." });
      }

      return httpClient.post(`orgs/${orgId}/machine-logs/${id}/notes`, note, { baseURL: environment.newApiUrl }).then((response) => {
        const item = response.data as MachineLogNote;
        return item;
      });
    },
    [httpClient]
  );

  const sendCreateMachineLogNotification = useCallback(
    async (orgId: number, machineLogId: number): Promise<void> => {
      const endpoint = `orgs/${orgId}/machine-logs/${machineLogId}/send-create-notification`;
      await httpClient.post(endpoint, null, { baseURL: environment.newApiUrl });
    },
    [httpClient]
  );

  const addMachineLog = useCallback(
    async (orgId: number, newMachineLog: MachineLog): Promise<MachineLog> => {
      const endpoint = `orgs/${orgId}/machine-logs`;
      return await httpClient
        .post(endpoint, newMachineLog, { baseURL: environment.newApiUrl })
        .then((newLogResponse) => {
          const newMachineLog = newLogResponse.data as MachineLog;

          return Promise.resolve(newMachineLog);
        })
        .then((response) => {
          const partialMachineLog = response;
          return getMachineLog(orgId, partialMachineLog.id);
        });
    },
    [getMachineLog, httpClient]
  );

  const updateMachineLog = useCallback(
    (orgId: number, data: MachineLog) => {
      const endpoint = `orgs/${orgId}/machine-logs/${data.id}`;
      return httpClient
        .put(endpoint, data, { baseURL: environment.newApiUrl })
        .then((updatedLogResponse) => {
          const updatedMachineLog = updatedLogResponse.data as MachineLog;

          return Promise.resolve(updatedMachineLog);
        })
        .then((response) => {
          const partialMachineLog = response;
          return getMachineLog(orgId, partialMachineLog.id);
        });
    },
    [getMachineLog, httpClient]
  );

  const deleteMachineLog = useCallback(
    (orgId: number, id: number) => {
      const endpoint = `orgs/${orgId}/machine-logs/${id}`;
      return httpClient.delete(endpoint, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  const deleteMachineLogFile = useCallback(
    (orgId: number, machineLogId: number, machineLogFileId: number) => {
      const endpoint = `orgs/${orgId}/machine-logs/${machineLogId}/files/${machineLogFileId}`;
      return httpClient.delete(endpoint, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  const deleteMachineLogNote = useCallback(
    (orgId: number, machineLogId: number, machineLogNoteId: number) => {
      const endpoint = `orgs/${orgId}/machine-logs/${machineLogId}/notes/${machineLogNoteId}`;
      return httpClient.delete(endpoint, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  return {
    getMachineLogs,
    getMachineLogsV2,
    getMachineLog,
    addMachineLogFile,
    addMachineLogNote,
    addMachineLog,
    updateMachineLog,
    deleteMachineLog,
    deleteMachineLogNote,
    deleteMachineLogFile,
    sendCreateMachineLogNotification,
  };
}

export default useMachineLogService;

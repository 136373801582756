import { Box, BoxProps, Grid, makeStyles, Typography } from "@material-ui/core";

export interface FooterProps extends Omit<BoxProps, "children" | "component"> {
  version: string;
  env?: string;
  backgroundColor?: string;
}

const useStyles = makeStyles((theme) => ({
  footer: (props: FooterProps) => ({}),
  bottom: {
    alignSelf: "end",
    marginBottom: "50px"
  }
}));

function Footer(props: FooterProps) {
  const { version, env } = props;

  const classes = useStyles(props);

  return (
    <Box component="footer" className={classes.footer}>
      <Grid container direction="column" className={classes.bottom} alignContent="center">
        <Grid item>
          <Typography align="center" >
            <a href="https://www.zapitmedical.com/app/index.html" style={{ color: '#D74F2C', textDecoration: "none" }}>
              Classic App
            </a>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary" align="center">
            {`Copyright © ZapIT Medical ${new Date().getFullYear()}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary" align="center">
            {env ? `v${version}-${env}` : `v${version}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;

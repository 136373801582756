import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { EventEmitter } from "events";
import Portal from "@material-ui/core/Portal";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import App from "../app/App";
import ConfirmDialog from "../app/components/Dialogs/ConfirmDialog";
import { Alert } from "../models/alert.model";
import { Confirmation } from "../models/confirmation.model";
import errorMessages from "../translations/en/errors.json";
import { useUserContext } from "./user.context";
import { useLocation } from "react-router-dom";

export enum AppEvent {
  signOut = "zapit_sign_out",
}

const appEventEmitter = new EventEmitter();
// Set this to a number higher than the entries stored in local storage
appEventEmitter.setMaxListeners(1000);

/**
 * Trigger a CustomEvent
 *
 * @param {String} type The event type name
 * @param {Object|null} options=null The event data to be sent
 * @returns {Boolean} The return value is false if at least one event listener called preventDefault(). Otherwise it returns true.
 */
const triggerAppEvent = (type: AppEvent, options?: { target?: Element; nonce?: string; payload?: any }): boolean => {
  return appEventEmitter.emit(type, options);
};

const useStyles = makeStyles((theme) => ({
  alerts: {
    "&.fixed-sidebar": {
      [theme.breakpoints.up(theme.sidebar.breakpointUp)]: {
        marginLeft: theme.sidebar.fixedWidth / 2,
      },
    },
  },
}));

const getAlertMessages = ({ messages }: Alert) => {
  return (
    <>
      {messages.length === 1 ? (
        <span>{messages[0]}</span>
      ) : (
        <ul style={{ margin: "0", paddingLeft: "16px" }}>
          {messages.map((m, i) => (
            <li key={i}>{m}</li>
          ))}
        </ul>
      )}
    </>
  );
};

const defaultAlertAnchor: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

export interface AppContext {
  alert?: Alert;
  setAlert(alert?: Alert): void;
  confirmation?: Confirmation;
  setConfirmation(confirmation?: Confirmation): void;
  toggleMachineSelect(show: boolean): void;
  showMachineSelect: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Context = createContext<AppContext>(null!);

function AppContextProvider() {
  const classes = useStyles();

  const [alert, _setAlert] = useState<Alert>();
  const [confirmation, _setConfirmation] = useState<Confirmation>();
  const [showMachineSelect, toggleMachineSelect] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const [previousLocationPath, setPreviousLocationPath] = useState(location.pathname);

  const { loading: userLoading } = useUserContext();

  const theme = useTheme();

  const setAlert = useCallback((alert?: Alert) => {
    if (!alert) {
      _setAlert(undefined);
      return;
    }

    // Delay setting value so we could show one after another.
    const delayTimer = setTimeout(() => {
      _setAlert(alert);
    }, alert.delay || 0);

    // this will clear Timeout when inputCriteria changes
    return () => {
      clearTimeout(delayTimer);
    };
  }, []);

  const setConfirmation = useCallback((confirmation?: Confirmation) => {
    _setConfirmation(confirmation);
  }, []);

  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (alert) {
      setShowAlert(true);
    }
  }, [alert]);

  useEffect(() => {
    if (confirmation) {
      setShowConfirmation(true);
    }
  }, [confirmation]);

  useEffect(() => {
    // Make sure an alert with no timeout hides when navigating to another page
    if (alert?.timeout === null && previousLocationPath !== location.pathname) {
      setShowAlert(false);
    }

    setPreviousLocationPath(location.pathname);
  }, [previousLocationPath, location, alert])

  const handleCloseAlert = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    // Ignore click away as there is a timeout on the alert
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  }, []);

  const handleCloseConfirmation = useCallback((event?: {}, reason?: string) => {
    setShowConfirmation(false);
  }, []);

  const handleConfirmConfirmation = useCallback(
    (event?: {}) => {
      confirmation?.onConfirm();
      setShowConfirmation(false);
    },
    [confirmation]
  );

  const handleConfirm2Confirmation = useCallback(
    (event?: {}) => {
      if (confirmation?.onConfirm2) {
        confirmation?.onConfirm2();
      }
      setShowConfirmation(false);
    },
    [confirmation]
  );

  const contextValue: AppContext = {
    alert,
    setAlert,
    confirmation,
    setConfirmation,
    toggleMachineSelect,
    showMachineSelect,
    loading,
    setLoading,
  };

  return (
    <Context.Provider value={{ ...contextValue }}>
      <App theme={theme} loading={loading || userLoading} />
      {/* alerts */}
      <Portal>
        <Snackbar
          className={clsx(classes.alerts, alert?.position)}
          open={showAlert}
          anchorOrigin={alert?.anchor || defaultAlertAnchor}
          autoHideDuration={alert?.timeout === undefined ? 4000 : alert?.timeout}
          onClose={handleCloseAlert}
        >
          {alert?.closable === false ?
            (<MuiAlert elevation={6} variant="standard" severity={alert?.severity}>
              {alert && getAlertMessages(alert!)}
            </MuiAlert>) :
            (<MuiAlert elevation={6} variant="standard" severity={alert?.severity} onClose={handleCloseAlert}>
              {alert && getAlertMessages(alert!)}
            </MuiAlert>)
          }
        </Snackbar>
      </Portal>
      <Portal>
        <ConfirmDialog
          headerProps={{
            title: confirmation?.title,
            subheader: confirmation?.subheader,
          }}
          open={showConfirmation}
          onClose={handleCloseConfirmation}
          body={confirmation?.body}
          confirmText={confirmation?.confirmText}
          confirmText2={confirmation?.confirmText2 ? confirmation?.confirmText2 : undefined}
          cancelText={confirmation?.cancelText ? confirmation?.cancelText : undefined}
          onConfirm={handleConfirmConfirmation}
          onConfirm2={handleConfirm2Confirmation}
          onCancel={handleCloseConfirmation}
        />
      </Portal>
    </Context.Provider>
  );
}

function useAppContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { AppContextProvider, useAppContext, appEventEmitter, triggerAppEvent };

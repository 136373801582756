import React, { useCallback, useEffect, useRef, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";

import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Divider, ListItemText, Menu, MenuItem, Tooltip } from "@material-ui/core";
import Card from "../../components/Card/Card";
import { useAppContext } from "../../../context/app.context";
import { NotificationsOutlined, RemoveCircleOutline } from "@material-ui/icons";

import IconButton from "../../components/IconButton/IconButton";
import environment, { EnvId } from "../../../env";
import { UserNotificationItem, UserNotificationQuerySearch } from "../../../models/user.model";
import Badge from "../../components/Badge/Badge";
import { useUserContext } from "../../../context/user.context";
import ImageWithText from "../../components/ImageWithText/ImageWithText";
import moment from "moment";
import Megaphone from "../../components/Svg/Megaphone";
import Searchbar from "../Searchbar/Searchbar";
import useUserNotificationService from "../../../hooks/user-notification-service.hook";
import { useQuery } from "react-query";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      height: theme.header.height,
      zIndex: theme.zIndex.snackbar,
      backgroundColor: theme.palette.background.default,
    },
    card: {
      marginTop: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },

    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    avatarButton: {
      marginRight: -theme.spacing(1),
    },
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    divider: {
      height: 28,
    },
    notifications: {
      flex: 1,
      //marginRight: -theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  });

interface HeaderProps extends WithStyles<typeof styles> {
  onDrawerToggle: () => void;
}

function Header(props: HeaderProps) {
  const { classes, onDrawerToggle } = props;
  const { showMachineSelect, setAlert } = useAppContext();
  const { org } = useUserContext();
  const [criteria] = useState<UserNotificationQuerySearch>({
    orgId: org?.id || undefined
  });

  const { getMyNotificationItems, deactivateNotificationItem } = useUserNotificationService();

  const headerRef = useRef<HTMLDivElement>(null!);

  const [notificationsButtonEl, setNotificationsButtonEl] = React.useState<null | HTMLElement>(null);
  const [notificationPollingId, setNotificationPollingId] = React.useState<any>();
  const [enabledNotifPolling, setEnabledNotifPolling] = React.useState(true);

  // const {
  //   isLoading: loading,
  //   error,
  //   data: notifications,
  //   refetch: refetchNotifications
  // } = useQuery([org, criteria], {
  //   queryFn: async () => {
  //     if (!org) {
  //       return Promise.resolve(undefined);
  //     }

  //     const r = await getMyNotificationItems({ ...criteria, orgId: org.id });

  //     return r;
  //   },
  // });

  // useEffect(() => {
  //   if (!enabledNotifPolling) {
  //     clearInterval(notificationPollingId);
  //     return;
  //   }
  //   const id = setInterval(() => refetchNotifications(), 15 * 1000);
  //   setNotificationPollingId(id);
  // }, [enabledNotifPolling]);

  const handleDeactivateNotification = useCallback(
    async (item: UserNotificationItem) => {
      setEnabledNotifPolling(false);
      await deactivateNotificationItem(item);
      //await refetchNotifications();
      setEnabledNotifPolling(true);
    },
    [deactivateNotificationItem] // refetchNotifications
  );

  const handleCloseNotifications = useCallback(() => setNotificationsButtonEl(null), []);
  const handleOpenNotifications = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setNotificationsButtonEl(event.currentTarget);
  }, []);

  return (
    <React.Fragment>
      <AppBar color="transparent" position="sticky" elevation={0} className={classes.root}>
        <Toolbar>
          <Grid container style={{ position: "relative" }} ref={headerRef}>
            <Grid item xs>
              <Card className={classes.card} variant="elevation" id="header">
                <Hidden mdUp>
                  <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerToggle} className={classes.menuButton} id="sidenav-button">
                    <MenuIcon />
                  </IconButton>
                </Hidden>

                <Searchbar fullWidth borderNone={true} disabled={!showMachineSelect} popupAnchorRef={headerRef} />

                {/* <Divider className={classes.divider} orientation="vertical" /> */}

                {/* <Grid container className={classes.notifications}>
                  <Grid item>
                    <IconButton id="notifications-button" color="inherit" onClick={handleOpenNotifications} edge="right" aria-haspopup="true">
                      <Badge badgeContent={notifications?.length} color="primary">
                        <NotificationsOutlined />
                      </Badge>
                    </IconButton>
                    <Menu
                      anchorEl={headerRef.current}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      disableScrollLock={true}
                      container={headerRef.current}
                      getContentAnchorEl={null}
                      open={Boolean(notificationsButtonEl)}
                      onClose={handleCloseNotifications}
                    >
                      {notifications?.length ? <h3 style={{ textAlign: "center" }}>Active Events</h3> : null}
                      {notifications?.length ? (
                        notifications!.map((item, index) => (
                          <MenuItem key={index}>
                            <Grid container spacing={2} justify="space-between" alignItems="center">
                              <Grid item xs>
                                <ListItemText
                                  id={`notification-${index}-label`}
                                  primary={item.scheduleTitle}
                                  secondary={`${item.equipmentName ? `${item.equipmentName} ` : ""}${item.dueDate ? moment(item.dueDate).format(environment.defaultDateFormat) : ""
                                    }`}
                                  secondaryTypographyProps={{ variant: "caption" }}
                                />
                              </Grid>
                              <Grid item>
                                <Grid container>
                                  <Grid item>
                                    <Tooltip title="Inactivate Event">
                                      <span>
                                        <IconButton edge="right" onClick={() => handleDeactivateNotification(item)}>
                                          <RemoveCircleOutline />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem onClick={handleCloseNotifications}>
                          <ImageWithText
                            image={<Megaphone size="80px" />}
                            title="No scheduled events"
                            subheader="You're in app scheduled events will show up here."
                          />
                        </MenuItem>
                      )}
                    </Menu>
                  </Grid>
                </Grid> */}
              </Card>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default withStyles(styles)(Header);

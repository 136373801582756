export class MimeUtil {
  static getType(file: File) {
    let fileType = file.type;

    if (!fileType && file.name.toLowerCase().endsWith(".dcm")) {
      fileType = "application/dicom";
    }

    return fileType;
  }
}

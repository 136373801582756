import {
  withStyles,
  Theme,
  createStyles,
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";

export interface AvatarProps extends MuiAvatarProps {
  size?: string | number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Partial<AvatarProps>) => ({
    width: props.size || 40,
    height: props.size || 40,
  }),
}));

function Avatar(props: AvatarProps) {
  const { ...rest } = props;

  const defaultProps: MuiAvatarProps = {
    ...rest,
  };

  const classes = useStyles(props);

  return <MuiAvatar classes={classes} {...defaultProps} />;
}

export default React.memo(Avatar);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import CardHeader, { CardHeaderProps } from "../CardHeader/CardHeader";

export interface DialogProps extends MuiDialogProps {
  headerProps: CardHeaderProps;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    boxShadow: `${theme.elevation.high} ${theme.shadowColor}`,
  },
}));

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Dialog({ classes, children, headerProps, ...rest }: DialogProps) {
  const defaultClasses = useStyles();

  const defaultProps: MuiDialogProps = {
    keepMounted: true,
    scroll: "body",
    classes: { ...defaultClasses, ...classes },
    ...rest,
  };

  const { variant: headerVariant, ...otherHeaderProps } = headerProps;

  const defaultHeaderProps: CardHeaderProps = {
    ...otherHeaderProps,
    variant: headerVariant || "h5",
    id: "confirm-dialog-title",
  };

  return (
    <MuiDialog TransitionComponent={Transition} {...defaultProps} aria-labelledby="confirm-dialog-title">
      <CardHeader {...defaultHeaderProps}></CardHeader>
      <br />
      {children}
    </MuiDialog>
  );
}

export default React.memo(Dialog);

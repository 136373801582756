import React, { useCallback, useMemo, useState } from "react";

import { MachineTestResult } from "../../../models/machine-test-result.model";
import StatusChip from "../StatusChip/StatusChip";
import Tooltip from "../Tooltip/Tooltip";
import Button from "../Button/Button";
import Table, { TableColumn, BaseTableProps } from "../Table/Table";
import moment from "moment";
import { Grid, Typography, useTheme } from "@material-ui/core";
import {
  EditOutlined,
  CheckBoxOutlined,
  InsertDriveFileOutlined,
  AttachFileOutlined,
} from "@material-ui/icons";
import environment from "../../../env";
import EditBoxIcon from "../Icons/EditBoxIcon";
import IconButton from "../IconButton/IconButton";
import TestResultStatusChips from "../TestResultStatusChips/TestResultStatusChips";
import { MachineTestFrequency } from "../../../models/machine-test.model";

export interface TestResultListProps extends BaseTableProps<MachineTestResult> {
  hideLocation?: boolean;
  hideFacility?: boolean;
  onViewItem?: (testResult: MachineTestResult) => void;
  signTestsAction?: {
    disabled?: (tests: MachineTestResult[]) => boolean;
    disabledText?: (tests: MachineTestResult[]) => string;
    onClick: (tests: MachineTestResult[]) => void;
  };
  approveTestsAction?: {
    disabled?: (tests: MachineTestResult[]) => boolean;
    disabledText?: (tests: MachineTestResult[]) => string;
    onClick: (tests: MachineTestResult[]) => void;
  };
}

function TestResultList(props: TestResultListProps) {
  const {
    id,
    header,
    actions,
    items,
    pageLimits,
    page,
    limit,
    total,
    hideSort,
    hideSelect,
    onSort,
    onChangePage,
    onViewItem,
    signTestsAction,
    approveTestsAction,
    hideLocation,
    hideFacility
  } = props;

  const [selected, setSelected] = useState<number[]>();
  const theme = useTheme();

  const handleSetSelected = useCallback((newSelected: number[]) => {
    setSelected(newSelected);
  }, []);

  const handleViewItem = useCallback(
    (testResult: MachineTestResult) => (event: React.MouseEvent<HTMLElement>) => {
      // Stop row from being selected if button pressed
      event.stopPropagation();
      if (!onViewItem) {
        return;
      }

      onViewItem(testResult);
    },
    [onViewItem]
  );

  const handleSignoff = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!signTestsAction) {
        return;
      }

      const selectedTests = items.filter((i) => selected?.includes(i.id));

      signTestsAction.onClick(selectedTests);
    },
    [signTestsAction, items, selected]
  );

  const handleApprove = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!approveTestsAction) {
        return;
      }

      const selectedTests = items.filter((i) => selected?.includes(i.id));

      approveTestsAction.onClick(selectedTests);
    },
    [approveTestsAction, items, selected]
  );

  const columns: TableColumn<MachineTestResult>[] = useMemo(() => {
    let _cols: TableColumn<MachineTestResult>[] = [
      {
        keyProp: "testVersion",
        label: "Test",
        hideSort: true,
        renderCell: (row) => (
          <span>
            {row.testVersion?.parentId
              ? `${row.setup?.alias?.trim().length ? row.setup.alias + " - " : ""}${row.testVersion.label}`
              : row.setup?.alias?.trim().length
                ? `${row.setup?.alias?.trim()}`
                : `${row.testVersion.label}`}
          </span>
        ),
      },
      {
        // Use id column for row actions
        keyProp: "id",
        label: "",
        hideSort: true,
        renderCell: (row) => (
          <div>
            <Tooltip title="View result">
              <IconButton color="primary" aria-label="test graph" onClick={handleViewItem(row)}>
                <EditBoxIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
      {
        keyProp: "setup",
        label: "Frequency",
        hideSort: true,
        // Check for bad data with missing setup information and don't show a frequency
        renderCell: (row) => <span>{!row.setup ? "" :
          row.setup.frequency == MachineTestFrequency.Daily ? "Daily" :
            row.setup.frequency == MachineTestFrequency.Weekly ? "Weekly" :
              row.setup.frequency == MachineTestFrequency.Biannual ? "Biannual" :
                row.setup.frequency == MachineTestFrequency.Biweekly ? "Biweekly" :
                  row.setup.frequency == MachineTestFrequency.Monthly ? "Monthly" :
                    row.setup.frequency == MachineTestFrequency.Quarterly ? "Quarterly" :
                      row.setup.frequency == MachineTestFrequency.Semiannual ? "Semiannual" :
                        row.setup.frequency == MachineTestFrequency.Yearly ? "Yearly" : ""}</span>,
      },
      {
        keyProp: "status",
        label: "Status / Sign-off / Approval",
        hideSort: true,
        renderCell: (row) => (
          <Grid container>
            <Grid item>
              <TestResultStatusChips testResult={row} dateFormat={"M/D/YY h:mma"} />
            </Grid>
            {row.testResultNotes?.length ? (
              <Grid item>
                <StatusChip icon={<InsertDriveFileOutlined />} status={`note${row.testResultNotes.length > 1 ? "s" : ""}`} />
              </Grid>
            ) : null}
            {row.testResultFiles?.length ? (
              <Grid item>
                <StatusChip icon={<AttachFileOutlined />} status={`file${row.testResultFiles.length > 1 ? "s" : ""}`} />
              </Grid>
            ) : null}
          </Grid>
        ),
      },

      {
        keyProp: "testDate",
        label: "Test Date",
        renderCell: (row) => <span>{moment(row.testDate).format(environment.defaultDateFormat)}</span>,
      },
      {
        keyProp: "userCreated",
        label: "Run By",
        altKey: "runBy",
        getSortComparer: (row) => `${row.performedByUser?.firstname || row.userCreated?.firstname || ""} ${row.performedByUser?.lastname || row.userCreated?.lastname || ""}`,
        renderCell: (row) => (
          <span>
            {row.performedByUser?.firstname || row.userCreated?.firstname || ""} {row.performedByUser?.lastname || row.userCreated?.lastname || ""}
          </span>
        ),
      },
      {
        keyProp: "location",
        label: "Location",
        getSortComparer: (row) => row.location || "",
        renderCell: (row) => row.location || "",
      },
      {
        keyProp: "facility",
        label: "Facility",
        getSortComparer: (row) => row.machine.facility?.name || "",
        renderCell: (row) => row.machine.facility?.name || "",
      },
    ];

    if (!onViewItem) {
      _cols = _cols.filter((c) => c.keyProp !== "id");
    }

    if (hideLocation) {
      _cols = _cols.filter((c) => c.keyProp !== "location");
    }

    if (hideFacility) {
      _cols = _cols.filter((c) => c.keyProp !== "facility");
    }

    return _cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleViewItem, hideLocation, onViewItem]);

  const SignTestsButton = useCallback(() => {
    if (!signTestsAction) {
      return null;
    }

    const selectedTests = items.filter((i) => selected?.includes(i.id));
    const disabled = signTestsAction?.disabled && signTestsAction.disabled(selectedTests);
    const disabledText = signTestsAction?.disabledText ? signTestsAction.disabledText(selectedTests) : "";

    return (
      <Tooltip title={disabled ? disabledText : ""}>
        <div>
          <Button variant="text" color="primary" aria-label="sign selected tests" onClick={handleSignoff} startIcon={<EditOutlined />} disabled={disabled}>
            <span>Signoff</span>
          </Button>
        </div>
      </Tooltip>
    );
  }, [items, selected, handleSignoff, signTestsAction]);

  const ApproveTestsButton = useCallback(() => {
    if (!approveTestsAction) {
      return null;
    }

    const selectedTests = items.filter((i) => selected?.includes(i.id));
    const disabled = approveTestsAction?.disabled && approveTestsAction.disabled(selectedTests);
    const disabledText = approveTestsAction?.disabledText ? approveTestsAction.disabledText(selectedTests) : "";

    return (
      <Tooltip title={disabled ? disabledText : ""}>
        <div>
          <Button
            variant="text"
            color="primary"
            aria-label="approve selected tests"
            onClick={handleApprove}
            startIcon={<CheckBoxOutlined />}
            disabled={disabled}
          >
            <span>Approve</span>
          </Button>
        </div>
      </Tooltip>
    );
  }, [items, selected, approveTestsAction, handleApprove]);

  return (
    <Table
      id={id}
      items={items}
      keyProp="id"
      columns={columns}
      header={header}
      page={page}
      limit={limit}
      total={total}
      pageLimits={pageLimits}
      hideSort={hideSort}
      hideSelect={hideSelect}
      onSort={onSort}
      onChangePage={onChangePage}
      onSelectKeys={handleSetSelected}
      actions={actions}
      selectedActions={
        <Grid container direction="row">
          <SignTestsButton />
          <ApproveTestsButton />
        </Grid>
      }
    />
  );
}

export default TestResultList;

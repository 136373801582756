import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader, { CardHeaderProps } from "../CardHeader/CardHeader";
import { GridDirection, GridProps } from "@material-ui/core/Grid";
import { Box, BoxProps } from "@material-ui/core";

export interface ImageWithTextProps extends CardHeaderProps {
  image?: React.ReactNode;
  action?: React.ReactNode;
  direction?: GridDirection;
  textMaxWidth?: number | string;
}

const useStyles = makeStyles((theme) => ({
  root: (props?: ImageWithTextProps) => ({
    display: "flex",
    flexDirection: props?.direction || "column",
    textAlign: props?.align || "center",
    alignItems: props?.align === "left" ? "flex-start" : props?.align === "right" ? "flex-end" : "center",
    ...((props?.style as any) || {}),
  }),
  image: { display: "inline-block", marginBottom: theme.spacing(2) },
  action: { marginTop: theme.spacing(2) },
}));

function ImageWithText(props: ImageWithTextProps) {
  const { image, action, direction, textMaxWidth, ...rest } = props;

  const classes = useStyles(props);

  const defaultHeaderProps: CardHeaderProps = {
    ...rest,
  };

  return (
    <div className={classes.root}>
      {image ? <div className={classes.image}>{image}</div> : null}
      <Box display="flex" flexDirection="column" maxWidth={textMaxWidth}>
        <CardHeader {...defaultHeaderProps} />
        {action ? <div className={classes.action}>{action}</div> : null}
      </Box>
    </div>
  );
}

export default ImageWithText;

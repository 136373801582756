import { QuerySearchParams, getQuerySearchParams } from "./query-search-params.model";

export interface EventSearchParams extends QuerySearchParams {
  daily?: boolean;
  weekly?: boolean;
  biweekly?: boolean;
  monthly?: boolean;
  quarterly?: boolean;
  semiannual?: boolean;
  yearly?: boolean;
  biannual?: boolean;
  hideInactiveMacs?: boolean;
  hasSelector?: boolean;
  notdue?: boolean;
  notActive?: boolean;
  notready?: boolean;
  notresolved?: boolean;
  due?: boolean;
  overdue?: boolean;
  mytests?: boolean;
  ready?: number;
  limit?: number;
  facilityId?: number;
  machineId?: number;
  inprogress?: boolean;
  open?: boolean;
  closed?: boolean;
  userRequestingId?: number;
  searchParams?: SearchParam[];
}

export interface SearchParam {
  dbColumn: string;
  filter: string;
  value: string;
  isAnd: boolean;
  dataType: DataTypes;
}

export enum DataTypes {
  String,
  Boolean,
  DateTime,
  Integer,
  Decimal,
  User,
  Facility,
  Machine,
  LogCategory,
  LogStatus,
  LogNote,
  None,
}

export type FilterLookup = {
  id: number;
  name: string;
  label: string;
  dataType: any;
};

export const FilterTypes: FilterLookup[] = [
  { id: 0, name: "contains_string", label: "Contains", dataType: DataTypes.String },
  { id: 1, name: "is_equal_to_string", label: "Is Equal To", dataType: DataTypes.String },
  { id: 2, name: "is_equal_to_date", label: "Is Equal To", dataType: DataTypes.DateTime },
  { id: 3, name: "is_before_date", label: "Is Before", dataType: DataTypes.DateTime },
  { id: 4, name: "is_after_date", label: "Is After", dataType: DataTypes.DateTime },
  { id: 5, name: "is_equal_to_user", label: "Is Equal To", dataType: DataTypes.User },
  { id: 6, name: "is_equal_to_machine", label: "Is Equal To", dataType: DataTypes.Machine },
  { id: 7, name: "is_equal_to_facility", label: "Is Equal To", dataType: DataTypes.Facility },
  { id: 8, name: "is_equal_to_log_category", label: "Is Equal To", dataType: DataTypes.LogCategory },
  { id: 9, name: "is_equal_to_log_status", label: "Is Equal To", dataType: DataTypes.LogStatus },
  { id: 10, name: "contains_log_note", label: "Contains", dataType: DataTypes.LogNote },
  { id: 11, name: "is_equal_to_log_note", label: "Is Equal To", dataType: DataTypes.LogNote },
];

/**
 * Get an object to pass as params formatted for the API.
 * @param searchParams
 */
export function getEventSearchParams<T extends EventSearchParams>(searchParams: T, removeFalseParams = false): any {
  // Convert base params
  const { notActive, ...rest } = searchParams;

  const baseParams = getQuerySearchParams(rest, removeFalseParams);
  let criteria = { ...baseParams };

  if (notActive) {
    criteria = { ...criteria, not_active: notActive };
  }

  return criteria;
}

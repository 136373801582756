import {
  withStyles,
  Theme,
  createStyles,
  Badge as MuiBadge,
  BadgeProps as MuiBadgeProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";

export interface BadgeProps extends Pick<MuiBadgeProps, Exclude<keyof MuiBadgeProps, "color">> {
  color?: "primary" | "secondary" | "default" | "error" | "warning" | "success" | "info";
  shape?: "default" | "square";
  htmlColor?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  badge: (props: Partial<BadgeProps>) => ({
    background: props.htmlColor
      ? props.htmlColor
      : props.color === "warning"
      ? theme.palette.warning.main
      : props.color === "success"
      ? theme.palette.success.main
      : props.color === "info"
      ? theme.palette.info.main
      : undefined,
    borderRadius: props.shape === "square" ? "0" : undefined,
    "&:not(.MuiBadge-dot)": {
      top: "4px",
      right: "-2px",
      border: `1px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      fontSize: "0.7rem",
      height: 16,
      minWidth: 16,
    },
  }),
}));

function Badge({ color, shape, htmlColor, ...rest }: BadgeProps) {
  const muiColor = color && ["default", "primary", "secondary", "error"].includes(color) ? color : undefined;

  const defaultProps: MuiBadgeProps = {
    color: muiColor as any,
    ...rest,
  };

  const classes = useStyles({ color: muiColor ? undefined : color, shape, htmlColor });

  return <MuiBadge classes={{ ...classes, ...rest.classes }} {...defaultProps} />;
}

export default React.memo(Badge);

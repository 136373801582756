import React, { createContext, useContext, useEffect, useState } from "react";
import { MachineType, MachineCode } from "../models/machine.model";
import useMachineService from "../hooks/machine-service.hook";
import errorMessages from "../translations/en/errors.json";
import { useAuthContext } from "./auth.context";
import useNotificationService from "../hooks/notification-service.hook";
import { Notification } from "../models/notification.model";
import { useUserContext } from "./user.context";


export interface LookupContext {
  machineTypes: MachineType[];
  machineCodes: MachineCode[];
  notifications: Notification[];
}

const Context = createContext<LookupContext>(null!);

function LookupContextProvider(props: React.PropsWithChildren<{}>) {
  const { session } = useAuthContext();
  const { org } = useUserContext();

  const { getMachineTypes, getMachineCodes } = useMachineService();
  const { getNotifications } = useNotificationService();

  const [machineTypes, setMachineTypes] = useState<MachineType[]>([]);

  const [machineCodes, setMachineCodes] = useState<MachineCode[]>([]);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (!session || machineCodes.length) {
      // To refresh lookups, log off log back on.
      return;
    }

    getMachineCodes().then((response) => {
      setMachineCodes(response);

      return response;
    });
  }, [getMachineCodes, machineCodes, session, setMachineCodes]);

  useEffect(() => {
    if (!session || machineTypes.length || !org) {
      // To refresh lookups, log off log back on.
      return;
    }

    getMachineTypes(org!.id).then((response) => {
      setMachineTypes(response);

      return response;
    });
  }, [getMachineTypes, org, machineTypes, session, setMachineTypes]);

  useEffect(() => {
    if (!session || notifications.length) {
      // To refresh lookups, log off log back on.
      return;
    }

    getNotifications().then((response) => {
      setNotifications(response);

      return response;
    });
  }, [getNotifications, notifications, session, setNotifications]);

  const contextValue: LookupContext = {
    machineTypes: machineTypes,
    machineCodes: machineCodes,
    notifications: notifications,
  };

  return <Context.Provider value={{ ...contextValue }}>{props.children}</Context.Provider>;
}

function useLookupContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(errorMessages.context.useHookWithinProvider);
  }
  return context;
}

export { LookupContextProvider, useLookupContext };

import { ChangeEvent, useCallback, useMemo } from "react";

import AutocompleteInput, { AutocompleteInputProps } from "../AutocompleteInput/AutocompleteInput";

type CustomAutocompleteProps<T, DisableClearable extends boolean | undefined> = AutocompleteInputProps<T, false, false, false>;

export interface SelectInputProps<
  TValue,
  TOption extends TValue | { [key in keyof TOption]: any } | undefined = TValue,
  DisableClearable extends boolean | undefined = false
> extends Pick<
    CustomAutocompleteProps<TOption, DisableClearable>,
    Exclude<keyof CustomAutocompleteProps<TOption, DisableClearable>, "disableClearable" | "value" | "onChange" | "renderInput">
  > {
  value?: TValue;
  onChange?: (event: ChangeEvent<{}>, value: TValue | null, option: TOption | null) => void;
  labelProp?: keyof TOption;
  valueProp?: keyof TOption;
  disableClearable?: boolean;
}

function SelectInput<
  TValue,
  TOption extends TValue | { [key in keyof TOption]: any } | undefined = TValue,
  DisableClearable extends boolean | undefined = false
>(props: SelectInputProps<TValue, TOption, DisableClearable>) {
  const { options, valueProp, labelProp, value, onChange, getOptionLabel, disableClearable, ...rest } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value?: TValue }>, option: TOption | null) => {
      if (!onChange) {
        return;
      }

      const val = option == null ? null : valueProp ? (option[valueProp] as any) : (option as any);
      onChange(event, val, option);
    },
    [onChange, valueProp]
  );

  const getOptionValue = useCallback(
    (option: TOption): TValue => {
      if (!valueProp) {
        return option as any;
      }

      return option[valueProp] as any;
    },
    [valueProp]
  );

  const getOption = useCallback(
    (optionValue: TValue): TOption | undefined => {
      const option = options?.find((i) => getOptionValue(i) === optionValue);

      return option;
    },
    [getOptionValue, options]
  );

  const selectedOption = useMemo(() => {
    /**
    ? DisableClearable extends true
    ? NonNullable<T | AutocompleteFreeSoloValueMapping<FreeSolo>>
    : T | null | AutocompleteFreeSoloValueMapping<FreeSolo>
    : Array<T | AutocompleteFreeSoloValueMapping<FreeSolo>>;
     */

    const option = value == null ? undefined : getOption(value);
    return option || null;
  }, [getOption, value]);

  const handleGetOptionLabel = useCallback(
    (option: TOption): string => {
      if (getOptionLabel) {
        return getOptionLabel(option);
      }

      if (!labelProp || !valueProp) {
        return `${option}`;
      }

      return `${option[labelProp]}`;
    },
    [getOptionLabel, labelProp, valueProp]
  );

  const defaultProps: Partial<CustomAutocompleteProps<TOption, DisableClearable>> = {
    onChange: handleChange,
    options,
    getOptionLabel: handleGetOptionLabel,
    ...rest,
  };

  return <AutocompleteInput {...defaultProps} disableClearable={disableClearable === true} value={selectedOption} />;
}

export default SelectInput;

import {
  withStyles,
  createStyles,
  Theme,
  WithStyles,
} from "@material-ui/core/styles";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@material-ui/core";
import React from "react";

const styles = createStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export interface CheckboxProps extends WithStyles<typeof styles> {}

function Checkbox(props: CheckboxProps & MuiCheckboxProps) {
  const { classes, ...rest } = props;

  const defaultProps: MuiCheckboxProps = {
    ...rest,
  };

  return <MuiCheckbox classes={classes} {...defaultProps}></MuiCheckbox>;
}

export default withStyles(styles)(Checkbox);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardHeader as MuiCardHeader, CardHeaderProps as MuiCardHeaderProps, CardHeaderTypeMap, PropTypes } from "@material-ui/core";
import { OverrideProps } from "@material-ui/core/OverridableComponent";
import { Variant } from "@material-ui/core/styles/createTypography";

export type CardHeaderProps<
  DefaultComponent extends React.ElementType = CardHeaderTypeMap["defaultComponent"],
  Props = { align?: PropTypes.Alignment; variant?: Variant | "inherit" },
  TitleTypographyComponent extends React.ElementType = "span",
  SubheaderTypographyComponent extends React.ElementType = "span"
> = OverrideProps<CardHeaderTypeMap<Props, DefaultComponent, TitleTypographyComponent, SubheaderTypographyComponent>, DefaultComponent>;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0",
  },
}));

function CardHeader({ align, variant, titleTypographyProps, subheaderTypographyProps, ...rest }: CardHeaderProps) {
  const defaultProps: MuiCardHeaderProps = {
    titleTypographyProps: {
      variant: variant || "h6",
      align: align || "inherit",
      ...(titleTypographyProps || {}),
    },
    subheaderTypographyProps: {
      align: align || "inherit",
      ...(subheaderTypographyProps || {}),
    },
    ...rest,
  };

  const classes = useStyles();

  return <MuiCardHeader className={classes.root} {...defaultProps} />;
}

export default CardHeader;

import { useCallback } from "react";
import { useHttpClientContext, HttpClientRequestConfig } from "../context/http-client.context";
import errorMessages from "../translations/en/errors.json";
import { MachineOptionParam, getMachineOptionUrlPrefix } from "../models/machine-option-type.model";
import { MachineEvent } from "../models/machine-event.model";
import { EventSearchParams, getEventSearchParams } from "../models/event-search-params.model";
import environment from "../env";

export interface MachineEventServiceOptions {}

export interface MachineEventService {
  getMachineSchedule(machineOptionType: MachineOptionParam, criteria?: EventSearchParams): Promise<{ items: MachineEvent[]; total: number }>;
  getMachineEventById(orgId: number, id: number): Promise<MachineEvent>;
  getMachineEventsByMachineId(orgId: number, machineId: number, criteria?: EventSearchParams): Promise<{ items: MachineEvent[]; total: number }>;
  addMachineEvent(orgId: number, data: Partial<MachineEvent>): Promise<MachineEvent>;
  updateMachineEvent(orgId: number, criteria: Partial<MachineEvent>): Promise<MachineEvent>;
}

function useMachineEventService(options?: MachineEventServiceOptions): MachineEventService {
  const { httpClient } = useHttpClientContext();

  const getMachineSchedule = useCallback(
    (machineOptionType: MachineOptionParam, criteria?: EventSearchParams) => {
      const prefix = getMachineOptionUrlPrefix(machineOptionType);

      const endpoint = `${prefix}/schedule`;

      const apiCriteria = criteria ? getEventSearchParams(criteria, true) : undefined;

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.root as MachineEvent[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  const getMachineEventById = useCallback(
    (orgId: number, id: number) => {
      const endpoint = `orgs/${orgId}/machine-events/${id}`;

      const config: HttpClientRequestConfig = {
        params: {
          id,
        },
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const item = response.data as MachineEvent;

        return item;
      });
    },
    [httpClient]
  );

  const getMachineEventsByMachineId = useCallback(
    (orgId: number, machineId: number, criteria?: EventSearchParams) => {
      const endpoint = `orgs/${orgId}/machine-events/machine/${machineId}`;

      const apiCriteria = criteria ? getEventSearchParams(criteria, true) : undefined;

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data as MachineEvent[];
        return { items, total: items.length };
      });
    },
    [httpClient]
  );

  const addMachineEvent = useCallback(
    (orgId: number, data: Partial<MachineEvent>): Promise<MachineEvent> => {
      const endpoint = `orgs/${orgId}/machine-events`;
      return httpClient.post(endpoint, data, { baseURL: environment.newApiUrl }).then((response) => {
        if (response.data.error) {
          return Promise.reject(response.data.error);
        }
        const item = response.data as MachineEvent;

        return item;
      });
    },
    [httpClient]
  );

  const updateMachineEvent = useCallback(
    (orgId: number, criteria: Partial<MachineEvent>): Promise<MachineEvent> => {
      const endpoint = `orgs/${orgId}/machine-events/${criteria.id}`;
      return httpClient.put(endpoint, criteria, { baseURL: environment.newApiUrl }).then((response) => {
        // Previous dev is returning 200 with errors. At this point I'm just not even shocked.
        if (response.data.error) {
          return Promise.reject(response.data.error);
        }
        const item = response.data as MachineEvent;

        return item;
      });
    },
    [httpClient]
  );

  return {
    getMachineSchedule,
    getMachineEventsByMachineId,
    addMachineEvent,
    updateMachineEvent,
    getMachineEventById,
  };
}

export default useMachineEventService;

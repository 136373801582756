import { CircularProgress, createStyles, InputProps, makeStyles } from "@material-ui/core";
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from "@material-ui/lab";
import React, { useCallback, useMemo } from "react";
import TextInput from "../TextInput/TextInput";
import AutocompleteInputMenu from "./AutocompleteInputMenu";

import { InputLabelProps } from "@material-ui/core/InputLabel";
import AutocompleteInputPopper from "./AutocompleteInputPopper";

type AutocompleteInputExtras = {
  error?: boolean;
  name?: string;
  label?: React.ReactNode;
  autoFocus?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  borderNone?: boolean;
  minWidth?: string | number;
  InputProps?: Partial<InputProps>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

type CustomAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

export interface AutocompleteInputProps<
  T,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Pick<
      CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
      Exclude<keyof CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "options" | "renderInput">
    >,
    AutocompleteInputExtras {
  options?: T[];
}

const useStyles = makeStyles((theme) =>
  createStyles({
    inputRoot: (props?: AutocompleteInputExtras) =>
      props?.borderNone
        ? {
            borderRadius: 0,
            backgroundColor: "transparent",

            "&.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
              paddingLeft: 0,
            },
            "&.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child": {
              paddingLeft: 0,
              paddingRight: 0,
            },

            "& .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },
          }
        : {
            "&.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
              paddingLeft: 12,
              paddingTop: 6,
              paddingBottom: 6,
            },

            "&.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input": {
              minHeight: 24,
              lineHeight: 24,
              margin: 2,
              minWidth: 80,
              padding: 0,
              width: "auto",
            },

            "&>.MuiAutocomplete-tagSizeSmall": {
              margin: 2,
              height: 24,
            },
          },
    input: (props: any) => (props.borderNone ? { padding: 0 } : {}),
    paper: {
      boxShadow: `${theme.elevation.main} ${theme.shadowColor}`,
    },
  })
);

function AutocompleteInput<
  T,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false
>(props: AutocompleteInputProps<T, Multiple, FreeSolo, DisableClearable>) {
  const { error, name, label, options, placeholder, autoFocus, inputRef, borderNone, minWidth, startAdornment, endAdornment, ...rest } = props;

  const classes = useStyles(props);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams & { InputLabelProps: InputLabelProps }) => {
      const { InputLabelProps: defaultLabelProps, InputProps: defaultInputProps, ...withoutLabelProps } = params;

      const showPlaceholder = Boolean(placeholder);

      // Split label props out and auto shrink label if placeholder.
      const labelProps: Partial<InputLabelProps> | undefined = !defaultLabelProps
        ? undefined
        : {
            ...defaultLabelProps,
            shrink: showPlaceholder ? true : defaultLabelProps?.shrink,
          };

      // Split out input props so we can append input adornments.
      const inputProps = !defaultInputProps
        ? undefined
        : {
            ...defaultInputProps,
            startAdornment:
              startAdornment || defaultInputProps.startAdornment ? (
                <>
                  {startAdornment}
                  {defaultInputProps.startAdornment}
                </>
              ) : undefined,
            endAdornment:
              endAdornment || defaultInputProps.endAdornment ? (
                <>
                  {defaultInputProps.endAdornment}
                  {endAdornment}
                </>
              ) : undefined,
          };

      return (
        <TextInput
          {...withoutLabelProps}
          inputRef={inputRef}
          autoFocus={autoFocus}
          name={name}
          label={label}
          error={error}
          borderNone={borderNone}
          minWidth={minWidth}
          placeholder={placeholder}
          InputLabelProps={labelProps}
          InputProps={inputProps}
        />
      );
    },
    [autoFocus, borderNone, error, inputRef, label, minWidth, name, placeholder, startAdornment, endAdornment]
  );

  const loading = useMemo(() => !options, [options]);

  const defaultProps: Partial<CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> = {
    openOnFocus: true,
    clearOnBlur: true,
    selectOnFocus: true,
    handleHomeEndKeys: true,
    PaperComponent: AutocompleteInputMenu,
    size: "small",
    popupIcon: loading ? <CircularProgress size={20} /> : undefined,
    loading,
    //noOptionsText: "No results",
    classes,
    PopperComponent: AutocompleteInputPopper,
    ...rest,
  };

  return <Autocomplete {...defaultProps} options={options || []} renderInput={renderInput} />;
}

export default AutocompleteInput;

export type Lookup<TId extends string | number = number, P = {}> = {
  id: TId;
  name: string;
  label?: string;
  meta?: any;
} & P;

export function enumToLookup<TId extends string | number = number>(enumType: any): Lookup<TId>[] {
  let keys = Object.keys(enumType);

  // If number then both keys and values will exist here and values (numbers) will be first.
  // So check the first key for number.
  const isNumeric = !isNaN(Number(keys[0]));

  // If number, then strip out the first half of the keys (numbers)
  if (isNumeric) {
    keys = keys.filter((key) => isNaN(Number(key)));
  }

  const items = keys.map((key) => {
    const enumVal = enumType[key];

    // Add spaces to key if not already there
    const parsedName = key.includes(" ") ? key : titleCase(key);
    const name = parsedName.charAt(0).toUpperCase() + parsedName.slice(1);

    return {
      id: enumVal,
      name: name,
      label: name,
    };
  });

  return items as Lookup<any>[];
}

const titleCase = (key: string) =>
  key
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
    // Add space between start and end of a number and any letter.
    .replace(/([a-z])([0-9])/g, "$1 $2")
    .replace(/([0-9])([A-Z])/g, "$1 $2")
    .replace(/([0-9])([a-z])/g, "$1 $2")
    // Replace all underscores with space.
    .replace(/_/g, " ")
    .trim();

export function getLookupName(lookups: Lookup<any>[], id: any): string | undefined {
  const item = lookups.find((i: any) => i.id === id);
  return item?.name;
}

import { makeStyles } from "@material-ui/core/styles";
import Badge from "../Badge/Badge";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "min-content minmax(0, 1fr)",
        alignItems: "center",
        columnGap: theme.spacing(1)
    },
    key: {
        marginRight: theme.spacing(1)
    },
    value: {
        color: theme.palette.text.secondary
    }
}));

export interface CalendarLegendItemProps extends React.HTMLAttributes<HTMLDivElement> {
    color: string;
    label: string;
    shape: CalendarLegendItemShape;
}

export type CalendarLegendItemShape = "circle" | "square";

export function CalendarLegendItem(props: CalendarLegendItemProps) {
    const classes = useStyles();

    return (
        <div className={clsx(props.className, classes.root)}>
            <Badge
                className={classes.key}
                variant="dot"
                shape={props.shape === "square" ? "square" : "default"}
                htmlColor={props.color}></Badge>
            <span className={classes.value}>{props.label}</span>
        </div>
    )
}
import Box from "@material-ui/core/Box";
import React from "react";

export type InputWrapperProps = {
  hidden?: boolean;
};

const InputWrapper = React.memo(({ hidden, children }: React.PropsWithChildren<InputWrapperProps>) => {
  return hidden ? <Box display="none">{children}</Box> : <>{children}</>;
});

export default InputWrapper;

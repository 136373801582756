import React, { ForwardedRef, useCallback, useMemo, useRef, useState } from "react";
import WidgetCard, { WidgetCardProps } from "../../../components/WidgetCard/WidgetCard";
import { Box, Grid, Link as MuiLink, List, ListItem, ListItemIcon, ListItemText, ListProps, Menu, MenuItem, Typography } from "@material-ui/core";
import { FilterList, HelpOutline, MoreVert, RemoveCircleOutline } from "@material-ui/icons";
import IconButton from "../../../components/IconButton/IconButton";
import { useUserContext } from "../../../../context/user.context";
import moment from "moment";
import useMachineService from "../../../../hooks/machine-service.hook";
import useMachineLogService from "../../../../hooks/machine-log-service.hook";
import useTrackerService from "../../../../hooks/tracker-service.hook";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Button from "../../../components/Button/Button";
import { useHistory, Link } from "react-router-dom";
import ImageWithText from "../../../components/ImageWithText/ImageWithText";
import Report from "../../../components/Svg/Report";
import RetryWidget from "../../../components/ImageCaptions/RetryWidget";
import { Components, Virtuoso } from "react-virtuoso";
import { useQuery } from "react-query";
import usePersistedState from "../../../../hooks/persisted-state.hook";
import { useStatusEffect } from "../../../../hooks/status-effect.hook";
import useSearchbarService from "../../../../hooks/searchbar-service.hook";
import useDeviceTestService from "../../../../hooks/device-test-service.hook";
import { TrackerItem } from "../../../../models/tracker.model";
import useMachineEventService from "../../../../hooks/machine-event-service.hook";

interface TrackerOptions {
  criteria: any;
  showSchedule: boolean;
  showLogs: boolean;
  showTestResults: boolean;
}

const defaultTrackerOptions: TrackerOptions = {
  showSchedule: true,
  showLogs: true,
  showTestResults: true,
  criteria: {
    startDate: moment.utc().startOf("day").subtract(3, "months").format("YYYY-MM-DD"),
  },
};

export interface TrackerWidgetProps extends WidgetCardProps {
  onRemoveWidget?: () => void;
}

function TrackerWidget(props: TrackerWidgetProps) {
  const { onRemoveWidget, ...rest } = props;
  const { searchbarFilter } = useSearchbarService();
  const { org, user } = useUserContext();

  const { getTestResultById, hasTestPermission } = useDeviceTestService();
  const { getMachineLog } = useMachineLogService();
  const { getTrackerData } = useTrackerService();
  const { getMachineEventById } = useMachineEventService();



  const [retry, setRetry] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();

  const [trackerOptions, setTrackerOptions] = usePersistedState<TrackerOptions>("home/widget/tracker/options", {
    defaultValue: defaultTrackerOptions,
  });

  const options = trackerOptions || defaultTrackerOptions;
  const [inputCriteria, setInputCriteria] = useState<TrackerOptions & { delay: number }>({ ...options, delay: 0 });

  const {
    isLoading: trackerLoading,
    isError: trackerError,
    data: trackerItems,
  } = useQuery(["home/widget/tracker", options, org, searchbarFilter, retry], {
    queryFn: async () => {
      if (!org) {
        return Promise.resolve([]);
      }

      let trackerData = await getTrackerData({
        orgId: org!.id,
        facilityId: searchbarFilter.facilityId,
        machineId: searchbarFilter.machineId,
        startDate: options.criteria.startDate,
        userId: user!.id
      });
      if (!options.showLogs) {
        trackerData = trackerData.filter((item) => {
          return item.type !== "Equipment Log";
        })
      }
      if (!options.showSchedule) {
        trackerData = trackerData.filter((item) => {
          return item.type !== "Event";
        })
      }
      if (!options.showTestResults) {
        trackerData = trackerData.filter((item) => {
          return item.type !== "Unresolved Test Result";
        })
      }

      return trackerData;
    },
    refetchOnMount: 'always',
  });

  // Handles async input changes with delay
  useStatusEffect(
    (status) => {
      // Skip first render as we only want to eval this effect when input changes actually happen
      if (status.current === "mount") {
        return;
      }

      const { delay, ...rest } = inputCriteria;

      // Delay setting value to limit api calls for every change
      const delayTimer = setTimeout(() => {
        if (status.current !== "unmount") {
          // close filter menu
          setMenuAnchorEl(null);
          // Set criteria for API
          setTrackerOptions((prev) => ({ ...prev!, ...rest }));
        }
      }, delay);

      // this will clear Timeout when inputCriteria changes
      return () => {
        clearTimeout(delayTimer);
      };
    },
    [inputCriteria, setTrackerOptions]
  );

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const handleCriteriaChange = useCallback(
    (change: Partial<typeof inputCriteria>, delay = 0) =>
      () => {
        setInputCriteria((prev) => {
          return { ...prev, ...change, criteria: { ...prev.criteria, ...change.criteria }, delay };
        });
      },
    []
  );

  const handleViewTestResult = useCallback(
    async (testResultId: number) => {
      const testResult = await getTestResultById(org!.id, testResultId);
      history.push(`/tests/${testResult.testVersion.testId}/results/${testResult.id}`, {
        previousLocation: { pathname: `/` },
        testResult: testResult,
        machine: {
          machineId: testResult.machineId,
          facilityId: testResult.machine.facilityId,
          name: testResult.machine.name,
        },
      });
    },
    [getTestResultById, org, history]
  );

  const handleViewLog = useCallback(
    async (machineLogId: number) => {
      const machineLog = await getMachineLog(org!.id, machineLogId)
      history.push(`/logs/${machineLogId}`, {
        previousLocation: { pathname: `/` },
        log: machineLog,
      });
    },
    [getMachineLog, history, org]
  );

  const handleViewEvent = useCallback(
    async (eventId: number) => {
      const machineEvent = await getMachineEventById(org!.id, eventId)
      history.push(`/events/${eventId}`, {
        previousLocation: { pathname: `/` },
        machineEvent,
      });
    },
    [getMachineEventById, history, org]
  );

  const handleViewItem = useCallback(
    (trackerItem: TrackerItem) => () => {
      if (trackerItem.type === "Unresolved Test Result") {
        handleViewTestResult(trackerItem.id);
      } else if (trackerItem.type === "Equipment Log") {
        handleViewLog(trackerItem.id);
      } else if (trackerItem.type === "Event") {
        handleViewEvent(trackerItem.id);
      }
    },
    [handleViewEvent, handleViewLog, handleViewTestResult]
  );

  const handleRetry = useCallback(() => {
    setRetry((prev) => prev + 1);
  }, []);

  const renderListItem = useCallback(
    (index: number) => {
      const item = trackerItems?.[index];
      const getSecondaryText = (item: any) => {
        if (searchbarFilter.machineId) {
          return item.label;
        }
        if (searchbarFilter.facilityId) {
          return `${item.machineName}\n${item.label}`;
        }
        return `${item?.facilityName} / ${item.machineName}\n${item.label}`;
      }

      return (
        <>
          <ListItemIcon>
            <Button color="primary">
              <div className="{classes.testListItemDate}">
                <div className="month">
                  <Typography variant="caption">{moment(item?.date).format("MMM").toUpperCase()}</Typography>
                </div>
                <div className="day">{moment(item?.date).format("D")}</div>
              </div>
            </Button>
          </ListItemIcon>
          <ListItemText primary={item?.type} secondaryTypographyProps={{ variant: "caption", style: { whiteSpace: "pre-line" } }} secondary={getSecondaryText(item)} />
        </>
      );
    },
    [searchbarFilter.facilityId, searchbarFilter.machineId, trackerItems]
  );

  const listComponents: Components = useMemo(() => {
    return {
      List: React.forwardRef(({ style, children }: ListProps, listRef: ForwardedRef<HTMLDivElement>) => {
        return (
          <List disablePadding style={{ ...style }} component="div" ref={listRef}>
            {children}
          </List>
        );
      }),

      Item: ({ children, ...props }) => {
        const index = props["data-item-index"];
        const item = trackerItems?.[index];

        return (

          <ListItem component="div" {...props} dense button disabled={false} onClick={handleViewItem(item!)}>
            {children}
          </ListItem>
        );
      },

      Footer: () => {
        return (
          <ListItem component="div">
            <Typography variant="body2" color="textSecondary">
              <span>
                Tracker only shows items for the past 3 months. For a longer look back, check out{" "}
                <MuiLink component={Link} underline="none" to="/reports">
                  Reports
                </MuiLink>
                .
              </span>
            </Typography>
          </ListItem>
        );
      },
    };
  }, [handleViewItem, trackerItems]);

  const TrackerItemList = useMemo(
    () =>
      trackerError ? (
        <RetryWidget onRetry={handleRetry} />
      ) : !trackerItems?.length ? (
        // Show empty image with text
        <ImageWithText image={<Report size="80px" />} title="All caught up!" subheader="Events that are due, or items that require follow up will show here." />
      ) : (
        <>
          <Box height={320} ml={-2} mr={-2}>
            <Virtuoso components={listComponents} totalCount={trackerItems.length} itemContent={renderListItem} />
          </Box>
        </>
      ),
    [trackerError, handleRetry, trackerItems?.length, listComponents, renderListItem]
  );

  const defaultWidgetCardProps = {
    ...rest,
  };

  const filterMenuButtonRef = useRef(null);
  const widgetMenuButtonRef = useRef(null);

  return (
    <WidgetCard
      id="tracker-widget"
      header="ZapIT Tracker"
      loading={trackerLoading}
      {...defaultWidgetCardProps}
      style={{ "height": "100%" }}
      actions={
        <>
          <Grid container>
            <Grid item ref={filterMenuButtonRef}>
              <IconButton id="tracker-filter-button" aria-label="filter tracker" aria-haspopup="true" onClick={handleOpenMenu}>
                <FilterList />
              </IconButton>

              <Menu
                id="tracker-filter-menu"
                keepMounted
                anchorEl={filterMenuButtonRef.current}
                open={Boolean(menuAnchorEl?.id === "tracker-filter-button")}
                onClose={handleCloseMenu}
                disableScrollLock={true}
                container={filterMenuButtonRef.current}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem button onClick={handleCriteriaChange({ showSchedule: !inputCriteria.showSchedule }, 1000)}>
                  <ListItemIcon style={{ marginLeft: "-8px", marginRight: "8px" }}>
                    <Checkbox checked={inputCriteria.showSchedule || false} />
                  </ListItemIcon>
                  <ListItemText primary="Show Upcoming Events" />
                </MenuItem>

                <MenuItem button onClick={handleCriteriaChange({ showLogs: !inputCriteria.showLogs }, 1000)}>
                  <ListItemIcon style={{ marginLeft: "-8px", marginRight: "8px" }}>
                    <Checkbox checked={inputCriteria.showLogs || false} />
                  </ListItemIcon>
                  <ListItemText primary="Show Logs" />
                </MenuItem>

                <MenuItem button onClick={handleCriteriaChange({ showTestResults: !inputCriteria.showTestResults }, 1000)}>
                  <ListItemIcon style={{ marginLeft: "-8px", marginRight: "8px" }}>
                    <Checkbox checked={inputCriteria.showTestResults || false} />
                  </ListItemIcon>
                  <ListItemText primary="Show Unresolved Tests" />
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item>
              <MuiLink
                component={IconButton}
                color="textSecondary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://zapit-user-manual.s3.amazonaws.com/ZapITTracker.pdf"
                aria-label="tracker help"
              >
                <HelpOutline />
              </MuiLink>
            </Grid>
            {onRemoveWidget != null && (
              <Grid item ref={widgetMenuButtonRef}>
                <IconButton id="tracker-widget-menu-button" aria-label="tracker widget menu" aria-haspopup="true" onClick={handleOpenMenu}>
                  <MoreVert />
                </IconButton>
                <Menu
                  id="tracker-menu"
                  keepMounted
                  anchorEl={widgetMenuButtonRef.current}
                  open={Boolean(menuAnchorEl?.id === "tracker-widget-menu-button")}
                  onClose={handleCloseMenu}
                  disableScrollLock={true}
                  container={widgetMenuButtonRef.current}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem button onClick={onRemoveWidget}>
                    <ListItemIcon style={{ marginLeft: "-8px", marginRight: "8px" }}>
                      <RemoveCircleOutline />
                    </ListItemIcon>
                    <ListItemText primary="Remove widget" />
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </>
      }
    >
      {TrackerItemList}
    </WidgetCard>
  );
}

export default React.memo(TrackerWidget);

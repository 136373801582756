import { makeStyles, useTheme } from "@material-ui/core/styles";
import { props } from "bluebird";
import { CalendarLegendItem, CalendarLegendItemShape } from "./CalendarLegendItem";
import { CalendarLegendSection } from "./CalendarLegendSection";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        display: "inline-grid",
        gridTemplateColumns: "auto auto",
        columnGap: theme.spacing(3)
    },
    section: {
    },
    item: {
    }
}));

export interface CalendarLegendProps extends React.HTMLAttributes<HTMLDivElement> {

}

export function CalendarLegend(props: CalendarLegendProps) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={clsx(props.className, classes.root)}>
            <CalendarLegendSection title="Tests Legend" className={classes.section}>
                <CalendarLegendItem
                    className={classes.item}
                    label="Due"
                    color={theme.palette.warning.light}
                    shape="circle" />
                <CalendarLegendItem
                    className={classes.item}
                    label="Pass"
                    color={theme.palette.success.main}
                    shape="circle" />
                <CalendarLegendItem
                    className={classes.item}
                    label="Skipped"
                    color="gray"
                    shape="circle" />
                <CalendarLegendItem
                    className={classes.item}
                    label="Failed and Unresolved"
                    color={theme.palette.error.main}
                    shape="circle" />
                <CalendarLegendItem
                    className={classes.item}
                    label="Failed and Resolved"
                    color={theme.palette.info.main}
                    shape="circle" />
            </CalendarLegendSection>
            <CalendarLegendSection title="Scheduled Items Legend" className={classes.section}>
                <CalendarLegendItem
                    className={classes.item}
                    label="Due"
                    color={theme.palette.warning.light}
                    shape="square" />
                <CalendarLegendItem
                    className={classes.item}
                    label="Completed"
                    color={theme.palette.success.main}
                    shape="square" />
            </CalendarLegendSection>
        </div>
    )
}
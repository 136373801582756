import { AutocompleteInputChangeReason } from "@material-ui/lab";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { useStatusEffect } from "../../../../hooks/status-effect.hook";
import { QuerySearch } from "../../../../models/query-search.model";

import SelectInput, { SelectInputProps } from "../SelectInput/SelectInput";

export interface SearchInputProps<
  TValue,
  TOption extends TValue | { [key in keyof TOption]: any } | undefined = TValue,
  DisableClearable extends boolean | undefined = false
> extends SelectInputProps<TValue, TOption, DisableClearable> {
  onSearch(criteria: QuerySearch): void;
  debounce?: number;
  /**
   * Used to ensure the initial value exists in options.
   */
  defaultOption?: TOption;
}

/**
 * Search input.
 * There is currently a bug when disableClearable is true, you cannot clear the input to search after a selection.
 * @param props
 * @returns
 */
function SearchInput<
  TValue,
  TOption extends TValue | { [key in keyof TOption]: any } | undefined = TValue,
  DisableClearable extends boolean | undefined = false
>(props: SearchInputProps<TValue, TOption, DisableClearable>) {
  const { defaultOption, debounce, onSearch, ...selectInputProps } = props;
  const { labelProp, valueProp, options, value, onChange, onBlur, ...rest } = selectInputProps;

  const [criteria, setCriteria] = useState<QuerySearch>({ page: 1 });
  const [selectedText, setSelectedText] = useState<string | undefined>();

  const _inputValue = useMemo(() => (criteria?.query != null ? criteria.query : undefined) || selectedText || "", [criteria?.query, selectedText]);

  // Ensure the default option exists in options.
  const _options = useMemo(() => {
    if (!defaultOption || !options) {
      return options;
    }

    if (valueProp) {
      return options.findIndex((i) => i[valueProp] === defaultOption[valueProp]) >= 0 ? options : [...options, defaultOption];
    }

    return options.includes(defaultOption) ? options : [...options, defaultOption];
  }, [defaultOption, options, valueProp]);

  // Debounce search query and send after delay.
  useStatusEffect(
    (status) => {
      if (status.current !== "update") {
        return;
      }

      const handler = setTimeout(() => {
        onSearch(criteria);
      }, debounce || 1000);

      return () => {
        clearTimeout(handler);
      };
    },
    [criteria, debounce, onSearch]
  );

  const handleSearch = useCallback((event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    // This component is causing a lot of drama right now, spending a lot of time troubleshooting issues with it.
    // May want to rething how to build a search input from scratch - and how we can use the same menu component so
    // style matches perfectly with our other inputs with menus.
    if (reason === "input") {
      setCriteria((prev) => ({ ...prev, query: value, page: 1 }));
    } else if (reason === "reset" && value && value.length) {
      setSelectedText(value);
    } else {
      setSelectedText(undefined);
    }
  }, []);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>) => {
      // Reset criteria as focus was lost.
      setCriteria((prev) => ({ ...prev, query: undefined, page: 1 }));

      if (!value) {
        setSelectedText(undefined);
      }

      if (!onBlur) {
        return;
      }

      onBlur(event);
    },
    [onBlur, value]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<{}>, newValue: TValue | null, option: TOption | null) => {
      // Reset criteria as a selection was made.
      setCriteria((prev) => ({ ...prev, query: undefined, page: 1 }));

      if (!newValue) {
        setSelectedText(undefined);
      }

      if (!onChange) {
        return;
      }

      onChange(event, newValue, option);
    },
    [onChange]
  );

  const handleFilterOptions = useCallback((options) => options, []);

  const defaultProps: Partial<SelectInputProps<TValue, TOption, DisableClearable>> = {
    inputValue: _inputValue,
    options: _options,
    value,
    labelProp,
    valueProp,
    filterOptions: handleFilterOptions,
    onInputChange: handleSearch,
    onChange: handleChange,
    onBlur: handleBlur,
    ...rest,
  };

  return <SelectInput {...defaultProps} />;
}

export default SearchInput;

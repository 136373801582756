import React from "react";
import { makeStyles, darken, lighten } from "@material-ui/core/styles";
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, Zoom } from "@material-ui/core";

export interface TooltipProps extends MuiTooltipProps {
  placementTopOffset?: string;
  placementBottomOffset?: string;
}

const useStyles = makeStyles((theme) => {
  const background =
    theme.palette.type === "light"
      ? darken(theme.palette.background.default, 0.05)
      : lighten(theme.palette.background.default, 0.05);

  return {
    tooltip: (props: any) => ({
      backgroundColor: background,
      color: theme.palette.text.primary,
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: `${theme.elevation.main} ${theme.shadowColor}`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }),
    arrow: {
      "&:before": {
        backgroundColor: background,
        border: `1px solid ${theme.palette.divider}`,
      },
    },
    tooltipPlacementTop: (props: any) => ({
      top: props?.placementTopOffset ? props.placementTopOffset : undefined,
    }),

    tooltipPlacementBottom: (props: any) => ({
      top: props?.placementBottomOffset ? props.placementBottomOffset : undefined,
    }),
  };
});

function Tooltip({ placementTopOffset, placementBottomOffset, ...rest }: TooltipProps) {
  const defaultProps: MuiTooltipProps = {
    //variant: "outlined",
    arrow: true,
    ...rest,
  };

  const classes = useStyles({ placementTopOffset, placementBottomOffset });

  return <MuiTooltip TransitionComponent={Zoom} classes={classes} {...defaultProps} />;
}

export default Tooltip;

import { useState, useEffect } from "react";
import { PropTypes, useTheme, Theme, lighten, darken } from "@material-ui/core";
import { PaletteColor } from "@material-ui/core/styles/createPalette";

export interface ThemePaletteOptions {
  color: string | PropTypes.Color;
  lightenCoefficient?: number;
  darkenCoefficient?: number;
}

export const getThemePalette = (props: ThemePaletteOptions, theme: Theme): [string, string, string] => {
  const themeColor: PaletteColor = (theme.palette as any)[props.color];
  const lightenCoefficient = props.lightenCoefficient != null ? props.lightenCoefficient : 0.85;
  const darkenCoefficient = props.darkenCoefficient != null ? props.darkenCoefficient : 0.5;

  const mainColor = themeColor?.main || props.color;
  const lightColor = themeColor?.light || lighten(mainColor, lightenCoefficient);
  const darkColor = themeColor?.dark || darken(mainColor, darkenCoefficient);

  return [mainColor, lightColor, darkColor];
};

/**
 * Returns main, light, and dark theme palette colors or adjusted colors from html color
 * @param options
 */
function useThemePalette(options: ThemePaletteOptions): [string, string, string] {
  const theme = useTheme();

  const [m, l, d] = getThemePalette(options, theme);

  const [mainColor, setMainColor] = useState<string>(m);
  const [lightColor, setLightColor] = useState<string>(l);
  const [darkColor, setDarkColor] = useState<string>(d);

  useEffect(() => {
    const [m, l, d] = getThemePalette(options, theme);

    setMainColor(m);
    setLightColor(l);
    setDarkColor(d);
  }, [options, theme]);

  return [mainColor, lightColor, darkColor];
}

export default useThemePalette;

import { useCallback } from "react";
import { HttpClientRequestConfig, useHttpClientContext } from "../context/http-client.context";
import environment from "../env";
import { DeviceSearch } from "../models/device-search.model";
import { FacilitySearch } from "../models/facility-search.model";
import { Facility } from "../models/facility.model";
import { PagedList } from "../models/paged-list.model";

export interface FacilityService {
  getFacilities(orgId: number, criteria?: FacilitySearch): Promise<PagedList<Facility>>;
  getFacilityById(orgId: number, id: number): Promise<Facility>;
  getFacilityComplianceScore(orgId: number, facilityId?: number, machineId?: number): Promise<number>;
  saveFacilityNames(orgId: number, facilities: Facility[]): Promise<void>;
}

function useFacilityService(): FacilityService {
  const { httpClient } = useHttpClientContext();

  /**
   * Gets machines by machine type, facility or organization
   */
  const getFacilities = useCallback(
    async (orgId: number, criteria?: FacilitySearch) => {
      const { query, ...reportParams } = criteria || {};

      let params: DeviceSearch = { ...reportParams };

      if (query && query.length) {
        params = { ...params, query };
      }

      const response = await httpClient.get<PagedList<Facility>>(`orgs/${orgId}/facilities`, { params, baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const getFacilityById = useCallback(
    async (orgId: number, id: number): Promise<Facility> => {
      const response = await httpClient.get<Facility>(`orgs/${orgId}/facilities/${id}`, { baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const getFacilityComplianceScore = useCallback(
    async (orgId: number, facilityId?: number, deviceId?: number): Promise<number> => {
      const config: HttpClientRequestConfig = {
        params: {
          facilityId: facilityId,
          deviceId: deviceId,
        },
        baseURL: environment.newApiUrl,
      };
      const response = await httpClient.get<{ score: number }>(`orgs/${orgId}/reports/compliance-score`, config);
      return response.data.score;
    },
    [httpClient]
  );

  const saveFacilityNames = useCallback(
    async (orgId: number, facilities: Facility[]): Promise<void> => {
      const response = await httpClient.post(`orgs/${orgId}/facilities/savefacilitynames`, { facilities: facilities }, { baseURL: environment.newApiUrl });

      if (response.data.error) {
        return Promise.reject(response.data.error);
      }
      return;
    },
    [httpClient]
  );

  return {
    getFacilities,
    getFacilityById,
    getFacilityComplianceScore,
    saveFacilityNames,
  };
}

export default useFacilityService;

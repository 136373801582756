import React, { useState, useCallback, useMemo, useRef } from "react";
import Calendar from "../../../components/Calendar/Calendar";
import WidgetCard, { WidgetCardProps } from "../../../components/WidgetCard/WidgetCard";
import { MachineTest } from "../../../../models/machine-test.model";
import { MachineTestResult } from "../../../../models/machine-test-result.model";
import moment from "moment";
import { useAppContext } from "../../../../context/app.context";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import environment from "../../../../env";
import { useHistory } from "react-router-dom";
import { Drawer, Menu, MenuItem, ListItemIcon, ListItemText, Divider, Grid, Link, Box } from "@material-ui/core";
import PageContainer from "../../../components/Page/PageContainer";
import TestList from "../../../components/TestList/TestList";
import { Close, HelpOutline, FilterList, MoreVert, RemoveCircleOutline } from "@material-ui/icons";
import { MachineEvent } from "../../../../models/machine-event.model";
import { MachineLog } from "../../../../models/machine-log.model";
import useMachineService from "../../../../hooks/machine-service.hook";
import { EventSearchParams } from "../../../../models/event-search-params.model";
import TestResultList from "../../../components/TestResultList/TestResultList";
import PageLoader from "../../../components/PageLoader/PageLoader";
import IconButton from "../../../components/IconButton/IconButton";
import { useUserContext } from "../../../../context/user.context";
import useMachineLogService from "../../../../hooks/machine-log-service.hook";
import useMachineTestResultService from "../../../../hooks/machine-test-result-service.hook";
import notifications from "../../../../translations/en/notifications.json";
import { Permission } from "../../../../models/group.model";
import RetryWidget from "../../../components/ImageCaptions/RetryWidget";
import { useQuery } from "react-query";
import usePersistedState from "../../../../hooks/persisted-state.hook";
import Button from "../../../components/Button/Button";
import { useStatusEffect } from "../../../../hooks/status-effect.hook";
import { CalendarLegendPopup } from "../../../components/Calendar/CalendarLegendPopup";
import useDeviceTestService from "../../../../hooks/device-test-service.hook";
import useSearchbarService from "../../../../hooks/searchbar-service.hook";

const CALENDAR_TEST_LIST_HASH = "#calendar-test-list";
const CALENDAR_TEST_RESULT_LIST_HASH = "#calendar-test-result-list";
const DATE_FORMAT = "YYYY-MM-DD";

interface CalendarOptions {
  criteria: EventSearchParams;
  showTests: boolean;
  showSchedule: boolean;
  showLogs: boolean;
  showTestResults: boolean;
}

const defaultCalendarOptions: CalendarOptions = {
  showTests: true,
  showSchedule: true,
  showLogs: true,
  showTestResults: true,
  criteria: {
    start: moment().startOf("month").unix(),
    end: moment().endOf("month").unix(),
    daily: true,
    weekly: true,
    biweekly: true,
    biannual: true,
    monthly: true,
    quarterly: true,
    semiannual: true,
    yearly: true,
    ready: 1,
    itemsPerPage: 1000,
  },
};

export interface CalendarWidgetProps extends WidgetCardProps {
  onRemoveWidget?: () => void;
}

function CalendarWidget(props: CalendarWidgetProps) {
  const { setAlert } = useAppContext();
  const { org, user, hasPermission, facility, machine } = useUserContext();
  const { getDeviceTests, hasTestPermission, getWidgetTestResults, getDeviceTestResults } = useDeviceTestService();
  const { approveTestResultsV2, signTestResultsV2 } = useMachineTestResultService();
  const { getMachineSchedule } = useMachineService();
  const { getMachineLogsV2 } = useMachineLogService();


  const history = useHistory();

  const [retry, setRetry] = useState(0);
  const [loading, setLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedDate, setSelectedDate] = usePersistedState<string>("home/widget/calendar/selected-date", {
    defaultValue: moment().format(DATE_FORMAT),
  });

  const [selectedTests, setSelectedTests] = usePersistedState<{ name: string; tests: MachineTest[] } | undefined>("home/widget/calendar/selected-tests");

  const [selectedTestResults, setSelectedTestResults] = usePersistedState<{ name: string; testResults: MachineTestResult[] } | undefined>(
    "home/widget/calendar/selected-test-results"
  );

  const [calendarOptions, setCalendarOptions] = usePersistedState<CalendarOptions>("home/widget/calendar/options", {
    defaultValue: defaultCalendarOptions,
  });

  const [showCalendarTestList, setShowCalendarTestList] = useState(() => {
    // Need to grab the initial state outside of history listen bc that is only
    // fired on a change and does not include initial route.
    return history.location.hash === CALENDAR_TEST_LIST_HASH;
  });

  const [showCalendarTestResultList, setShowCalendarTestResultList] = useState(() => {
    // returning false on initial load so the test reuslt list goes away when you change orgs.
    // it gets set below when you click results
    return false;
  });

  const options = { ...defaultCalendarOptions, ...calendarOptions };
  const [inputCriteria, setInputCriteria] = useState<CalendarOptions & { delay: number }>({ ...options, delay: 0 });
  const { searchbarFilter } = useSearchbarService();

  // Toggle the test or test result list.
  // Need access to status outside of effect.
  const { status } = useStatusEffect(
    (status) => {
      // By returning a function the function will be called on unmount
      const listener = history.listen((route) => {
        if (status.current !== "unmount") {
          // We need to listen for back button press to close calendar test list
          if (route.hash !== CALENDAR_TEST_LIST_HASH && showCalendarTestList) {
            setShowCalendarTestList(false);
          } else if (route.hash === CALENDAR_TEST_LIST_HASH && !showCalendarTestList) {
            setShowCalendarTestList(true);
          }

          if (route.hash !== CALENDAR_TEST_RESULT_LIST_HASH && showCalendarTestResultList) {
            setShowCalendarTestResultList(false);
          } else if (route.hash === CALENDAR_TEST_RESULT_LIST_HASH && !showCalendarTestResultList) {
            setShowCalendarTestResultList(true);
          }
        }
      });

      return listener;
    },
    [history, showCalendarTestList, showCalendarTestResultList, setShowCalendarTestList, setShowCalendarTestResultList]
  );

  // Get the data for this widget

  const {
    isLoading: testsLoading,
    isError: testsError,
    data: tests,
  } = useQuery(["home/widget/calendar/tests", searchbarFilter, org, retry], {
    queryFn: async () => {
      if (!options.showTests || !searchbarFilter || !org) {
        return Promise.resolve({ data: [], total: 0 });
      }

      options.criteria.facilityId = searchbarFilter.facilityId;
      options.criteria.machineId = searchbarFilter.machineId;
      const orgId = org ? org.id : searchbarFilter.orgId!;
      const result = await getDeviceTests(orgId, options.criteria);

      return {
        data: result.items.filter(test => hasTestPermission(test.facilityId, test.machineTypeId, test.frequency)),
        total: result.total
      };
    },
    refetchOnMount: 'always',
  });

  const {
    isLoading: logsLoading,
    isError: logsError,
    data: logs,
  } = useQuery(["home/widget/calendar/logs", options, searchbarFilter, retry], {
    queryFn: async () => {
      if (!options.showLogs || !searchbarFilter || !searchbarFilter.orgId || !user) {
        return Promise.resolve([]);
      }

      const { items } = await getMachineLogsV2(org!.id, user!.id, options.criteria, searchbarFilter.facilityId, searchbarFilter.machineId);
      return items;
    },
    refetchOnMount: 'always',
  });

  const {
    isLoading: testResultsLoading,
    isError: testResultsError,
    data: testResults,
  } = useQuery(["home/widget/tracker/test-results", options, org, searchbarFilter, retry], {
    queryFn: async () => {
      if (!options.showTestResults || !org) {
        return Promise.resolve([]);
      }

      const r = await getWidgetTestResults(org.id, searchbarFilter.machineId ? { machineId: searchbarFilter.machineId } : searchbarFilter.facilityId ? { facilityId: searchbarFilter.facilityId } : { orgId: org.id }, {
        limit: 10000,
        ...options.criteria,
      });
      return r.items.filter(test => hasTestPermission(test.facilityId, test.machineTypeId, test.frequency))
    },
    refetchOnMount: 'always',
  });

  const {
    isLoading: eventsLoading,
    isError: eventsError,
    data: events,
  } = useQuery(["home/widget/calendar/events", options, searchbarFilter, retry], {
    queryFn: async () => {
      if (!options.showSchedule || !searchbarFilter || !searchbarFilter.orgId) {
        return Promise.resolve([]);
      }

      const start = options.criteria.start;
      const machineId = machine ? machine.id : undefined;
      const facilityId = facility ? facility.id : undefined;

      const { items } = await getMachineSchedule(org!.id, { start: start, machineId: machineId, facilityId: facilityId });
      return items.filter((b) => b.active);
    },
    refetchOnMount: 'always',
  });

  // Handles async input changes with delay
  useStatusEffect(
    (status) => {
      // Skip first render as we only want to eval this effect when input changes actually happen
      if (status.current === "mount") {
        return;
      }

      const { delay, ...rest } = inputCriteria;

      // Delay setting value to limit api calls for every change
      const delayTimer = setTimeout(() => {
        if (status.current !== "unmount") {
          // close filter menu
          setMenuAnchorEl(null);
          // Set criteria for API
          setCalendarOptions((prev) => ({ ...prev!, ...rest }));
        }
      }, delay);

      // this will clear Timeout when inputCriteria changes
      return () => {
        clearTimeout(delayTimer);
      };
    },
    [inputCriteria, setCalendarOptions]
  );

  const handleCriteriaChange = useCallback(
    (change: Partial<typeof inputCriteria>, delay = 0) =>
      () => {
        setInputCriteria((prev) => {
          return { ...prev, ...change, criteria: { ...prev.criteria, ...change.criteria }, delay };
        });
      },
    []
  );

  const handleCalendarDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setSelectedDate(moment(date).format(DATE_FORMAT));
    },
    [setSelectedDate]
  );

  const handleCalendarMonthChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setSelectedDate(moment(date).format(DATE_FORMAT));

      const start = moment(date).startOf("month").unix();
      const end = moment(date).endOf("month").unix();

      handleCriteriaChange(
        {
          criteria: {
            start,
            end,
          },
        },
        500
      )();
    },
    [handleCriteriaChange, setSelectedDate]
  );

  const handleRunTests = useCallback(
    (tests: MachineTest[]) => {
      history.push(`/tests/runs/${tests[0].machineId}`, {
        previousLocation: { pathname: `/` },
        tests: tests,
      });
    },
    [history]
  );

  const handleViewLog = useCallback(
    (log: MachineLog) => {
      history.push(`/logs/${log.id}`, {
        previousLocation: { pathname: `/` },
        log: log,
      });
    },
    [history]
  );

  const handleViewEvent = useCallback(
    (event: MachineEvent) => {
      history.push(`/events/${event.id}`, {
        previousLocation: { pathname: `/` },
        event,
      });
    },
    [history]
  );

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const openCalendarTestList = useCallback(
    (newSelectedTests: { name: string; tests: MachineTest[] }) => {
      setSelectedTests(newSelectedTests);
      setShowCalendarTestList(true);

      if (history.location.hash !== CALENDAR_TEST_LIST_HASH) {
        history.push(`/${CALENDAR_TEST_LIST_HASH}`);
      }
    },
    [history, setSelectedTests]
  );

  const handleOpenTestResultList = useCallback(
    async (selectedTestResults: { name: string; id: number; frequency?: number }) => {
      const flattenedOptions = {
        ...options.criteria,
        limit: 10000,
        start: moment(selectedDate).startOf("day").unix(),
        end: moment(selectedDate).endOf("day").unix(),
      };

      const r = await getDeviceTestResults(org!.id, { machineId: selectedTestResults.id }, flattenedOptions);

      const newSelectedTestResults =
      {
        name: selectedTestResults.name,
        testResults: r.items.filter(test => (selectedTestResults.frequency ? selectedTestResults.frequency == test.setup.frequency : true) && hasTestPermission(test.machine.facilityId, test.machine.machineType.id, test.setup.frequency))
      };

      setSelectedTestResults(newSelectedTestResults);
      setShowCalendarTestResultList(true);

      if (history.location.hash !== CALENDAR_TEST_RESULT_LIST_HASH) {
        history.push(`/${CALENDAR_TEST_RESULT_LIST_HASH}`);
      }
    },
    [options.criteria, selectedDate, getDeviceTestResults, org, setSelectedTestResults, history, hasTestPermission]
  );

  const handleViewTestResult = useCallback(
    (testResult: MachineTestResult) => {
      history.push(`/tests/${testResult.testVersion.testId}/results/${testResult.id}`, {
        previousLocation: `/${CALENDAR_TEST_RESULT_LIST_HASH}`,
        testResult: testResult,
        machine: {
          machineId: testResult.machine.id,
          facilityId: testResult.machine.facility!.id,
          name: testResult.machine.name,
        },
      });
    },
    [history]
  );

  const handleViewTestResults = useCallback(
    (test: MachineTest) => {
      history.push(`/tests/${test.testId}/results`, test);
    },
    [history]
  );

  const handleViewTestGraph = useCallback(
    (test: MachineTest) => {
      history.push(`/tests/${test.testId}/graph`, test);
    },
    [history]
  );

  const handleViewTestSetup = useCallback(
    (test: MachineTest) => {
      history.push(`/tests/${test.testId}`, test);
    },
    [history]
  );

  const handleSignoff = useCallback(
    (selectedTestResults: MachineTestResult[]) => {
      const ids = selectedTestResults.map((t) => t.id);

      setLoading(true);
      signTestResultsV2(org!.id, ids)
        .then(() => {
          setAlert({ severity: "success", messages: [`Signed off on test result${ids.length ? "s" : ""}`] });
          handleCriteriaChange({}, 0)();
          setRetry((prev) => prev + 1);
        })
        .catch((error) => {
          console.error(error);
          setAlert({ severity: "error", messages: [`Could not sign off on test result${ids.length ? "s" : ""}`] });
        })
        .finally(() => {
          setLoading(false);
          setShowCalendarTestResultList(false);
        });
    },
    [handleCriteriaChange, org, setAlert, signTestResultsV2]
  );

  const handleApprove = useCallback(
    (selectedTestResults: MachineTestResult[]) => {
      const ids = selectedTestResults.map((t) => t.id);

      setLoading(true);
      approveTestResultsV2(org!.id, ids)
        .then(() => {
          setAlert({ severity: "success", messages: [`Approved test result${ids.length ? "s" : ""}`] });
          handleCriteriaChange({}, 0)();
          setRetry((prev) => prev + 1);
        })
        .catch((error) => {
          console.error(error);
          setAlert({ severity: "error", messages: [`Could not approve test result${ids.length ? "s" : ""}`] });
        })
        .finally(() => {
          setLoading(false);
          setShowCalendarTestResultList(false);
        });
    },
    [approveTestResultsV2, handleCriteriaChange, org, setAlert]
  );

  const handleCloseDrawer = useCallback(
    (hash: string) => (event?: React.KeyboardEvent | React.MouseEvent | {}) => {
      if (
        event != null &&
        (event as React.KeyboardEvent | React.MouseEvent).type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      if (hash === CALENDAR_TEST_LIST_HASH) {
        setShowCalendarTestList(false);
      } else if (hash === CALENDAR_TEST_RESULT_LIST_HASH) {
        setShowCalendarTestResultList(false);
      }

      if (history.location.hash === hash) {
        // This will trigger our history listener to close the dialog
        history.push("/");
      }
    },
    [history]
  );

  const handleToday = useCallback(() => {
    handleCalendarMonthChange(moment().startOf("day"));
  }, [handleCalendarMonthChange]);

  const handleRetry = useCallback(() => {
    setRetry((prev) => prev + 1);
  }, []);

  const MemoCalendar = useMemo(
    () => (
      <Calendar
        value={moment(selectedDate || moment())}
        onValueChange={handleCalendarDateChange}
        onMonthChange={handleCalendarMonthChange}
        onSelectMachineTests={openCalendarTestList}
        onSelectMachineTestResults={handleOpenTestResultList}
        onSelectMachineLog={handleViewLog}
        onSelectMachineEvent={handleViewEvent}
        tests={tests?.data || []}
        testsTotal={tests?.total || 0}
        events={events || []}
        logs={logs || []}
        testResults={testResults || []}
        dateFormat={`${DATE_FORMAT}`}
      />
    ),
    [selectedDate, handleCalendarDateChange, handleCalendarMonthChange, openCalendarTestList, handleOpenTestResultList, handleViewLog, handleViewEvent, tests?.data, tests?.total, events, logs, testResults]
  );

  const disableEditTestIds = useMemo(
    () => tests?.data?.filter((i) => !hasPermission(Permission.adminEditTests, { facilityId: i.facilityId })).map((i) => i.id) || [],
    [hasPermission, tests]
  );

  const disableRunTestIds = useMemo(() => {
    const items = tests?.data?.filter((i) => {
      const perm =
        i.frequency === 1
          ? Permission.dailyPerform
          : i.frequency === 7 || i.frequency === 14
            ? Permission.weeklyPerform
            : i.frequency === 30
              ? Permission.monthlyPerform
              : i.frequency === 91
                ? Permission.quarterlyPerform
                : i.frequency === 183 || i.frequency === 365 || i.frequency === 730
                  ? Permission.yearlyPerform
                  : undefined;

      const hasPerm = perm && hasPermission(perm, { facilityId: i.facilityId, deviceTypeId: i.machineTypeId });
      return !hasPerm;
    });

    return items?.map((i) => i.id) || [];
  }, [hasPermission, tests]);

  const disableSignTestIds = useMemo(() => {
    const items = testResults?.filter((i) => {
      const perm =
        i.frequency === 1
          ? Permission.dailySignoff
          : i.frequency === 7 || i.frequency === 14
            ? Permission.weeklySignoff
            : i.frequency === 30
              ? Permission.monthlySignoff
              : i.frequency === 91
                ? Permission.quarterlySignoff
                : i.frequency === 183 || i.frequency === 365 || i.frequency === 730
                  ? Permission.yearlySignoff
                  : undefined;

      const hasPerm =
        perm &&
        hasPermission(perm, {
          facilityId: i.facilityId,
          deviceTypeId: i.machineTypeId,
        });
      return !hasPerm;
    });

    return items?.map((i) => i.id) || [];
  }, [hasPermission, testResults]);

  const disableApproveTestIds = useMemo(() => {
    const items = testResults?.filter((i) => {
      const perm =
        i.frequency === 1
          ? Permission.dailyApprove
          : i.frequency === 7 || i.frequency === 14
            ? Permission.weeklyApprove
            : i.frequency === 30
              ? Permission.monthlyApprove
              : i.frequency === 91
                ? Permission.quarterlyApprove
                : i.frequency === 183 || i.frequency === 365 || i.frequency === 730
                  ? Permission.yearlyApprove
                  : undefined;

      const hasPerm =
        perm &&
        hasPermission(perm, {
          facilityId: i.facilityId,
          deviceTypeId: i.machineTypeId,
        });
      return !hasPerm;
    });

    return items?.map((i) => i.id) || [];
  }, [hasPermission, testResults]);

  const MemoTestListDrawer = useMemo(() => {
    const viewItemAction = {
      onClick: handleViewTestSetup,
      disabled: (item: MachineTest) => disableEditTestIds.includes(item.id),
      disabledText: () => notifications.action.noPermission,
    };

    const disableRunTests = (selectedItems: MachineTest[]) => {
      const selectedIds = selectedItems.map((i) => i.id);
      return selectedIds.findIndex((i) => disableRunTestIds.includes(i)) >= 0;
    };

    const disableRunTestsText = (selectedItems: MachineTest[]) => {
      const macId = selectedItems[0]?.machineId;
      const selectedIds = selectedItems.map((i) => i.id);
      const noPermission = selectedIds.findIndex((i) => disableRunTestIds.includes(i)) >= 0;

      if (noPermission) {
        return notifications.action.noPermission;
      }

      if (selectedItems.findIndex((i) => i.status !== "ready") >= 0) {
        return notifications.test.notReady;
      }

      if (selectedItems.findIndex((i) => i.machineId !== macId) >= 0) {
        return notifications.test.noMultiMachineRuns;
      }

      return "";
    };

    const runTestsAction = {
      onClick: handleRunTests,
      disabled: disableRunTests,
      disabledText: disableRunTestsText,
    };

    return (
      <Drawer
        anchor="bottom"
        open={showCalendarTestList}
        onClose={handleCloseDrawer(CALENDAR_TEST_LIST_HASH)}
        PaperProps={{
          style: { top: "10%" },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <PageContainer>
          <TestList
            id="test-list"
            header={`${selectedTests?.name} - Tests`}
            items={selectedTests?.tests || []}
            total={selectedTests?.tests?.length || 0}
            pageLimits={environment.pageLimits}
            onViewTestResults={handleViewTestResults}
            viewItemAction={viewItemAction}
            onViewTestGraph={handleViewTestGraph}
            runTestsAction={runTestsAction}
            actions={
              <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                  <Link
                    component={IconButton}
                    color="textSecondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://zapit-user-manual.s3.amazonaws.com/TestList.pdf"
                    aria-label="test list help"
                  >
                    <HelpOutline />
                  </Link>

                  <IconButton edge="right" aria-label="close list" onClick={handleCloseDrawer(CALENDAR_TEST_LIST_HASH)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            }
          ></TestList>
        </PageContainer>
        {showCalendarTestList && loading && <PageLoader opacity="0.5" position="fixed" />}
      </Drawer>
    );
  }, [
    handleViewTestSetup,
    handleRunTests,
    showCalendarTestList,
    handleCloseDrawer,
    selectedTests?.name,
    selectedTests?.tests,
    handleViewTestResults,
    handleViewTestGraph,
    loading,
    disableEditTestIds,
    disableRunTestIds,
  ]);

  const MemoTestResultListDrawer = useMemo(() => {
    const disableSignTests = (selectedItems: MachineTestResult[]) => {
      const selectedIds = selectedItems.map((i) => i.id);
      return selectedIds.findIndex((i) => disableSignTestIds.includes(i)) >= 0;
    };

    const disableSignTestsText = (selectedItems: MachineTestResult[]) => {
      const selectedIds = selectedItems.map((i) => i.id);
      const noPermission = selectedIds.findIndex((i) => disableSignTestIds.includes(i)) >= 0;

      if (noPermission) {
        return notifications.action.noPermission;
      }

      return "";
    };

    const signTestsAction = {
      onClick: handleSignoff,
      disabled: disableSignTests,
      disabledText: disableSignTestsText,
    };

    const disableApproveTests = (selectedItems: MachineTestResult[]) => {
      const selectedIds = selectedItems.map((i) => i.id);
      return selectedIds.findIndex((i) => disableApproveTestIds.includes(i)) >= 0;
    };

    const disableApproveTestsText = (selectedItems: MachineTestResult[]) => {
      const selectedIds = selectedItems.map((i) => i.id);
      const noPermission = selectedIds.findIndex((i) => disableApproveTestIds.includes(i)) >= 0;

      if (noPermission) {
        return notifications.action.noPermission;
      }

      return "";
    };

    const approveTestsAction = {
      onClick: handleApprove,
      disabled: disableApproveTests,
      disabledText: disableApproveTestsText,
    };

    return (
      <Drawer
        anchor="bottom"
        open={showCalendarTestResultList}
        onClose={handleCloseDrawer(CALENDAR_TEST_RESULT_LIST_HASH)}
        PaperProps={{
          style: { top: "10%" },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <PageContainer>
          <TestResultList
            id="test-result-list"
            header={`${selectedTestResults?.name} - Test Results`}
            items={selectedTestResults?.testResults || []}
            total={selectedTestResults?.testResults?.length || 0}
            onViewItem={handleViewTestResult}
            pageLimits={environment.pageLimits}
            signTestsAction={signTestsAction}
            approveTestsAction={approveTestsAction}
            hideLocation={!selectedTestResults?.testResults[0].machine.mobile}
            hideFacility={selectedTestResults?.testResults[0].machine.mobile}
            actions={
              <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                  <Link
                    component={IconButton}
                    color="textSecondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://zapit-user-manual.s3.amazonaws.com/ResultsList.pdf"
                    aria-label="result list help"
                  >
                    <HelpOutline />
                  </Link>
                  <IconButton edge="right" aria-label="close list" onClick={handleCloseDrawer(CALENDAR_TEST_RESULT_LIST_HASH)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            }
          ></TestResultList>
        </PageContainer>
        {showCalendarTestResultList && loading && <PageLoader opacity="0.5" position="fixed" />}
      </Drawer>
    );
  }, [handleSignoff, handleApprove, showCalendarTestResultList, handleCloseDrawer, selectedTestResults?.name, selectedTestResults?.testResults, handleViewTestResult, loading, disableSignTestIds, disableApproveTestIds]);

  const { onRemoveWidget, ...rest } = props;
  const defaultWidgetCardProps = {
    ...rest,
  };

  const widgetMenuButtonRef = useRef(null);

  return (
    <WidgetCard
      id="calendar-widget"
      header="Calendar"
      loading={loading || testsLoading || testResultsLoading || eventsLoading}
      {...defaultWidgetCardProps}
      actions={
        <>
          <Grid container>
            <Grid item>
              <Box paddingRight={1}>
                <Button variant="outlined" onClick={handleToday}>
                  Today
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <CalendarLegendPopup
                trigger={<Button variant="outlined">Legend</Button>}
              />
            </Grid>
            <Grid item>
              <Link
                component={IconButton}
                color="textSecondary"
                target="_blank"
                rel="noopener noreferrer"
                href="https://zapit-user-manual.s3.amazonaws.com/Calendar.pdf"
                aria-label="calendar help"
              >
                <HelpOutline />
              </Link>
            </Grid>

            {onRemoveWidget != null && (
              <Grid item ref={widgetMenuButtonRef}>
                <IconButton id="calendar-widget-menu-button" aria-label="calendar widget menu" aria-haspopup="true" onClick={handleOpenMenu}>
                  <MoreVert />
                </IconButton>
                <Menu
                  id="calendar-menu"
                  keepMounted
                  anchorEl={widgetMenuButtonRef.current}
                  open={Boolean(menuAnchorEl?.id === "calendar-widget-menu-button")}
                  onClose={handleCloseMenu}
                  disableScrollLock={true}
                  container={widgetMenuButtonRef.current}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem button onClick={onRemoveWidget}>
                    <ListItemIcon style={{ marginLeft: "-8px", marginRight: "8px" }}>
                      <RemoveCircleOutline />
                    </ListItemIcon>
                    <ListItemText primary="Remove widget" />
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </>
      }
    >
      {testsError || testResultsError || eventsError ? (
        <RetryWidget onRetry={handleRetry} />
      ) : (
        <>
          {MemoCalendar}
          {MemoTestListDrawer}
          {MemoTestResultListDrawer}
        </>
      )}
    </WidgetCard>
  );
}

export default React.memo(CalendarWidget);

import { Card, makeStyles } from "@material-ui/core";
import React from "react";
import { Popper } from "../Popper/Popper";
import { CalendarLegend } from "./CalendarLegend";

const useStyles = makeStyles(theme => ({
    popper: {
        // Hack to prevent calendar squares/circles from showing on top of popup
        zIndex: 1000
    },
    content: {
        padding: theme.spacing(2)
    }
}));

export interface CalendarLegendPopupProps extends React.HTMLAttributes<HTMLDivElement> {
    trigger: React.ReactElement<any, any>;
}

export function CalendarLegendPopup(props: CalendarLegendPopupProps) {
    const classes = useStyles();

    return (
        <Popper
            trigger={props.trigger}
            placement="bottom-end"
            modifiers={{
                offset: {
                    offset: "0, 10"
                }
            }}>
            <Card variant="outlined" className={classes.content}>
                <CalendarLegend />
            </Card>
        </Popper >
    );
}
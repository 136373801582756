import React, { useState, useCallback, useMemo, useEffect } from "react";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../../../context/auth.context";
import Button from "../../../../components/Button/Button";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import { VerifyResetRequest } from "../../../../../models/login-request.model";
import IconButton from "../../../../components/IconButton/IconButton";
import { LockOpenOutlined, LockOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import queryString from "query-string";
import { Controller, useForm } from "react-hook-form";
import InputWrapper from "../../../../components/Inputs/InputWrapper/InputWrapper";
import VerificationCodeForm from "./VerificationCodeForm";
import { passwordRules, emailRules } from "./form-validation-rules";

function ResetForm() {
  const history = useHistory();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { username } = queryString.parse(location.search);

  const verifyCode = useMemo(() => location.pathname.endsWith("/verify"), [location.pathname]);

  const { verifyResetPassword } = useAuthContext();

  const {
    clearErrors,
    control,
    handleSubmit,
    //formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    return () => {
      setError("");
      clearErrors();
    };
  }, [location.pathname, clearErrors]);

  const toggleShowPassword = useCallback(
    (show?: boolean) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setShowPassword((prev) => (show ? show : !prev));
    },
    []
  );

  const handleSubmitVerifyReset = useCallback(
    (formValue: VerifyResetRequest) => {
      const { username, password, verificationCode } = formValue;

      setError("");
      setLoading(true);
      verifyResetPassword({ username, password, verificationCode })
        .then(() => {
          // Nav to home
          history.replace("/");
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    },
    [history, verifyResetPassword]
  );

  if (!verifyCode) {
    return <VerificationCodeForm codeType="reset" />;
  }

  return (
    <>
      <form action={"reset/verify"} onSubmit={handleSubmit(handleSubmitVerifyReset)} noValidate>
        <Grid container spacing={2}>
          {error && (
            <Grid container item xs={12} justify="center">
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid container item xs={12} justify="center">
            <Typography align="center" variant="body2" color="textSecondary">
              {"We sent you an email with a verification code."}
            </Typography>
            <Link component={RouterLink} to={`/reset${username ? `?username=${username}` : ""}`} align="center" variant="body2">
              {"Don't have a code? Get one"}
            </Link>
          </Grid>
          <InputWrapper hidden={Boolean(username)}>
            <Grid item xs={12}>
              <Controller
                name="username"
                control={control}
                defaultValue={username || ""}
                rules={emailRules()}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextInput
                    inputRef={ref}
                    {...rest}
                    margin="none"
                    fullWidth
                    id="username"
                    name="username"
                    type={username ? "hidden" : "text"}
                    autoComplete="username"
                    autoFocus
                    label={fieldState.error?.message || "Email"}
                    error={Boolean(fieldState.error)}
                  />
                )}
              />
            </Grid>
          </InputWrapper>
          {username && (
            <Grid container item xs={12} justify="center">
              <Typography align="center" variant="body1" color="textPrimary">
                {username}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={passwordRules("New password")}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <TextInput
                  inputRef={ref}
                  {...rest}
                  margin="none"
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  label={fieldState.error?.message || "New password"}
                  error={Boolean(fieldState.error)}
                  InputProps={{
                    endAdornment: (
                      <IconButton type="button" edge="right" onClick={toggleShowPassword()}>
                        {showPassword ? <LockOpenOutlined /> : <LockOutlined />}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="verificationCode"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: "Verification code is required",
                },
              }}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <TextInput
                  inputRef={ref}
                  {...rest}
                  margin="none"
                  fullWidth
                  id="verificationCode"
                  name="verificationCode"
                  type="text"
                  autoComplete="one-time-code"
                  inputMode="numeric"
                  label={fieldState.error?.message || "Verification code"}
                  error={Boolean(fieldState.error)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
              {!loading ? "Verify and sign in" : "Verifying..."}
            </Button>
          </Grid>
        </Grid>
      </form>
      {loading && <PageLoader opacity="0.5" position="fixed" />}
    </>
  );
}

export default ResetForm;

import { useCallback } from "react";
import { HttpClientRequestConfig, useHttpClientContext } from "../context/http-client.context";
import mime from "mime";
import { File as OrgFile } from "../models/file.model";
import axios from "axios";
import { MimeUtil } from "../shared/mime.util";
import environment from "../env";

export interface FileUploadService {
  uploadFile: (file: File, organizationId?: number) => Promise<OrgFile>;
}

export interface FileUploadServiceOptions {
  maxFileSizeBytes?: number;
}

function useFileUploadService(options?: FileUploadServiceOptions): FileUploadService {
  const { maxFileSizeBytes } = options || {};

  const { httpClient } = useHttpClientContext();

  const uploadFile = useCallback(
    (file: File, organizationId?: number) => {
      if (maxFileSizeBytes && file.size > maxFileSizeBytes) {
        return Promise.reject({ message: `File exceeds ${maxFileSizeBytes / 1024 / 1024} MB limit.` });
      }

      const fileType = MimeUtil.getType(file);

      const mimeExtension = fileType ? mime.getExtension(fileType) : undefined;
      const extension = mimeExtension ? `.${mimeExtension}` : undefined;

      const newFile = {
        filename: file.name,
        size: file.size,
        type: fileType,
        extension,
        orgId: organizationId,
      };

      const config: HttpClientRequestConfig = {
        params: {
          type: fileType,
          extension,
        },
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      const urlRequest = httpClient.get("v2/file-upload/getUploadUrl", config).then((response) => response.data[0] as { key: string; url: string });

      // Making a request to a url not within the api, so just use a raw axios instance.
      const uploadRequest = urlRequest.then((urlResponse) =>
        axios.put(urlResponse.url, file, { headers: { "Content-Type": fileType } }).then((uploadResponse) => {
          return {
            ...newFile,
            key: urlResponse.key,
          };
        })
      );

      const result = uploadRequest.then((uploadResponse) =>
        httpClient.post("v2/file-upload/uploadFile", uploadResponse, { baseURL: environment.newApiUrl }).then((fileResponse) => fileResponse.data[0] as OrgFile)
      );

      return result;
    },
    [httpClient, maxFileSizeBytes]
  );

  return { uploadFile };
}

export default useFileUploadService;

import * as React from 'react';
import { default as MuiPopper } from '@mui/material/Popper';
import { ClickAwayListener, Fade, PopperPlacementType } from '@material-ui/core';
import { truncateSync } from 'fs';
import { ClassNames } from '@emotion/react';

export interface PopperProps extends React.HTMLAttributes<HTMLDivElement> {
    trigger: React.ReactElement<any, any>;
    placement?: PopperPlacementType;
    modifiers?: any;
}

export function Popper(props: React.PropsWithChildren<PopperProps>) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);

    const handleAnchorClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    const handleClickAway = (event: React.MouseEvent<Document>) => {
        setOpen(false);
    }

    return (
        <div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <div onClick={handleAnchorClick}>
                        {props.trigger}
                    </div>
                    <MuiPopper
                        open={open}
                        anchorEl={anchorEl}
                        placement={props.placement}
                        transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                {/* HACK: 
                                    I get syntax errors for some reason when using "modifiers" property on MuiPopper 
                                */}
                                <div style={{ position: "relative", top: "5px" }}>{props.children}</div>
                            </Fade>
                        )}
                    </MuiPopper>
                </div>
            </ClickAwayListener>
        </div>
    );
}

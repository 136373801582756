import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useQueryClient } from "react-query";
import { Route, RouteProps, Switch, useRouteMatch } from "react-router-dom";
import { useAuthContext } from "../../../context/auth.context";
import { useUserContext } from "../../../context/user.context";
import PageContainer from "../../components/Page/PageContainer";
import HomePage from "../pages/Home/Home";
import Header from "./Header";
import Sidebar from "./Sidebar";





const NotFound = React.lazy(() => import("../pages/NotFound/NotFound"));
const EventDetail = React.lazy(() => import("../pages/EventDetail/EventDetail"));
const MachinesPage = React.lazy(() => import("../pages/Machines/Machines"));
const MachineDetail = React.lazy(() => import("../pages/MachineDetail/MachineDetail"));
const UsersPage = React.lazy(() => import("../pages/Users/Users"));
const UserPage = React.lazy(() => import("../pages/UserDetail/UserDetail"));
const Groups = React.lazy(() => import("../pages/Groups/Groups"));
const GroupDetail = React.lazy(() => import("../pages/GroupDetail/GroupDetail"));
const UserProfile = React.lazy(() => import("../pages/UserProfile/UserProfile"));

const TestResultsPage = React.lazy(() => import("../pages/TestResults/TestResults"));
const TestResultPage = React.lazy(() => import("../pages/TestResultDetail/TestResultDetail"));
const TestGraphPage = React.lazy(() => import("../pages/TestGraph/TestGraph"));
const MachineTestsOld = React.lazy(() => import("../pages/MachineTestsOld/MachineTests"));
const MachineTests = React.lazy(() => import("../pages/MachineTests/MachineTests"));

const MachineTestDetail = React.lazy(() => import("../pages/MachineTestDetail/MachineTestDetail"));
const TestRunsPage = React.lazy(() => import("../pages/TestRuns/TestRuns"));
const MachineLogs = React.lazy(() => import("../pages/MachineLogs/MachineLogs"));
const MachineLogDetail = React.lazy(() => import("../pages/MachineLogDetail/MachineLogDetail"));
const EventsPage = React.lazy(() => import("../pages/Events/Events"));
const SettingsPage = React.lazy(() => import("../pages/Settings/Settings"));
const NotificationDetailPage = React.lazy(() => import("../pages/Settings/Notifications/NotificationDetail"));
const OrgSettingsPage = React.lazy(() => import("../pages/Settings/OrgSettings/OrgSettings"));
const ReportsPage = React.lazy(() => import("../pages/Reports/Reports"));
const ReportDetailPage = React.lazy(() => import("../pages/Reports/ReportDetail/ReportDetail"));
const ProceduresPage = React.lazy(() => import("../pages/Procedures/Procedures"));
const ProcedureDetailPage = React.lazy(() => import("../pages/Procedures/ProcedureDetail/ProcedureDetail"));

export interface ShellProps {
  theme: Theme;
}

const useStyles = makeStyles((theme) => ({
  root: (props: ShellProps) => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  }),
  drawer: (props: ShellProps) => ({
    [theme.breakpoints.up(props.theme.sidebar.breakpointUp)]: {
      width: props.theme.sidebar.fixedWidth,
      flexShrink: 0,
    },
  }),
  app: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    overflowX: "hidden",
  },
  scrollContent: {
    minHeight: "calc(100vh-50px)",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "space-between",
    width: "100%",
    "&>*": {
      width: "100%",
    },
  },
}));

function Shell({ children, theme, ...rest }: RouteProps & ShellProps) {
  const { signOut } = useAuthContext();
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const parentRoute = useRouteMatch();
  const classes = useStyles({ theme });

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    signOut();
  };

  const drawerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} id="sidenav">
        <Hidden mdUp implementation="css">
          <Sidebar ref={drawerRef} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} onSignOut={handleSignOut} />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar ref={drawerRef} onSignOut={handleSignOut} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <div className={classes.scrollContent}>
          <PageContainer>
            <Suspense fallback={<div style={{ width: "100%", height: "100%" }}>{/**just empty as our pages load fast */}</div>}>
              <Switch>
                <Route exact path={parentRoute.path}>
                  <>
                    <Helmet>
                      <title>Home</title>
                    </Helmet>
                    <HomePage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}tests/runs/:machineId`}>
                  <>
                    <Helmet>
                      <title>Test Runs</title>
                    </Helmet>
                    <TestRunsPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}tests-old/`}>
                  <>
                    <Helmet>
                      <title>Tests (Old)</title>
                    </Helmet>
                    <MachineTestsOld />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}tests/`}>
                  <>
                    <Helmet>
                      <title>Tests</title>
                    </Helmet>
                    <MachineTests />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}tests/:testId/`}>
                  <>
                    <Helmet>
                      <title>Test Setup</title>
                    </Helmet>
                    <MachineTestDetail />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}tests/:testId/graph/`}>
                  <>
                    <Helmet>
                      <title>Test Graph</title>
                    </Helmet>
                    <TestGraphPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}tests/:testId/results/`}>
                  <>
                    <Helmet>
                      <title>Test Results</title>
                    </Helmet>
                    <TestResultsPage />
                  </>
                </Route>
                <Route path={`${parentRoute.path}tests/:testId/results/:testResultId/`}>
                  <>
                    <Helmet>
                      <title>Test Result Detail</title>
                    </Helmet>
                    <TestResultPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}tests/:testId/procedures/`}>
                  <>
                    <Helmet>
                      <title>Procedures</title>
                    </Helmet>
                    <ProceduresPage />
                  </>
                </Route>
                <Route path={`${parentRoute.path}tests/:testId/procedures/:procedureId/`}>
                  <>
                    <Helmet>
                      <title>Procedure Detail</title>
                    </Helmet>
                    <ProcedureDetailPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}logs/`}>
                  <>
                    <Helmet>
                      <title>Logs</title>
                    </Helmet>
                    <MachineLogs />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}logs/:logId`}>
                  <>
                    <Helmet>
                      <title>Log Detail</title>
                    </Helmet>
                    <MachineLogDetail />
                  </>
                </Route>

                <Route exact path={`${parentRoute.path}events/`}>
                  <>
                    <Helmet>
                      <title>Events</title>
                    </Helmet>
                    <EventsPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}events/:eventId`}>
                  <>
                    <Helmet>
                      <title>Event Detail</title>
                    </Helmet>
                    <EventDetail />
                  </>
                </Route>

                <Route exact path={`${parentRoute.path}equipment/`}>
                  <>
                    <Helmet>
                      <title>Equipment</title>
                    </Helmet>
                    <MachinesPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}equipment/:machineId`}>
                  <>
                    <Helmet>
                      <title>Equipment Detail</title>
                    </Helmet>
                    <MachineDetail />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}profile`}>
                  <>
                    <Helmet>
                      <title>Profile</title>
                    </Helmet>
                    <UserProfile />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}profile/:userId`}>
                  <>
                    <Helmet>
                      <title>Profile</title>
                    </Helmet>
                    <UserProfile />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}users/`}>
                  <>
                    <Helmet>
                      <title>Users</title>
                    </Helmet>
                    <UsersPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}users/:userId`}>
                  <>
                    <Helmet>
                      <title>User Detail</title>
                    </Helmet>
                    <UserPage />
                  </>
                </Route>

                <Route exact path={`${parentRoute.path}groups/`}>
                  <>
                    <Helmet>
                      <title>Groups</title>
                    </Helmet>
                    <Groups />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}groups/:groupId`}>
                  <>
                    <Helmet>
                      <title>Group Detail</title>
                    </Helmet>
                    <GroupDetail />
                  </>
                </Route>

                <Route exact path={`${parentRoute.path}settings/`}>
                  <>
                    <Helmet>
                      <title>Settings</title>
                    </Helmet>
                    <SettingsPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}settings/notifications/:notificationId`}>
                  <>
                    <Helmet>
                      <title>Notification Detail</title>
                    </Helmet>
                    <NotificationDetailPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}settings/organization`}>
                  <>
                    <Helmet>
                      <title>Org Settings</title>
                    </Helmet>
                    <OrgSettingsPage />
                  </>
                </Route>
                <Route exact path={`${parentRoute.path}reports/`}>
                  <>
                    <Helmet>
                      <title>Reports</title>
                    </Helmet>
                    <ReportsPage />
                  </>
                </Route>

                <Route exact path={`${parentRoute.path}reports/:reportId`}>
                  <>
                    <Helmet>
                      <title>Report Detail</title>
                    </Helmet>
                    <ReportDetailPage />
                  </>
                </Route>

                {/** This has to be last */}
                <Route>
                  <>
                    <Helmet>
                      <title>404</title>
                    </Helmet>
                    <NotFound />
                  </>
                </Route>
              </Switch>
            </Suspense>
          </PageContainer>
        </div>
      </div>
    </div>
  );
}

export default Shell;

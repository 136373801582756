import { Divider, Grid, Link, ListSubheader, Typography } from "@material-ui/core";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, Theme, useTheme, withStyles, WithStyles } from "@material-ui/core/styles";
import {
  AccountCircleOutlined,
  AssignmentOutlined,
  Brightness4,
  Brightness5,
  CheckCircleOutlined,
  ChevronRightOutlined,
  CloseRounded,
  EventOutlined,
  GroupOutlined,
  HomeOutlined,
  InsertChartOutlined, PowerSettingsNewRounded,
  SettingsOutlined
} from "@material-ui/icons";
import { Omit } from "@material-ui/types";
import clsx from "clsx";
import React, { forwardRef, Ref, useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link as RouterLink, NavLink } from "react-router-dom";
import { useAppContext } from "../../../context/app.context";
import { useThemeContext } from "../../../context/theme.context";
import { useUserContext } from "../../../context/user.context";
import environment, { EnvId } from "../../../env";
import useOrgService from "../../../hooks/org-service.hook";
import { QuerySearch } from "../../../models/query-search.model";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import IconButton from "../../components/IconButton/IconButton";
import MachineIcon from "../../components/Icons/MachineIcon";
import MultipleStopIcon from "../../components/Icons/MultipleStopIcon";
import ImageWithText from "../../components/ImageWithText/ImageWithText";
import SearchInput from "../../components/Inputs/SearchInput/SearchInput";
import PageContainer from "../../components/Page/PageContainer";
import Tooltip from "../../components/Tooltip/Tooltip";
import UserAvatar from "../../components/UserAvatar/UserAvatar";

interface NavItem {
  id: string;
  label: string;
  route: string;
  icon?: JSX.Element;
  children?: NavItem[];
}

const menuItems: NavItem[] = [
  { id: "home", label: "Home", route: "/", icon: <HomeOutlined /> },
  { id: "qa", label: "QA Details", route: "/tests", icon: <CheckCircleOutlined /> },
  { id: "events", label: "Events", route: "/events", icon: <EventOutlined /> },
  { id: "equipment-log", label: "Equipment Log", route: "/logs", icon: <AssignmentOutlined /> },
  { id: "equipment", label: "Equipment", route: "/equipment", icon: <MachineIcon /> },
  { id: "users", label: "Users", route: "/users", icon: <AccountCircleOutlined /> },
  { id: "groups", label: "Groups", route: "/groups", icon: <GroupOutlined /> }, //AccountTree, FactCheck, VerifiedUser,
  { id: "reporting", label: "Reporting", route: "/reports", icon: <InsertChartOutlined /> },
];

const styles = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: theme.sidebar.width,
      //borderRight: "none",
      display: "flex",
      flexDirection: "column",
      background: theme.palette.background.paper,
      // background: `linear-gradient(45deg, ${theme.palette.secondary.dark} 30%, ${theme.palette.secondary.main} 90%)`,
      color: theme.sidebar.color,
      //fontWeight: 600,
      fontWeight: 600,
    },
    logoContainer: {
      height: 40,
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    logo: {
      height: 32,
      margin: "0 auto",
    },
    header: {
      zIndex: 1,
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontSize: 24,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: theme.header.height,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(theme.sidebar.breakpointUp)]: {
        marginBottom: theme.spacing(3),
      },
    },
    navList: {
      paddingTop: 0,
      paddingBottom: 64,
    },
    user: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    userActions: {
      marginTop: theme.spacing(1),
    },
    userAction: {
      display: "flex",
      flexDirection: "column",
    },
    avatarButton: {
      border: "2px solid " + theme.palette.primary.light,
    },
    accountButton: {
      background: "transparent",
      color: "inherit",
      minHeight: 0,
      fontSize: "inherit",
      padding: "2px 8px",

      "&:hover": {
        background: "transparent",
      },

      "& .org": {
        maxWidth: "160px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    itemHeader: {
      //color: "rgba(255, 255, 255, 0.9)",
    },
    item: {
      //color: "rgba(255, 255, 255, 0.7)",
      "&:hover,&:focus": {
        //backgroundColor: "rgba(255, 255, 255, 0.08)",
      },

      "&.active": {
        color: theme.palette.primary.light,
      },

      "&.active $activeIcon": {
        display: "flex",
      },

      [theme.breakpoints.up(theme.sidebar.breakpointUp)]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    itemPrimary: {
      fontSize: "inherit",
      fontWeight: "inherit",
    },
    heading: {
      color: theme.sidebar.titleColor,
      fontWeight: "inherit",
    },
    subHeading: {
      fontWeight: "inherit",
      letterSpacing: "-0.5px",
      "&.heading": {
        color: theme.sidebar.titleColor,
      },
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
    },
    activeIcon: {
      minWidth: "auto",
      marginRight: 0,
      marginLeft: theme.spacing(2),
      display: "none",
    },
    actions: {
      padding: theme.spacing(2),
    },
  });

function SidebarListItem(classes: any, item: NavItem, onClick?: () => void): JSX.Element {
  return (
    <ListItem key={item.id} button component={NavLink} to={item.route} exact={item.route === "/"} activeClassName="active" className={clsx(classes.item)} onClick={onClick}>
      <ListItemIcon className={classes.itemIcon}>{item.icon}</ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.itemPrimary,
        }}
      >
        {item.label}
      </ListItemText>
      <span className={classes.activeIcon}>
        <ChevronRightOutlined />
      </span>
    </ListItem>
  );
}

function SidebarList(classes: any, navItems: NavItem[], listKey: number, subheader?: JSX.Element): JSX.Element {
  const { refreshOrg } = useUserContext();

  return (
    <List disablePadding key={listKey} subheader={subheader}>
      {navItems.map((item, index) => {
        if (item.children && item.children.length) {
          return SidebarList(
            classes,
            item.children,
            index++,
            <ListSubheader id={item.id} disableSticky={true} className={classes.itemHeader}>
              {item.label}
            </ListSubheader>
          );
        }

        // refreshOrg is passed to the SidebarListItem component to force a refresh of the organization data
        // when a sidebar item is clicked.
        return SidebarListItem(classes, item, refreshOrg);
      })}

      <br />
    </List>
  );

  /**/
}

// Extend DrawerProps but exclude classes from it
// This adds the ability to pass in inherited props, such as:
// PaperProps={{ style: { width: drawerWidth } }}
interface SidebarProps extends Omit<DrawerProps, "classes">, WithStyles<typeof styles> {
  onSignOut?: () => void;
}

const Sidebar = forwardRef((props: SidebarProps, ref: Ref<HTMLDivElement>) => {
  const { classes, onSignOut, ...other } = props;

  const { themeType, setThemeType, theme } = useThemeContext();
  const { showMachineSelect } = useAppContext();
  const { user, setOrgId, org, setMachine, setFacility } = useUserContext();

  const name = user?.firstname ? `${user.firstname} ${user.lastname}` : "";

  const hasMultiOrgs = useMemo(() => user && org && user.orgs && user.orgs.length > 1, [org, user]);

  const [orgCriteria, setOrgCriteria] = useState<QuerySearch>({ page: 1 });

  const { getOrgs } = useOrgService();

  const {
    isLoading: orgsLoading,
    isError: orgsError,
    data: orgs,
  } = useQuery([`shell/sidebar/orgs`, org, orgCriteria], {
    queryFn: async () => {
      if (!org || !orgCriteria) {
        return Promise.resolve(undefined);
      }

      const r = await getOrgs(orgCriteria);
      return r;
    },
  });

  const handleToggleDarkTheme = useCallback(() => {
    const newTheme = themeType === "dark" ? "default" : "dark";
    setThemeType(newTheme);
  }, [setThemeType, themeType]);

  const [orgSelectEl, setOrgSelectEl] = React.useState<null | HTMLElement>(null);

  const changingOrg = useMemo(() => Boolean(showMachineSelect && orgSelectEl), [orgSelectEl, showMachineSelect]);

  const handleCloseChangeOrg = useCallback(() => {
    setOrgSelectEl(null);
  }, []);

  const handleChangeOrg = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!hasMultiOrgs || !showMachineSelect) {
        return;
      }

      setOrgSelectEl(orgSelectEl ? null : event.currentTarget);
    },
    [hasMultiOrgs, orgSelectEl, showMachineSelect]
  );

  const handleOrgSearch = useCallback((criteria: QuerySearch) => {
    setOrgCriteria((prev) => ({ ...prev, ...criteria }));
  }, []);

  const handleSelectOrg = useCallback(
    (event: any, orgId: number | null) => {
      if (!orgId) {
        return;
      }

      const isNewOrg = org?.id !== orgId;

      if (isNewOrg) {
        // Change org and reset selected facility and device.
        setOrgId(orgId);
        setMachine(null);
        setFacility(null);
      }

      // Close org selector menu
      setOrgSelectEl(null);
    },
    [org, setFacility, setMachine, setOrgId]
  );

  return (
    <Drawer
      ref={ref}
      variant="permanent"
      {...other}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div className={clsx(classes.header)}>
        <div className={clsx(classes.logoContainer)}>
          <img src={theme.sidebar.logoUrl} className={classes.logo} alt="ZapIT" />
        </div>
      </div>
      {environment.id == EnvId.Test && (
        <Grid container style={{ color: "yellow  ", fontSize: 40 }} alignItems="center" justify="center">
          <Grid item>
            <div>
              TEST
            </div>
          </Grid>
        </Grid>
      )}
      {
        user && (
          <div className={classes.user}>
            <ImageWithText
              align="center"
              title={`${name}`}
              subheader={
                changingOrg ? undefined : (
                  <Tooltip title={!hasMultiOrgs ? "" : !showMachineSelect ? "You cannot change Organizations from the current page" : "Change Organization"}>
                    <Typography align="center" color="textSecondary" variant="caption">
                      <Button
                        id="change-org"
                        disableRipple={true}
                        className={classes.accountButton}
                        endIcon={hasMultiOrgs ? <MultipleStopIcon /> : null}
                        onClick={handleChangeOrg}
                      >
                        <span className="org">{org ? org.name || "(blank org)" : "select org"}</span>
                      </Button>
                    </Typography>
                  </Tooltip>
                )
              }
              image={
                <Link component={RouterLink} underline="none" to={`/profile`}>
                  <IconButton className={classes.avatarButton}>
                    <UserAvatar user={user} size={64} />
                  </IconButton>
                </Link>
              }
            />

            {!changingOrg && (
              <Grid container className={classes.userActions} alignItems="center" justify="center">
                <Grid item>
                  <NavLink to={"/settings"}>
                    <Tooltip title="Settings">
                      <IconButton id="settings">
                        <SettingsOutlined />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </Grid>

                <Grid item>
                  <Tooltip title="Sign out">
                    <IconButton id="sign-out" onClick={onSignOut}>
                      <PowerSettingsNewRounded />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Tooltip title={themeType === "dark" ? "Change to light theme" : "Change to dark theme"}>
                    <IconButton id="change-theme" onClick={handleToggleDarkTheme}>
                      {themeType !== "dark" ? <Brightness4 /> : <Brightness5 />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </div>
        )
      }

      {
        changingOrg && (
          <>
            <Divider />
            <PageContainer style={{ padding: "8px 16px" }}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs container alignItems="center" justify="space-between">
                  <Typography color="textPrimary">Change Organization</Typography>
                  <IconButton edge="right" onClick={handleCloseChangeOrg}>
                    <CloseRounded />
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <SearchInput
                    autoFocus
                    selectOnFocus
                    fullWidth
                    id="org-select"
                    placeholder={"Select an Org"}
                    value={org?.id}
                    options={orgs?.items}
                    defaultOption={org || undefined}
                    onChange={handleSelectOrg}
                    valueProp="id"
                    labelProp="name"
                    getOptionLabel={(option) => option.name || "(blank)"}
                    onSearch={handleOrgSearch}
                  />
                </Grid>
              </Grid>
            </PageContainer>
          </>
        )
      }

      {!changingOrg && <div className={classes.navList}>{SidebarList(classes, menuItems, 0)}</div>}

      <Footer env={environment.id === EnvId.Prod ? undefined : EnvId[environment.id].toLowerCase()} version={environment.version} />
    </Drawer >
  );
});

export default withStyles(styles)(Sidebar);

import { useCallback, useRef, useState } from "react";
import { HttpClientRequestConfig, useHttpClientContext } from "../context/http-client.context";
import { useUserContext } from "../context/user.context";
import environment from "../env";
import { DeviceTestSearch } from "../models/device-test-search.model";
import { DeviceTest, DeviceTestOverdue, DeviceTestOverdueRequest, DeviceTestValidationResult, Frequency } from "../models/device-test.model";
import { EventSearchParams } from "../models/event-search-params.model";
import { Permission } from "../models/group.model";
import { MachineOptionParam } from "../models/machine-option-type.model";
import { MachineTestResult, WidgetTestResult } from "../models/machine-test-result.model";
import {
  MachineTestOrder,
  MachineTestSetupFieldHistory,
  MachineTestSetupHistorySearch,
  MachineTypeSubtest,
  MachineTypeTest,
} from "../models/machine-test.model";
import { PagedList } from "../models/paged-list.model";
import { getQuerySearchParams } from "../models/query-search-params.model";
import { QuerySearch } from "../models/query-search.model";
import useMachineTestService from "./machine-test-service.hook";

export interface DeviceTestService {
  getOverdueTests(request: DeviceTestOverdueRequest): Promise<DeviceTestOverdue[]>;
  getDeviceTypeTestVersions(orgId: number, deviceTypeId: number, criteria?: QuerySearch): Promise<PagedList<MachineTypeSubtest>>;
  getDeviceTypeTestVersion(orgId: number, deviceTypeId: number, id: number): Promise<MachineTypeSubtest>;
  getDeviceTests(orgId: number, criteria?: DeviceTestSearch): Promise<PagedList<DeviceTest>>;
  getDeviceTestResults(orgId: number, machineOptionType: MachineOptionParam, criteria: EventSearchParams): Promise<PagedList<MachineTestResult>>;
  hasTestPermission(facilityId: number, machineTypeId: number, frequency: number): boolean;
  getWidgetTestResults(orgId: number, machineOptionType: MachineOptionParam, criteria: EventSearchParams): Promise<PagedList<WidgetTestResult>>;
  getQATests(orgId: number, criteria?: DeviceTestSearch): Promise<PagedList<DeviceTest>>;
  validateOutputConstancyTestSetup(orgId: number, facilityId: number, machineId: number): Promise<DeviceTestValidationResult>;
  validateTG51TestRun(orgId: number, facilityId: number): Promise<DeviceTestValidationResult>;
  getFrequenciesFromTestSetups(orgId: number, machineId: number): Promise<Frequency[]>;
  updateTestsOrder(orgId: number, orders: MachineTestOrder[]): Promise<void>;
  getSetupHistory(orgId: number, criteria: MachineTestSetupHistorySearch): Promise<PagedList<MachineTestSetupHistorySearch>>;
  getSetupFieldHistory(orgId: number, machineType: string, setupId: number, testId: number, machineId: number): Promise<MachineTestSetupFieldHistory[]>;
  getTestResultById(orgId: number, testResultId: number): Promise<MachineTestResult>;
}

/**
 * @returns This is the new machine test service, to replace `machine-test-service.hook.ts` eventually.
 */
function useDeviceTestService(): DeviceTestService {
  const { httpClient } = useHttpClientContext();
  const { hasPermission } = useUserContext();
  const { getMachineTypeTests } = useMachineTestService();
  const machineTypeTestsCache = useRef<MachineTypeTest[]>();

  const getOverdueTests = useCallback(
    async (request: DeviceTestOverdueRequest) => {
      const url = `v2/orgs/${request.orgId}/machine-tests/overdue`;
      const options = {
        params: {
          facilityId: request.facilityId,
          machineId: request.machineId,
          startDate: request.startDate,
          endDate: request.endDate,
          includeWeekends: request.includeWeekends,
        },
        baseURL: environment.newApiUrl,
      };

      return await httpClient.get<DeviceTestOverdue[]>(url, options).then((response) => response.data);
    },
    [httpClient]
  );

  const hasTestPermission = (facilityId: number, machineTypeId: number, frequency: number): boolean => {
    let permission = undefined;

    if (frequency === 1) {
      permission = Permission.dailyPerform;
    } else if (frequency === 7 || frequency === 14) {
      permission = Permission.weeklyPerform;
    } else if (frequency === 30) {
      permission = Permission.monthlyPerform;
    } else if (frequency === 91) {
      permission = Permission.quarterlyPerform;
    } else if (frequency === 183 || frequency === 365 || frequency === 730) {
      permission = Permission.yearlyPerform;
    }

    const goodToGoDude =
      !!permission &&
      !!hasPermission(permission, {
        facilityId: facilityId,
        deviceTypeId: machineTypeId,
      });

    return goodToGoDude;
  };

  const getDeviceTypeTestVersions = useCallback(
    async (orgId: number, deviceTypeId: number, criteria?: QuerySearch) => {
      const { query, ...otherParams } = criteria || {};

      let params: DeviceTestSearch = { ...otherParams };

      if (query && query.length) {
        params = { ...params, query };
      }

      const response = await httpClient.get<PagedList<MachineTypeSubtest>>(`orgs/${orgId}/machine-types/${deviceTypeId}/tests/test-versions`, {
        params,
        baseURL: environment.newApiUrl,
      });
      return response.data;
    },
    [httpClient]
  );

  const getDeviceTypeTestVersion = useCallback(
    async (orgId: number, deviceTypeId: number, id: number) => {
      const response = await httpClient.get<MachineTypeSubtest>(`orgs/${orgId}/machine-types/${deviceTypeId}/tests/test-versions/${id}`, {
        baseURL: environment.newApiUrl,
      });
      return response.data;
    },
    [httpClient]
  );

  /**
   * Gets machine test results.
   */
  const getDeviceTestResults = useCallback(
    (orgId: number, machineOptionType: MachineOptionParam, criteria: EventSearchParams) => {
      const endpoint = `v2/orgs/${orgId}/test-results`;

      const apiCriteria = { ...getQuerySearchParams(criteria, true), ...machineOptionType };

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.items as MachineTestResult[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  /**
   * Gets reduced widget machine test results for widgets.
   */
  const getWidgetTestResults = useCallback(
    (orgId: number, machineOptionType: MachineOptionParam, criteria: EventSearchParams) => {
      const endpoint = `v2/orgs/${orgId}/test-results/testResultWidget`;

      const apiCriteria = { ...getQuerySearchParams(criteria, true), ...machineOptionType };

      const config: HttpClientRequestConfig = {
        params: apiCriteria,
        // Bc the original developer decided to use multiple cases for params, we cannot
        // serialize this
        useRequestParamsToSnake: false,
        baseURL: environment.newApiUrl,
      };

      return httpClient.get(endpoint, config).then((response) => {
        const items = response.data.items as WidgetTestResult[];
        const total = response.data.total;

        return { items, total };
      });
    },
    [httpClient]
  );

  const getDeviceTests = useCallback(
    async (orgId: number, criteria?: DeviceTestSearch) => {
      const { query, status, ...otherParams } = criteria || {};

      let params: Record<string, any> = { ...otherParams };

      if (query && query.length) {
        params = { ...params, query };
      }

      if (status && status.length) {
        params = { ...params, status: status.join(",") };
      }

      // Use the V2 API for better performance
      const response = await httpClient.get<PagedList<DeviceTest>>(`v2/orgs/${orgId}/machine-tests`, { params, baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const getQATests = useCallback(
    async (orgId: number, criteria?: DeviceTestSearch) => {
      const { query, status, ...otherParams } = criteria || {};
      let params: Record<string, any> = { ...otherParams };

      if (query && query.length) {
        params = { ...params, query };
      }

      if (status && status.length) {
        params = { ...params, status: status.join(",") };
      }

      const response = await httpClient.get<PagedList<DeviceTest>>(`v2/orgs/${orgId}/machine-tests/qa`, { params, baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const validateOutputConstancyTestSetup = useCallback(
    async (orgId: number, facilityId: number, machineId: number) => {
      const response = await httpClient.get<DeviceTestValidationResult>(`v2/orgs/${orgId}/machine-tests/validateOutputConstancyTestSetup`, {
        params: { orgId, facilityId, machineId },
        baseURL: environment.newApiUrl,
      });
      return response.data;
    },
    [httpClient]
  );

  const validateTG51TestRun = useCallback(
    async (orgId: number, facilityId: number) => {
      const response = await httpClient.get<DeviceTestValidationResult>(`v2/orgs/${orgId}/machine-tests/validateTG51TestRun`, {
        params: { facilityId },
        baseURL: environment.newApiUrl,
      });
      return response.data;
    },
    [httpClient]
  );

  const getFrequenciesFromTestSetups = useCallback(
    async (orgId: number, machineId: number) => {
      const url = `v2/orgs/${orgId}/machine-tests/frequencies`;
      const options = {
        params: {
          orgId,
          machineId,
        },
        baseURL: environment.newApiUrl,
      };

      return await httpClient.get<Frequency[]>(url, options).then((response) => response.data);
    },
    [httpClient]
  );

  const updateTestsOrder = useCallback(
    async (orgId: number, orders: MachineTestOrder[]) => {
      const url = `v2/orgs/${orgId}/machine-tests/order`;
      const options = {
        baseURL: environment.newApiUrl,
      };

      await httpClient.put(url, orders, options).then((response) => response.data);
    },
    [httpClient]
  );

  const getSetupHistory = useCallback(
    async (orgId: number, criteria: MachineTestSetupHistorySearch) => {
      const url = `v2/orgs/${orgId}/machine-tests/history`;
      const options = {
        params: criteria,
        baseURL: environment.newApiUrl,
      };

      return await httpClient.get<PagedList<MachineTestSetupHistorySearch>>(url, options).then((response) => response.data);
    },
    [httpClient]
  );

  const getTestResultById = useCallback(
    async (orgId: number, testResultId: number): Promise<MachineTestResult> => {
      const options = {
        baseURL: environment.newApiUrl,
      };
      const response = await httpClient.get(`v2/orgs/${orgId}/test-results/${testResultId}`, options);
      return response.data;
    },
    [httpClient]
  );

  const getSetupFieldHistory = useCallback(
    async (orgId: number, machineType: string, setupId: number, testId: number, machineId: number) => {
      const url = `v2/orgs/${orgId}/machine-tests/history/${setupId}/fields`;
      const options = {
        baseURL: environment.newApiUrl,
        params: {
          testId,
          machineId,
        },
      };

      const setupFieldHistory = await httpClient.get<MachineTestSetupFieldHistory[]>(url, options).then((response) => response.data);

      // HACK: The combo values are not stored in the database so we have to lookup
      // the values from the machine type test JSON
      let machineTypeTests = [];

      // Check the cache first
      if (machineTypeTestsCache.current?.[0]?.machineType?.name == machineType) {
        machineTypeTests = machineTypeTestsCache.current;
      } else {
        machineTypeTests = await getMachineTypeTests({
          machineType,
        });
        // This can be megabytes of data, so it's cached to improve performance
        machineTypeTestsCache.current = machineTypeTests;
      }

      const machineTypeTest = machineTypeTests.find((machineTypeTest) => machineTypeTest.id === testId);
      const testVersion = machineTypeTest?.versions[0];

      setupFieldHistory.forEach((field) => {
        if (field.xType === "combo") {
          const machineTypeField = testVersion?.testFields.find((f) => f.id === field.testFieldId);
          const comboValue = machineTypeField?.comboValues.find((v) => v.value === field.value);
          const previousComboValue = machineTypeField?.comboValues.find((v) => v.value === field.previousValue);

          field.value = comboValue?.label || field.value;
          field.previousValue = previousComboValue?.label || field.previousValue;
        }
      });

      return setupFieldHistory;
    },
    [httpClient, getMachineTypeTests]
  );

  return {
    getOverdueTests,
    getDeviceTypeTestVersions,
    getDeviceTypeTestVersion,
    getDeviceTests,
    getDeviceTestResults,
    hasTestPermission,
    getWidgetTestResults,
    getQATests,
    validateOutputConstancyTestSetup,
    validateTG51TestRun,
    getFrequenciesFromTestSetups,
    updateTestsOrder,
    getSetupHistory,
    getSetupFieldHistory,
    getTestResultById,
  };
}

export default useDeviceTestService;

import React, { useState, FormEvent, useEffect, useCallback, useMemo } from "react";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../../../context/auth.context";
import { useUserContext } from "../../../../../context/user.context";
import Button from "../../../../components/Button/Button";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import { useAppContext } from "../../../../../context/app.context";
import environment, { EnvId } from "../../../../../env";
import { RegisterRequest, VerifyRegisterRequest } from "../../../../../models/login-request.model";
import Switch from "../../../../components/Switch/Switch";
import IconButton from "../../../../components/IconButton/IconButton";
import { LockOpenOutlined, LockOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { Controller, useForm } from "react-hook-form";
import InputWrapper from "../../../../components/Inputs/InputWrapper/InputWrapper";
import queryString from "query-string";
import { passwordRules, emailRules, nameRules } from "./form-validation-rules";
import VerificationCodeForm from "./VerificationCodeForm";

function RegisterForm() {
  const history = useHistory();
  const location = useLocation<RegisterRequest | undefined>();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const verifyCode = useMemo(() => location.pathname.endsWith("/verify"), [location.pathname]);
  const getCode = useMemo(() => location.pathname.endsWith("/code"), [location.pathname]);

  const { username } = queryString.parse(location.search);

  const { register, verifySignUp } = useAuthContext();

  const {
    clearErrors,
    control,
    handleSubmit,
    //formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    return () => {
      setError("");
      clearErrors();
    };
  }, [location.pathname, clearErrors]);

  const toggleShowPassword = useCallback(
    (show?: boolean) => (event: never) => {
      setShowPassword((prev) => (show ? show : !prev));
    },
    []
  );

  const handleSubmitRegister = useCallback(
    (formValue: RegisterRequest) => {
      const { name, username, password } = formValue;

      setError("");
      setLoading(true);

      register({ name, username: username, password })
        .then(() => {
          history.push(`register/verify?username=${username}`);
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => setLoading(false));
    },
    [history, register]
  );

  const handleSubmitVerifyRegister = useCallback(
    (formValue: VerifyRegisterRequest) => {
      const { username, password, verificationCode } = formValue;

      setError("");
      setLoading(true);
      verifySignUp({ username: username, password, verificationCode })
        .then(() => {
          // Nav to home
          history.replace("/");
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    },
    [history, verifySignUp]
  );

  // If path is register/code we need to show form to get code
  if (getCode) {
    return <VerificationCodeForm codeType="register" />;
  }

  return (
    <>
      <form
        action={verifyCode ? "register/verify" : "register"}
        onSubmit={verifyCode ? handleSubmit(handleSubmitVerifyRegister) : handleSubmit(handleSubmitRegister)}
        noValidate
      >
        <Grid container spacing={2}>
          {error && (
            <Grid container item xs={12} justify="center">
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid container item xs={12} justify="center">
            <Typography align="center" variant="body2" color="textSecondary">
              {verifyCode ? "We sent you an email with a verification code." : "We'll send you an email with a verification code."}
            </Typography>

            <Link
              component={RouterLink}
              to={verifyCode ? `/register/code${username ? `?username=${username}` : ""}` : `/register/verify${username ? `?username=${username}` : ""}`}
              align="center"
              variant="body2"
            >
              {verifyCode ? "Don't have a code? Get one" : "Already have a code? Verify it"}
            </Link>
          </Grid>
          {!verifyCode && (
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={nameRules()}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextInput
                    inputRef={ref}
                    {...rest}
                    margin="none"
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    autoComplete="name"
                    autoFocus
                    label={fieldState.error?.message || "Name"}
                    error={Boolean(fieldState.error)}
                  />
                )}
              />
            </Grid>
          )}
          <InputWrapper hidden={Boolean(username)}>
            <Grid item xs={12}>
              <Controller
                name="username"
                control={control}
                defaultValue={username || ""}
                rules={emailRules()}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextInput
                    inputRef={ref}
                    {...rest}
                    margin="none"
                    fullWidth
                    id="username"
                    name="username"
                    type={username ? "hidden" : "text"}
                    autoComplete="username"
                    label={fieldState.error?.message || "Email"}
                    error={Boolean(fieldState.error)}
                  />
                )}
              />
            </Grid>
          </InputWrapper>
          {username && (
            <Grid container item xs={12} justify="center">
              <Typography align="center" variant="body1" color="textPrimary">
                {username}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            {verifyCode ? (
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: `Re-enter password is required`,
                  },
                }}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextInput
                    inputRef={ref}
                    {...rest}
                    margin="none"
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete={"current-password"}
                    label={fieldState.error?.message || "Re-enter password"}
                    error={Boolean(fieldState.error)}
                    InputProps={{
                      endAdornment: (
                        <IconButton type="button" edge="right" onClick={toggleShowPassword()}>
                          {showPassword ? <LockOpenOutlined /> : <LockOutlined />}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={passwordRules()}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextInput
                    inputRef={ref}
                    {...rest}
                    margin="none"
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete={"new-password"}
                    label={fieldState.error?.message || "Create Password"}
                    error={Boolean(fieldState.error)}
                    InputProps={{
                      endAdornment: (
                        <IconButton type="button" edge="right" onClick={toggleShowPassword()}>
                          {showPassword ? <LockOpenOutlined /> : <LockOutlined />}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {verifyCode && (
            <Grid item xs={12}>
              <Controller
                name="verificationCode"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "Verification code is required",
                  },
                }}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextInput
                    inputRef={ref}
                    {...rest}
                    margin="none"
                    fullWidth
                    id="verificationCode"
                    name="verificationCode"
                    type="text"
                    autoComplete="one-time-code"
                    inputMode="numeric"
                    label={fieldState.error?.message || "Verification code"}
                    error={Boolean(fieldState.error)}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
              {verifyCode ? (!loading ? "Verify and sign in" : "Verifying...") : !loading ? "Sign up" : "Signing up..."}
            </Button>
          </Grid>
        </Grid>
      </form>
      {loading && <PageLoader opacity="0.5" position="fixed" />}
    </>
  );
}

export default RegisterForm;

import { useCallback } from "react";
import { useHttpClientContext } from "../context/http-client.context";
import environment from "../env";
import { OrgEquipmentField } from "../models/org-field.model";
import { Organization, OrgQuerySearch } from "../models/organization.model";
import { PagedList } from "../models/paged-list.model";

export interface OrganizationService {
  getOrgs(criteria?: OrgQuerySearch): Promise<PagedList<Organization>>;
  getOrgById(id: number): Promise<Organization>;
  updateOrg(org: Organization): Promise<Organization>;
  getOrgEquipmentFields(id: number): Promise<OrgEquipmentField[]>;
  updateOrgEquipmentField(field: OrgEquipmentField): Promise<void>;
}

function useOrgService(): OrganizationService {
  const { httpClient } = useHttpClientContext();

  const getOrgs = useCallback(
    async (criteria?: OrgQuerySearch) => {
      const { query, ...otherParams } = criteria || {};

      let params: OrgQuerySearch = { ...otherParams };

      if (query && query.length) {
        params = { ...params, query };
      }

      const response = await httpClient.get<PagedList<Organization>>(`orgs`, { params, baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const getOrgById = useCallback(
    async (id: number) => {
      const response = await httpClient.get<Organization>(`orgs/${id}`, { baseURL: environment.newApiUrl });

      return response.data;
    },
    [httpClient]
  );

  const updateOrg = useCallback(
    async (org: Organization) => {
      const response = await httpClient.put<Organization>(`orgs/${org.id}`, org, { baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const updateOrgEquipmentField = useCallback(
    async (field: OrgEquipmentField) => {
      await httpClient.put(`orgs/${field.orgId}/field`, field, { baseURL: environment.newApiUrl });
      return;
    },
    [httpClient]
  );

  const getOrgEquipmentFields = useCallback(
    async (id: number) => {
      const response = await httpClient.get<OrgEquipmentField[]>(`/orgs/${id}/fields`, { baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  return { getOrgs, getOrgById, updateOrg, getOrgEquipmentFields, updateOrgEquipmentField };
}

export default useOrgService;

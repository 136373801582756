import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, PaperProps, StandardProps } from "@material-ui/core";

export interface PageContainerProps extends React.HTMLAttributes<HTMLDivElement> { }

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: "100%",
    width: "100%",
    flex: 1,
    padding: theme.spacing(3, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2),
    },
  },
}));

function PageContainer({ ...rest }: React.PropsWithChildren<PageContainerProps>) {
  const defaultProps = {
    ...rest,
  };

  const classes = useStyles();

  return <main className={classes.pageContainer} {...defaultProps} />;
}

export default PageContainer;

import React from "react";
import { SvgProps } from "./svg.model";
import useThemePalette from "../../../hooks/theme-palette.hook";

function ErrorScreen(props: SvgProps) {
  const color = props.color || "secondary";
  const size = props.size || "100%";

  const [mainColor, lightColor, darkColor] = useThemePalette({ color });
  const gradientId = `_linearGradient_ErrorScreen_Svg`;

  /**
   * outline: #2626bc
   * fill:#6583fe
   */

  //const mainColor = "#6583fe";
  //const lightColor = "#d2deff";
  //const darkColor = "#2626bc";

  return (
    <svg width={size} height={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id={gradientId} gradientUnits="userSpaceOnUse" x1="63.731" x2="463.845" y1="377.304" y2="146.298">
        <stop offset="0" stopColor={lightColor} />
        <stop offset="1" stopColor={lightColor} />
      </linearGradient>

      <g>
        <path
          d="m505.626 291.921c-11.826-34.61-38.138-60.16-69.852-72.202-16.43-6.239-27.346-21.856-27.587-39.429-.005-.384-.012-.769-.021-1.154-1.507-64.582-55.488-116.33-120.077-115.221-38.094.654-71.788 19.355-92.905 47.92-18.185 24.599-46.49 39.965-77.08 39.949-.898 0-1.797.009-2.7.029-61.917 1.354-114.567 54.478-115.393 116.404-.88 65.931 52.302 119.654 118.032 119.654 50.947 0 94.349-32.277 110.886-77.497 4.327-11.832 17.152-18.174 29.283-14.772.151.042.303.085.455.127 11.099 3.064 18.458 13.64 17.55 25.119-1.217 15.383.581 31.255 5.798 46.796 20.521 61.135 87.188 94.685 148.493 74.68 62.711-20.465 96.379-88.185 75.118-150.403z"
          fill={`url(#${gradientId})`}
        />
        <path
          d="m456 203.734v-92.979c0-8.28-6.72-15-15-15h-145.131l-31.274-52.574c-3.877-6.517-13.312-6.517-17.189 0l-31.274 52.574h-145.132c-8.28 0-15 6.72-15 15v92.979z"
          fill={darkColor}
          opacity=".1"
        />
        <path d="m312.71 473.713h-113.421l22.682-89.904h68.057z" fill="#fff" />
        <path d="m456 131.759v254.25c0 8.28-6.72 15-15 15h-370c-8.28 0-15-6.72-15-15v-254.25c0-8.28 6.72-15 15-15h370c8.28 0 15 6.72 15 15z" fill="#fff" />
        <path d="m456 206.734v-74.975c0-8.28-6.72-15-15-15h-370c-8.28 0-15 6.72-15 15v74.975z" fill={mainColor} />
        <path d="m456 164.93v-33.171c0-8.284-6.716-15-15-15h-370c-8.284 0-15 6.716-15 15v33.171z" fill="#fff" />
        <path d="m56 350.617v35.39c0 8.284 6.716 15 15 15h370c8.284 0 15-6.716 15-15v-35.39z" fill="#fff" />
        <g fill={darkColor}>
          <path d="m94.082 145.917c-2.762 0-5-2.239-5-5v-.145c0-2.761 2.238-5 5-5s5 2.239 5 5v.145c0 2.761-2.238 5-5 5z" />
          <path d="m113.406 145.917c-2.762 0-5-2.239-5-5v-.145c0-2.761 2.238-5 5-5s5 2.239 5 5v.145c0 2.761-2.238 5-5 5z" />
          <path d="m132.731 145.917c-2.762 0-5-2.239-5-5v-.145c0-2.761 2.238-5 5-5s5 2.239 5 5v.145c0 2.761-2.238 5-5 5z" />
        </g>
        <path
          d="m138.584 276.677h234.831c7.756 0 12.56-8.447 8.594-15.112l-117.414-197.383c-3.877-6.517-13.312-6.517-17.189 0l-117.416 197.383c-3.965 6.666.838 15.112 8.594 15.112z"
          fill="#fff"
        />
        <path
          d="m441 111.759h-117.646c-2.762 0-5 2.239-5 5s2.238 5 5 5h117.646c5.514 0 10 4.486 10 10v28.171h-123.631l-58.478-98.305c-2.731-4.59-7.551-7.331-12.893-7.331-5.341 0-10.16 2.741-12.89 7.332l-29.823 50.133h-142.285c-11.028 0-20 8.972-20 20v254.247c0 11.028 8.972 20 20 20h140.215l-9.71 38.487c-.676 2.678.947 5.396 3.625 6.071.41.104.821.153 1.227.153 2.236 0 4.272-1.511 4.844-3.778l10.327-40.934h68.945l15.819 62.701h-126.226c-2.762 0-5 2.239-5 5s2.238 5 5 5h151.867c2.762 0 5-2.239 5-5s-2.238-5-5-5h-15.33l-15.819-62.701h140.216c11.028 0 20-8.972 20-20v-254.246c0-11.028-8.972-20-20-20zm10 58.171v175.686h-390v-175.686h117.683l-52.989 89.078c-2.794 4.696-2.85 10.334-.148 15.084s7.575 7.585 13.039 7.585h234.832c5.464 0 10.338-2.835 13.039-7.585s2.646-10.388-.148-15.083l-52.989-89.078zm-199.297-103.192c1.313-2.206 3.441-2.443 4.297-2.444.854 0 2.985.238 4.298 2.444l117.415 197.383c1.342 2.256.481 4.268.05 5.027-.433.76-1.722 2.528-4.347 2.528h-234.832c-2.625 0-3.914-1.768-4.347-2.528-.432-.76-1.292-2.771.05-5.028zm-180.703 55.021h136.338l-22.707 38.171h-123.631v-28.171c0-5.514 4.486-10 10-10zm370 274.247h-370c-5.514 0-10-4.486-10-10v-30.39h390v30.39c0 5.514-4.486 10-10 10z"
          fill={darkColor}
        />
        <path d="m256 209.871c-5.522 0-10-4.477-10-10v-65.75c0-5.523 4.478-10 10-10s10 4.477 10 10v65.75c0 5.523-4.478 10-10 10z" fill={darkColor} />
        <path d="m256 240.929c-5.522 0-10-4.477-10-10v-.115c0-5.523 4.478-10 10-10s10 4.477 10 10v.115c0 5.523-4.478 10-10 10z" fill={darkColor} />
        <path
          d="m123.104 322.922c-1.279 0-2.56-.488-3.535-1.464-2.34-2.339-6.146-2.341-8.485 0-1.951 1.952-5.119 1.952-7.07 0-.977-.977-1.465-2.256-1.465-3.536s.488-2.559 1.465-3.536c1.133-1.133 1.757-2.64 1.757-4.243s-.624-3.109-1.757-4.242c-.977-.976-1.465-2.256-1.465-3.535 0-1.28.488-2.56 1.464-3.536 1.953-1.953 5.118-1.954 7.071 0 1.134 1.133 2.641 1.757 4.243 1.757s3.108-.624 4.241-1.757c1.953-1.953 5.118-1.954 7.071 0 .977.976 1.465 2.256 1.465 3.535 0 1.28-.488 2.56-1.464 3.536-2.34 2.34-2.34 6.146-.001 8.485.977.977 1.465 2.256 1.465 3.536s-.488 2.559-1.465 3.536c-.975.976-2.255 1.464-3.535 1.464z"
          fill={mainColor}
        />
        <path
          d="m163.771 322.922c-1.279 0-2.56-.488-3.535-1.464-2.34-2.34-6.146-2.34-8.486 0-1.951 1.952-5.119 1.952-7.07 0-.977-.977-1.465-2.256-1.465-3.536s.488-2.559 1.465-3.536c1.133-1.133 1.757-2.64 1.757-4.243s-.624-3.109-1.757-4.242c-.977-.976-1.465-2.256-1.465-3.535 0-1.28.488-2.56 1.464-3.536 1.953-1.953 5.118-1.954 7.071 0 2.34 2.339 6.146 2.339 8.486 0 1.951-1.952 5.118-1.953 7.071 0 .976.976 1.464 2.256 1.464 3.536 0 1.279-.488 2.559-1.465 3.535-1.133 1.133-1.757 2.64-1.757 4.242s.624 3.109 1.757 4.243c.977.977 1.465 2.256 1.465 3.536s-.488 2.559-1.465 3.536c-.975.976-2.255 1.464-3.535 1.464z"
          fill={mainColor}
        />
        <path
          d="m204.438 322.922c-1.279 0-2.56-.488-3.535-1.464-2.34-2.34-6.146-2.34-8.486 0-1.951 1.952-5.119 1.952-7.07 0-.977-.977-1.465-2.256-1.465-3.536s.488-2.559 1.465-3.536c1.133-1.133 1.757-2.64 1.757-4.243s-.624-3.109-1.757-4.242c-.977-.976-1.465-2.256-1.465-3.535 0-1.28.488-2.56 1.464-3.536 1.953-1.953 5.118-1.954 7.071 0 2.34 2.339 6.146 2.339 8.486 0 1.951-1.952 5.118-1.953 7.071 0 .976.976 1.464 2.256 1.464 3.536 0 1.279-.488 2.559-1.465 3.535-1.133 1.133-1.757 2.64-1.757 4.242s.624 3.109 1.757 4.243c.977.977 1.465 2.256 1.465 3.536s-.488 2.559-1.465 3.536c-.975.976-2.255 1.464-3.535 1.464z"
          fill={mainColor}
        />
        <path
          d="m399.443 305.695h-157.154c-2.766 0-5.008-2.242-5.008-5.008s2.242-5.008 5.008-5.008h157.154c2.766 0 5.008 2.242 5.008 5.008s-2.241 5.008-5.008 5.008z"
          fill={darkColor}
        />
        <path
          d="m399.443 324.609h-157.154c-2.766 0-5.008-2.242-5.008-5.008s2.242-5.008 5.008-5.008h157.154c2.766 0 5.008 2.242 5.008 5.008 0 2.765-2.241 5.008-5.008 5.008z"
          fill={darkColor}
        />
        <g fill={mainColor}>
          <path d="m143.749 455.797c-1.326 0-2.598-.527-3.535-1.464l-11.018-11.018c-1.953-1.953-1.953-5.119 0-7.071l11.018-11.018c.938-.938 2.209-1.464 3.535-1.464s2.598.527 3.535 1.464l11.019 11.018c.938.938 1.465 2.209 1.465 3.536s-.526 2.598-1.465 3.536l-11.019 11.018c-.937.936-2.209 1.463-3.535 1.463zm-3.946-16.018 3.946 3.947 3.947-3.947-3.947-3.947z" />
          <path d="m320.866 84.022c-1.326 0-2.598-.527-3.535-1.464l-11.019-11.018c-.938-.938-1.465-2.209-1.465-3.536s.526-2.598 1.465-3.536l11.019-11.018c.938-.938 2.209-1.464 3.535-1.464s2.598.527 3.535 1.464l11.018 11.018c1.953 1.953 1.953 5.119 0 7.071l-11.018 11.018c-.937.938-2.209 1.465-3.535 1.465zm-3.947-16.018 3.947 3.947 3.946-3.947-3.946-3.947z" />
          <path d="m364.982 65.33c-2.762 0-5-2.239-5-5 0-3.318-2.7-6.018-6.019-6.018-2.762 0-5-2.239-5-5s2.238-5 5-5c3.318 0 6.019-2.7 6.019-6.018 0-2.761 2.238-5 5-5s5 2.239 5 5c0 3.318 2.699 6.018 6.018 6.018 2.762 0 5 2.239 5 5s-2.238 5-5 5c-3.318 0-6.018 2.7-6.018 6.018 0 2.762-2.238 5-5 5z" />
          <path d="m163.771 84.022c-2.762 0-5-2.239-5-5 0-3.318-2.699-6.018-6.018-6.018-2.762 0-5-2.239-5-5s2.238-5 5-5c3.318 0 6.018-2.7 6.018-6.018 0-2.761 2.238-5 5-5s5 2.239 5 5c0 3.318 2.7 6.018 6.019 6.018 2.762 0 5 2.239 5 5s-2.238 5-5 5c-3.318 0-6.019 2.7-6.019 6.018 0 2.761-2.238 5-5 5z" />
        </g>
      </g>
    </svg>
  );
}

export default ErrorScreen;

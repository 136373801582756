import React, { useState, useCallback } from "react";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../../../context/auth.context";
import Button from "../../../../components/Button/Button";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import { VerificationCodeRequest } from "../../../../../models/login-request.model";
import Alert from "@material-ui/lab/Alert";
import queryString from "query-string";
import { Controller, useForm } from "react-hook-form";
import InputWrapper from "../../../../components/Inputs/InputWrapper/InputWrapper";
import { emailRules } from "./form-validation-rules";

export type VerificationCodeType = "register" | "reset";

function VerificationCodeForm(props: { codeType: VerificationCodeType }) {
  const history = useHistory();
  const location = useLocation<VerificationCodeRequest | undefined>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { username } = queryString.parse(location.search);

  const { codeType } = props;

  const { resetPassword, resendSignUpCode } = useAuthContext();

  const {
    control,
    handleSubmit,
    //formState: {  errors },
  } = useForm({
    mode: "all",
  });

  const handleSubmitCode = useCallback(
    (formValue: VerificationCodeRequest) => {
      const { username } = formValue;

      setError("");
      setLoading(true);

      const request = codeType === "reset" ? resetPassword({ username: username }) : resendSignUpCode({ username: username });

      request
        .then(() => {
          if (codeType === "reset") {
            history.push(`reset/verify?username=${username}`);
          } else {
            history.push(`register/verify?username=${username}`);
          }
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    },
    [codeType, history, resendSignUpCode, resetPassword]
  );

  return (
    <>
      <form action={`${codeType}/code`} onSubmit={handleSubmit(handleSubmitCode)} noValidate>
        <Grid container spacing={2}>
          {error && (
            <Grid container item xs={12} justify="center">
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid container item xs={12} justify="center">
            <Typography align="center" variant="body2" color="textSecondary">
              {"We'll send you an email with a verification code."}
            </Typography>
            <Link
              component={RouterLink}
              to={
                codeType === "reset" ? `/reset/verify${username ? `?username=${username}` : ""}` : `/register/verify${username ? `?username=${username}` : ""}`
              }
              align="center"
              variant="body2"
            >
              {"Already have a code? Verify it"}
            </Link>
          </Grid>
          <InputWrapper hidden={Boolean(username)}>
            <Grid item xs={12}>
              <Controller
                name="username"
                control={control}
                defaultValue={username || ""}
                rules={emailRules()}
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextInput
                    inputRef={ref}
                    {...rest}
                    margin="none"
                    fullWidth
                    id="username"
                    name="username"
                    type={username ? "hidden" : "text"}
                    autoComplete="username"
                    autoFocus
                    label={fieldState.error?.message || "Email"}
                    error={Boolean(fieldState.error)}
                  />
                )}
              />
            </Grid>
          </InputWrapper>
          {username && (
            <Grid container item xs={12} justify="center">
              <Typography align="center" variant="body1" color="textPrimary">
                {username}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
              {!loading ? "Send code" : "Sending..."}
            </Button>
          </Grid>
        </Grid>
      </form>
      {loading && <PageLoader opacity="0.5" position="fixed" />}
    </>
  );
}

export default VerificationCodeForm;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, CircularProgressProps, Box, Typography } from "@material-ui/core";
import Card from "../Card/Card";

export interface SpinningLoaderProps extends CircularProgressProps {
  /** Set total items to load to enable auto increment */
  total?: number;
  /** Set auto increment amount. Default is 1 */
  autoIncrement?: number;
}

const useStyles = makeStyles((theme) => ({
  loader: {
    lineHeight: 0,
    borderRadius: "50%",
  },
}));

function SpinningLoader({ total, autoIncrement, value, ...rest }: SpinningLoaderProps) {
  const classes = useStyles();
  const [progress, setProgress] = useState(value);

  // Update mounting progress in a fluid manner
  useEffect(() => {
    if (value == null) {
      return;
    }

    const _actualVal = Math.max(0, Math.min(value, 100));

    setProgress(_actualVal);

    if (_actualVal === 100) {
      return;
    }

    if (total == null) {
      return;
    }

    // figure the next progress value so we don't estimate over it
    const finishedCount = _actualVal * 0.01 * total;
    const nextProgress = (finishedCount >= total ? 1 : (finishedCount + 1) / total) * 100;
    const delay = 100;

    const interval = setInterval(() => {
      setProgress((prev) => Math.min(prev! + (autoIncrement || 1), nextProgress));
    }, delay);

    return () => {
      clearInterval(interval);
    };
  }, [autoIncrement, total, value]);

  const defaultProps: CircularProgressProps = {
    color: "primary",
    ...rest,
    size: "48px",
    value: progress,
  };

  return (
    <Card className={classes.loader} variant="elevation">
      {defaultProps.value != null ? (
        <Box position="relative" display="inline-flex">
          <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress style={{ opacity: "0.1" }} {...defaultProps} variant="indeterminate" value={undefined} />
          </Box>
          <CircularProgress {...defaultProps} />
          <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
            <Typography variant="caption" component="div" color="textSecondary">{`${progress?.toFixed(0)}%`}</Typography>
          </Box>
        </Box>
      ) : (
        <CircularProgress {...defaultProps} />
      )}
    </Card>
  );
}

export default SpinningLoader;

import { useCallback, useMemo } from "react";
import { useHttpClientContext } from "../context/http-client.context";
import { useUserSettingsContext } from "../context/user-settings.context";
import { useUserContext } from "../context/user.context";
import environment from "../env";
import { PagedList } from "../models/paged-list.model";
import { SearchbarSearchQuerySearch, SearchbarSearchResult, SearchbarSearchType } from "../models/searchbar.model";

export interface SearchbarFilter {
  orgId: number;
  facilityId: number;
  machineId: number;
}

export interface SearchbarService {
  searchOrg(orgId: number, criteria?: SearchbarSearchQuerySearch): Promise<PagedList<SearchbarSearchResult>>;
  getById(orgId: number, type: SearchbarSearchType, id: number): Promise<SearchbarSearchResult>;
  searchbarFilter: SearchbarFilter;
}

function useSearchbarService(): SearchbarService {
  const { httpClient } = useHttpClientContext();
  const { userSettings } = useUserSettingsContext();

  const searchOrg = useCallback(
    async (orgId: number, criteria?: SearchbarSearchQuerySearch) => {
      const { query, ...otherParams } = criteria || {};

      let params: SearchbarSearchQuerySearch = { ...otherParams };

      params.hideInactiveMachines = userSettings.hideInactiveMachines;

      if (query && query.length) {
        params = { ...params, query };
      }

      const response = await httpClient.get<PagedList<SearchbarSearchResult>>(`orgs/${orgId}/search`, { params, baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient, userSettings]
  );

  const getById = useCallback(
    async (orgId: number, type: SearchbarSearchType, id: number) => {
      const response = await httpClient.get<SearchbarSearchResult>(`orgs/${orgId}/search/${type}/${id}`, { baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const { org, facility, machine } = useUserContext();

  const searchbarFilter = useMemo(() => {
    const filter: any = {};

    if (machine) {
      filter.machineId = machine.id;
    }

    if (facility) {
      filter.facilityId = facility.id;
    }

    if (org) {
      filter.orgId = org.id;
    }

    return filter;
  }, [facility, machine, org]);

  return { searchOrg, getById, searchbarFilter };
}

export default useSearchbarService;

import React from "react";
import { PaperProps } from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../Card/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    overflow: "hidden",
  },
}));

function AutocompleteInputMenu(props: PaperProps) {
  const classes = useStyles();
  return <Card classes={classes} {...props} variant="elevation" />;
}

export default AutocompleteInputMenu;

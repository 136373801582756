import React from "react";
import { SvgProps } from "./svg.model";
import useThemePalette from "../../../hooks/theme-palette.hook";

function Report(props: SvgProps) {
  const color = props.color || "secondary";
  const size = props.size || "100%";

  const [mainColor, lightColor, darkColor] = useThemePalette({ color });
  const gradientId = `_linearGradient_Report_Svg`;

  return (
    <svg width={size} height={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id={gradientId} gradientUnits="userSpaceOnUse" x1="63.731" x2="463.845" y1="377.304" y2="146.298">
        <stop offset="0" stopColor="#d2deff" />
        <stop offset="1" stopColor="#b7c5ff" />
      </linearGradient>
      <g>
        <path
          d="m20.337 133.512c-23.967 39.398-26.493 107.613-8.937 150.256 15.377 37.351 45.966 72.409 86.12 76.801 14.766 1.615 29.858-1.024 44.472 1.638 50.257 9.152 68.952 71.09 108.01 104.014 27.182 22.913 64.898 31.022 100.174 26.607 35.276-4.414 68.302-20.363 97.376-40.824 22.957-16.157 44.436-36.022 55.764-61.707 23.744-53.839-4.19-118.501-47.587-158.239-13.517-12.377-28.582-23.294-39.875-37.727-11.293-14.434-18.48-33.695-13.099-51.214 4.293-13.977 15.849-24.812 20.227-38.763 5.958-18.984-3.035-40.28-17.802-53.616-14.767-13.335-34.308-20.039-53.666-24.64-81.103-19.281-258.823-11.524-331.177 107.414z"
          fill={`url(#${gradientId})`}
        />
        <g>
          <g>
            <g>
              <path
                d="m393.469 475.568h-274.938c-5.523 0-10-4.477-10-10v-364.136c0-5.523 4.477-10 10-10h274.938c5.523 0 10 4.477 10 10v364.136c0 5.523-4.477 10-10 10z"
                fill="#2626bc"
                opacity=".1"
              />
              <path
                d="m393.469 450.568h-274.938c-5.523 0-10-4.477-10-10v-364.136c0-5.523 4.477-10 10-10h274.938c5.523 0 10 4.477 10 10v364.136c0 5.523-4.477 10-10 10z"
                fill="#6583fe"
              />
              <path
                d="m367.099 413.79h-222.199c-4.849 0-8.78-3.931-8.78-8.78v-329.798c0-4.849 3.931-8.78 8.78-8.78h222.199c4.849 0 8.78 3.931 8.78 8.78v329.798c.001 4.849-3.93 8.78-8.78 8.78z"
                fill="#fff"
              />
              <g fill="#00c">
                <path d="m393.469 455.568h-274.938c-8.271 0-15-6.729-15-15v-364.136c0-8.271 6.729-15 15-15h43.428c2.761 0 5 2.239 5 5s-2.239 5-5 5h-43.428c-2.757 0-5 2.243-5 5v364.136c0 2.757 2.243 5 5 5h274.938c2.757 0 5-2.243 5-5v-99.434c0-2.761 2.238-5 5-5s5 2.239 5 5v99.434c0 8.271-6.729 15-15 15z" />
                <path d="m314.633 71.432h-93.165c-2.761 0-5-2.239-5-5s2.239-5 5-5h93.165c2.762 0 5 2.239 5 5s-2.238 5-5 5z" />
                <path d="m403.469 317.034c-2.762 0-5-2.239-5-5v-235.602c0-2.757-2.243-5-5-5h-41.041c-2.762 0-5-2.239-5-5s2.238-5 5-5h41.041c8.271 0 15 6.729 15 15v235.602c0 2.761-2.239 5-5 5z" />
              </g>
              <g>
                <g>
                  <path
                    d="m187.367 97.117h-20.408c-2.761 0-5-2.239-5-5v-36.598c0-2.762 2.239-5 5-5h20.408c2.761 0 5 2.238 5 5v36.597c0 2.762-2.238 5.001-5 5.001z"
                    fill="#fff"
                  />
                  <path
                    d="m187.368 102.117h-20.409c-5.514 0-10-4.486-10-10v-36.598c0-5.514 4.486-10 10-10h20.409c5.514 0 10 4.486 10 10v36.597c0 5.515-4.486 10.001-10 10.001zm-20.409-46.598v36.597h20.415l-.006-36.597z"
                    fill="#00c"
                  />
                </g>
                <g>
                  <path
                    d="m345.041 97.117h-20.408c-2.761 0-5-2.239-5-5v-36.598c0-2.762 2.239-5 5-5h20.408c2.762 0 5 2.238 5 5v36.597c0 2.762-2.239 5.001-5 5.001z"
                    fill="#fff"
                  />
                  <path
                    d="m345.041 102.117h-20.408c-5.514 0-10-4.486-10-10v-36.598c0-5.514 4.486-10 10-10h20.408c5.514 0 10 4.486 10 10v36.597c0 5.515-4.486 10.001-10 10.001zm-20.408-46.598v36.597h20.415l-.007-36.597z"
                    fill="#00c"
                  />
                </g>
              </g>
            </g>
            <g>
              <ellipse cx="199.262" cy="155.446" fill="#6583fe" rx="30.718" ry="30.718" transform="matrix(.886 -.464 .464 .886 -49.369 110.154)" />
              <path
                d="m213.556 148.974h-7.822v-7.822c0-2.441-1.979-4.42-4.42-4.42h-4.103c-2.441 0-4.42 1.979-4.42 4.42v7.822h-7.822c-2.441 0-4.42 1.979-4.42 4.42v4.103c0 2.441 1.979 4.42 4.42 4.42h7.822v7.822c0 2.441 1.979 4.42 4.42 4.42h4.103c2.441 0 4.42-1.979 4.42-4.42v-7.822h7.822c2.441 0 4.42-1.979 4.42-4.42v-4.103c0-2.441-1.979-4.42-4.42-4.42z"
                fill="#fff"
              />
            </g>
            <g>
              <path d="m344.311 163.358h-91.52c-2.762 0-5-2.239-5-5s2.238-5 5-5h91.52c2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z" fill="#00c" />
            </g>
            <g>
              <path d="m344.311 181.529h-91.52c-2.762 0-5-2.239-5-5s2.238-5 5-5h91.52c2.762 0 5 2.239 5 5s-2.238 5-5 5z" fill="#00c" />
            </g>
            <g>
              <path d="m279.311 137.867h-26.52c-2.762 0-5-2.239-5-5s2.238-5 5-5h26.52c2.762 0 5 2.239 5 5s-2.238 5-5 5z" fill="#00c" />
            </g>
            <path
              d="m341.383 258.5h-170.767c-2.761 0-5-2.239-5-5v-35.096c0-2.762 2.239-5 5-5h170.767c2.761 0 5 2.238 5 5v35.096c0 2.761-2.238 5-5 5z"
              fill="#fff"
            />
            <path d="m211.998 213.404h-41.381c-2.761 0-5 2.239-5 5v35.096c0 2.761 2.239 5 5 5h41.381z" fill="#6583fe" />
            <g>
              <path d="m319.311 240.952h-86.52c-2.762 0-5-2.239-5-5s2.238-5 5-5h86.52c2.762 0 5 2.239 5 5s-2.238 5-5 5z" fill="#00c" />
            </g>
            <g>
              <g>
                <path d="m301.76 308.546h-95.18v10h95.18c2.762 0 5-2.239 5-5s-2.239-5-5-5z" fill="#00c" />
              </g>
              <g>
                <path d="m246.76 288.596h-40.18v10h40.18c2.762 0 5-2.239 5-5s-2.239-5-5-5z" fill="#00c" />
              </g>
              <path
                d="m210.876 293.596c0-9.161-7.453-16.613-16.613-16.613-9.161 0-16.613 7.453-16.613 16.613 0 4.518 1.818 8.615 4.754 11.613-2.936 2.998-4.754 7.095-4.754 11.613 0 2.761 2.239 5 5 5s5-2.239 5-5c0-3.647 2.967-6.613 6.613-6.613 3.647 0 6.613 2.967 6.613 6.613 0 2.761 2.239 5 5 5s5-2.239 5-5c0-4.518-1.818-8.615-4.755-11.613 2.936-2.998 4.755-7.095 4.755-11.613zm-16.614-6.614c3.647 0 6.613 2.967 6.613 6.613s-2.967 6.613-6.613 6.613-6.613-2.967-6.613-6.613 2.967-6.613 6.613-6.613z"
                fill="#00c"
              />
              <g>
                <g>
                  <g>
                    <path d="m330.336 352.333h-129.77c-2.762 0-5-2.239-5-5s2.238-5 5-5h129.77c2.762 0 5 2.239 5 5s-2.238 5-5 5z" fill="#00c" />
                  </g>
                  <g>
                    <path d="m181.664 352.391c2.761 0 5-2.239 5-5v-.115c0-2.761-2.239-5-5-5s-5 2.239-5 5v.115c0 2.761 2.238 5 5 5z" fill="#00c" />
                  </g>
                  <g>
                    <path d="m342.434 298.653c2.761 0 5-2.239 5-5v-.115c0-2.761-2.239-5-5-5s-5 2.239-5 5v.115c0 2.762 2.239 5 5 5z" fill="#00c" />
                  </g>
                  <g>
                    <path d="m326.667 298.653c2.761 0 5-2.239 5-5v-.115c0-2.761-2.239-5-5-5s-5 2.239-5 5v.115c0 2.762 2.239 5 5 5z" fill="#00c" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="m330.336 371.854h-129.77c-2.762 0-5-2.239-5-5s2.238-5 5-5h129.77c2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z" fill="#00c" />
                  </g>
                  <g>
                    <path d="m181.664 371.912c2.761 0 5-2.239 5-5v-.115c0-2.761-2.239-5-5-5s-5 2.239-5 5v.115c0 2.761 2.238 5 5 5z" fill="#00c" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="m330.336 391.376h-129.77c-2.762 0-5-2.239-5-5s2.238-5 5-5h129.77c2.762 0 5 2.239 5 5s-2.238 5-5 5z" fill="#00c" />
                  </g>
                  <g>
                    <path d="m181.664 391.434c2.761 0 5-2.239 5-5v-.115c0-2.761-2.239-5-5-5s-5 2.239-5 5v.115c0 2.761 2.238 5 5 5z" fill="#00c" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g fill="#6583fe">
            <g>
              <g>
                <path d="m63.97 217.135c-2.761 0-5-2.239-5-5v-.15c0-2.761 2.239-5 5-5s5 2.239 5 5v.15c0 2.761-2.239 5-5 5z" />
                <path d="m63.97 244.919c-2.761 0-5-2.239-5-5v-.15c0-2.761 2.239-5 5-5s5 2.239 5 5v.15c0 2.762-2.239 5-5 5z" />
              </g>
              <g>
                <path d="m51.999 224.044c-.846 0-1.702-.215-2.488-.667l-.13-.075c-2.394-1.376-3.218-4.433-1.841-6.827s4.432-3.218 6.827-1.841l.13.075c2.394 1.376 3.218 4.433 1.841 6.827-.924 1.608-2.608 2.508-4.339 2.508z" />
                <path d="m76.061 237.936c-.843 0-1.697-.213-2.481-.663l-.13-.075c-2.396-1.373-3.225-4.428-1.853-6.824 1.373-2.395 4.429-3.227 6.824-1.852l.13.075c2.396 1.373 3.225 4.428 1.853 6.824-.923 1.612-2.609 2.515-4.343 2.515z" />
              </g>
              <g>
                <path d="m51.879 237.936c-1.731 0-3.414-.9-4.339-2.508-1.377-2.394-.553-5.451 1.841-6.827l.13-.075c2.395-1.378 5.451-.552 6.827 1.841 1.377 2.394.553 5.451-1.841 6.827l-.13.075c-.785.453-1.642.667-2.488.667z" />
                <path d="m75.941 224.044c-1.734 0-3.419-.903-4.343-2.515-1.373-2.396-.543-5.451 1.853-6.824l.13-.075c2.396-1.374 5.451-.544 6.824 1.853 1.373 2.396.543 5.451-1.853 6.824l-.13.075c-.784.449-1.638.662-2.481.662z" />
              </g>
            </g>
            <path d="m76.066 297.374c-2.761 0-5-2.239-5-5 0-3.492-2.841-6.333-6.334-6.333-2.761 0-5-2.239-5-5s2.239-5 5-5c3.492 0 6.334-2.841 6.334-6.334 0-2.761 2.239-5 5-5s5 2.239 5 5c0 3.492 2.841 6.334 6.333 6.334 2.761 0 5 2.239 5 5s-2.239 5-5 5c-3.492 0-6.333 2.841-6.333 6.333 0 2.762-2.238 5-5 5z" />
            <path d="m443.889 184.794c-2.762 0-5-2.239-5-5 0-3.492-2.841-6.334-6.333-6.334-2.762 0-5-2.239-5-5s2.238-5 5-5c3.492 0 6.333-2.841 6.333-6.333 0-2.761 2.238-5 5-5s5 2.239 5 5c0 3.492 2.841 6.333 6.333 6.333 2.762 0 5 2.239 5 5s-2.238 5-5 5c-3.492 0-6.333 2.841-6.333 6.334 0 2.761-2.239 5-5 5z" />
            <g>
              <g>
                <path d="m443.889 324.469c-2.762 0-5-2.239-5-5v-.15c0-2.761 2.238-5 5-5s5 2.239 5 5v.15c0 2.761-2.239 5-5 5z" />
                <path d="m443.889 352.253c-2.762 0-5-2.239-5-5v-.15c0-2.761 2.238-5 5-5s5 2.239 5 5v.15c0 2.762-2.239 5-5 5z" />
              </g>
              <g>
                <path d="m431.918 331.378c-.846 0-1.702-.215-2.488-.667l-.13-.075c-2.393-1.376-3.218-4.433-1.841-6.827 1.376-2.394 4.432-3.218 6.827-1.841l.13.075c2.394 1.376 3.218 4.433 1.841 6.827-.925 1.608-2.608 2.508-4.339 2.508z" />
                <path d="m455.979 345.27c-.846 0-1.702-.215-2.488-.667l-.13-.075c-2.394-1.376-3.218-4.433-1.841-6.827 1.377-2.393 4.432-3.218 6.827-1.841l.13.075c2.394 1.376 3.218 4.433 1.841 6.827-.924 1.609-2.607 2.508-4.339 2.508z" />
              </g>
              <g>
                <path d="m431.798 345.27c-1.732 0-3.414-.9-4.339-2.508-1.377-2.394-.553-5.451 1.841-6.827l.13-.075c2.396-1.377 5.451-.552 6.827 1.841 1.377 2.394.553 5.451-1.841 6.827l-.13.075c-.786.453-1.642.667-2.488.667z" />
                <path d="m455.859 331.378c-1.731 0-3.414-.9-4.339-2.508-1.377-2.394-.553-5.451 1.841-6.827l.13-.075c2.397-1.376 5.451-.552 6.827 1.841 1.377 2.394.553 5.451-1.841 6.827l-.13.075c-.786.453-1.642.667-2.488.667z" />
              </g>
            </g>
            <path d="m447.654 402.709c-2.762 0-5-2.239-5-5 0-3.492-2.841-6.333-6.333-6.333-2.762 0-5-2.239-5-5s2.238-5 5-5c3.492 0 6.333-2.841 6.333-6.333 0-2.761 2.238-5 5-5s5 2.239 5 5c0 3.492 2.841 6.333 6.333 6.333 2.762 0 5 2.239 5 5s-2.238 5-5 5c-3.492 0-6.333 2.841-6.333 6.333 0 2.762-2.238 5-5 5z" />
          </g>
        </g>
        <path
          d="m341.384 263.5h-170.767c-5.514 0-10-4.486-10-10v-35.096c0-5.514 4.486-10 10-10h170.767c5.514 0 10 4.486 10 10v35.096c0 5.514-4.487 10-10 10zm-170.767-45.096v35.096h170.773l-.006-35.095h-170.767z"
          fill="#00c"
        />
      </g>
    </svg>
  );
}

export default Report;

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { TextFieldProps, TextField, BaseTextFieldProps, InputBaseProps } from "@material-ui/core";
import React, { useRef } from "react";

export type TextInputProps = BaseTextFieldProps &
  TextFieldProps & {
    borderNone?: boolean;
    minWidth?: string | number;
  };

const useStyles = makeStyles(
  createStyles({
    root: (props) =>
      props.borderNone
        ? {
            // Make the default text field the same height as a button
            // bc it looks bad if not.
            borderRadius: 0,
            backgroundColor: "transparent",
            minWidth: props.minWidth,
            paddingLeft: 0,
            paddingRight: 0,
            "&.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }
        : { minWidth: props.minWidth },
    input: (props: any) => (props.borderNone ? { paddingLeft: 0, paddingRight: 0 } : {}),
    notchedOutline: {
      display: (props: any) => (props.borderNone ? "none" : "block"),
    },
    inputMarginDense: {
      minHeight: 20,
      paddingTop: 10,
      paddingBottom: 10,
    },
  })
);

const TextInput = ({ borderNone, minWidth, ...rest }: TextInputProps) => {
  const classes = useStyles({ type: rest.type, borderNone, minWidth: minWidth != null ? minWidth : 200 });

  const defaultProps: TextFieldProps = {
    size: "small",
    variant: "outlined",
    autoComplete: "off",
    ...rest,
  };

  return (
    <TextField
      InputProps={
        {
          classes,
        } as Partial<InputBaseProps>
      }
      {...defaultProps}
    />
  );
};

export default TextInput;

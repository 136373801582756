export enum NotificationTimeId {
  "Immediate" = 1,
  "1:00am" = 3,
  "2:00am" = 4,
  "3:00am" = 5,
  "4:00am" = 6,
  "5:00am" = 7,
  "6:00am" = 8,
  "7:00am" = 9,
  "8:00am" = 10,
  "9:00am" = 11,
  "10:00am" = 12,
  "11:00am" = 13,
  "12:00pm" = 14,
  "1:00pm" = 15,
  "2:00pm" = 16,
  "3:00pm" = 17,
  "4:00pm" = 18,
  "5:00pm" = 19,
  "6:00pm" = 20,
  "7:00pm" = 21,
  "8:00pm" = 22,
  "9:00pm" = 23,
  "10:00pm" = 24,
  "11:00pm" = 25,
}

export enum NotificationId {
  EquipmentLogCreated = 1,
  EquipmentLogEdited = 2,
  EquipmentLogStatusChange = 3,
  EquipmentLogDeleted = 4,
  EquipmentLogFileAdded = 5,
  EquipmentLogFileDeleted = 6,
  EquipmentLogNoteAdded = 7,
  EquipmentLogNoteDeleted = 8,
  EquipmentLogScheduleItemAssociated = 9,
  EquipmentLogScheduleItemDisassociated = 10,
  TestRunCreated = 11,
  TestRunApproved = 12,
  TestRunSignedoff = 13,
  TestRunDateChange = 14,
  TestRunExcluded = 15,
  TestRunUserChange = 16,
  TestRunFileAdded = 17,
  TestRunFileDeleted = 18,
  TestRunNoteAdded = 19,
  TestRunNoteDeleted = 20,
  TestsDue = 21,
  TestsOverdue = 22,
  SetupChange = 23,
  ScheduleItemCreated = 24,
  ScheduleItemEdited = 25,
  ScheduleItemDue = 27,
}

export interface Notification {
  id: NotificationId;
  action: string;
  createdAt: string;
  daysPrior: boolean;
  emailSubject: string;
  frequencyDaily: boolean;
  frequencyImmediate: boolean;
  frequencyMonthly: boolean;
  frequencyWeekly: boolean;
  table: string;
  updatedAt: string;
  filters?: NotificationFilter[];
}

export interface NotificationFilter {
  id: 7;
  allowAll: boolean;
  label: string;
  labelField: string;
  notificationId: number;
  tableName: string;
  valueField: string;
}

export interface NotificationText {
  title: string;
  schedule: string;
}

import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CardHeader from "../CardHeader/CardHeader";
import SpinningLoader from "../SpinningLoader/SpinningLoader";
import { CircularProgressProps } from "@material-ui/core/CircularProgress";

export interface PageLoaderProps extends CircularProgressProps {
  title?: string;
  subheader?: string;
  position?: "fixed" | "absolute" | "fixed-sidebar";
  minHeight?: string | number;
  opacity?: string;
  /** Set total items to load to enable auto increment */
  total?: number;
  /** Set auto increment amount. Default is 1 */
  autoIncrement?: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    page: (props) => ({
      //opacity: (props: PageLoaderProps) => props.opacity || "1",
      position: "relative",
      background: "transparent",
      width: "100%",
      height: "100%",
      display: "flex",
      minHeight: props?.minHeight || 200,
      overflow: "hidden",
      "&.fixed": {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: theme.zIndex.modal,
      },
      "&.fixed-sidebar": {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        [theme.breakpoints.up(theme.sidebar.breakpointUp)]: {
          left: theme.sidebar.fixedWidth,
          width: "auto",
          height: "auto",
        },
        zIndex: theme.zIndex.modal,
      },
      "&.absolute": {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        width: "auto",
        height: "auto",
      },
    }),
    heading: {
      marginTop: theme.spacing(1),
    },
    container: {
      position: "relative",
      //width: "100%",
      //height: "100%",
      flex: "1",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    backdrop: (props: PageLoaderProps) => ({
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      background: theme.palette.background.default,
      opacity: props.opacity || "1",
    }),
  })
);

function PageLoader(props: PageLoaderProps) {
  const { title, subheader, opacity, position, total, autoIncrement, value, minHeight, ...rest } = props;

  const classes = useStyles(props);

  return (
    <div className={clsx(classes.page, position && position)}>
      <div className={classes.container}>
        <div className={classes.backdrop}></div>
        {value != null ? (
          <SpinningLoader {...rest} variant="determinate" total={total} autoIncrement={autoIncrement} value={value} />
        ) : (
          <SpinningLoader {...rest} />
        )}
        {(title || subheader) && (
          <div className={classes.heading}>
            <CardHeader title={title} subheader={subheader} />
          </div>
        )}
      </div>
    </div>
  );
}

export default PageLoader;

import Popper, { PopperProps } from "@material-ui/core/Popper";

function AutocompleteInputPopper(props: PopperProps) {
  const { style, ...popperProps } = props;

  const defaultPopperProps: PopperProps = {
    ...popperProps,
    style: { ...style, minWidth: "fit-content" },
  };

  return <Popper {...defaultPopperProps} />;
}

export default AutocompleteInputPopper;

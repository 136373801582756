import { useCallback } from "react";
import { HttpClientRequestConfig, useHttpClientContext } from "../context/http-client.context";
import environment from "../env";
import { PagedList } from "../models/paged-list.model";
import { User, UserNotification, UserNotificationItem, UserNotificationQuerySearch, UserType } from "../models/user.model";

export interface UserService {
  /** Get the current logged in user if no id or a specific user by id */
  getUser(id?: number): Promise<User>;
  updateUser(user: User): Promise<User>;
  getUserTypes(): Promise<UserType[]>;
  getUsersForMachineTestResult(
    orgId: number,
    criteria: {
      facilityId: number;
      frequency: number;
      machineType: string;
    }
  ): Promise<PagedList<{ id: number; name: string }>>;
  getNotifications(criteria?: UserNotificationQuerySearch): Promise<PagedList<UserNotification>>;
  addNotification(notif: UserNotification): Promise<UserNotification>;
  updateNotification(notif: UserNotification): Promise<UserNotification>;
  deleteNotification(notif: UserNotification): Promise<void>;
  deleteNotificationItem(notif: UserNotificationItem): Promise<void>;
}

export interface UserServiceOptions {}

function useUserService(options?: UserServiceOptions): UserService {
  const { httpClient } = useHttpClientContext();

  const getUserTypes = useCallback(() => {
    return httpClient.get(`users/types`, { baseURL: environment.newApiUrl }).then((response) => {
      const userTypes = response.data as UserType[];
      return userTypes;
    });
  }, [httpClient]);

  const getUser = useCallback(
    (id?: number) => {
      return httpClient.get(`users/${id || "me"}`, { baseURL: environment.newApiUrl }).then((response) => {
        const user = response.data as User;
        return user;
      });
    },
    [httpClient]
  );

  const updateUser = useCallback(
    (user: User) => {
      const endpoint = `users/${user.id}`;
      return httpClient.put(endpoint, user, { baseURL: environment.newApiUrl }).then((response) => {
        const item = response.data as User;

        return item;
      });
    },
    [httpClient]
  );

  const getUsersForMachineTestResult = useCallback(
    (orgId: number, criteria: { facilityId: number; frequency: number; machineType: string }) => {
      const config: HttpClientRequestConfig = {
        params: criteria,
        baseURL: environment.newApiUrl,
      };
      return httpClient.get(`v2/orgs/${orgId}/FacilityUser/can-do`, config).then((response) => {
        const items = response.data as { id: number; name: string }[];
        const total = response.data.length;

        return { items, total };
      });
    },
    [httpClient]
  );

  const getNotifications = useCallback(
    async (criteria?: UserNotificationQuerySearch) => {
      const { orgId, ...queryParams } = criteria || {};

      let params: UserNotificationQuerySearch = { ...queryParams };

      if (orgId) {
        params = { ...params, orgId };
      }

      const response = await httpClient.get<PagedList<UserNotification>>(`users/me/notifications`, { params, baseURL: environment.newApiUrl });
      return response.data;
    },
    [httpClient]
  );

  const addNotification = useCallback(
    (notif: UserNotification) => {
      return httpClient.post("account_notification", { rec: notif }).then((response) => {
        const item = response.data.root[0] as UserNotification;

        return item;
      });
    },
    [httpClient]
  );

  const updateNotification = useCallback(
    (notif: UserNotification) => {
      return httpClient.put(`account_notification`, { rec: notif }).then((response) => {
        const item = response.data.root[0] as UserNotification;

        return item;
      });
    },
    [httpClient]
  );

  const deleteNotification = useCallback(
    (notif: UserNotification) => {
      return httpClient.delete(`v2/user-notifications/${notif.id}`, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  const deleteNotificationItem = useCallback(
    (notifItem: UserNotificationItem) => {
      return httpClient.delete(`v2/user-notifications/myitems/${notifItem.id}`, { baseURL: environment.newApiUrl }).then((response) => {
        return;
      });
    },
    [httpClient]
  );

  return {
    getUser,
    updateUser,
    getUsersForMachineTestResult,
    getNotifications,
    addNotification,
    updateNotification,
    deleteNotification,
    deleteNotificationItem,
    getUserTypes,
  };
}

export default useUserService;

import { memo, MouseEventHandler, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "./Dialog";
import { Grid } from "@material-ui/core";
import Button from "../Button/Button";

export interface ConfirmDialogProps extends DialogProps {
  confirmText: ReactNode;
  confirmText2?: ReactNode;
  cancelText: ReactNode;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onConfirm2?: MouseEventHandler<HTMLButtonElement>;
  confirmType?: "button" | "submit";
  confirmFormId?: string;
  body?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.modal,
  },
}));

function ConfirmDialog({ body, confirmText, confirmText2, confirmType, confirmFormId, cancelText, onConfirm, onConfirm2, onCancel, ...otherDialogProps }: ConfirmDialogProps) {
  const classes = useStyles();

  const defaultProps: DialogProps = {
    ...otherDialogProps,
  };

  return (
    <Dialog aria-describedby="confirm-dialog-description" className={classes.root} style={{ zIndex: 100000 }} {...defaultProps}>
      <Grid container spacing={2}>
        {body && (
          <Grid item xs={12} id="confirm-dialog-description">
            {body}
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ justifyContent: "end", columnGap: "10px" }}>
            {cancelText && (
              <Button type="button" variant="outlined" onClick={onCancel} color="primary">
                {cancelText}
              </Button>
            )}
            <Button type={confirmType || "button"} form={confirmFormId} onClick={onConfirm} color="primary">
              {confirmText}
            </Button>
            {confirmText2 && (
              <Button type={confirmType || "button"} form={confirmFormId} onClick={onConfirm2} color="primary">
                {confirmText2}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default memo(ConfirmDialog);

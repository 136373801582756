import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { ComputerOutlined } from "@material-ui/icons";

export interface MachineIconProps extends SvgIconProps {}

function MachineIcon(props: MachineIconProps) {
  const { ...rest } = props;

  const iconProps: SvgIconProps = {
    ...rest,
  };

  return <ComputerOutlined {...iconProps} />;
}

export default MachineIcon;

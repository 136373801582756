import React, { useState, useMemo, useCallback, useRef } from "react";
import WidgetCard, { WidgetCardProps } from "../../../components/WidgetCard/WidgetCard";
import { Grid, Link, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { HelpOutline, MoreVert, RemoveCircleOutline } from "@material-ui/icons";
import IconButton from "../../../components/IconButton/IconButton";
import CircularProgressChart from "../../../components/CircularProgressChart/CircularProgressChart";
import { ScoreRating } from "../../../../models/score.model";
import { enumToLookup } from "../../../../models/lookup.model";
import { useUserContext } from "../../../../context/user.context";
import RetryWidget from "../../../components/ImageCaptions/RetryWidget";
import { useQuery } from "react-query";
import useFacilityService from "../../../../hooks/facility-service.hook";

const ratings = enumToLookup<number>(ScoreRating);

const useStyles = makeStyles((theme) => ({
  scoreChart: {
    width: 200,
    height: 200,
  },
  scoreContainer: {
    position: "relative",
    width: 200,
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  scoreContent: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  scoreValue: {
    fontSize: "3rem",
    lineHeight: 1,
  },
}));

export interface ScoreWidgetProps extends WidgetCardProps {
  onRemoveWidget?: () => void;
}

function ScoreWidget(props: ScoreWidgetProps) {

  const { org, facility, machine } = useUserContext();
  const [retry, setRetry] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const { getFacilityComplianceScore } = useFacilityService();

  const { onRemoveWidget, ...rest } = props;

  const defaultWidgetCardProps = {
    ...rest,
  };

  const {
    isLoading: scoreLoading,
    error: scoreError,
    data: scoreValue,
    refetch,
  } = useQuery(["home/widget/score/compliance", org, facility, machine, retry], {
    queryFn: async () => {
      const complianceScore = await getFacilityComplianceScore(org!.id, facility?.id, machine?.id);
      return complianceScore;
    },
    initialData: 0,
    retry: (count, { status }) => status !== 404,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const handleRetry = useCallback(() => {
    setRetry((prev) => prev + 1);
  }, []);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const rating = useMemo(() => ratings.sort((a, b) => a.id - b.id).find((r) => r.id <= (!scoreValue ? 0 : scoreValue) && r.id + 10 >= (!scoreValue ? 0 : scoreValue)), [scoreValue])?.label || "Newbie";
  const widgetMenuButtonRef = useRef(null);

  return (
    <WidgetCard
      id="score-widget"
      header="12 Month Compliance Score"
      loading={scoreLoading}
      {...defaultWidgetCardProps}
      style={{ "height": "100%" }}
      actions={
        <Grid container alignItems="center">
          <Grid item>
            <Link
              component={IconButton}
              color="textSecondary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://zapit-user-manual.s3.amazonaws.com/ComplianceScore.pdf"
              aria-label="score help"
            >
              <HelpOutline />
            </Link>
          </Grid>

          {onRemoveWidget != null && (
            <Grid item ref={widgetMenuButtonRef}>
              <IconButton id="score-widget-menu-button" aria-label="score widget menu" aria-haspopup="true" onClick={handleOpenMenu}>
                <MoreVert />
              </IconButton>
              <Menu
                id="score-menu"
                keepMounted
                anchorEl={widgetMenuButtonRef.current}
                open={Boolean(menuAnchorEl?.id === "score-widget-menu-button")}
                onClose={handleCloseMenu}
                disableScrollLock={true}
                container={widgetMenuButtonRef.current}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem button onClick={onRemoveWidget}>
                  <ListItemIcon style={{ marginLeft: "-8px", marginRight: "8px" }}>
                    <RemoveCircleOutline />
                  </ListItemIcon>
                  <ListItemText primary="Remove widget" />
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>
      }
    >
      {scoreError ? (
        <RetryWidget onRetry={handleRetry} />
      ) : (
        <Grid container justify="center">
          <Grid item>
            <div className={classes.scoreContainer}>
              <CircularProgressChart
                className={classes.scoreChart}
                value={scoreValue || 0}
                strokeWidth={8}
                circleRatio={0.75}
                rotation={1 / 2 + 1 / 8}
                transitionDuration="1s"
                pathColor="primary"
              />

              <div className={classes.scoreContent}>
                <div id="score-widget-score" className={classes.scoreValue}>
                  {scoreValue}%
                </div>
                <div id="score-widget-rating">
                  <Typography color="textSecondary">{rating}</Typography>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </WidgetCard>
  );
}

export default React.memo(ScoreWidget);

import { QuerySearchParams, getQuerySearchParams } from "./query-search-params.model";

export interface MachineTestResultSearchParams extends QuerySearchParams {
  testVersionId?: number;
  hideInactiveMacs?: any;
  hasSelector?: boolean;
  notexcluded?: boolean; // 1 or 0
  excluded?: boolean; // 1 or 0
  notsignedoff?: boolean; // 1 or 0
  signedoff?: boolean; // 1 or 0
  approved?: boolean; // 1 or 0
  notapproved?: boolean; // 1 or 0
  pass?: boolean; // 1 or 0
  fail?: boolean; // 1 or 0
  institutionName?: string;
  includeData?: number;
  testId?: number;
}

export function getMachineTestResultSearchParams<T extends MachineTestResultSearchParams>(searchParams: T, removeFalseParams = false): any {
  const { testVersionId, includeData, institutionName, ...rest } = searchParams;

  // Convert base params
  const baseParams = getQuerySearchParams(rest, removeFalseParams);

  let criteria = {
    ...baseParams,
    test_version_id: testVersionId,
    include_data: includeData,
  };

  if (institutionName) {
    criteria = { ...criteria, institution_name: institutionName };
  }

  return criteria;
}

import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import { CssBaseline } from "@material-ui/core";

import { AppContextProvider } from "./context/app.context";
import { AuthContextProvider } from "./context/auth.context";
import { UserContextProvider } from "./context/user.context";
import { HttpClientContextProvider } from "./context/http-client.context";

import { LookupContextProvider } from "./context/lookup.context";
import environment, { EnvId } from "./env";
import { GtmContextProvider } from "./context/gtm.context";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { QueryClient, QueryClientProvider } from "react-query";
import { StorageContextProvider } from "./context/storage.context";
import { ThemeContextProvider } from "./context/theme.context";
import mime from "mime";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

// TODO: Add <React.StrictMode> back when mui v5 is released
ReactDOM.render(
  <StorageContextProvider version={environment.version}>
    <GtmContextProvider {...(environment.gtmConfig || {})}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthContextProvider>
            <HttpClientContextProvider>
              <UserContextProvider>
                <LookupContextProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ThemeContextProvider>
                      <CssBaseline />
                      <AppContextProvider />
                    </ThemeContextProvider>
                  </MuiPickersUtilsProvider>
                </LookupContextProvider>
              </UserContextProvider>
            </HttpClientContextProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </GtmContextProvider>
  </StorageContextProvider>,
  document.getElementById("root")
);

if (!mime.getExtension("application/dicom")) {
  mime.define({
    "application/dicom": ["dcm"],
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (environment.id === EnvId.Local) {
  serviceWorker.unregister();
}

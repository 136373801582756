import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import createMuiTheme, { Theme, ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import createPalette, { PaletteColorOptions, PaletteColor, Palette, PaletteOptions, ExtendedPalette } from "@material-ui/core/styles/createPalette";

export type ElevationPropType = "main" | "low" | "high";

declare module "@material-ui/core/styles/createPalette" {
  interface ExtendedPalette {
    default: PaletteColor;
  }

  interface ExtendedPaletteOptions {
    default: PaletteColorOptions;
  }

  // Extending the interface to hold extra variables
  interface Palette extends ExtendedPalette { }

  // allow configuration using `createPalette`
  interface PaletteOptions extends Partial<ExtendedPaletteOptions> { }
}

declare module "@material-ui/core/styles/createMuiTheme" {
  interface ExtendedTheme {
    elevation: {
      main: string;
      low: string;
      high: string;
    };
    shadowColor: string;
    header: {
      height: number;
    };
    footer: {
      height: number;
    };
    sidebar: {
      logoUrl: string;
      width: number;
      fixedWidth: number;
      breakpointUp: Breakpoint;
      background?: string;
      color?: string;
      titleColor?: string;
    };
  }

  // Extending the interface to hold extra variables
  interface Theme extends ExtendedTheme { }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions extends Partial<ExtendedTheme> { }
}

const getTheme = (options: ThemeOptions): Theme => {
  const _theme = createMuiTheme(options);

  return {
    ..._theme,
    // Extended theme
    // Need to override here so we can set the color and titleColor to match theme colors
    sidebar: {
      ..._theme.sidebar,
      color: _theme.palette.text.hint,
      titleColor: _theme.palette.text.primary,
    },
    props: {},
    // Material UI theme overrides
    overrides: {
      MuiBackdrop: {
        root: {
          //backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0,0,30,0.4)",
        },
      },
      /*MuiSwitch: {
        root: {
          padding: _theme.spacing(1),
        },
      },*/
      MuiToolbar: {
        gutters: {
          [_theme.breakpoints.down("sm")]: {
            paddingLeft: _theme.spacing(2),
            paddingRight: _theme.spacing(2),
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: "unset",
          marginRight: "unset",
        },
        labelPlacementStart: {
          marginLeft: "unset",
          marginRight: "unset",
        },
      },
      MuiIconButton: {
        root: {
          padding: _theme.spacing(1),
        },
        edgeStart: {
          marginLeft: -_theme.spacing(1),
        },
        edgeEnd: {
          marginRight: -_theme.spacing(1),
        },
      },
      MuiList: {
        root: {
          color: "inherit",
        },
      },
      MuiListSubheader: {
        root: {
          color: "#8391a2",
          //lineHeight: "inherit",
          textTransform: "uppercase",
          fontSize: 11,
          fontWeight: 700,
          letterSpacing: 0.5,
        },
      },
      MuiListItemText: {
        primary: {
          //fontWeight: theme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: "inherit",
          marginRight: _theme.spacing(2),
          minWidth: "auto",
          "& svg": {
            fontSize: 20,
          },
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          // Offset parent padding of 14px, to account for
          // icon button padding from svg of 8px
          marginRight: "-14px", // "-6px",
        },
        positionStart: {
          marginLeft: "-14px",
        },
      },
    },
  };
};

const sharedTheme = {
  palette: {
    primary: {
      light: "#FF8E53",
      main: "#D74F2C",
      dark: "#B12906",
    },
    secondary: {
      light: "#d2deff",
      main: "#6583fe",
      dark: "#2626bc",
    },
    success: {
      light: "rgb(75, 191, 107)",
      main: "rgb(75, 191, 107)",
      dark: "rgb(75, 191, 107)",
    },
    default: {
      light: "#e0e0e0",
      main: "#e0e0e0",
      dark: "#616161",
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  // Extended theme
  elevation: {
    main: "0px 6px 15px -4px",
    low: "0px 4px 10px -4px",
    high: "0px 8px 20px -4px",
  },
  header: {
    height: 72,
  },
  footer: {
    height: 20,
  },
  sidebar: {
    width: 256,
    fixedWidth: 256,
    breakpointUp: "md" as Breakpoint,
  },
};

const lightTheme = getTheme({
  ...sharedTheme,
  palette: {
    ...sharedTheme.palette,
    default: {
      ...sharedTheme.palette.default,
      main: sharedTheme.palette.default.light,
    },
    type: "light",
    background: {
      default: "#fafafa",
    },
  },
  // Extended theme
  shadowColor: "rgba(0, 0, 0, 0.2)",
  sidebar: {
    ...sharedTheme.sidebar,
    logoUrl: "/logo.svg",
  },
});

const darkTheme = getTheme({
  ...sharedTheme,
  palette: {
    ...sharedTheme.palette,
    default: {
      ...sharedTheme.palette.default,
      main: sharedTheme.palette.default.dark,
    },
    type: "dark",
    background: {
      default: "#1e1e2f",
      paper: "#27293d",
    },
  },
  // Extended theme
  shadowColor: "rgba(0, 0, 0, 0.2)",
  sidebar: {
    ...sharedTheme.sidebar,
    logoUrl: "/logo-light.svg",
  },
});

export { lightTheme, darkTheme };

import { AnalyzeUpload, AnalyzeUploadList } from "./analyze-upload.model";
import { MachineTestResult } from "./machine-test-result.model";
import {
  MachineSubtest,
  MachineSubtestValue,
  MachineTest,
  MachineTestSetup,
  MachineTypeSubtest,
  MachineTypeSubtestField,
  MachineTypeTest,
} from "./machine-test.model";
import { Machine } from "./machine.model";

export type TestRunValue = any;

export enum TestRunStatus {
  incomplete = "incomplete",
  notRun = "not_run",
  pass = "pass",
  fail = "fail",
}

export interface TestRunCriteria {
  machineType: string;
  setup: MachineTestSetup;
  test: {
    name: string;
    versions: MachineTypeSubtest[];
  };
  subtest?: {
    ordinal: number;
    subtestValue?: MachineSubtestValue;
    subtestValueId: number;
  };
  lastTestRun?: MachineTestResult;
  analyzeUpload?: AnalyzeUpload;
  lastTG51TestRun?: MachineTestResult;
}

export interface TestRun extends TestRunCriteria {
  test: MachineTypeTest;
  machine: Machine;
  machineTest: MachineTest;
  setup: MachineTestSetup;
  lastTestRun?: MachineTestResult;
  analyzeUpload?: AnalyzeUpload;
  subtest?: MachineSubtest;
  ionChambers?: Machine[];
  electrometers?: Machine[];
  lastTG51TestRun?: MachineTestResult;
}

export interface TestRunContext {
  id: number;
  name: string;
  value: TestRunValue;
}

export interface DisplayField {
  field: MachineTypeSubtestField;
  label: string;
  value: any;
}

export interface DisplayInputLabel {
  field: MachineTypeSubtestField;
  label: string;
  analyzeField?: TestRunContext;
  machine?: Machine;
}

export interface DisplayInput {
  field: MachineTypeSubtestField;
  value: any;
  options: { label: string; value: any }[];
}

export interface TestRunUpload extends AnalyzeUploadList {
  analyzeContext: TestRunContext[];
}

export interface TestRunEntry {
  testRun: TestRun;
  status: TestRunStatus;
  setupContext: TestRunContext[];
  inputContext: TestRunContext[];
  calcContext: TestRunContext[];
  order: number;
  versionId: number;
  note?: string;
  location?: string;
  uploadId?: number;
  testDate?: string;
}

import React from "react";
import clsx from "clsx";
import { Chip as MuiChip, ChipProps as MuiChipProps, makeStyles, PropTypes, Theme, useTheme } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

export interface ChipProps extends MuiChipProps {}

const useStyles = makeStyles((theme) => ({
  root: { borderRadius: theme.shape.borderRadius },
  icon: {
    width: "22px",
    height: "22px",
  },
  iconSmall: {
    width: "18px",
    height: "18px",
  },
}));

function Chip(props: ChipProps) {
  const { ...muiChipProps } = props;

  const classes = useStyles();

  const defaultProps: MuiChipProps = {
    size: "small",
    classes,
    deleteIcon: <CloseOutlined />,
    ...muiChipProps,
  };

  return <MuiChip {...defaultProps} />;
}

export default Chip;

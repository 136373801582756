import { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CalendarWidget from "./CalendarWidget";
import ScheduleWidget from "./ScheduleWidget";
import ScoreWidget from "./ScoreWidget";
import TrackerWidget from "./TrackerWidget";
import Card from "../../../components/Card/Card";
import ImageWithText from "../../../components/ImageWithText/ImageWithText";
import Button from "../../../components/Button/Button";
import { AddOutlined, CloseOutlined, EmailOutlined } from "@material-ui/icons";
import Avatar from "../../../components/Avatar/Avatar";
import { enumToLookup } from "../../../../models/lookup.model";
import IconButton from "../../../components/IconButton/IconButton";
import { Box, Drawer, Grid, Link, Toolbar, Typography } from "@material-ui/core";
import PageContainer from "../../../components/Page/PageContainer";
import RouterHashModal, { RenderRouterHashModalProps } from "../../RouterHashModal/RouterHashModal";
import { useHistory } from "react-router-dom";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Widget from "../../../components/Svg/Widget";
import { DashboardWidget, useUserSettingsContext } from "../../../../context/user-settings.context";
import OverdueTestWidget from './OverdueTestWidget';

const ADD_WIDGET_HASH = "#widgets";

const useStyles = makeStyles((theme) => ({
  page: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  dashboard: {
    // Grid layout via flexbox
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: `calc(${theme.spacing(2)}px * -0.5)`,

    /*"@supports (display: grid)": {
      display: "grid",
      gridGap: theme.spacing(2),
      gridTemplateColumns: "repeat(3, 1fr)",

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
    },*/
  },

  widget: {
    position: "relative",
    border: `calc(${theme.spacing(2)}px * 0.5) solid transparent`,
    minWidth: "160px",
    width: "calc(200% / 3)",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  smallWidget: {
    width: "calc(100% / 3)",

    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  largeWidget: {
    width: "100%",
  },
  widgetButton: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
}));

function Home() {
  const classes = useStyles();
  const history = useHistory();
  const { userSettings, setUserSettings, dashboardSettings, setDashboardSettings } = useUserSettingsContext();

  const dashboardWidgets = Object.values(DashboardWidget);

  // Widget state
  const selectedWidgets = dashboardSettings?.widgets || dashboardWidgets;

  const availableWidgets = dashboardWidgets.filter((x) => !selectedWidgets.includes(x));

  const handleOpenMenu = useCallback(
    (hash: string) => () => {
      history.push(`${hash}`);
    },
    [history]
  );

  const handleRemoveWidget = useCallback(
    (widget: DashboardWidget) => () => {
      setDashboardSettings((prev) => ({ ...prev, widgets: [...(prev?.widgets || []).filter((w) => w !== widget)] }));
    },
    [setDashboardSettings]
  );
  const handleAddWidget = useCallback(
    (widget: DashboardWidget) => () => {
      setDashboardSettings((prev) => ({ ...prev, widgets: [...(prev?.widgets || []).filter((w) => w !== widget), widget] }));
      history.push(`/`);
    },
    [history, setDashboardSettings]
  );

  const renderAddWidgetModal = useCallback(
    (props: RenderRouterHashModalProps) => {
      return (
        <Drawer
          open={props.open}
          onClose={props.onClose}
          anchor="bottom"
          PaperProps={{
            style: { top: "10%" },
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <PageContainer>
            <Grid container direction="column">
              <Grid item>
                <PageHeader id={"add-widget-header"} header={`Add dashboard widgets`}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      <IconButton onClick={props.onClose} edge="right">
                        <CloseOutlined />
                      </IconButton>
                    </Grid>
                  </Grid>
                </PageHeader>
              </Grid>

              {props.open && (
                <Grid item xs={12}>
                  <div className={classes.dashboard}>
                    {availableWidgets.map((item, index) => (
                      <div
                        key={item}
                        className={clsx(
                          classes.widget,
                          item === DashboardWidget.Schedule
                            ? classes.smallWidget
                            : item === DashboardWidget.Score
                              ? classes.smallWidget
                              : item === DashboardWidget.Tracker
                                ? classes.smallWidget
                                : null
                        )}
                      >
                        {item === DashboardWidget.Calendar ? (
                          <CalendarWidget style={{ opacity: 0.4 }} />
                        ) : item === DashboardWidget.Schedule ? (
                          <ScheduleWidget style={{ opacity: 0.4 }} />
                        ) : item === DashboardWidget.Score ? (
                          <ScoreWidget style={{ opacity: 0.4 }} />
                        ) : item === DashboardWidget.Tracker ? (
                          <TrackerWidget style={{ opacity: 0.4 }} />
                        ) : null}

                        <Box position="absolute" width="100%" height="100%" top={0} left={0}>
                          <div className={classes.widgetButton}>
                            <Button
                              color="secondary"
                              variant="contained"
                              elevation
                              startIcon={<AddOutlined />}
                              onClick={handleAddWidget(item as DashboardWidget)}
                            >
                              Add {Object.values(DashboardWidget)[index]}
                            </Button>
                          </div>
                        </Box>
                      </div>
                    ))}
                  </div>
                </Grid>
              )}
            </Grid>
          </PageContainer>
        </Drawer>
      );
    },
    [availableWidgets, classes.dashboard, classes.smallWidget, classes.widget, classes.widgetButton, handleAddWidget]
  );

  return (
    <>
      <div className={classes.page}>
        <div className={classes.dashboard}>
          {!selectedWidgets.includes(DashboardWidget.Calendar) ? null : (
            <div className={classes.widget}>
              <CalendarWidget /*onRemoveWidget={handleRemoveWidget(DashboardWidget.Calendar)}*/ />
            </div>
          )}

          {!selectedWidgets.includes(DashboardWidget.Schedule) ? null : (
            <div className={clsx(classes.widget, classes.smallWidget)}>
              <ScheduleWidget /*onRemoveWidget={handleRemoveWidget(DashboardWidget.Schedule)}*/ />
            </div>
          )}

          {!selectedWidgets.includes(DashboardWidget.Score) ? null : (
            <div className={clsx(classes.widget, classes.smallWidget)}>
              <ScoreWidget /*onRemoveWidget={handleRemoveWidget(DashboardWidget.Score)}*/ />
            </div>
          )}

          {!selectedWidgets.includes(DashboardWidget.Tracker) ? null : (
            <div className={clsx(classes.widget, classes.smallWidget)}>
              <TrackerWidget /*onRemoveWidget={handleRemoveWidget(DashboardWidget.Tracker)}*/ />
            </div>
          )}

          <div className={clsx(classes.widget, classes.smallWidget)}>
            {/* <OverdueTestWidget /> */}
          </div>
        </div>
      </div>
      <RouterHashModal hash={ADD_WIDGET_HASH} previousLocation={`/`} renderModal={renderAddWidgetModal} />
    </>
  );
}

export default Home;

/** Permission */
export enum Permission {
  adminAssignUsers = "adminAssignUsers",
  adminEditProcedures = "adminEditProcedures",
  adminEditTests = "adminEditTests",
  adminManageMachines = "adminManageMachines",
  dailyApprove = "dailyApprove",
  dailyEdit = "dailyEdit",
  dailyPerform = "dailyPerform",
  dailySignoff = "dailySignoff",
  machinelogCreate = "machinelogCreate",
  machinelogDelete = "machinelogDelete",
  machinelogDeleteNoteFile = "machinelogDeleteNoteFile",
  machinelogEditCategory = "machinelogEditCategory",
  machinelogEditDowntime = "machinelogEditDowntime",
  machinelogEditStatus = "machinelogEditStatus",
  monthlyApprove = "monthlyApprove",
  monthlyEdit = "monthlyEdit",
  monthlyPerform = "monthlyPerform",
  monthlySignoff = "monthlySignoff",
  orgAdminEquipmentFields = "orgAdminEquipmentFields",
  orgAdminManageAutoLogout = "orgAdminManageAutoLogout",
  orgAdminManagePasswordSettings = "orgAdminManagePasswordSettings",
  orgAdminManagePermissionGroups = "orgAdminManagePermissionGroups",
  orgAdminViewHiddenFields = "orgAdminViewHiddenFields",
  quarterlyApprove = "quarterlyApprove",
  quarterlyEdit = "quarterlyEdit",
  quarterlyPerform = "quarterlyPerform",
  quarterlySignoff = "quarterlySignoff",
  scheduleCreate = "scheduleCreate",
  scheduleDelete = "scheduleDelete",
  scheduleEdit = "scheduleEdit",
  weeklyApprove = "weeklyApprove",
  weeklyEdit = "weeklyEdit",
  weeklyPerform = "weeklyPerform",
  weeklySignoff = "weeklySignoff",
  yearlyApprove = "yearlyApprove",
  yearlyEdit = "yearlyEdit",
  yearlyPerform = "yearlyPerform",
  yearlySignoff = "yearlySignoff",
}

export type PermissionName = keyof typeof Permission;

export type PermissionSet = {
  [key in PermissionName]: boolean;
};

/** Role */
export interface Group extends PermissionSet {
  id: number;
  name: string;
  oldId: string;
  orgId: number;
  createdAt: string;
  deletedAt?: string;
  updatedAt?: string;
  userCreatedId: number;
  userDeletedId?: number;
  userUpdatedId?: number;
  facilityUsers?: { id: number }[];
}

import Grid, { GridProps } from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar, { ToolbarProps } from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { GridRootProps } from "react-virtuoso";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    title: {
      lineHeight: "1em",
    },
    children: {
      flex: "1 1 auto",
    },
  })
);

export interface PageHeaderProps extends GridProps {
  header?: React.ReactNode;
}

const PageHeader = (props: PageHeaderProps) => {
  const classes = useStyles();

  const { header, alignItems, id: propId, children, ...rest } = props;

  const id = propId || "page-header";

  return (
    <Grid id={`${id}`} container spacing={2} alignItems={alignItems} className={classes.toolbar} {...rest}>
      {header && (
        <Grid item>
          <Typography className={classes.title} variant="h5" component="h1" id={`${id}-title`}>
            {header}
          </Typography>
        </Grid>
      )}

      {children && (
        <Grid item className={classes.children} id={`${id}-children`}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;

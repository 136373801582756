import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper as MuiCard, PaperProps as MuiCardProps } from "@material-ui/core";

export interface CardProps extends MuiCardProps {
  padding?: string | number;
  maxWidth?: string | number;
}

const useElevationStyles = makeStyles((theme) => ({
  root: () => ({
    "&.MuiPaper-elevation1": {
      boxShadow: `${theme.elevation.low} ${theme.shadowColor}`,
    },
    "&.MuiPaper-elevation2": {
      boxShadow: `${theme.elevation.main} ${theme.shadowColor}`,
    },
    "&.MuiPaper-elevation3": {
      boxShadow: `${theme.elevation.high} ${theme.shadowColor}`,
    },
  }),
  elevation1: {
    boxShadow: `${theme.elevation.low} ${theme.shadowColor}`,
  },
  elevation2: {
    boxShadow: `${theme.elevation.main} ${theme.shadowColor}`,
  },
  elevation3: {
    boxShadow: `${theme.elevation.high} ${theme.shadowColor}`,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: (props?: Partial<CardProps>) => ({
    padding: props?.padding != null ? props.padding : theme.spacing(2),
    maxWidth: props?.maxWidth != null ? props.maxWidth : undefined,
    position: "relative",
    overflow: "hidden",

    ...((props?.classes?.root as any) || {}),
  }),
  elevation1: {
    boxShadow: `${theme.elevation.low} ${theme.shadowColor}`,
  },
  elevation2: {
    boxShadow: `${theme.elevation.main} ${theme.shadowColor}`,
  },
  elevation3: {
    boxShadow: `${theme.elevation.high} ${theme.shadowColor}`,
  },
}));

function Card(props: CardProps) {
  const { classes: propClasses, maxWidth, ...rest } = props;

  const classes = useStyles({ ...rest, maxWidth, classes: propClasses });

  const defaultProps: MuiCardProps = {
    variant: "outlined",
    classes,
    ...rest,
  };

  return <MuiCard {...defaultProps} />;
}

export default React.memo(Card);

import React, { forwardRef, HTMLAttributes, MutableRefObject, PropsWithChildren, SyntheticEvent, useCallback, useMemo, useRef, useState } from "react";
import {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteGetTagProps,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AutocompleteInput from "../../components/Inputs/AutocompleteInput/AutocompleteInput";
import {
  Box,
  Grid,
  Hidden,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popper,
  PopperProps,
  Typography,
} from "@material-ui/core";
import { CloseOutlined, Favorite, FavoriteBorder, KeyboardArrowRightRounded, ScheduleOutlined, SearchOutlined } from "@material-ui/icons";
import { useUserContext } from "../../../context/user.context";
import MachineIcon from "../../components/Icons/MachineIcon";
import FacilityIcon from "../../components/Icons/FacilityIcon";
import Chip, { ChipProps } from "../../components/Chip/Chip";
import usePersistedState from "../../../hooks/persisted-state.hook";
import IconButton from "../../components/IconButton/IconButton";
import { SearchbarSearchQuerySearch, SearchbarSearchResult, SearchbarSearchType } from "../../../models/searchbar.model";
import useSearchbarService from "../../../hooks/searchbar-service.hook";
import { useQuery } from "react-query";
import { useStatusEffect } from "../../../hooks/status-effect.hook";
import CardHeader from "../../components/CardHeader/CardHeader";
import CardList from "../../components/CardList/CardList";
import PageLoader from "../../components/PageLoader/PageLoader";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import { useHistory } from "react-router-dom";
import { Permission } from "../../../models/group.model";
import { useAppContext } from "../../../context/app.context";
import notifications from "../../../translations/en/notifications.json";

export interface SearchbarProps {
  open?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  borderNone?: boolean;
  popupAnchorRef?: MutableRefObject<any>;
}

const useListboxStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      margin: 0,
      padding: 0,
      maxHeight: 400,
      overflow: "auto",
      "& .MuiAutocomplete-option": {
        padding: 0,
      },
    },
  })
);

const chipBackground = "#aaaaaa33";
const maxRecentSearches = 5;
const maxFavorites = 20;

// Placeholders to hold selectable values that are not returned from search.
const facilityPlaceholder: SearchbarSearchResult = { id: 0, name: "", label: "Facility", tags: [], type: SearchbarSearchType.Facility };
const devicePlaceholder: SearchbarSearchResult = { id: 0, name: "", label: "Device", tags: [], type: SearchbarSearchType.Device };

const placeholderOptions: SearchbarSearchResult[] = [facilityPlaceholder, devicePlaceholder];

const defaultCriteria: SearchbarSearchQuerySearch = {
  query: "",
  page: 1,
};

type RecentSearch = SearchbarSearchQuerySearch & {
  org: SearchbarSearchResult;
  facility?: SearchbarSearchResult;
  device?: SearchbarSearchResult;
};

function FavoriteButton(props: { option: SearchbarSearchResult; favorites: SearchbarSearchResult[]; onToggle(favorite: SearchbarSearchResult): void }) {
  const { option, favorites, onToggle } = props;

  const isFav = favorites.findIndex((i) => i.id === option.id && i.type === option.type) >= 0;

  const handleToggle = useCallback(
    (favorite: SearchbarSearchResult) => (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onToggle(favorite);
    },
    [onToggle]
  );

  return (
    <IconButton edge="right" onClick={handleToggle(option)}>
      {isFav ? <Favorite fontSize="small" /> : <FavoriteBorder fontSize="small" />}
    </IconButton>
  );
}

function Searchbar(props: SearchbarProps) {
  const { open, disabled, borderNone, popupAnchorRef, fullWidth } = props;

  const { user, org, facility, machine: device, setFacility, setMachine: setDevice, hasPermission } = useUserContext();
  const { setAlert } = useAppContext();
  const { searchOrg } = useSearchbarService();
  const history = useHistory();

  const [keepOpen, setKeepOpen] = useState(false);

  const [retrySearch, setRetrySearch] = useState(0);

  const [recentSearches, setRecentSearches] = usePersistedState<RecentSearch[]>(`${user?.email}/searchbar/recent-searches`, {
    defaultValue: [],
    storageType: "local",
  });

  const [favorites, setFavorites] = usePersistedState<SearchbarSearchResult[]>(`${user?.email}/searchbar/favorites`, {
    defaultValue: [],
    storageType: "local",
  });

  const [criteria, setCriteria] = useState<SearchbarSearchQuerySearch>({ ...defaultCriteria, facilityId: facility?.id, deviceId: device?.id });

  // Mimic criteria above with a delay to debounce api calls.
  const [inputCriteria, setInputCriteria] = useState<SearchbarSearchQuerySearch & { delay: number }>(() => ({
    ...criteria!,
    delay: 0,
  }));

  const filteredFavorites = useMemo(() => {
    if (!org) {
      return [];
    }

    const faves = favorites.filter((i) => (i.type === "device" ? i.parent!.parent!.id === org.id : i.type === "facility" ? i.parent!.id === org.id : false));
    // Only the org is selected so have to filter favorites by org id.
    return inputCriteria.type ? faves.filter((i) => i.type === inputCriteria.type) : faves;
  }, [favorites, org, inputCriteria.type]);

  const filteredRecentSearches = useMemo(() => {
    if (!org) {
      return [];
    }

    return recentSearches.filter((i) => i.org.id === org.id);
  }, [org, recentSearches]);

  const canAddUsers = useMemo(() => (!facility ? false : hasPermission(Permission.adminAssignUsers, { facilityId: facility.id })), [facility, hasPermission]);

  // Handles async input changes with delay.
  // Need access to status outside of effect so grab it here.
  const { status } = useStatusEffect(
    (status) => {
      // Skip first render as we only want to eval this effect when input changes actually happen.
      if (status.current === "mount") {
        return;
      }

      const { delay, ...rest } = inputCriteria;

      // Delay setting value to limit api calls for every change
      const delayTimer = setTimeout(() => {
        if (status.current !== "unmount") {
          // Set criteria for API
          setCriteria(rest);
        }
      }, delay);

      // this will clear Timeout when inputCriteria changes
      return () => {
        clearTimeout(delayTimer);
      };
    },
    [inputCriteria, setCriteria]
  );

  const {
    isLoading: searchLoading,
    isError: searchError,
    data: searchResults,
  } = useQuery([`searchbar/options`, org, criteria, retrySearch], {
    queryFn: async () => {
      if (!org || !criteria || (!criteria.type && !criteria.query)) {
        return Promise.resolve(undefined);
      }

      const r = await searchOrg(org.id, criteria);

      if (status.current !== "unmount") {
        setRecentSearches((prev) => {
          const prevSaved = prev.filter(
            (item, index) =>
              index + 1 < maxRecentSearches &&
              (item.query != criteria.query || item.type != criteria.type || item.facilityId !== criteria.facilityId || item.deviceId != criteria.deviceId)
          );

          // Attach selected facility and device to recent search so they can be displayed.
          const orgSearchResult: SearchbarSearchResult = {
            id: org.id,
            name: org.name,
            label: org.name,
            type: SearchbarSearchType.Org,
            disabled: !org.active,
            tags: [],
          };

          const latestSearch: RecentSearch = { ...criteria, org: orgSearchResult, facility: facility || undefined, device: device || undefined };
          return [latestSearch, ...prevSaved];
        });
      }

      return r;
    },
  });

  const isFavoriate = useCallback(
    (item: SearchbarSearchResult) => favorites && favorites.findIndex((f) => f.id === item.id && f.type === item.type) >= 0,
    [favorites]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: (string | SearchbarSearchResult)[] | null, reason?: AutocompleteChangeReason) => {
      if (!org || !newValue) {
        return;
      }

      const selectedValue = newValue.filter((i) => (i as SearchbarSearchResult).id) as SearchbarSearchResult[];

      const selectedDevice = selectedValue.find((val) => val.id && val.parent && val.type === SearchbarSearchType.Device) || null;
      let selectedFacility = selectedValue.find((val) => val.id && val.type === SearchbarSearchType.Facility) || null;

      // If mac was selected and no facility then set the facility
      if (!selectedFacility && selectedDevice) {
        selectedFacility = selectedDevice.parent!;
      }

      setFacility((prev) => (prev?.id === selectedFacility?.id ? prev : selectedFacility));
      setDevice((prev) => (prev?.id === selectedDevice?.id ? prev : selectedDevice));

      // Remove params when a selection is made.
      setInputCriteria(({ type, page, facilityId, deviceId, query, ...rest }) => ({
        ...rest,
        delay: 0,
        facilityId: selectedFacility?.id,
        deviceId: selectedDevice?.id,
      }));

      if (selectedDevice?.id) {
        setKeepOpen(false);
      }
    },
    [org, setFacility, setDevice]
  );

  const handleInputChange = useCallback(
    (delay = 0, setQuery?: string) =>
      (event: React.ChangeEvent<any>, inputQuery: string, reason: AutocompleteInputChangeReason) => {
        setInputCriteria((prev) => {
          const query = reason === "reset" ? prev.query : setQuery !== undefined ? setQuery : inputQuery || undefined;

          if (prev.query === query) {
            return prev;
          }

          return { ...prev, page: 1, delay, query };
        });
      },
    []
  );

  const handleClose = useCallback((event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === "blur" || reason === "toggleInput") {
      setKeepOpen(false);
    }
  }, []);

  const handleOpen = useCallback((event: React.ChangeEvent<{}>) => {
    setKeepOpen(true);
  }, []);

  const handleSetSearchType = useCallback(
    (searchType?: SearchbarSearchType) => () => {
      const { type, ...withoutType } = inputCriteria;

      setInputCriteria((prev) => ({ ...withoutType, type: searchType, delay: 0 }));
    },
    [inputCriteria]
  );

  const handleRemoveRecentSearch = useCallback(
    (index: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setRecentSearches((prev) => prev.filter((item, itemIndex) => itemIndex != index));
    },
    [setRecentSearches]
  );

  const handleSelectRecentSearch = useCallback(
    (recentSearch: RecentSearch) => () => {
      const { device, facility, page, ...rest } = recentSearch;

      setDevice((prev) => (prev?.id === device?.id ? prev : device || null));
      setFacility((prev) => (prev?.id === facility?.id ? prev : facility || null));
      const newCriteria = { ...rest, delay: 0 };

      setInputCriteria(newCriteria);
    },
    [setDevice, setFacility]
  );

  const toggleFavorite = useCallback(
    (favorite: SearchbarSearchResult) => {
      setFavorites((prev) => {
        // Remove it if already here.
        const isFav = prev.findIndex((i) => i.id === favorite.id && i.type === favorite.type) >= 0;

        if (isFav) {
          return prev.filter((i) => !(i.id === favorite.id && i.type === favorite.type));
        }

        const favCount = prev.length || 0;

        if (favCount >= maxFavorites) {
          // Show alert and return.
          setAlert({ severity: "info", position: "fixed-sidebar", messages: [notifications.searchbar.maxFavorites] });
          return prev;
        }

        // Add the favorite
        return [favorite, ...prev];
      });
    },
    [setAlert, setFavorites]
  );

  const handleToggleFavorite = useCallback(
    (favorite: SearchbarSearchResult) => (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      toggleFavorite(favorite);
    },
    [toggleFavorite]
  );

  const handleSelectFavorite = useCallback(
    (favorite: SearchbarSearchResult) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      handleChange(event, [favorite]);
    },
    [handleChange]
  );

  const handleRemoveFavorite = useCallback(
    (index: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setFavorites((prev) => prev.filter((item, itemIndex) => itemIndex != index));
    },
    [setFavorites]
  );

  /** Rendering functions */
  const renderPopup = useCallback(
    (props: PopperProps) => {
      let popperProps: PopperProps = {
        ...props,
        keepMounted: true,
        placement: "bottom-start",
      };

      if (popupAnchorRef?.current) {
        popperProps = {
          ...popperProps,
          anchorEl: popupAnchorRef.current,
          container: popupAnchorRef.current,
          style: { width: "100%" },
        };
      }

      return <Popper {...popperProps} />;
    },
    [popupAnchorRef]
  );

  const renderOption = useCallback(
    (option: SearchbarSearchResult, state: AutocompleteRenderOptionState) => {
      // Do not render placeholder options.
      if (!option.id) {
        return null;
      }

      if (option.type === SearchbarSearchType.Facility) {
        return (
          <ListItem component="div" key={`facility_${option.id}`}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Box display="flex">
                  <FacilityIcon />
                </Box>
              </Grid>
              <Grid item>
                <Typography>{option.label}</Typography>
              </Grid>
              <Grid item>
                <ListItemSecondaryAction>
                  <FavoriteButton option={option} favorites={favorites} onToggle={toggleFavorite} />
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        );
      }

      if (option.type === SearchbarSearchType.Device) {
        return (
          <ListItem component="div" key={`facility_${option.parent!.id}_machine_${option.id}`}>
            <Grid container alignItems="center" spacing={1}>
              <Hidden xsUp={Boolean(facility)}>
                <Grid item>
                  <Box display="flex">
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <FacilityIcon />
                      </Grid>
                      <Grid item>
                        <Typography>{option.parent!.label}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex">
                    <KeyboardArrowRightRounded />
                  </Box>
                </Grid>
              </Hidden>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Box display="flex">
                      <MachineIcon />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex">
                      <Typography>{option.label}</Typography>
                    </Box>
                  </Grid>
                  <Hidden xsUp={Boolean(!option?.disabled)}>
                    <Grid item>
                      <Box display="flex">(inactive)</Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
              <Grid item>
                <ListItemSecondaryAction>
                  <FavoriteButton option={option} favorites={favorites} onToggle={toggleFavorite} />
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        );
      }

      // Do not render any other options.
      return null;
    },
    [facility, favorites, toggleFavorite]
  );

  const getOptionLabel = useCallback((option: SearchbarSearchResult) => option.label || "test", []);

  const renderSelections = useCallback((selections: SearchbarSearchResult[], getTagProps: AutocompleteGetTagProps) => {
    const chips: JSX.Element[] = [];

    selections.forEach((item, itemIndex) => {
      let chipProps: ChipProps = {
        ...getTagProps({ index: itemIndex }),
      };

      const isLast = itemIndex === selections.length - 1;

      if (!isLast) {
        // Remove delete button from pre-pended chips
        chipProps = {
          ...chipProps,
          deleteIcon: <></>,
        };
      }

      chips.push(
        <Chip
          key={itemIndex}
          id={`selected-${item.type}`}
          size="small"
          icon={item.type === SearchbarSearchType.Facility ? <FacilityIcon /> : item.type === SearchbarSearchType.Device ? <MachineIcon /> : undefined}
          variant={isLast ? "default" : "outlined"}
          color="primary"
          label={item.label}
          {...chipProps}
        />
      );
    });

    return chips;
  }, []);

  const handleGetOptionSelected = useCallback(
    (option: SearchbarSearchResult, selectedVal: SearchbarSearchResult): boolean => {
      // This control can have 2 selections, a facility and a device. These selections come from an outside context so
      // use a placeholder for each and return that those placeholders are selected if the facility and/or device are
      // set.
      if (
        device != null &&
        option.id === 0 &&
        option.type === SearchbarSearchType.Device &&
        selectedVal.type === SearchbarSearchType.Device &&
        device.id === selectedVal.id
      ) {
        return true;
      }

      if (
        facility != null &&
        option.id === 0 &&
        option.type === SearchbarSearchType.Facility &&
        selectedVal.type === SearchbarSearchType.Facility &&
        facility.id === selectedVal.id
      ) {
        return true;
      }

      return false;
    },
    [facility, device]
  );

  const handleFilterOptions = useCallback((options: SearchbarSearchResult[], state: FilterOptionsState<SearchbarSearchResult>): SearchbarSearchResult[] => {
    return options;
  }, []);

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    event?.stopPropagation();
    event?.preventDefault();
    event?.nativeEvent?.stopPropagation();
    event?.nativeEvent?.preventDefault();
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    event?.stopPropagation();
    event?.preventDefault();
    event?.nativeEvent?.stopPropagation();
    event?.nativeEvent?.preventDefault();
  }, []);

  const handleAddLog = useCallback(() => {
    setKeepOpen(false);
    history.push(`/logs/new`);
  }, [history]);

  const handleAddEvent = useCallback(() => {
    setKeepOpen(false);
    history.push(`/events/new`);
  }, [history]);

  const handleViewDevices = useCallback(() => {
    setKeepOpen(false);
    history.push(`/equipment`);
  }, [history]);

  const handleAddUser = useCallback(() => {
    setKeepOpen(false);
    history.push(`/users/new`);
  }, [history]);

  const searchbarMenuWrapperclasses = useListboxStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Override the Autocomplete ListboxComponent to allow interaction with buttons.
   */
  const renderSearchbarMenuWrapper = useCallback(
    (menuProps: { menu: React.ReactNode; loading?: boolean }) => {
      const { menu, loading } = menuProps;

      return forwardRef<HTMLUListElement, React.PropsWithChildren<PropsWithChildren<HTMLAttributes<HTMLElement>>>>((props, ref) => {
        // Do not want className or onMouseDown
        const { children, className, onMouseDown, ...other } = props;

        // const itemData = React.Children.toArray(children);

        const resultCount = searchResults?.items.length || 0;

        return (
          <ul ref={ref} {...other} role="listbox" className={searchbarMenuWrapperclasses.root} onClick={handleClick} onMouseDown={handleMouseDown}>
            {loading ? (
              <PageLoader />
            ) : // Using placeholders for options so we can show the menu and not "no options" option
              resultCount > 0 ? (
                children
              ) : (
                menu
              )}
          </ul>
        );
      });
    },
    [handleClick, handleMouseDown, searchResults?.items.length, searchbarMenuWrapperclasses.root]
  );

  return (
    <AutocompleteInput
      multiple
      freeSolo
      disableClearable
      name={"searchbar"}
      id={"searchbar"}
      placeholder={
        device
          ? "Search..."
          : facility
            ? "Select device, or search..."
            : inputCriteria.type === SearchbarSearchType.Device
              ? "Select device, or search..."
              : inputCriteria.type === SearchbarSearchType.Facility
                ? "Select facility, or search..."
                : "Select facility, device, or search..."
      }
      fullWidth={fullWidth}
      disabled={disabled}
      inputRef={inputRef}
      borderNone={borderNone}
      // Use placeholders for the 2 possible selections of facility and device.
      // This allows us to disable the no options menu, control loading state,
      // and sync the selected facility and/or device to the placeholders.
      // Placeholders are not shown bc they're filtered out in renderOption.
      options={[...placeholderOptions, ...(searchResults?.items || [])]}
      value={facility && device ? [facility, device] : facility ? [facility] : []}
      onChange={handleChange}
      inputValue={inputCriteria.query || ""}
      onInputChange={handleInputChange(1000)}
      open={open != null ? open : keepOpen}
      autoSelect={false}
      filterOptions={handleFilterOptions}
      onOpen={handleOpen}
      onClose={handleClose}
      PopperComponent={renderPopup}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      renderTags={renderSelections}
      getOptionSelected={handleGetOptionSelected}
      forcePopupIcon={true}
      startAdornment={
        inputCriteria.type ? (
          <InputAdornment position="start" style={{ marginLeft: "0" }}>
            <Chip
              label={inputCriteria.type === SearchbarSearchType.Facility ? "Facility" : inputCriteria.type === SearchbarSearchType.Device ? "Device" : null}
              onDelete={handleSetSearchType()}
            />
          </InputAdornment>
        ) : !facility && !device ? (
          <InputAdornment position="start" style={{ marginLeft: "0" }}>
            <SearchOutlined color="disabled" />
          </InputAdornment>
        ) : null
      }
      ListboxComponent={renderSearchbarMenuWrapper({
        loading: searchLoading,
        menu: (
          <>
            {/** No results found. Use criteria here and not input criteria bc input criteria is updated instantly and criteria is debounced. */}
            <Hidden xsUp={Boolean(!criteria.query?.length)}>
              <Box padding={2} paddingBottom={0}>
                <CardHeader title={`Nothing found for "${criteria.query}"`} />
              </Box>
            </Hidden>
            {/** Nothing selected. */}
            {!criteria.type && !facility && !device && (
              <Box padding={2} paddingBottom={filteredRecentSearches.length || filteredFavorites.length ? 0 : 2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">{`I'm looking for a...`}</Typography>
                  </Grid>

                  <Grid item>
                    <Chip
                      size="medium"
                      variant="outlined"
                      icon={<FacilityIcon />}
                      onClick={handleSetSearchType(SearchbarSearchType.Facility)}
                      label={`Facility`}
                    />
                  </Grid>
                  <Grid item>
                    <Chip size="medium" variant="outlined" icon={<MachineIcon />} onClick={handleSetSearchType(SearchbarSearchType.Device)} label={`Device`} />
                  </Grid>
                </Grid>
              </Box>
            )}
            {/** Selected device or facility quick actions. */}
            {device ? (
              <Box padding={2} paddingBottom={filteredRecentSearches.length || filteredFavorites.length ? 0 : 2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">{`I want to...`}</Typography>
                  </Grid>

                  {!isFavoriate(device) && (
                    <Grid item>
                      <Chip onClick={handleToggleFavorite(device)} variant="outlined" size="medium" label="Favorite this device" />
                    </Grid>
                  )}

                  <Grid item>
                    <Chip onClick={handleAddLog} variant="outlined" size="medium" label="Add a log" />
                  </Grid>
                  <Grid item>
                    <Chip onClick={handleAddEvent} variant="outlined" size="medium" label="Add an event" />
                  </Grid>
                </Grid>
              </Box>
            ) : facility ? (
              <Box padding={2} paddingBottom={filteredRecentSearches.length || filteredFavorites.length ? 0 : 2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">{`I want to...`}</Typography>
                  </Grid>

                  {!isFavoriate(facility) && (
                    <Grid item>
                      <Chip onClick={handleToggleFavorite(facility)} variant="outlined" size="medium" label="Favorite this facility" />
                    </Grid>
                  )}
                  <Grid item>
                    <Chip onClick={handleSetSearchType(SearchbarSearchType.Device)} variant="outlined" size="medium" label="View all devices" />
                  </Grid>

                  {canAddUsers && (
                    <Grid item>
                      <Chip onClick={handleAddUser} variant="outlined" size="medium" label="Add a user" />
                    </Grid>
                  )}
                </Grid>
              </Box>
            ) : null}
            {/** Favorites. */}
            {!!filteredFavorites.length && (
              <Box padding={2} paddingBottom={filteredRecentSearches.length ? 0 : 2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">{`Your favorites`}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {filteredFavorites.map((favorite, favoriteIndex) => (
                        <Grid item key={favoriteIndex}>
                          <Chip
                            size="medium"
                            icon={
                              favorite.type === SearchbarSearchType.Facility ? (
                                <FacilityIcon />
                              ) : favorite.type === SearchbarSearchType.Device ? (
                                <MachineIcon />
                              ) : undefined
                            }
                            style={{ background: chipBackground }}
                            onClick={handleSelectFavorite(favorite)}
                            onDelete={handleRemoveFavorite(favoriteIndex)}
                            label={favorite.label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            {/** Recent searches. */}
            {!!filteredRecentSearches.length && (
              <Box padding={2} paddingBottom={0}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">{`Recent searches`}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <CardList disablePadding={true}>
                      {filteredRecentSearches.map((recentSearch, recentSearchIndex) => (
                        <ListItem key={recentSearchIndex} button onClick={handleSelectRecentSearch(recentSearch)}>
                          <ListItemIcon>
                            <ScheduleOutlined />
                          </ListItemIcon>
                          {recentSearch.device ? (
                            <ListItemIcon>
                              <Chip icon={<MachineIcon />} label={recentSearch.device.label} style={{ background: chipBackground }} />
                            </ListItemIcon>
                          ) : recentSearch.facility ? (
                            <ListItemIcon>
                              <Chip icon={<FacilityIcon />} label={recentSearch.facility.label} style={{ background: chipBackground }} />
                            </ListItemIcon>
                          ) : recentSearch.type ? (
                            <ListItemIcon>
                              <Chip
                                label={
                                  recentSearch.type === SearchbarSearchType.Facility
                                    ? "Facility"
                                    : recentSearch.type === SearchbarSearchType.Device
                                      ? "Device"
                                      : null
                                }
                                style={{ background: chipBackground }}
                              />
                            </ListItemIcon>
                          ) : null}
                          <ListItemText primary={recentSearch.query} />
                          <ListItemSecondaryAction>
                            <IconButton edge="right" onClick={handleRemoveRecentSearch(recentSearchIndex)}>
                              <CloseOutlined fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </CardList>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        ),
      })}
    />
  );
}

export default Searchbar;

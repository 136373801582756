import React from "react";
import { SvgProps } from "./svg.model";
import useThemePalette from "../../../hooks/theme-palette.hook";

function Missing(props: SvgProps) {
  const color = props.color || "secondary";
  const size = props.size || "100%";

  const [mainColor, lightColor, darkColor] = useThemePalette({ color });
  const gradientId = `_linearGradient_Missing_Svg`;

  return (
    <svg width={size} height={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <linearGradient id={gradientId} gradientUnits="userSpaceOnUse" x1="437.085" x2="-4" y1="74.915" y2="516">
        <stop offset="0" stopColor="#d2deff" />
        <stop offset="1" stopColor="#b7c5ff" />
      </linearGradient>
      <path
        d="m411.152 155.152c-29.991 0-54.303-24.312-54.303-54.303 0-55.698-45.152-100.849-100.849-100.849s-100.848 45.151-100.848 100.848c0 29.991-24.312 54.303-54.303 54.303-55.698.001-100.849 45.152-100.849 100.849s45.151 100.848 100.848 100.848c29.991 0 54.303 24.312 54.303 54.303.001 55.698 45.152 100.849 100.849 100.849s100.848-45.151 100.848-100.848c0-29.991 24.312-54.303 54.303-54.303 55.698-.001 100.849-45.152 100.849-100.849s-45.151-100.848-100.848-100.848z"
        fill={`url(#${gradientId})`}
      />
      <path
        d="m454.062 430.452-81.755-81.755v-276.092c0-5.19-4.21-9.4-9.4-9.4h-255.9c-5.19 0-9.4 4.21-9.4 9.4v273.556s0 0-.001 0c-23.338 0-42.256 18.919-42.256 42.256s18.919 42.256 42.256 42.256h.001v47.732c0 5.19 4.21 9.4 9.4 9.4h255.9c5.19 0 9.4-4.21 9.4-9.4v-69.712l51.757 51.757c3.452 3.452 9.048 3.452 12.499 0l17.499-17.499c3.451-3.451 3.451-9.047 0-12.499z"
        fill="#2626bc"
        opacity=".1"
      />
      <path
        d="m372.307 45.605v405.8c0 5.19-4.21 9.4-9.4 9.4h-255.9c-5.19 0-9.4-4.21-9.4-9.4v-405.8c0-5.19 4.21-9.4 9.4-9.4h255.9c5.19 0 9.4 4.21 9.4 9.4z"
        fill="#fff"
      />
      <path d="m372.307 45.605v303.9h-274.7v-303.9c0-5.19 4.21-9.4 9.4-9.4h255.9c5.19 0 9.4 4.21 9.4 9.4z" fill="#fff" />
      <path d="m372.307 85.037v-39.423c0-5.197-4.213-9.41-9.41-9.41h-255.881c-5.197 0-9.41 4.213-9.41 9.41v39.423z" fill="#6583fe" />
      <g>
        <g>
          <g>
            <g>
              <path d="m184.908 85.029 35.357-45.768-7.914-6.114-40.08 51.882z" fill="#2626bc" />
            </g>
            <g>
              <path
                d="m341.612 67.069h-113.255c-2.597 0-4.703-2.106-4.703-4.703s2.105-4.703 4.703-4.703h113.255c2.597 0 4.703 2.106 4.703 4.703s-2.105 4.703-4.703 4.703z"
                fill="#2626bc"
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <path d="m292.551 290.335 18.326-18.325 55.613 55.613-18.325 18.326z" fill="#fff" />
        <g>
          <path
            d="m310.321 289.71c-41.719 41.719-109.601 41.719-151.321 0s-41.719-109.602 0-151.321 109.602-41.719 151.321 0 41.719 109.601 0 151.321z"
            fill="#6583fe"
          />
        </g>
        <g fill="#fff">
          <ellipse cx="234.612" cy="214.051" rx="80.473" ry="80.473" />
          <path d="m284.457 150.815-113.03 113.03c-4.47-5.66-8.06-11.79-10.78-18.2l105.61-105.61c6.41 2.72 12.54 6.31 18.2 10.78z" />
          <path d="m314.406 203.375-90.42 90.42c-16.25-2.15-31.97-9.26-44.68-21.32l113.78-113.78c12.06 12.71 19.171 28.43 21.32 44.68z" />
          <path d="m454.062 415.951-17.499 17.499c-3.452 3.452-9.048 3.452-12.499 0l-82.911-82.911c-3.452-3.452-3.452-9.048 0-12.499l17.499-17.499c3.452-3.452 9.048-3.452 12.499 0l82.911 82.911c3.451 3.452 3.451 9.048 0 12.499z" />
        </g>
        <g fill="#6583fe">
          <path d="m410.239 419.626 13.824 13.824c3.452 3.452 9.048 3.452 12.499 0l17.499-17.499c3.452-3.452 3.452-9.048 0-12.499l-13.824-13.824z" />
          <path d="m353.242 362.705 29.994-29.994 19.796 19.796-29.994 29.994z" />
        </g>
      </g>
      <g fill="#2626bc">
        <path d="m228.491 243.315c-2.762 0-5-2.239-5-5v-6.016c0-3.881 1.018-7.713 2.943-11.084 2.948-5.159 7.917-8.984 13.633-10.496 8.729-2.307 14.826-10.234 14.826-19.277 0-5.43-2.145-10.506-6.037-14.293-3.891-3.785-9.026-5.783-14.468-5.636-10.445.289-19.132 9.024-19.364 19.473-.049 2.182.251 4.33.89 6.385.82 2.637-.653 5.439-3.29 6.259-2.635.82-5.439-.652-6.259-3.29-.961-3.089-1.411-6.311-1.339-9.576.355-15.959 13.131-28.806 29.085-29.248 8.175-.22 15.874 2.78 21.718 8.464 5.845 5.686 9.064 13.308 9.064 21.461 0 13.577-9.158 25.48-22.271 28.945-3.143.831-5.878 2.941-7.505 5.789-1.064 1.862-1.627 3.979-1.627 6.123v6.016c.001 2.762-2.237 5.001-4.999 5.001z" />
        <path d="m228.491 262.509c-2.762 0-5-2.239-5-5v-.106c0-2.761 2.238-5 5-5s5 2.239 5 5v.106c0 2.762-2.238 5-5 5z" />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path d="m312.216 417.54h-154.52c-2.762 0-5 2.239-5 5s2.238 5 5 5h154.52c2.762 0 5-2.239 5-5s-2.238-5-5-5z" fill="#2626bc" />
            </g>
            <g>
              <path d="m194.133 398.037h-36.436c-2.762 0-5 2.239-5 5s2.238 5 5 5h36.436c2.762 0 5-2.239 5-5s-2.238-5-5-5z" fill="#2626bc" />
            </g>
            <g>
              <path
                d="m218.171 398.037h75.668c2.761 0 5 2.239 5 5s-2.239 5-5 5h-75.883c-2.924 0-5.261-2.51-4.976-5.492.248-2.599 2.58-4.508 5.191-4.508z"
                fill="#2626bc"
              />
            </g>
          </g>
        </g>
        <g fill="#2626bc">
          <circle cx="175.685" cy="378.506" r="5" />
          <circle cx="159.435" cy="378.506" r="5" />
        </g>
      </g>
      <g>
        <ellipse cx="97.606" cy="361.417" fill="#6583fe" rx="42.256" ry="42.256" transform="matrix(.033 -.999 .999 .033 -266.859 446.942)" />
        <g fill="#2626bc">
          <g>
            <path d="m97.605 276.7c2.762 0 5-2.239 5-5v-226.091c0-2.429 1.977-4.405 4.406-4.405h186.688c2.762 0 5-2.239 5-5s-2.238-5-5-5h-186.687c-7.943 0-14.406 6.462-14.406 14.405v226.091c-.001 2.761 2.238 5 4.999 5z" />
            <path d="m457.597 399.917-80.29-80.29v-274.018c0-7.943-6.462-14.405-14.404-14.405h-38.602c-2.762 0-5 2.239-5 5s2.238 5 5 5h38.602c2.429 0 4.404 1.976 4.404 4.405v267.562c-.788-.138-1.59-.219-2.406-.219-1.738 0-3.422.329-4.995.938l-34.37-34.37c31.556-43.748 27.667-105.321-11.678-144.667-43.67-43.669-114.726-43.669-158.392 0-21.122 21.122-32.755 49.248-32.755 79.196s11.633 58.075 32.755 79.196c21.834 21.834 50.515 32.751 79.196 32.751 23.002 0 46-7.029 65.47-21.073l34.37 34.37c-.609 1.573-.938 3.258-.938 4.996 0 3.697 1.439 7.172 4.054 9.786l29.689 29.69v67.635c0 2.429-1.976 4.405-4.404 4.405h-255.891c-2.43 0-4.406-1.976-4.406-4.405v-42.994c23.715-2.505 42.257-22.621 42.257-46.99s-18.542-44.484-42.257-46.99v-12.126c0-2.761-2.238-5-5-5s-5 2.239-5 5v12.127c-23.715 2.506-42.256 22.622-42.256 46.989s18.541 44.483 42.256 46.989v42.995c0 7.943 6.463 14.405 14.406 14.405h255.891c7.942 0 14.404-6.462 14.404-14.405v-57.636l43.221 43.221c2.614 2.614 6.089 4.054 9.786 4.054 3.696 0 7.171-1.439 9.784-4.053l17.499-17.499c5.395-5.395 5.395-14.174 0-19.57zm-397.247-38.5c0-20.543 16.713-37.256 37.257-37.256 20.543 0 37.256 16.713 37.256 37.256s-16.713 37.256-37.256 37.256c-20.544 0-37.257-16.713-37.257-37.256zm102.185-75.243c-19.232-19.233-29.825-44.847-29.825-72.125s10.593-52.892 29.825-72.125c19.885-19.885 46.005-29.827 72.125-29.827s52.24 9.943 72.126 29.828c39.769 39.77 39.769 104.48 0 144.25-39.77 39.767-104.478 39.768-144.251-.001zm151.321 7.071c1.893-1.893 3.699-3.841 5.428-5.833l32.712 32.713-11.261 11.261-32.712-32.712c1.992-1.73 3.94-3.536 5.833-5.429zm136.67 119.171-17.5 17.5c-1.45 1.45-3.979 1.448-5.428 0l-82.911-82.911c-.725-.725-1.124-1.689-1.124-2.714s.399-1.989 1.124-2.714l17.5-17.499c.725-.725 1.688-1.124 2.713-1.124s1.989.399 2.715 1.125l82.91 82.911c1.497 1.494 1.497 3.929.001 5.426z" />
          </g>
          <path d="m114.219 355.61c0-9.161-7.453-16.613-16.613-16.613s-16.613 7.453-16.613 16.613c0 4.518 1.818 8.615 4.755 11.613-2.936 2.998-4.755 7.095-4.755 11.613 0 2.761 2.238 5 5 5s5-2.239 5-5c0-3.646 2.967-6.613 6.613-6.613s6.613 2.967 6.613 6.613c0 2.761 2.238 5 5 5s5-2.239 5-5c0-4.518-1.818-8.615-4.755-11.613 2.937-2.997 4.755-7.095 4.755-11.613zm-16.613-6.613c3.646 0 6.613 2.967 6.613 6.613s-2.967 6.613-6.613 6.613-6.613-2.967-6.613-6.613 2.966-6.613 6.613-6.613z" />
        </g>
      </g>
      <g fill="#6583fe">
        <path d="m438.333 154.913h-3v-3c0-2.761-2.238-5-5-5s-5 2.239-5 5v3h-3c-2.762 0-5 2.239-5 5s2.238 5 5 5h3v3c0 2.761 2.238 5 5 5s5-2.239 5-5v-3h3c2.762 0 5-2.239 5-5s-2.238-5-5-5z" />
        <path d="m68.333 251.505h-3v-3c0-2.761-2.238-5-5-5s-5 2.239-5 5v3h-3c-2.762 0-5 2.239-5 5s2.238 5 5 5h3v3c0 2.761 2.238 5 5 5s5-2.239 5-5v-3h3c2.762 0 5-2.239 5-5s-2.238-5-5-5z" />
        <path d="m419.114 210.855c-1.279 0-2.56-.488-3.535-1.464l-4.465-4.464-4.465 4.464c-1.951 1.952-5.119 1.952-7.07 0-1.953-1.953-1.953-5.119 0-7.071l8-8c1.951-1.952 5.119-1.952 7.07 0l8 8c1.953 1.953 1.953 5.119 0 7.071-.975.976-2.255 1.464-3.535 1.464z" />
        <path d="m60.333 303.497c-1.279 0-2.56-.488-3.535-1.464l-4.465-4.464-4.465 4.464c-1.951 1.952-5.119 1.952-7.07 0-1.953-1.953-1.953-5.119 0-7.071l8-8c1.951-1.952 5.119-1.952 7.07 0l8 8c1.953 1.953 1.953 5.119 0 7.071-.975.975-2.256 1.464-3.535 1.464z" />
        <path d="m460.333 370.417c-1.279 0-2.56-.488-3.535-1.464l-4.465-4.464-4.465 4.464c-1.951 1.952-5.119 1.952-7.07 0-1.953-1.953-1.953-5.119 0-7.071l8-8c1.951-1.952 5.119-1.952 7.07 0l8 8c1.953 1.953 1.953 5.119 0 7.071-.975.976-2.256 1.464-3.535 1.464z" />
      </g>
      <path d="m137.953 344.505h203.2v10h-203.2z" fill="#2626bc" />
    </svg>
  );
}

export default Missing;

import {
  Checkbox,
  Grid,
  GridSize,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { ArrowDropDown, DragIndicator } from "@material-ui/icons";
import clsx from "clsx";
import React, { DragEvent, Fragment, useCallback, useEffect, useMemo } from "react";
import Tooltip from "../Tooltip/Tooltip";

type SortOrder = "asc" | "desc";

export interface TableColumn<T> {
  keyProp?: keyof T;
  label: string;
  altKey?: string;
  align?: "right" | "left";
  disablePadding?: boolean;
  hideSort?: boolean;
  renderCell?: (row: T) => React.ReactNode;
  getSortComparer?: (row: T) => string | number;
}

export interface ReorderedList<T = any> {
  originalIndex: number;
  newIndex: number;
  reorderedItems: T[];
  originalItems: T[];
}

export interface BaseTableProps<T> {
  items: T[];
  pageLimits?: number[];
  id?: string;
  header?: string;
  headerContent?: React.ReactNode;
  actions?: React.ReactNode;
  actionsSize?: GridSize;
  selectedActions?: React.ReactNode;
  page?: number;
  limit?: number;
  total?: number;
  hideSort?: boolean;
  hideSelect?: boolean;
  onSort?: (key: keyof T | string, asc: boolean) => void;
  onChangePage?: (page: number, limit: number) => void;
  selectedKeys?: any[];
  disabledKeys?: any[];
  onSelectKeys?: (key: any[]) => void;
  onRowReorder?: (data: ReorderedList<T>) => void;
  containerProps?: TableContainerProps;
  enableRowReordering?: boolean;
  onRowExpanded?: (item: T, index: number) => void;
  renderExpandedRow?: (item: T, index: number) => React.ReactNode;
}

export interface TableProps<T> extends BaseTableProps<T> {
  keyProp?: keyof T;
  columns: TableColumn<T>[];
}

function descendingComparator<T>(a: T, b: T, cols: TableColumn<T>[], orderBy: keyof T | string) {
  // Find the column we are sorting
  const col = cols.find((c) => (c.altKey || c.keyProp) === orderBy)!;

  // Use the alt key comparer or the prop from the
  const aVal = col.getSortComparer ? col.getSortComparer(a) : a[orderBy as keyof T];
  const bVal = col.getSortComparer ? col.getSortComparer(b) : b[orderBy as keyof T];

  if (bVal < aVal) {
    return -1;
  }

  if (bVal > aVal) {
    return 1;
  }

  return 0;
}

function getComparator<T>(cols: TableColumn<T>[], order: SortOrder, orderBy: keyof T | string): (a: T, b: T) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, cols, orderBy) : (a, b) => -descendingComparator(a, b, cols, orderBy);
}

function stableSort<T>(array: T[], cols: TableColumn<T>[], order: SortOrder, orderBy: keyof T | string) {
  const comparator = getComparator(cols, order, orderBy);

  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginBottom: theme.spacing(2),
      overflow: "visible",
    },
    selectedItems: {
      paddingLeft: theme.spacing(1),
      marginBottom: 0,
      minHeight: 40,
    },
    title: {
      flex: 1,
      lineHeight: "normal",
    },
    actions: {
      flex: "1 1 auto",
      lineHeight: "normal",
    }
  })
);

interface TableHeaderProps<T> {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  selectedActions?: React.ReactNode;
  hideSort?: boolean;
  hideSelect?: boolean;
  onRequestSort: (event: React.MouseEvent<unknown>, col: TableColumn<T>) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: SortOrder;
  orderBy?: keyof T | string;
  rowCount: number;
  columns: TableColumn<T>[];
  enableRowReordering?: boolean;
  enableExpandedRow?: boolean;
}

function SelectedActionsToolbar<T>(props: TableHeaderProps<T>) {
  const classes = useToolbarStyles();
  const { numSelected, rowCount, selectedActions } = props;

  return (
    <Toolbar disableGutters={true} className={clsx(classes.root, numSelected && classes.selectedItems)}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography color="primary" variant="body2">
            {numSelected}/{rowCount} selected
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.actions}>{selectedActions}</div>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

function TableHeader<T>(props: TableHeaderProps<T>) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    hideSort,
    hideSelect,
    onRequestSort,
    columns,
    enableRowReordering,
    enableExpandedRow
  } = props;

  const handleSort = (col: TableColumn<T>) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, col);
  };

  return (
    <TableHead>
      <TableRow className={clsx(!hideSelect && numSelected && classes.highlighted)}>
        {enableRowReordering && (
          <TableCell style={{ width: "42px" }}></TableCell>
        )}
        {
          enableExpandedRow &&
          <TableCell style={{ width: "42px" }}></TableCell>
        }
        {!hideSelect && (
          <TableCell style={{ paddingLeft: "8px", width: numSelected ? undefined : "42px" }} colSpan={numSelected ? columns.length + 1 : 1}>
            <Grid container alignItems="center">
              <Grid item>
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all" }}
                />
              </Grid>

              {numSelected ? (
                <Grid item>
                  <SelectedActionsToolbar {...props} />{" "}
                </Grid>
              ) : null}
            </Grid>
          </TableCell>
        )}
        {!hideSelect && numSelected
          ? null
          : columns.map((col, colIndex) => (
            <TableCell
              key={col.altKey || col.keyProp?.toString() || colIndex}
              align={col.align || "left"}
              padding={col.disablePadding ? "none" : "default"}
              sortDirection={col.hideSort ? undefined : orderBy === (col.altKey || col.keyProp) ? order : false}
            >
              {hideSort || col.hideSort ? (
                <span>{col.label}</span>
              ) : (
                <TableSortLabel
                  active={orderBy === (col.altKey || col.keyProp)}
                  direction={orderBy === (col.altKey || col.keyProp) ? order : "asc"}
                  onClick={handleSort(col)}
                >
                  {col.label}
                  {orderBy === (col.altKey || col.keyProp) ? (
                    <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

const usePaginationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      overflow: "visible",
    },
    toolbar: {
      paddingLeft: 0,
      paddingRight: 0,
      overflow: "visible",
    },
    actions: {
      marginLeft: theme.spacing(2),
      marginRight: -theme.spacing(1),
    },
    selectRoot: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  })
);

interface TableToolbarProps {
  id?: string;
  header?: string;
  actions?: React.ReactNode;
  actionsSize?: GridSize;
}

const TableToolbar = (props: TableToolbarProps) => {
  const classes = useToolbarStyles();
  const { header, actions, id, actionsSize } = props;

  return (
    <Toolbar id={id} disableGutters={true} className={clsx(classes.root)}>
      <Grid container spacing={2}>
        {header && (
          <Grid item>
            <Typography className={clsx(classes.title)} variant="h5" id="tableTitle">
              {header}
            </Typography>
          </Grid>
        )}
        {actions && (
          <Grid item className={classes.actions}>
            {actions ? actions : null}
          </Grid>
        )}
      </Grid>
    </Toolbar>
  );
};

const dropTransitionTime = 500;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    table: {
      minWidth: 750,
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    spacedRow: {
      "&>*:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    spaceBetweenRow: {
      justifyContent: "space-between",
    },
    name: {
      minWidth: 180,
      maxWidth: 200,
    },
    pagination: {
      paddingRight: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    highlighted: {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.light}55`,
    },
    tr: {
      verticalAlign: "inherit",
    },
    td: {
      verticalAlign: "inherit",
    },
    dragging: {
      opacity: ".4"
    },
    "dragging--over": {
      backgroundColor: `${theme.palette.success.light} !important`,
      outline: `1px dotted ${theme.palette.success.main}`,
      opacity: ".8",
    },
    dropped: {
      backgroundColor: `${theme.palette.primary.light} !important`,
      transition: `${dropTransitionTime}ms`
    },
    "dropped--done": {
      backgroundColor: "transparent !important",
    }
  })
);

function Table<T>(props: TableProps<T>) {
  const {
    header,
    headerContent,
    actions,
    actionsSize,
    selectedActions,
    items,
    keyProp,
    columns,
    pageLimits,
    page: pageProp,
    limit: limitProp,
    total: totalProp,
    hideSort,
    hideSelect,
    onSort,
    onChangePage,
    selectedKeys,
    disabledKeys,
    onSelectKeys,
    id,
    containerProps,
    enableRowReordering,
    onRowReorder,
    onRowExpanded,
    renderExpandedRow
  } = props;

  const tableId = id || "table";
  const classes = useStyles();
  const paginationClasses = usePaginationStyles();

  const [order, setOrder] = React.useState<SortOrder>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T | string>();
  const [selected, setSelected] = React.useState<any[]>(selectedKeys || []);
  const [page, setPage] = React.useState(pageProp || 1);
  const [limit, setLimit] = React.useState(limitProp != null ? limitProp : pageLimits != null ? pageLimits[0] : items.length);
  const [total, setTotal] = React.useState(totalProp != null ? totalProp : items.length);

  useEffect(() => {
    setPage(pageProp || 1);
  }, [pageProp]);

  useEffect(() => {
    setLimit(limitProp != null ? limitProp : pageLimits != null ? pageLimits[0] : items.length);
  }, [items.length, limitProp, pageLimits]);

  useEffect(() => {
    setTotal(totalProp != null ? totalProp : items.length);
  }, [items, totalProp]);

  useEffect(() => {
    // Clear selected any time the list is changed
    setSelected([]);
  }, [items]);

  // Keep parent selected in sync
  useEffect(() => {
    if (!onSelectKeys) {
      return;
    }

    // Delay sending selected to parent so it stays in sync
    // but does not impact performance of this component
    const delayTimer = setTimeout(() => {
      onSelectKeys(selected);
    }, 500);

    // clear the timeout on unmount or when the selected value changes
    return () => {
      clearTimeout(delayTimer);
    };
  }, [onSelectKeys, selected]);

  const handleSort = useCallback(
    (event: React.MouseEvent<unknown>, col: TableColumn<T>) => {
      const prop = col.altKey || (col.keyProp as string);

      const isAsc = orderBy === prop && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(prop);

      if (onSort) {
        onSort(prop, isAsc);
      }
    },
    [orderBy, order, setOrderBy, setOrder, onSort]
  );

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSelected = event.target.checked ? items.map((n, i) => (keyProp ? n[keyProp] : Array.isArray(n) ? n[i] : (n as any))) : [];
      setSelected(newSelected);
    },
    [setSelected, items, keyProp]
  );

  const handleSelectRow = useCallback(
    (key: any) => () => {
      setSelected((prevSelected) => {
        const newSelected = prevSelected.includes(key) ? prevSelected.filter((i) => i !== key) : [...prevSelected, key];

        return newSelected;
      });
    },
    [setSelected]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (onChangePage) {
        setSelected([]);
        onChangePage(newPage + 1, limit);
        return;
      }

      setPage(newPage + 1);
    },
    [onChangePage, setSelected, limit]
  );

  const handleChangeLimit = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangePage) {
        setSelected([]);
        onChangePage(1, parseInt(event.target.value));
        return;
      }

      setLimit(parseInt(event.target.value, 10));
      setPage(1);
    },
    [onChangePage, setSelected, setLimit, setPage]
  );

  const isSelected = (key: any) => selected.indexOf(key) !== -1;

  const Pagination = useMemo(
    () => (
      <TablePagination
        classes={paginationClasses}
        rowsPerPageOptions={pageLimits}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeLimit}
      />
    ),
    [paginationClasses, pageLimits, total, limit, page, handleChangePage, handleChangeLimit]
  );

  const [dragActiveIndex, setDragActiveIndex] = React.useState<number>(-1);
  const [dragEnterIndex, setDragEnterIndex] = React.useState<number>(-1);
  const [didDragHandle, setDidDragHandle] = React.useState<boolean>(false);
  const [expandedRowId, setExpandedRowId] = React.useState<string>("");

  return (
    <div id={`${tableId}-container`} className={clsx(classes.root)}
      onMouseDown={(e: any) => {
        setDidDragHandle(!!(e.target as HTMLElement).closest(".drag-handle"));
      }}
      onDragStart={(e: DragEvent) => {
        if (!didDragHandle) {
          e.preventDefault();
          return;
        }

        const dragElement = e.target as HTMLElement;
        const dragElementIndex = Array.from(dragElement.parentElement?.children || []).indexOf(dragElement);

        setDragActiveIndex(dragElementIndex);
        dragElement.classList.add(classes.dragging);
        e.dataTransfer.effectAllowed = "move";
      }}
      onDragEnter={(e: DragEvent) => {
        const itemElement = (e.target as HTMLElement).closest("tr");
        setDragEnterIndex(Array.from(itemElement?.parentElement?.children || []).indexOf(itemElement!));

        if (dragEnterIndex !== dragActiveIndex) {
          itemElement?.classList.add(classes["dragging--over"]);
        }
      }}
      onDragLeave={(e: DragEvent) => {
        const itemElement = (e.target as HTMLElement).closest("tr");
        const dragLeaveIndex = Array.from(itemElement?.parentElement?.children || []).indexOf(itemElement!);

        // This event fires when dragging over child elements (ANNOYING!), so make sure we've left the actual
        // item element before remove the class
        if (dragEnterIndex !== dragLeaveIndex) {
          itemElement?.classList.remove(classes["dragging--over"]);
        }
      }}
      onDragEnd={(e: DragEvent) => {
        const itemElement = (e.target as HTMLElement).closest("tr");
        itemElement?.classList.remove(classes.dragging);

        const dragEnterElement = itemElement?.parentElement?.children.item(dragEnterIndex);
        dragEnterElement?.classList.remove(classes["dragging--over"]);
      }}
      onDrop={(e: DragEvent) => {
        const dropElement = (e.target as HTMLElement).closest("tr");
        const dropIndex = Array.from(dropElement?.parentElement?.children || []).indexOf(dropElement!);

        const reorderedItems: any[] = [];

        items.forEach((item, index) => {
          if (index === dragActiveIndex) {
            return;
          }

          reorderedItems.push(item);
        });

        reorderedItems.splice(dropIndex, 0, items[dragActiveIndex]);

        if (onRowReorder) {
          // Wait for all events in the queue to finish so the drag event handlers run and update the styles correctly
          setTimeout(() => {
            dropElement?.classList.add(classes.dropped);

            onRowReorder({
              originalIndex: dragActiveIndex,
              newIndex: dropIndex,
              originalItems: items,
              reorderedItems: reorderedItems
            });
          });
        }
      }}>
      {(header || actions) && <TableToolbar id={`${tableId}-header`} header={header} actions={actions} actionsSize={actionsSize} />}
      <Grid container alignItems="flex-start" justify={headerContent ? "space-between" : "flex-end"}>
        {headerContent ?
          (<Grid item>
            {headerContent}
          </Grid>)
          : null
        }
        <Grid item>
          {total > 0 && pageLimits != null && Pagination}
        </Grid>
      </Grid>
      <TableContainer {...(containerProps || {})}>
        <MuiTable id={tableId} className={classes.table} aria-labelledby="tableTitle" size="small" aria-label={header}>
          <TableHeader
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            selectedActions={selectedActions}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleSort}
            rowCount={items.length}
            hideSort={hideSort}
            hideSelect={hideSelect}
            columns={columns}
            enableRowReordering={enableRowReordering}
            enableExpandedRow={!!onRowExpanded}
          />
          <TableBody>
            {(onSort || hideSort || !orderBy ? items : stableSort(items, columns, order, orderBy))
              .slice(pageProp ? 0 : (page - 1) * limit, pageProp ? items.length : (page - 1) * limit + limit)
              .map((row, rowIndex) => {
                const itemId: any = keyProp ? row[keyProp] : Array.isArray(row) ? row[rowIndex] : row;

                const isItemSelected = !hideSelect && isSelected(itemId);
                const labelId = `${tableId}-checkbox-${rowIndex}`;
                const canSelect = !disabledKeys?.includes(itemId);

                return (
                  <Fragment key={`${itemId}-row`}>
                    <TableRow
                      hover
                      onClick={!canSelect ? undefined : handleSelectRow(itemId)}
                      role={!hideSelect && canSelect ? "checkbox" : "row"}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={rowIndex}
                      selected={isItemSelected}
                      className={classes.tr}
                      onDragOver={(e: DragEvent) => {
                        // Allow as a drop target
                        e.preventDefault();
                      }}
                      onTransitionEnd={(e) => {
                        const element = e.target as HTMLElement;

                        if (element.classList.contains(classes["dropped--done"])) {
                          // Wait for the transition to finish before cleaning up the drop classes
                          setTimeout(() => {
                            element.classList.remove(classes.dropped);
                            element.classList.remove(classes["dropped--done"]);
                          }, dropTransitionTime);
                        } else if (element.classList.contains(classes.dropped)) {
                          element.classList.add(classes["dropped--done"]);
                        }
                      }}
                      draggable={true}
                    >
                      {enableRowReordering && (
                        <TableCell>
                          <DragIndicator className="drag-handle" style={{ cursor: "grab" }} />
                        </TableCell>
                      )}
                      {
                        onRowExpanded &&
                        <TableCell style={{ width: "42px" }}>
                          <ArrowDropDown style={{ transform: `rotate(${expandedRowId === itemId ? "180deg" : "0"})`, cursor: "pointer" }} onClick={(e) => {
                            const isCollapsed = expandedRowId === itemId;
                            setExpandedRowId(isCollapsed ? "" : itemId);

                            if (!isCollapsed) {
                              onRowExpanded(row, rowIndex);
                            }

                            e.stopPropagation();
                          }} />
                        </TableCell>
                      }
                      {!hideSelect && (
                        <Tooltip title={canSelect ? "" : `You cannot`}>
                          <TableCell style={{ paddingLeft: "8px", width: "42px" }} className={classes.td}>
                            <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                          </TableCell>
                        </Tooltip>
                      )}
                      {columns.map((col, colIndex) => (
                        <TableCell className={classes.td} key={col.altKey || col.keyProp?.toString() || colIndex} align={col.align || "left"}>
                          <>{col.renderCell ? col.renderCell(row) : col.keyProp ? row[col.keyProp] : Array.isArray(row) ? row[colIndex] : row}</>
                        </TableCell>
                      ))}
                    </TableRow>
                    {expandedRowId === itemId &&
                      <TableRow key={`${itemId}-expanded`} selected={true}>
                        <TableCell style={{ width: "42px" }}></TableCell>
                        <TableCell colSpan={columns.length} key={`${itemId}-details-cell`}>
                          <div style={{ position: "relative" }}>
                            {renderExpandedRow?.(row, rowIndex)}
                          </div>
                        </TableCell>
                      </TableRow>
                    }
                  </Fragment>
                );
              })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {total > 0 && pageLimits != null && Pagination}
    </div >
  );
}

export default Table;
